import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-location-confirmation',
  templateUrl: './modal-location-confirmation.component.html',
  styleUrls: ['./modal-location-confirmation.component.scss']
})
export class ModalLocationConfirmationComponent implements OnDestroy {

  @ViewChild("locationConfirmationModal") LocationConfirmationModal: TemplateRef<any>;

  @Input() locationName : string;
  @Output() onCorrectLocation: EventEmitter<any> = new EventEmitter();
  @Output() onIncorrectLocation: EventEmitter<any> = new EventEmitter();
  modalOpen: boolean = false;

  constructor(private modalService: NgbModal) { }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

  async confirmCorrectLocation() {
    this.modalOpen = true;
    this.modalService.open(this.LocationConfirmationModal, { 
      size: 'lg',
      ariaLabelledBy: 'LocationConfirmation-Modal',
      centered: true,
      windowClass: 'theme-modal location-confirmation-modal LocationConfirmationModal',
      backdrop: 'static'
    }).result.then((result) => {
      `Result ${result}`
    }); 
  }

  correctLocationClick() {
    this.modalOpen = false;
    this.modalService.dismissAll();
    this.onCorrectLocation.emit();
  }

  incorrectLocationClick() {
    this.modalOpen = false;
    this.modalService.dismissAll();
    this.onIncorrectLocation.emit();
  }
}
