import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../data.service';
import { GoogleAnalyticService } from '../google-analytic.service';
import { OrderRoutingService } from '../order-routing.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class GuestOrderReceiptComponent {
  orderGuid: string = "";
  receipt: any;
  status: any;
  statusDate : string;
  statusTime : string;
  pickupTSDate : string;
  pickupTSTime : string;
  placedTSDate : string;
  placedTSTime : string;
  pickUpType : string;
  pickUpInfo : string;
  deliveryAddress: string;
  merchantNotes: string;
  currencySymbol: any;

  constructor(
    public route: ActivatedRoute,
    private loadingController: LoadingController,
    private translate: TranslateService,
    private dataService: DataService,
    private ga: GoogleAnalyticService,
    private orderRouting: OrderRoutingService
  ) { }

  async ionViewDidEnter() {
    this.orderGuid = this.route.snapshot.params['guid'];  


    const loading = await this.loadingController.create({
      message: this.translate.instant('Loading...')
    });

    await loading.present();
    //call api to get the data for receipt
    if (this.orderGuid){
      let merchantGuid = sessionStorage.getItem("guid");
      this.dataService.getGuestOrderDetails(this.orderGuid, merchantGuid)
        .subscribe(
          (data) => { 
              console.log(data);
              this.receipt = data.receipt;
              this.currencySymbol = this.receipt.currencySymbol;
              this.status = data.lastStatus;
              this.statusDate = data.lastStatusUpdatedOnDate;
              this.statusTime =  (data.lastStatusUpdatedOnTime != null) ? data.lastStatusUpdatedOnTime.toString().replace(/^0+/, "") : "";
              this.pickupTSDate = data.pickupTSDate;
              this.pickupTSTime = (data.pickupTSTime != null) ?  data.pickupTSTime.toString().replace(/^0+/, "") : "";
              this.placedTSDate = data.placedTSDate;
              this.placedTSTime =  (data.placedTSTime != null) ?  data.placedTSTime.toString().replace(/^0+/, "") : "";
              this.pickUpType = data.pickUpType;
              this.pickUpInfo = data.pickUpInfo;
              this.deliveryAddress = data.deliveryAddress;
              this.merchantNotes = data.merchantNotes;
              this.ga.sendEvent('receipt_viewed','');
              loading.dismiss()
          },
          (err) => { 
            loading.dismiss();
            alert(err.message);
          } 
        )
    }
  }

  back(){
    if (this.status == "NEW" || this.status == "ACCEPTED" || this.status == "FULLFILLED") {
      this.orderRouting.goToGuestOrderStatus(this.orderGuid);
    } 
    else {
      this.orderRouting.goToLocation();
    }
  }

}
