import { OrderModifier } from './order-modifiers';

export class OrderItem {
    constructor(itemId:number, quantity:number, notes: string, source: string){
        this.id = itemId;
        this.quantity = quantity;
        this.modifiers = [];
        this.notes = notes;
        this.source = source;
    }
    id: number;
    quantity: number;
    notes: string;
    modifiers: OrderModifier[];
    source: string;
}