import { Component, OnInit } from '@angular/core';
import { CustomerSessionService } from '../customer-session.service';
import { LoadingController } from '@ionic/angular';
import { DataService } from '../data.service';
import { Location } from '@angular/common';
import { GoogleAnalyticService } from '../google-analytic.service';

@Component({
    selector: 'app-nmi-payment',
    templateUrl: './nmi-payment.component.html',
    styleUrls: ['./nmi-payment.component.scss']
})

export class NMIPaymentComponent implements OnInit {
    constructor(private customerSession: CustomerSessionService,
        private loadingController: LoadingController,
        private dataService: DataService,
        private ga: GoogleAnalyticService,
        private location: Location) { }

    cardHolderName: string = "";
    loading: any;

    async ngOnInit() {
        this.loadCollectJSWithToken();
        setTimeout(() => { this.configureCollectJS() }, 1000);

        this.loading = await this.loadingController.create({
            message: 'Adding Card...'
        });
    }

    async loadCollectJSWithToken() {
        let node = document.createElement('script');
        node.src = 'https://secure.networkmerchants.com/token/Collect.js';
        node.type = 'text/javascript';
        node.setAttribute('data-tokenization-key', await this.customerSession.getNMIToken());
        node.setAttribute('data-variant', 'inline');
        node.setAttribute('data-field-ccnumber-placeholder', '•••• •••• •••• ••••');
        node.setAttribute('data-field-ccexp-placeholder', 'MM/YYYY');
        node.setAttribute('data-field-cvv-placeholder', 'CVV');
        node.setAttribute('data-validation-callback', "(function (field, valid, message) {console.log(field + ': ' + valid + ' -- ' + message)})");
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    // There's a chance that the DOM isn't fully loaded with the new script
    // and CollectJS isn't available on window right away, keeps retrying.
    configureCollectJS() {
        if (window.CollectJS) {
            window.CollectJS.configure({
                callback: this.NMITokenCallback.bind(this),
                invalidCss: {
                    "color": "white",
                    "background-color": "rgb(251, 61, 103)"
                },
            });
        }
        else {
            setTimeout(() => { this.configureCollectJS() }, 1000);
        }
    }

    async submitForTokenization() {
        await this.loading.present();
        window.CollectJS.startPaymentRequest();
    }

    async NMITokenCallback(nmiResult) {
        this.dataService.addCard(nmiResult.token, this.cardHolderName, null, this.customerSession.locationId).subscribe((result) => {
            this.loading.dismiss();
            console.log('add card result object is:', result);
            //navigate back to manage - TODO: make sure this is successful
            if (result.isSuccess) {
                this.ga.sendEvent('payment_card_added', '');
                this.location.back();
            } else {
                if (result.message && result.message != "General Error") {
                    alert(result.message);
                } else {
                    alert('Sorry Something Went Wrong When Trying To Add Your Card, Please Try Again.');
                }
            }
        }, 
        () => {},
        () =>{ this.loading.dismiss(); });

    }
    back() {
        this.location.back();
    }
}