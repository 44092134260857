export class ApplePaymentData {
    currencySymbol: string;
    countryCode: string;
    currencyCode: string;
    lineItems: ApplePayLineItem[];
    shippingMethods: ShippingMethod[];
    total: ApplePayLineItem;
    supportedNetworks: string[];
    merchantCapabilities: string[];
    requiredShippingContactFields: string[];
    requiredBillingContactFields: string[];
    applicationData: string;
    fees: OOFees[];
    walletBalanceApplied: number;
    giftCardBalanceApplied: number;
    ooMerchantLocationId: number;
    logoUrl: string;
}

export class ApplePayLineItem {
    type: string;
    label: string;
    amount: number;
}

export class ShippingMethod {
    label: string;
    amount: string;
    identifier: string;
    detail:  string;
}

export class OOFees {
    feeType: string;
    amount: string;
    name: string;
}
