import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { DataService } from '../data.service';
import { OrderRoutingService } from '../order-routing.service';
import { Location } from '@angular/common';
import { LoadingController } from '@ionic/angular';
import { CustomerSessionService } from '../customer-session.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'my-orders',
    templateUrl: './my-orders.component.html',
    styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent {

    limit: number;
    offset: number;
    orders: any[] = [];
    isMobile;
    hasOrders: boolean = true;

    constructor(public router: Router,
        private dataService: DataService,
        private orderRouting: OrderRoutingService,
        private loadingController: LoadingController,
        private customerSession: CustomerSessionService,
        private location: Location,
        private translate: TranslateService) { }

    ionViewWillEnter() {
        this.orders = [];
    }

    ionViewDidEnter() {

        this.customerSession.mobileMode.subscribe((data) => {
            console.log("DASHBOARD COMPONENT SUBSCRIPTION FOR MOBILE MODE: " + data);
            if (data) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        });

        this.limit = 10;
        this.offset = 0;
        this.loadData().subscribe();

       const today =  this.isDateToday("5/24/2020")
       console.log("Is it Today " + today);

       const yesterday =  this.isDateYesterDay("5/24/2020")
       console.log("Is it YesterDay " + yesterday);
      
    }

    doRefresh(event) {
        this.orders = [];
        this.loadData().subscribe(
            (data) => event ? event.target.complete() : "",
            (err) => event ? event.target.complete() : ""
        )
    }

    async doRefreshByButton() {
        this.orders = [];
        const loading = await this.loadingController.create({
            message: this.translate.instant('Refreshing Orders...')
          });
    
            await loading.present();
            this.loadData().subscribe(
                (data) =>
                {                    
                    loading.dismiss();
                }, 
                (err) => {                    
                     loading.dismiss();
                }                 
            )    
        }
    


    loadData() {
        return this.dataService.getOrdersHistory(this.limit, this.offset)
            .pipe(tap((result) => {
                result.orders.forEach((order) => {
                    order.active = this.isOrderActive(order.lastStatus)
                    if (order.active) {
                        order.title = this.translate.instant("Order in Progress");
                        if(this.isDateToday(order.orderPlacedDate)) 
                        {
                            order.subtitle = `${this.translate.instant('Order placed')} ${'Today' +  ' ' +  order.orderPlacedTime}`;
                        }
                        if(this.isDateYesterDay(order.orderPlacedDate)) 
                        {
                            order.subtitle = `${this.translate.instant('Order placed')} ${'Yesterday' +  ' ' +  order.orderPlacedTime}`;
                        }                        
                        else if (!this.isDateToday(order.orderPlacedDate) && !this.isDateYesterDay(order.orderPlacedDate))
                        {
                            order.subtitle = `${this.translate.instant('Order placed on')} ${order.orderPlacedDate +  ' ' +  order.orderPlacedTime}`;
                        }                        
                    }
                    else {
                        order.title = `${this.translate.instant('Order No.')} ${order.orderNumber}`;
                        order.subtitle = `${this.translate.instant(this.capitalizeFirstLetter(order.lastStatus))} - ${order.orderPlacedDate}`;
                        if (order.lastStatus !== 'COMPLETED') {
                            order.color = "red";
                        }
                    }
                })
                this.orders = this.orders.concat(result.orders);
                if(this.orders.length < 0) 
                {
                    this.hasOrders = false;
                }
            }))
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    isOrderActive(lastStatus) {
        if (lastStatus == "NEW" || lastStatus == "ACCEPTED" || lastStatus == "FULLFILLED") {
            return true;
        }
        return false;
    }

    selectOrder(order) {
        if (order.active) {
            this.orderRouting.goToOrderStatus(order.orderId);
        }
        else {
            this.orderRouting.goToOrderReceipt(order.orderId);
        }
    }

    back() {
        this.location.back();
    }

    isDateToday(someDate) 
    {
        const dateToEval = new Date(someDate);
        const today = new Date()
        return dateToEval.getDate() == today.getDate() &&
        dateToEval.getMonth() == today.getMonth() &&
        dateToEval.getFullYear() == today.getFullYear()
    }

    isDateYesterDay(someDate) 
    {
        const dateToEval = new Date(someDate);
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        today.toDateString()

        return dateToEval.getDate() == yesterday.getDate() &&
        dateToEval.getMonth() == yesterday.getMonth() &&
        dateToEval.getFullYear() == yesterday.getFullYear()        
    }
}
