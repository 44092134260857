import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../cart.service';
import { OrderRoutingService } from '../order-routing.service';

@Component({
  selector: 'redeemables',
  templateUrl: './redeemables.component.html',
  styleUrls: ['./redeemables.component.scss']
})
export class RedeemablesComponent {
  
  availableRedeemables: any[] = [];
  unavailableRedeemables: any[] = [];

  selectedRedeemable: any;
  constructor(private cartService: CartService, public router: Router, private orderRouting: OrderRoutingService) { }

  ionViewWillEnter(){
    this.availableRedeemables = [];
    this.unavailableRedeemables = [];

    this.availableRedeemables = this.cartService.order.redeemables.filter(x => x.isAvailable === true);  
    this.unavailableRedeemables = this.cartService.order.redeemables.filter(x => x.isAvailable === false);  
    
    if(this.cartService.getAppliedRedeemable()){
       let appliedRedeemable = this.availableRedeemables.filter(x => x.code === this.cartService.getAppliedRedeemable());
       if (appliedRedeemable && appliedRedeemable.length > 0) {
        this.selectedRedeemable = appliedRedeemable[0];
       }
    }
  }
  
  ionViewDidEnter() {
    
  }

  navigateToCart(){
    this.orderRouting.goToCart();
  }

  applyRedeemable(){
    if(this.selectedRedeemable == null) return;
    this.cartService.applyRedeemable(this.selectedRedeemable.code);
    this.navigateToCart();
  }

  back(){
    this.navigateToCart();
  }

   /* Accessibility Implementation for Key Press */
   eventHandler(event)  {
    try {
      if (event.keyCode == 13) {
        let el = `#${event.currentTarget.id}`;
        let btn =  $(el)[0].children[1] as HTMLElement;
       btn.click();
      }               
    } catch (error) {
        
    }      
   }
}
