import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OrderRoutingService } from '../order-routing.service';
import { AuthService } from '../auth/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AlertPopupComponent } from '../common/alert-popup/alert-popup.component';
import { AssetService } from '../merchant-assets/asset.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { orderByDistance } from 'geolib';
import { MembershipRoutingService } from '../membership-routing.service';
import { UtilService } from '../util.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-connect-in-store',
    templateUrl: './connect-in-store.component.html',
    styleUrls: ['./connect-in-store.component.scss']
})
export class ConnectInStoreComponent implements OnInit  {

    registerForm : FormGroup;
    name : FormControl;
    email : FormControl;
    password : FormControl;
    cpassword : FormControl;
    phone : FormControl;
    agreeToCommunication : FormControl;
    agreeToTerms : FormControl;
    isUnloading = false;
    CURRENT_VIEW_ENTERED = "connect-in-store";
    merchantGuid: string;
    membershipModule: boolean = false;

    siteKey: string = environment.recaptchaSiteKey;

    @ViewChild('alertPopup') alertPopup: AlertPopupComponent;

    constructor(private authService: AuthService,
        private orderRouting: OrderRoutingService,  
        private fb: FormBuilder, 
        private assetService: AssetService, 
        private spinner: NgxSpinnerService, 
        private membershipRouting: MembershipRoutingService,
        private utilService: UtilService,
        private translate: TranslateService) { }
        
  async ionViewWillEnter() 
  {
    this.isUnloading = false;   
    /* LOAD CUSTOM STYLE IF PROVIDED - <param - component name> */     
    if (sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) && sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) !== "") {
        this.assetService.getCustomCSS(sessionStorage.getItem(this.CURRENT_VIEW_ENTERED), this.CURRENT_VIEW_ENTERED);
    }
  }

  async ionViewWillLeave() 
  {
    this.spinner.show();
    this.isUnloading = true;   
    const clearCustomCss = () => new Promise<boolean>(async (resolve, reject) => { $("#" + this.CURRENT_VIEW_ENTERED + "_CUSTOM_CSS").remove(); return resolve(true)});  
    await clearCustomCss().then((result) => console.log("CLEARED CUSTOM CSS FOR " + this.CURRENT_VIEW_ENTERED)).then(() => {
        setTimeout(() => {
            this.spinner.hide();
        }, 1000); 
    });    
  }
  
    ngOnInit() {      
        this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;

        this.name = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(2)]);
        this.email = new FormControl('', [Validators.required, Validators.email]);
        this.password = new FormControl('', [Validators.required, Validators.maxLength(15), Validators.minLength(5)]);
        this.cpassword = new FormControl('', [Validators.required, this.authService.MustMatch(this.password)]);
        this.phone = new FormControl('', [Validators.required, this.utilService.validatePhoneNumber]);       
        this.agreeToTerms = new FormControl('false', [Validators.requiredTrue]);
        this.agreeToCommunication = new FormControl('false', [Validators.requiredTrue]);
        if (this.membershipModule) {
            this.merchantGuid = this.membershipRouting.guid;
        } else {
            this.merchantGuid = this.orderRouting.guid;
        }

        this.registerForm = this.fb.group({    
            'name' : this.name,       
            'email' : this.email,
            'password' : this.password,
            'cpassword' : this.cpassword,
            'phone' : this.phone,            
            'agreeToTerms' : this.agreeToTerms,
            'agreeToCommunication' : this.agreeToCommunication       
          });

    }

    async connect() {
        
        let userDetails = this.registerForm.value;
        console.log(userDetails);

        if (this.registerForm.controls['agreeToCommunication'].value == false) {
            $("#agreeToCommunicationlbl").addClass("text-danger");
            return;
        }

        if (this.registerForm.controls['agreeToTerms'].value == false) {
            $("#agreeToTermslbl").addClass("text-danger");
            return;
        }

        //validation - ends

        // get action token
        const recaptchaAction = 'ConnectInStoreAccount'
        const token = await (<any>window).grecaptcha.enterprise.execute(this.siteKey, {action: recaptchaAction});

        var connectInStoreAccountRequest = {
            name: userDetails.name,
            email: userDetails.email,
            password: userDetails.password,
            mobile: userDetails.phone,
            tosAccepted: userDetails.agreeToTerms,
            recaptchaToken: token,
            recaptchaAction: recaptchaAction
        }
        //console.log('Connect In-store request - ', connectInStoreAccountRequest);

        this.authService.connectInStoreAccount(connectInStoreAccountRequest).subscribe((data) => {
            console.log(data);
            if (this.membershipModule) {
                this.membershipRouting.goBack();
            } else {
                this.orderRouting.goBack();
            }
        }, (err) => {
            console.log('got an error trying to connect the account', err);
            if (err.status != 401) {
                this.alertPopup.show(this.translate.instant("Error!"), err.error.message); 
            }
        });
    }

    goToRegisterKeytag() {
        if (this.membershipModule) {
            this.membershipRouting.goToRegisterKeytag();
        } else {
            this.orderRouting.goToRegisterKeytag();
        }
    }

    back() {
        if (this.membershipModule) {
            this.membershipRouting.goToLogin();
        } else {
            this.orderRouting.goToLogin();
        }
    }


}
