import { Observable, defer, isObservable, of, Subject } from 'rxjs';
import { shareReplay, first, mergeMap } from 'rxjs/operators';

export class RenewableCache {

	private returnObs$: Observable<any>;

	renewableCacheAfterTimer(obs: Observable<any>, time: number, bufferReplays: number = 1) {
		if (this.returnObs$) return this.returnObs$;

		let shared$: Observable<any>;

		const createReturnObs = () =>
			shared$ ||
			(shared$ = obs.pipe(shareReplay(bufferReplays, time)));

		this.returnObs$ = defer(createReturnObs).pipe(
			first(null, defer(() => {
				shared$ = null;
				return createReturnObs();
			})),			
			mergeMap(d => (isObservable(d) ? d : of(d))),
		);

		return this.returnObs$;
	}
}