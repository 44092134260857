import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from 'src/app/cart.service';
import { CatalogService } from 'src/app/catalog.service';
import { UtilService } from 'src/app/util.service';

@Component({
  selector: 'app-modal-item-edit',
  templateUrl: './modal-item-edit.component.html',
  styleUrls: ['./modal-item-edit.component.scss']
})

export class ModalItemEditComponent implements OnInit, AfterViewInit, OnDestroy {

  public modalOpen: boolean = false;
  public closeResult: string;
  @Input() lineItemIndex : number;
  @Input() removeCartItemFunction: Function;

  item : any;

  @ViewChild("itemEditModal") ItemEditModal: TemplateRef<any>;

  constructor( private modalService: NgbModal) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
   
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

  async confirmItemRemoval() {
    this.modalOpen = true;
    console.log(this.lineItemIndex);
    this.modalService.open(this.ItemEditModal, { 
      size: 'sm',
      ariaLabelledBy: 'ItemEdit-Modal',
      centered: true,
      windowClass: 'theme-modal item-edit-modal ItemEditModal'
    }).result.then((result) => {
      `Result ${result}`
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });    
    
  }

  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async removeCartitem() {
    this.modalOpen = false;
    this.modalService.dismissAll();
    await this.removeCartItemFunction(this.lineItemIndex);
  }

}
