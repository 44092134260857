import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ErrorHandlerService } from './error-handler.service';
import { MembershipPurchasePayment } from './models/membership-purchase-payment';
import { MembershipRoutingService } from './membership-routing.service';
import { DecimalPipe } from '@angular/common';
import { AlertController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MembershipPaymentService {
  membership: any;
  paymentDetails: MembershipPurchasePayment = new MembershipPurchasePayment();
  currencySymbol: string = "$"; 
  loadingMessage: string = "Purchasing membership...";

  constructor(
    private dataService: DataService,
    private loadingController: LoadingController,
    private errorHandlerService: ErrorHandlerService,
    private alertController: AlertController,
    private membershipRouting: MembershipRoutingService,
    private decimalPipe: DecimalPipe,
  ) { }

  getPaymentBtnText(){
    if (this.membership.isCustomerMembership && this.membership.customerMembership.status == "SUSPENDED") {
      this.loadingMessage = "Updating payment method...";
      return "Update Payment Method";
    }
    this.loadingMessage = "Purchasing membership...";
    return "Start My Subscription " + this.membership.currencySymbol + this.decimalPipe.transform(this.paymentDetails.Amount, '1.2-2');
  }

  processPayment(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const loadingPurchase = await this.loadingController.create({
        message: this.loadingMessage
      });
      await loadingPurchase.present();
  
      await this.dataService.purchaseMembership(this.membership.membershipId, this.paymentDetails).toPromise().then(
        (result) => {
          console.log("data received from purchaseMembership", result);
          if (result.isSuccess) {
            loadingPurchase.dismiss();
            resolve(true);
          }
        },
        (err) => {
          loadingPurchase.dismiss();
          console.log("purchaseMembership -- error is: ", err);
          this.alertMessage(err.error.message);
          resolve(false)
        }
      );
    });
  }

  removeCard(paymentIntegrationId): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const confirm = (await this.alertController.create({
        header: 'Confirm Deletion',
        message: 'This change will affect all memberships connected to this payment method. Would you like to continue?',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: async () => {
                console.log('Confirm No to removing card');
                resolve(false);
            }
          }, {
            text: 'Yes',
            handler: async () => {
                console.log('Confirm Yes to removing card');
                const removingCardLoad = await this.loadingController.create({
                    message: 'Removing Card...'
                });

                //present loader
                await removingCardLoad.present();
                await this.dataService.deleteCardByIntegrationId(paymentIntegrationId).toPromise().then((result) => {
                    removingCardLoad.dismiss();
                    if (result.isSuccess) {
                        resolve(true);
                    }
                    else {
                        this.errorHandlerService.alertMessage("Something went wrong. Unable to remove the card.");
                        resolve(false);
                    }
                });
                return false;
            }
          }
        ]
      })).present();
    });
  }

  alertMessage(message): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
        let alert = await this.alertController.create({
            header: "Oops",
            message: message,
            buttons: [{
                text: 'OK',
                handler: () => {
                    alert.dismiss().then(() => { resolve(true); });
                    return false;
                }
            }]
        });
        alert.present();
    });
}

}
