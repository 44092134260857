import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CartService } from '../cart.service';
import { CartItem } from '../cart/cart.model';
import { OrderRoutingService } from '../order-routing.service';

@Component({
  selector: 'app-shopping-widget',
  templateUrl: './shopping-widget.component.html',
  styleUrls: ['./shopping-widget.component.scss']
})
export class ShoppingWidgetComponent implements OnInit {

  @Input() shoppingCartItems: CartItem[] = [];

  constructor(private cartService: CartService, private orderRouting: OrderRoutingService) { }

  ngOnInit() {
   
  }

  

}
