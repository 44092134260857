import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { DataService } from 'src/app/data.service';
import { ErrorHandlerService } from 'src/app/error-handler.service';
import { MembershipRoutingService } from 'src/app/membership-routing.service';

@Component({
  selector: 'membership-cancellation',
  templateUrl: './membership-cancellation.component.html',
  styleUrls: ['./membership-cancellation.component.scss']
})
export class MembershipCancellationComponent implements OnInit {
  membership: any;
  isLoading: boolean;
  cancellationReasons: any = [ 
    {label: 'It\'s too expensive', value: 'It\'s too expensive'},
    {label: 'I am not satisfied with the benefits', value: 'I am not satisfied with the benefits'},
    {label: 'Switching to another product', value: 'Switching to another product'},
    {label: 'I do not want it to auto-renew', value: 'I do not want it to auto-renew'},
    {label: 'Other', value: 'other'},
  ]
  selectedReason: string;
  otherReason: string = "";
  showConfirmation: boolean = false;
  validUntilDate: string;

  constructor(
    private membershipRouting: MembershipRoutingService,
    private dataService: DataService,
    private alertController: AlertController,
    private errorHandlerService: ErrorHandlerService,
    private loadingController: LoadingController
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.membership = this.membershipRouting.getMembership();
  }

  setCancelReason(event) {
    this.selectedReason = event.detail.value;
  }

  async cancelMembership() {
    // validate cancellation reason
    var cancellationReason = "";
    if (this.selectedReason == null) {
      this.alertMessage("Please select a reason for cancellation.");
      return false;
    } else if (this.selectedReason == "other") {
      if (this.otherReason == "") {
        this.alertMessage("Please enter a reason for cancellation.");
        return false;
      } else {
        cancellationReason = this.otherReason;
      }
    } else {
      cancellationReason = this.selectedReason;
    }

    const loadingCancel = await this.loadingController.create({
      message: 'Cancelling membership....'
    });
    await loadingCancel.present();

    this.dataService.cancelMembership(this.membership.membershipId, cancellationReason).subscribe(
      (result) => {
        console.log("data received from cancelMembership", result);
        if (result) {
          loadingCancel.dismiss();
          this.validUntilDate = result.validUntilDate;
          this.showConfirmation = true;
        } 
      },
      (err) => {
        loadingCancel.dismiss();
        console.log("cancelMembership -- error is: ", err);
        this.alertMessage(err.error.message);
      }
    );
  }

  continue() {
    this.membershipRouting.goToMembershipList();
  }

  async alertMessage(message) {
    const alert = await this.alertController.create({
        header: 'Oops',
        message: message,
        buttons: ['OK']
    });
    await alert.present();
   }

  back() {
    this.membershipRouting.goToMembershipDetails(this.membership.membershipId);
  }

}
