import { OrderItem } from './order-item';
import { OrderRedeemable } from './order-redeemable';
import { FutureOrderDateTime } from './validate-future-order-request';
import { TipDetails } from './tip-details.model';
import { DeliveryDetails } from './delivery-details.model';
import { OrderPromoCode } from './order-promo-code';

export enum MOSource {
    MOBILEAPP = <any>"MOBILEAPP",
    WEB = <any>"WEB",
}

export class PrepareOrderRequest {
    catalogId: number;
    orderId: string;
    items: OrderItem[];
    redeemables: OrderRedeemable[];
    promoCode: OrderPromoCode;
    // displayedPrice: number;
    currency: string;
    merchantLocationId: number;
    notes: string;
    source: MOSource;
    futureOrderDateTime: FutureOrderDateTime;
    curbSideOrder: boolean;
    curbSideOrderDetails: any;
    dineInOrder: boolean;
    dineInOrderDetails: any;
    tipDetails: TipDetails;
    deliveryDetails: DeliveryDetails;
    isCheckoutPage: boolean;
    token: string;
    recaptchaAction: string;
}

