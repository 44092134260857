import { Injectable, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CartService } from './cart.service';
import { CatalogService } from './catalog.service';
import { ItemTrackedService } from './common/item-tracked.service';
import { CustomerSessionService } from './customer-session.service';
import { AssetService } from './merchant-assets/asset.service';
import { OrderRoutingService } from './order-routing.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService  {
  isLoading = false;
  activatedRoute: ActivatedRoute
  merchantAssets: Promise<any>;
  showHomeButton$: Observable<boolean>;
  interval: any

  constructor(public alertCtrl: AlertController, 
    public loadingCtrl: LoadingController,
    private orderRouting: OrderRoutingService, 
    private cartService: CartService,
    private customerSession: CustomerSessionService,
    private assetService: AssetService,
    private catalogService: CatalogService,
    private translate: TranslateService) {     
     clearInterval(this.interval);
     this.interval = setInterval(() => this.makeRequest(), 500);
    }

  sethomeButton(val: boolean) {
    this.showHomeButton$ = of(val)
  }

    
  /* Item Related Methods */  
  getCurrencySymbol() 
  {
    let currencySymbol = this.catalogService.currencySymbol;
    return currencySymbol;
  }

  getCartCount() 
  {
    let cartCount = this.cartService.getCartCount();
    return cartCount;
  }

  /* Logic placed in util to avoid circular dependency */
  async clearSessionForLocationChange() : Promise<any>
  {
    return new Promise<any>(async (resolve, reject) => {
      this.catalogService.setLastSelectedCatalogId('');
      this.cartService.clearFutureOrderSettingAndData();
      this.cartService.clearCart();
      this.cartService.askedForOrderType = false;
      this.cartService.orderAhedSettings$ = null; 
      this.catalogService.defaultlastSelectedCategoryId();
      this.customerSession.removePaymentSetupInfo();    
      sessionStorage.removeItem('lastSelectedCatalogId');
      sessionStorage.removeItem('target_catalog_id');
      sessionStorage.removeItem('selected_order_type');
      sessionStorage.setItem('reload_catalog',  'true');   
      resolve(true);  
    });    
  }
 

  /* Navigation  */
  back() 
  {
    window.history.back();
  }

   /*
    Is Back Button Visible
    If user has first item added to cart we always show order-type-picker.
    User should not be able to route back unless the order-type is picked
    Call this method to determine if back button needs to be hidden or visible
  */
  isBackButtonVisible() 
  {
    let askedForOrderType = this.cartService.askedForOrderType;
    let cartCount = this.cartService.getCartCount();
    let result = true;
    if (!askedForOrderType && cartCount > 0) {
      result = false;
    }  
    if (!askedForOrderType && cartCount == 0) {
      result = true;
    }          
    return result;
  }

  /*
    Route Back
    based on from where user was redirected to catalog-picker - we have that component name stored in activated route
    Call this method to find whehe the user should be redirected after process is complete,
    extend this method if you want to add more compoments
  */
  routeBack() 
  {
    if(this.activatedRoute && (<any>this.activatedRoute.component).name == "CartComponent") 
    {
      this.orderRouting.goToCart();    
      return;            
    }
    if(this.activatedRoute && (<any>this.activatedRoute.component).name == "CheckoutComponent") 
    {
      this.orderRouting.goToCheckout();  
      return;                
    }
    else
    {
      this.orderRouting.goToCatalog();
      return;  
    }         
  }

   /*
    Document Activated Route 
    param : activatedRouteParam = pass the ActivatedRoute route object  
    Call this method in the ionViewWillEnter or ngOnInit to set the component name when the user enters the view.
    Recommended to use ionViewWillEnter as ionic 4 has bug where it caches components - so ngOnInit is not always called
  */
  documentActivatedRoute(activatedRouteParam: ActivatedRoute): void {
    this.activatedRoute = activatedRouteParam;
    console.log("ACTIVATED ROUTE => " + (<any>this.activatedRoute.component).name);
 }

  /*
    Show Loading Message
    param : msg = message to display
    Call this method to show Loading popup,
  */
  async showLoadingMessage(msg?) {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: msg,
      spinner: 'bubbles',
    }).then(a => {
      a.present().then(() => {
        //console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }


   /*
    Hide Loading Message
    Call this method to hide Loading popup,
  */
  async hideLoadingMessage() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }

    /*
    Hide Loading Message
    Call this method to hide Loading popup,
  */
    async hideLoadingMessagePromise() {
      this.isLoading = false;
      return await this.loadingCtrl.dismiss().then(() => {
        console.log('dismissed');
        return true;
      });
    }


  /*
    Show Warning Alert Message
    param : msg = message to display
    Call this method to show Warning Alert,
  */
   async showWarningAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('Warning!'),
      message: msg,
      buttons: [this.translate.instant('OK')]
    });

    await alert.present();
  }

  async showSimpleAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: '',
      message: msg,
      buttons: [this.translate.instant('OK')]
    });

    await alert.present();
  }

  /*
   Show Error Alert Message
   param : msg = message to display
   Call this method to show Error Alert,
   */
  async showErrorAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('Error!'),
      message: msg,
      buttons: [this.translate.instant('OK')]
    });

    await alert.present();
  }

  

  /* Get Merchant Assets */
  getMerchantAssets() 
  {
    if (!this.merchantAssets) {
      this.merchantAssets = this.assetService.getMerchantAssets().toPromise();
    }

    return this.merchantAssets;
    
  }

  /* Set FB Pixel vetification Tag */
  set_FB_Pixel_Verification_Meta(content: any) {
    this.assetService.setFacebookPixelVerificationMeta(content);
  }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  addhttp($url) {
    if (!/^https?:\/\//i.test($url)) {
      $url = 'http://' + $url;
    }
    return $url;
  }

  public openWithSystemBrowser(url : string){
    parent.postMessage({action: 'openExternalLink', url: url}, sessionStorage.getItem("hostingapp"));
  }

  makeRequest() {
    let guid = sessionStorage.getItem('guid');   
    if (guid && guid == "e906d179-5540-4902-8a6a-1ff441616dcc") {      
      document.getElementById('randomDiv').click();
    }    
    else if (guid && guid != "e906d179-5540-4902-8a6a-1ff441616dcc")  {     
      clearInterval(this.interval);
    }         
  }

  /* Form Phone Number Validator */
  validatePhoneNumber(c: FormControl) {
    let REGEX_NA = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; // Regular Expression North America
    let REGEX_UK = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/; // Regular Expression United Kingdom
  
    if(c.value) {
      return (REGEX_NA.test(c.value) || REGEX_UK.test(c.value)) ? null : {
          validatePhoneNumber: {
          valid: false
        }
      };
    }
  }

  postAppSpecificRequestsToFrame() {
    console.log('POSTING MESSAGE TO CHECK FOR MOBILE DEVICE AND APPLE PAY');
    parent.postMessage({action: 'getPlatform'}, sessionStorage.getItem("hostingapp")); 
    parent.postMessage({action: 'getApplePayAvailable'}, sessionStorage.getItem("hostingapp"));   
    parent.postMessage({action: 'isMobileApp'}, sessionStorage.getItem("hostingapp"));
  }

  checkIfMobileApp() {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let isMobileApp = false;
    let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
    let mobileAppVal = sessionStorage.getItem('isMobileApp');

    // Fix for iPadOS not being detected as mobile on safari (known bug)
    if (!isMobile) {      
      if (isIpad) {
        isMobile = true;
      }
    }

    if (mobileAppVal && mobileAppVal == 'true') {
       isMobileApp = true;
    }
    else {
      isMobileApp = false;
    }
    
    let result = {'isMobile': isMobile, 'isMobileApp': isMobileApp, 'isIpad': isIpad};
    return result;
  }

}


