import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { OrderRoutingService } from "../order-routing.service";
import { AuthService } from "../auth/auth.service";
import { filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class EmailVerificationGuardService implements CanActivate {

    constructor(private orderRoutingService: OrderRoutingService,
        private spinner: NgxSpinnerService,) { }

    canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean {
        this.spinner.hide();
        if (this.emailVerificationRequired) {
            this.orderRoutingService.goToEmailVerification();
            return false;
        } else {
            return true;
        }
    }

    get emailVerificationRequired() : boolean {
        return (sessionStorage.getItem('email_verification') == 'true') ? true : false;
    }
      
    set emailVerificationRequired (value:boolean) {
        sessionStorage.setItem("email_verification", value.toString());
    }

    get customerEmail(): string|null {
        return sessionStorage.getItem('customer_email');
    }

    get verificationEmailSentTime() : string|null {
        return sessionStorage.getItem('verification_email_sent_time');
    }

    get locationPhone(): string|null {
        return sessionStorage.getItem("locationPhone");
    }
}