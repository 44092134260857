import { Item } from '../models/item';
import { Modifier } from '../models/modifier';
import { ModifierGroup } from '../models/modifier-group';

export class CartModel {
    constructor(){
        this.items = [];
    }
    orderId : string;
    catalogId : number;
    items : CartItem[];
    redeemableCode : any;
    promoCode : string;
}

export class CartItem{
    constructor(item:Item, quantity:number, modifiers:Modifier[], specialInstructions: string, name: string, source: string){
        this.id = item.id;
        this.quantity = quantity;
        this.notes = specialInstructions;
        this.modifiers = [];
        this.price = item.price;
        modifiers.forEach((modifier)=>{
            this.modifiers.push(new CartItemModifier(modifier))
        });
        this.name = name;
        this.source = source;
        this.uniqueCartItemId = null;
    }
    id: number;
    quantity: number;
    modifiers: CartItemModifier[];
    // displayedPrice: number;
    notes: string;
    price: number;
    name?: string;
    image?: string;
    source: string;
    uniqueCartItemId?: string;  
}

export class CartItemModifier{
    constructor(modifier:Modifier){
        this.id = modifier.id;
        this.quantity = modifier.quantity ? modifier.quantity : 1 ;
        this.itemModifierGroupId = modifier.itemModifierGroupId;
        this.modifierModifierGroupId = modifier.modifierModifierGroupId;
        this.modifierGroupId = modifier.modifierGroupId;
        this.modifiers = this.GetNestedModifiers(modifier.modifierGroups);
    }
    id:number;
    quantity:number;
    itemModifierGroupId: number;
    modifierModifierGroupId: number;
    modifierGroupId: number;
    modifiers: CartItemModifier[];

    private GetNestedModifiers(modifierGroups: ModifierGroup[]): CartItemModifier[] {
        if (!modifierGroups || modifierGroups.length == 0) return [];
        let modifiers = [];
        modifierGroups.forEach(mg => {
            if (mg.totalSelected > 0 && mg.modifiers) {
                mg.modifiers.forEach(m => {
                    if (m.selected)
                        modifiers.push(new CartItemModifier(m))
                });
            }
        });
        return modifiers;
    }
}
