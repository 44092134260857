import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from './breadcrumb';
import { PropertyReader } from './property-reader';
import { CatalogTracked } from './catalogTracked';

@Injectable({
  providedIn: 'root'
})

export class CategoryTrackedService {

  private selectedCategory = new BehaviorSubject<any>(null);
  private categoryBreadCrumb = new BehaviorSubject<any>(null);
  private subCategoryViewOpen = new BehaviorSubject<any>(null);
  private backCategory$ = new BehaviorSubject<any>(null);
  categoriesTacker$ =  new BehaviorSubject<CatalogTracked[]>(null);
  catalogHasImages$ =  new BehaviorSubject<boolean>(null);
  catalogCategories$ =  new BehaviorSubject<any>(null);

  categoryBreadCrumbObj : BreadCrumb[] = [];
  breadCrumb: BreadCrumb;
  catalogComponent: PropertyReader;  
  subCatComponent: PropertyReader;  
  categoriesTacker: CatalogTracked[] = [];  
  backCategory: any;
  lastOrderIds: string[] = [];

  constructor() { }

  public registerCatalogComponent(comp: PropertyReader)
  {
    this.catalogComponent = comp;
  }

  public registerSubCatComponent(comp: PropertyReader)
  {
    this.subCatComponent = comp;
  }

  setCatalogCategories(value: any) 
  {
    this.catalogCategories$.next(value)
  }

  getCatalogCategories() 
  {
    return this.catalogCategories$.asObservable();
  }

  setSelectedCatalogHasImages(value: boolean) 
  {
    this.catalogHasImages$.next(value);
  }

  getSelectedCatalogHasImages() 
  {
    return this.catalogHasImages$.asObservable();
  }

  goToRoot() 
  {
    if(this.categoryBreadCrumbObj.length > 0) 
    {
      let rootCategory = this.categoryBreadCrumbObj.filter((x) => { return x.isMainCatalog});
      this.catalogComponent.callOpenCategory(rootCategory[0]);
      this.setSubCategoryViewOpen(false);
    }    
  }

  setCategoriesTracker(val : CatalogTracked[]) 
  {
    this.categoriesTacker$.next(val);
    this.categoriesTacker$.subscribe((result) => {
      if(result) 
      {
        this.categoriesTacker = result;
      }
    });
  }
  
  setSelectedCategory(category : any, rootName: string, rootId: number, catalogId: number)
  {       
    if(this.categoryBreadCrumbObj.length == 0) 
    {
      this.breadCrumb = new BreadCrumb();
      this.breadCrumb.isMainCatalog = true;  
      this.breadCrumb.id = rootId; 
      this.breadCrumb.rootName = rootName; 
      this.categoryBreadCrumbObj.push(this.breadCrumb); 
    }
    let checkIfCatExists = this.categoryBreadCrumbObj.filter((x) => { return x.id == category.id})
   // let checkIfCatExists = this.categoryBreadCrumbObj.filter((x) => { return x.id == category[0].id})
    if(checkIfCatExists.length > 0) 
    {
     //let index = this.categoryBreadCrumbObj.findIndex((x) => { return x.id == category[0].id});
     let index = this.categoryBreadCrumbObj.findIndex((x) => { return x.id == category.id});
     this.categoryBreadCrumbObj.splice(index + 1, this.categoryBreadCrumbObj.length - 1);
     let selectedCategoriesList = category.categories.filter(x => x.rootId == catalogId);
     //let selectedCategoriesList = this.categoriesTacker.filter(x => x.rootId == catalogId);
     let selectedCategory = selectedCategoriesList[0].subCategory.filter(x => x.id == category.id);
     //let selectedCategory = selectedCategoriesList[0].subCategory.filter(x => x.id == category[0].id);
     category = selectedCategory;
    }
    else 
    {
      // this.breadCrumb = new BreadCrumb();
      // this.breadCrumb.id = category[0].id;
      // this.breadCrumb.rootName = category[0].name;
      // this.breadCrumb.isMainCatalog = false;
      // this.categoryBreadCrumbObj.push(this.breadCrumb);

      this.breadCrumb = new BreadCrumb();
      this.breadCrumb.id = category.id;
      this.breadCrumb.rootName = category.name;
      this.breadCrumb.isMainCatalog = false;
      this.categoryBreadCrumbObj.push(this.breadCrumb);
    }    
    this.subCategoryViewOpen.next(true);
    this.categoryBreadCrumb.next(rootName);    
    //this.selectedCategory.next(category[0]); 
    this.selectedCategory.next(category);

   // let backCatIndex = this.categoryBreadCrumbObj.findIndex((x) => { return x.id == category[0].id});
    let backCatIndex = this.categoryBreadCrumbObj.findIndex((x) => { return x.id == category.id});
    backCatIndex = backCatIndex -1;
    if (backCatIndex < 0 ) { backCatIndex = 0; } 
    this.setBackCategory(this.categoryBreadCrumbObj[backCatIndex]);  
  } 

  getSelectedCategory() 
  {
    return this.selectedCategory.asObservable();
  }
  setSubCategoryViewOpen(open: boolean) 
  {
    this.subCategoryViewOpen.next(open);
  }
  getSubCategoryViewOpen() 
  {
    return this.subCategoryViewOpen.asObservable();
  }

  addItem(item: any) 
  {
   this.catalogComponent.callAddItem(item);
  }

  setBackCategory(val : any) 
  {
    this.backCategory$.next(val);
    this.backCategory$.subscribe((result) => {
      if(result) 
      {
        this.backCategory = result;
      }
    });
  }

  resetCart() {
    try {
      this.catalogComponent.callResetCart();
    } catch (error) {
      console.log(error);
    }
   
  }

}
