import { CartItem } from "../cart/cart.model";

export class ValidateDeliveryOrderRequest {
    merchantLocationId: number;
    deliveryLongitude: number;
    deliveryLatitude: number;
    address: string;
    phone: string;
    orderAheadDate: Date;
    orderAheadDeliveryDate: string;
    orderAheadDeliveryTime: string;
    validatePhone: boolean;
    items: CartItem[];
    catalogId: number;
}
