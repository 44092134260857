import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'alert-popup',
    templateUrl: './alert-popup.component.html',
    styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent {

    @Output() popupClosed = new EventEmitter<string>();

    isPopupEnabled: boolean = false;
    alertTitle: string = "";
    alertMessage: string = "";    

    show(title: string, message: string) {
        this.alertTitle = title;
        this.alertMessage = message;
        this.isPopupEnabled = true;
    }

    closePopup() {
        this.isPopupEnabled = false;
        this.alertTitle = "";
        this.alertMessage = "";
        this.popupClosed.next();
    }
}
