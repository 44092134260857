import { GuestDetails } from './guest-details.model';
import { OrderPayment, POSOrderPayment } from './order-payment';

export class PlaceOrderRequest {
    OrderId: string;
    Payments: OrderPayment[] = [];
    Notes: string;
    PayViaPos: boolean;   
    GuestDetails: GuestDetails = null;
    Token: string;
    RecaptchaAction: string;
}

export class PlaceOrderRequestPOS {
    OrderId: string;
    Payments: POSOrderPayment[] = [];
    Notes: string;
    PayViaPos: boolean;
}