
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomerSessionService } from '../customer-session.service';
import { OrderRoutingService } from '../order-routing.service';

@Injectable()
export class LocationGuardService implements CanActivate {
 
  constructor(private customerSession: CustomerSessionService, 
              public router: Router,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private orderRouting: OrderRoutingService) {}
  
  canActivate() : Observable<boolean>{

    this.spinner.show();
    
    return this.customerSession.hasLocation()
    .pipe( 
        tap((hasLocation) => { 
          this.spinner.hide();
          if(hasLocation === false){

            let guid = sessionStorage.getItem('guid');
            console.log('location guard - guid is: ' + guid);

            //when refreshing you will hit this guard again - but the guid will be empty
            //thus you need to get guid from href - edge case look into it
            if (!guid) {
              console.log('location guard - href is: ' + window.location.href);
            }

            this.orderRouting.goToLocation();
            
          }
        }),
    );   
  }
}