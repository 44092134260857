import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, Subject, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, tap, finalize, filter, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { OrderRoutingService } from '../order-routing.service';
import { LoadingController } from '@ionic/angular';
import { UtilService } from '../util.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    refreshTokenInProgress = false;

    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    private isTokenRefreshing : boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    prevAuthType: string = null;
  
    
    constructor(private authService : AuthService,
                private router : Router,
                private route: ActivatedRoute,
                public util: UtilService,
                private orderRouting: OrderRoutingService) {}

    addAuthHeader(req :HttpRequest<any>):HttpRequest<any>{
        let guid = sessionStorage.getItem('guid');
        let token = this.authService.getToken();
        let scheme = token ? 'Bearer ' + token : '';
        const authReq = req.clone({
            headers: new HttpHeaders({
            'Authorization':  scheme,
            'MERCHANT_GUID': (guid) ? guid : ''
            })
        });
        return authReq;
    }

    refreshToken() {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {            
            this.refreshTokenInProgress = true;
            console.log('post message for exhange token')
            parent.postMessage({action: 'renewtoken'}, sessionStorage.getItem("hostingapp"));
            return this.authService.waitForExchange()
               .pipe(
                   tap(() => {                      
                        this.tokenRefreshedSource.next();
                        this.refreshTokenInProgress = false;
                        console.log('exchange complete')    
                    })
                );                     
                    
        }
    }

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

                if (req.headers.get('Anonymous')) {
                    const newHeaders = req.headers.delete('Anonymous')
                    const newRequest = req.clone({ headers: newHeaders });
                    return next.handle(newRequest);
                } else {
                    req = this.addAuthHeader(req);
                    return next.handle(req).pipe(                     
                        catchError(error => {
                            //let isMobile = sessionStorage.getItem('is_mobile');
                            //if (false && error.status === 401 && isMobile) {
                            //console.log('requesting refresh from ' + req.url)
                            //return this.refreshToken()
                            //    .pipe(
                            //        switchMap(() => {
                            //             req = this.addAuthHeader(req);
                            //             return next.handle(req);
                            //        }),
                            //        catchError(() => {
                            //          this.router.navigate(['/unauthorized']);
                            //          return of(null);
                            //        })
                            //    )
                            //} 
                            //else
                            if (error.status === 401 && error.statusText === "Token Expired") {
                                console.log("Token expired. Attempting refresh ...");
                                return this.handleHttpResponseError(req, next);
                            }                            
                            else if (error.status === 401) {
                                let guestTokenExpired: boolean = sessionStorage.getItem("guestTokenExpired") == "1" ? true : false;
                                if (!guestTokenExpired) {
                                    this.orderRouting.goToLogin();
                                }
                                return throwError(error);
                            }
                            return throwError(error);
                        })
                    )  
                  }
    }

    private handleHttpResponseError(request : HttpRequest<any>, next : HttpHandler) 
    {
        this.prevAuthType = sessionStorage.getItem("authType");
        if (!this.isTokenRefreshing) {
            this.isTokenRefreshing = true;
            this.tokenSubject.next(null);
            return this.authService.getNewRefreshToken().pipe(
                switchMap((tokenresponse: any) => {
                    if (tokenresponse) {
                        this.tokenSubject.next(tokenresponse.token);
                        console.log("Token refreshed...");
                        return next.handle(this.attachTokenToRequest(request));
                    }
                    return this.authService.logout();
                }),
                catchError(err => {
                    if (this.prevAuthType == "GUEST") {
                        sessionStorage.setItem("guestTokenExpired", "1");
                    }
                    this.authService.logout();
                    return this.handleError(err);
                }),
                finalize(() => {
                    this.isTokenRefreshing = false;
                })
            );
        }
        else {
            return this.tokenSubject.pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => next.handle(this.attachTokenToRequest(request))));

        }
    }

    private attachTokenToRequest(request: HttpRequest<any>)
    {
        let token = localStorage.getItem(sessionStorage.getItem("guid"));
        return request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
    }

    private handleError(errorResponse : HttpErrorResponse)
    {
        let errorMsg : string;

        if(errorResponse.error instanceof Error)
        {       
        errorMsg = "An error occured : " + errorResponse.message;
        } else
        {       
        errorMsg = errorResponse.message;
        }
        return throwError(errorMsg);
    }
}