import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CartService } from '../cart.service';
import { CatalogService } from '../catalog.service';
import { CustomerSessionService } from '../customer-session.service';
import { OrderRoutingService } from '../order-routing.service';

@Injectable({
  providedIn: 'root'
})
export class OrderAheadGuard implements CanActivate {

  
  constructor(private customerSession: CustomerSessionService, 
    public router: Router,
    private spinner: NgxSpinnerService,
    private catalogService: CatalogService,
    private orderRouting: OrderRoutingService,
    private cartService: CartService) 
    {
           
    }

    canActivate() : Observable<boolean> {
      this.spinner.show();
      
      return this.hasOrderTypeSelected()
      .pipe( 
        tap((hasOrderTypeSelected) => { 
          this.spinner.hide();         
          if(hasOrderTypeSelected){            
            return true;
          }
          else
          {            
           // this.cartService.loadOrderAheadSettings();
           
           /* Check if the user has already selected the location - No point redirecting again to select */
           var hasLocationSelected = sessionStorage.getItem('locationId'); 
            if(hasLocationSelected && hasLocationSelected !== '') 
            {     
              this.cartService.setOrderAheadSetting().then((result) => {
                if(result) 
                {
                  this.orderRouting.goToCatalogPicker();   
                 // this.orderRouting.goToOrderAhead();    
                }
              });      
             
            }
            else
            {
              this.orderRouting.goToLocation();
            }  
            
           //this.orderRouting.goToLocation();
          }             
        }),
    ); 
  }

  hasOrderTypeSelected(): Observable<boolean> {
    let lastSelectedCatalogId = this.catalogService.getLastSelectedCatalogId();
    if(lastSelectedCatalogId > 0) 
    {
        return of(true);
    }
    else 
    {
        return of(false);
    }       
  }
}
