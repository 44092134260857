import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable, BehaviorSubject, Subscriber } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CartItem, CartModel } from './cart/cart.model';
import { DataService } from './data.service';
import { OrderItem } from './models/order-item';
import { OrderModifier } from './models/order-modifiers';
import { MOPaymentType, OrderPayment, POSOrderPayment } from './models/order-payment';
import { OrderRedeemable } from './models/order-redeemable';
import { PlaceOrderRequest, PlaceOrderRequestPOS } from './models/place-order-request';
import { MOSource, PrepareOrderRequest } from './models/prepare-order-request';
import { PreparedOrder } from './models/prepared-order.model';
import { CustomerSessionService } from './customer-session.service';
import { FutureOrderDateTime } from './models/validate-future-order-request';
import { CardPayment } from './payment-card/card-payment';
import { TipDetails, TipType } from './models/tip-details.model';
import { DeliveryDetails } from './models/delivery-details.model';
import { CatalogsTimeFrames } from './models/catalogs-time-frames';
import { CalculateCartPriceRequest } from './models/calculate-cart-price-request';
import { CategoryTrackedService } from './common/category-tracked.service';
import { OrderRoutingService } from './order-routing.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AlertController, LoadingController } from '@ionic/angular';
import { OrderPromoCode } from './models/order-promo-code';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

// Get product from sessionstorage
let products = JSON.parse(sessionStorage.getItem("cart")) || [];
@Injectable({
  providedIn: 'root'
})
export class CartService {
  //stored cart
  public cart = new CartModel();
  //order in progress
  public order = new PreparedOrder();
  //order eta Message
  public etaTimeMessage = "";
  // checkout page flag  
  public isCheckoutPage: boolean = false;
  //Tip flag
  public isTipEnabled: boolean = true;

  //special instructions
  private specialInstructions: string;

  //utensils required
  private utensilsRequired: boolean = false;

  //payment methods applied to current order
  private paymentMethodsApplied: string;

  private cartSubTotal = new BehaviorSubject<number>(0.00);

  // Will keep last selected orderahedsetting cached 
  public orderAhedSettings$: Observable<any>;

   // Edit button Text
   public editbuttonText$ = new BehaviorSubject<string>("");
   public editBtnText = "";

  public dineInChanged = new BehaviorSubject<boolean>(false);

    public siteKey: string = environment.recaptchaSiteKey;
        
  constructor(private http : HttpClient,
    private dataService:DataService,
    private customerSession :CustomerSessionService,
    private orderRouting: OrderRoutingService,
    public loadingCtrl: LoadingController,
    private spinner: NgxSpinnerService,
    private translate: TranslateService
  ) {
    //read from sessionStorage the cart if there is any
    this.loadCart(); 
  }
      
  private loadCart(){
    let cart = sessionStorage.getItem('cart');
    if (cart){
      this.cart = JSON.parse(cart)
    }
  }

  private storeCart(){
    if (this.cart && this.cart.items.length > 0) {      
       this.cart.items.forEach(cartitem => {
         cartitem.uniqueCartItemId = this.makeid(10);
       });
    }
    sessionStorage.setItem('cart', JSON.stringify(this.cart));
  }

  public makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

    public clearCartOrderId() {
        this.cart.orderId = '';
        this.storeCart();
    }

  getCartCount(){ 
    let cartCount = 0;
    if(this.cart.items.length > 0)
      cartCount = this.cart.items.map(item => item.quantity).reduce((sum, current) => sum + current);
    return cartCount;
  }

  async updateCart(item: CartItem, catalogId: number, oldLineItemIndex: number): Promise<boolean> {
    if(this.cart.catalogId == null)
      this.cart.catalogId = catalogId;

      let findOldItem = this.cart.items[oldLineItemIndex];

      if (findOldItem) {
        //const index =  this.cart.items.indexOf(findOldItem);
        if (oldLineItemIndex > -1) {
          this.cart.items.splice(oldLineItemIndex, 1);
        }
      }
     
    this.cart.items.splice(oldLineItemIndex, 0, item);
    console.log('added cart item');
    let calculated: boolean = await this.calculateCartPrice();
    if (calculated != true) {
        this.cart.items.pop(); return false;
    }
    else {
        this.storeCart();
        return true;
    }
  }

  async addToCart(item: CartItem, catalogId: number): Promise<boolean> {
        if(this.cart.catalogId == null)
          this.cart.catalogId = catalogId;
        this.cart.items.push(item);
        console.log('added cart item');
        let calculated: boolean = await this.calculateCartPrice();
        if (calculated != true) {
            this.cart.items.pop(); return false;
        }
        else {
            this.storeCart();
            return true;
        }
  }

    async removeFromCart(index: any, isAutoAddItemRemoved: boolean = false): Promise<boolean> {
        if (isAutoAddItemRemoved || this.order.details.lines[index].discount){
          console.log("need to remove redeemable code, not applicable anymore");
          this.cart.redeemableCode = "";
          this.cart.promoCode = "";
        }
        let removedItem = this.cart.items.splice(index, 1);
        console.log('removed cart item');
          let calculated: boolean = await this.calculateCartPrice();
          if (calculated != true) {
              this.cart.items.splice(index, 0, removedItem[0]);
              return false;
          }
          else {
              this.storeCart();    
              return true;
          }
      }

    async calculateCartPrice(): Promise<boolean> {
        let items: OrderItem[] = [];
        this.buildOrderItemAndGetSubTotal(items);

        if (items.length == 0) {
            this.cartSubTotal.next(0.00);
            this.cart.promoCode = "";
            return Promise.resolve(true);
        }

        let calculateCartPriceRequest: CalculateCartPriceRequest = {
            catalogId:  this.cart.catalogId,
            items: items,
            merchantLocationId: this.customerSession.locationId,
            orderId: this.cart.orderId
        };

       return this.dataService.calculateCartPrice(calculateCartPriceRequest).toPromise().then(
           (result) => {
                console.log(result);
                if (result) {
                    this.cartSubTotal.next(result.cartPrice);
                    return true;
               }
               return false;
            },
            async (err) => {                
                console.log("Evaluate Cart Total -- error: ", err);
              /* This error occurs when GUID id changed from URL - When cart is expired/catalog not found/catalog disabled => we need to clear cart */
                if(err && err.message == "Order is expired (catalog not found)")
                {
                  /* Clear last selected catalog and clear cart */                 
                  this.loadingCtrl.create({
                    message: this.translate.instant('Order is expired. Loading new session...'),
                    spinner: 'bubbles',
                  }).then(a => {
                    a.present().then(() => {
                      this.clearSessionForNewOrder().then(async (result) => {
                        if(result) 
                        {            
                          a.dismiss().then(() => console.log('abort presenting'));           
                          location.reload();
                        }
                      }).catch(error => {
                        console.log(error);
                      });  
                    });
                  });                            
                }
                else
                {
                  return false;
                }               
            }
       );
    }

  clearSessionPromise() : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.clearCartForCatalogChange();
      resolve(true);
    });
  }

  clearCart(){
    this.order = new PreparedOrder();
    this.cart = new CartModel();
    this.setSpecialInstructions('');
    this.storeCart();
    this.paymentMethodsApplied = "";
    this.clearFutureOrderSettingAndData();
    this.clearTipData();
    this.clearLastSelectedCategoryId();
    this.isCheckoutPage = false;
    this.calculateCartPrice();
  }

    clearDineInData() {
        sessionStorage.removeItem('is_dinein_order');
        sessionStorage.removeItem('dinein_table_name');
        sessionStorage.removeItem('order_type_picked');
        this.dineInChanged.next(true);
    }


  clearCartForCatalogChange() 
  {
    this.order = new PreparedOrder();
    this.cart = new CartModel();
    this.storeCart();
    this.paymentMethodsApplied = "";
    this.clearTipData();
    this.clearLastSelectedCategoryId();
    this.isCheckoutPage = false;
    this.calculateCartPrice();
    this.askedForOrderType = false;
  }

  clearGuestCheckoutData() {
    sessionStorage.removeItem("guestPhone");
    sessionStorage.removeItem("guestEmail");
    sessionStorage.removeItem("guestName");
  }

  async clearSessionForNewOrder() : Promise<any>
  {
    return new Promise<any>(async (resolve, reject) => {
      this.clearCart();
      this.clearDineInData();

      let isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;
      if (isGuest) {
        this.clearGuestCheckoutData();
      }

      this.askedForOrderType = false;
      sessionStorage.removeItem('lastSelectedCatalogId');
      sessionStorage.removeItem('target_catalog_id');
      sessionStorage.removeItem('selected_order_type');
      sessionStorage.setItem('reload_catalog',  'true');
      this.orderAhedSettings$ = null;
      await this.setOrderAheadSetting().then((result) => {
        if (result) {
          resolve(true);
        }
      });
    });    
  }
  
  applyPromoCode(promoCode) {
    this.cart.promoCode = promoCode;
    this.storeCart();
  }

  getAppliedPromoCode(){
    return this.cart.promoCode;
  }

  applyRedeemable(redeemableCode){
    this.cart.redeemableCode = redeemableCode;
    this.cart.promoCode = "";
    this.storeCart();
  }

  getAppliedRedeemable(){
    return this.cart.redeemableCode;
  }

  removeRedeemable(){
    this.cart.redeemableCode = "";
    this.cart.promoCode = "";
    this.storeCart();
  }

  setSpecialInstructions(specialInstructions){
    this.specialInstructions = specialInstructions;
  }

  getSpecialInstructions(){
    return this.specialInstructions;
  }

  updateUtensilsRequired(value: boolean){
    this.utensilsRequired = value;
  }

  getUtensilsRequired(){
    return this.utensilsRequired;
  }

  get getCartSubTotal()
  {
   return this.cartSubTotal.asObservable();
  }

  // PrepareOrderPromise
  prepareOrderPromise(locationId:number): Promise<any> 
  {
      return new Promise<any>(async (resolve, reject) => {
        let items: OrderItem[] = [];
        let subTotal: number = this.buildOrderItemAndGetSubTotal(items);

        //redeemables
      let redeemables: OrderRedeemable[] = [];
      if (this.cart.redeemableCode) {
        let curRedeemable = new OrderRedeemable();
        curRedeemable.Code = this.cart.redeemableCode;          
        redeemables.push(curRedeemable);
      }
     
      let promoCode: OrderPromoCode = null;
      if (!this.cart.redeemableCode && this.cart.promoCode) {
        promoCode = new OrderPromoCode(this.cart.promoCode);
      } 

      //Modify delivery details
      let deliverDetails = this.getDeliveryOrderDetails(); 
      let leaveAtDoor = "Leave at door.";
      let handToMe = "Hand to me.";
      if (this.isDeliveryOrder) {        
          if (deliverDetails) {
              if ((deliverDetails.details
                  && !deliverDetails.details.startsWith(handToMe)
                  && !deliverDetails.details.startsWith(leaveAtDoor)) || !deliverDetails.details) {
                  let instructionOption = this.deliveryInstruction == "1" ? handToMe : this.deliveryInstruction == "0" ? leaveAtDoor : "";
                  deliverDetails.details = instructionOption + (deliverDetails.details ? " " + deliverDetails.details : "");
              }
          }
      }

      const recaptchaAction = 'prepareOrder'
      const token = await (<any>window).grecaptcha.enterprise.execute(this.siteKey, {action: recaptchaAction});

      //build the request object
      let prepareOrderRequest = new PrepareOrderRequest();
      prepareOrderRequest.catalogId = this.cart.catalogId;
      prepareOrderRequest.orderId = this.cart.orderId;
      prepareOrderRequest.items = items;
      prepareOrderRequest.redeemables = redeemables;
      prepareOrderRequest.promoCode = promoCode;
      // prepareOrderRequest.displayedPrice = subTotal;
      prepareOrderRequest.merchantLocationId = locationId;
      prepareOrderRequest.notes = this.getNotesForOrder(); //this.getSpecialInstructions();
      prepareOrderRequest.source = this.customerSession.isMobile() ? MOSource.MOBILEAPP : MOSource.WEB;
      prepareOrderRequest.futureOrderDateTime = this.isFutureOrder ? this.getFutureOrderDateTime() : null;
      prepareOrderRequest.curbSideOrder = this.isCurbSideOrder;
      prepareOrderRequest.curbSideOrderDetails = this.isCurbSideOrder ? this.getCurbSideOrderDetails() : null;
      prepareOrderRequest.dineInOrder = this.isDineInOrder;
      prepareOrderRequest.dineInOrderDetails = this.isDineInOrder ? this.dineInTableName : null;
      prepareOrderRequest.tipDetails = this.getTipDetails();
      prepareOrderRequest.deliveryDetails = deliverDetails;
      prepareOrderRequest.isCheckoutPage = this.isCheckoutPage;    
      prepareOrderRequest.token = token;
      prepareOrderRequest.recaptchaAction = recaptchaAction;

      this.dataService.prepareOrder(prepareOrderRequest).subscribe((preparedOrder) => {
        if (preparedOrder) {
          this.cart.orderId = preparedOrder.orderId;
          this.cart.items = [];
          this.cart.items = preparedOrder.items; //auto add item thats why
          this.order = preparedOrder;
          this.storeCart();
          this.setEtaTimeMessage();
          this.setupTipDetails(); 
          resolve(true);  
        }               
      }, (error) => {
        reject(error);
      });        
    });
  }

  //API Calls
  async prepareOrder(locationId:number){
    let items: OrderItem[] = [];

    let subTotal: number = this.buildOrderItemAndGetSubTotal(items);    

    //redeemables
    let redeemables: OrderRedeemable[] = [];
    if (this.cart.redeemableCode){
      let curRedeemable = new OrderRedeemable();
      curRedeemable.Code = this.cart.redeemableCode;
        
      redeemables.push(curRedeemable);
    }

    let promoCode: OrderPromoCode = null;
    if (!this.cart.redeemableCode && this.cart.promoCode) {
      promoCode = new OrderPromoCode(this.cart.promoCode);
    }

    //Modify delivery details
    let deliverDetails = this.getDeliveryOrderDetails(); 
    let leaveAtDoor = "Leave at door.";
    let handToMe = "Hand to me.";
    if (this.isDeliveryOrder) {        
        if (deliverDetails) {
            if ((deliverDetails.details
                && !deliverDetails.details.startsWith(handToMe)
                && !deliverDetails.details.startsWith(leaveAtDoor)) || !deliverDetails.details) {
                let instructionOption = this.deliveryInstruction == "1" ? handToMe : this.deliveryInstruction == "0" ? leaveAtDoor : "";
                deliverDetails.details = instructionOption + (deliverDetails.details ? " " + deliverDetails.details : "");
            }
        }
    }

    const recaptchaAction = 'prepareOrder'
    const token = await (<any>window).grecaptcha.enterprise.execute(this.siteKey, {action: recaptchaAction});

    //build the request object
    let prepareOrderRequest = new PrepareOrderRequest();
    prepareOrderRequest.catalogId = this.cart.catalogId;
    prepareOrderRequest.orderId = this.cart.orderId;
    prepareOrderRequest.items = items;
    prepareOrderRequest.redeemables = redeemables;
    prepareOrderRequest.promoCode = promoCode;
    // prepareOrderRequest.displayedPrice = subTotal;
    prepareOrderRequest.merchantLocationId = locationId;
    prepareOrderRequest.notes = this.getNotesForOrder();//this.getSpecialInstructions();
    prepareOrderRequest.source = this.customerSession.isMobile() ? MOSource.MOBILEAPP : MOSource.WEB;
    prepareOrderRequest.futureOrderDateTime = this.isFutureOrder ? this.getFutureOrderDateTime() : null;
    prepareOrderRequest.curbSideOrder = this.isCurbSideOrder;
    prepareOrderRequest.curbSideOrderDetails = this.isCurbSideOrder ? this.getCurbSideOrderDetails() : null;
    prepareOrderRequest.dineInOrder = this.isDineInOrder;
    prepareOrderRequest.dineInOrderDetails = this.isDineInOrder ? this.dineInTableName : null;
    prepareOrderRequest.tipDetails = this.getTipDetails();
    prepareOrderRequest.deliveryDetails = deliverDetails;
    prepareOrderRequest.isCheckoutPage = this.isCheckoutPage;
    prepareOrderRequest.token = token;
    prepareOrderRequest.recaptchaAction = recaptchaAction;

   return this.dataService.prepareOrder(prepareOrderRequest)
      .pipe(
        tap((preparedOrder) => {
          this.cart.orderId = preparedOrder.orderId;
          this.cart.items = [];
          this.cart.items = preparedOrder.items; //auto add item thats why
          this.order = preparedOrder;
          this.storeCart();
          this.setEtaTimeMessage();
          this.setupTipDetails();            
        })
      );
  }

  setupTipDetails() {
        //tip settings
        if (this.order.isTipEnabled != null && this.order.isTipEnabled != undefined) {
            this.isTipEnabled = this.order.isTipEnabled;
        }

        if (this.isTipEnabled) {
            if (this.order.tipDetails.type == 1) {
                let optionIndex = this.order.availableTipableAmounts.findIndex(t => t.percentage == this.order.tipDetails.value);
                if (optionIndex >= 0) {
                    this.tipOption = optionIndex;
                    this.tipValue = this.order.availableTipableAmounts[optionIndex].percentage;
                }
                else {
                    this.tipOption = 1;
                    this.tipValue = this.order.availableTipableAmounts[1].percentage;
                }
            }
            else {
                this.tipValue = this.order.tipDetails.value;
                if (this.tipValue > 0) {
                    this.tipOption = -2; //custom amount
                }
                else {
                    this.tipOption = -1; // no tip value
                }
            }
        }
        else if (!this.isTipEnabled) {
            this.tipOption = -1;
            this.tipValue = 0;
        }
  }

  buildOrderItemAndGetSubTotal(items: OrderItem[]): number {
        let subTotal: number;
        this.cart.items.forEach((cartItem) => {
            let orderItem = new OrderItem(cartItem.id, cartItem.quantity, cartItem.notes, cartItem.source)
            // subTotal += cartItem.displayedPrice
            cartItem.modifiers.forEach((modifier) => {
                let orderModifier = new OrderModifier(modifier.id, modifier.itemModifierGroupId, modifier.modifierModifierGroupId, modifier.modifierGroupId, modifier.quantity, modifier.modifiers);
                orderItem.modifiers.push(orderModifier);
            })
            items.push(orderItem);
        })
        return subTotal;
  }



  setEtaTimeMessage(){
    if (this.isDeliveryOrder == true){
      this.etaTimeMessage = this.translate.instant("Your order will be delivered to you");
    }
    else if (this.isDineInOrder == true) {
        this.etaTimeMessage = "";
    }
    else {
      if (this.isFutureOrder == true){
        this.etaTimeMessage = this.getFutureOrderDisplayDateTimeMessage() + this.getOrderLocationName();
      } else {
          this.etaTimeMessage = this.translate.instant("Your order will be ready in {eta} mins", { eta: this.order.eta }) + this.getOrderLocationName();
      }
    }
  }

  getOrderLocationName() {
    if(this.order.details && this.order.details.businessName){
      return " " + this.translate.instant("at") + " " + this.order.details.businessName;
    }
    return "";
  }

  getPaymentMethodsApplied(){
    return this.paymentMethodsApplied;
  }

  get isFutureOrder():boolean{
    return (sessionStorage.getItem('is_future_order') == 'true') ? true : false;
  }
  
  set isFutureOrder(value:boolean){
    sessionStorage.setItem("is_future_order", value.toString());
  }

  get isCurbSideOrder():boolean{
    return (sessionStorage.getItem('is_curbside_order') == 'true') ? true : false;
  }
  
  set isCurbSideOrder(value:boolean){
    sessionStorage.setItem("is_curbside_order", value.toString());
  }

  get isDeliveryOrder():boolean{
    return (sessionStorage.getItem('is_delivery_order') == 'true') ? true : false;
  }
  
  set isDeliveryOrder(value:boolean){
    sessionStorage.setItem("is_delivery_order", value.toString());
  }

    get isDineInOrder(): boolean {
        return (sessionStorage.getItem('is_dinein_order') == 'true') ? true : false;
    }

    set isDineInOrder(value: boolean) {
        sessionStorage.setItem("is_dinein_order", value.toString());
    }

    get dineInTableName(): string {
        return sessionStorage.getItem('dinein_table_name');
    }

    set dineInTableName(value: string) {
        sessionStorage.setItem("dinein_table_name", value);
    }

  get deliveryInstruction():string{
    let result = "0";
    if (sessionStorage.getItem('delivery_instruction_radio')) {
      result = sessionStorage.getItem('delivery_instruction_radio');
    }
    return result;
  }
  
  set deliveryInstruction(value:string){
    sessionStorage.setItem("delivery_instruction_radio", value.toString());
  }

  set refreshOrderAheadSetting(refresh:boolean){
     if (refresh) {
       this.askedForFutureOrder = false;
       this.orderAhedSettings$ = null;
     }
  }

  get askedForOrderType():boolean{
    return (sessionStorage.getItem('asked_for_order_type') == 'true') ? true : false;
  }
  
  set askedForOrderType(value:boolean){
    sessionStorage.setItem("asked_for_order_type", value.toString());
  }

  get selectedOrderType():number{
    return (sessionStorage.getItem('selected_order_type')) ? Number(sessionStorage.getItem('selected_order_type')) : 0;
  }
  
  set selectedOrderType(value:number){
    sessionStorage.setItem("selected_order_type", value.toString());
  }

  get isCorrectOrderLocation():boolean{
    return (sessionStorage.getItem('is_correct_order_location') == 'true') ? true : false;
  }
  
  set isCorrectOrderLocation(value:boolean){
    sessionStorage.setItem("is_correct_order_location", value.toString());
  }
  

  getFutureOrderDateTime(){
    let futureOrderDate = sessionStorage.getItem('future_order_date');
    let result = new FutureOrderDateTime();
    if (futureOrderDate){
      result = JSON.parse(futureOrderDate)
    }

    return result;
  }

  setFutureOrderDateTime(futureOrderDate: FutureOrderDateTime){
    sessionStorage.setItem('future_order_date', JSON.stringify(futureOrderDate));
  }

  clearFutureOrderDateTime(){
    sessionStorage.removeItem('future_order_date');
  }

  getCurbSideOrderDetails(){
    let curbSideOrderDetails = sessionStorage.getItem('curbside_order_details');
    let result = "";
    if (curbSideOrderDetails) {
      result = curbSideOrderDetails;
    }
    return result;
  }

  setCurbSideOrderDetails(details: any){
    sessionStorage.setItem('curbside_order_details', details);
  }

  getCatalogsTimeFrames(): CatalogsTimeFrames[]{
    let catalogsTimeFrames = sessionStorage.getItem('catalog_timeframes');
    let result: CatalogsTimeFrames[];
    if (catalogsTimeFrames){
        result = JSON.parse(catalogsTimeFrames);

        if (result.length > 0 && this.hideCatalogWhenNotOpen) {
          result = result.filter(function( ctf ) {
            return ctf.isAsapAvailable === true;
          });
        }
    }    
   
    return result;
  }

  setCatalogsTimeFrames(catalogsTimeFrames: CatalogsTimeFrames){
    sessionStorage.setItem('catalog_timeframes', JSON.stringify(catalogsTimeFrames));
  }

  clearCurbSideOrderDetails(){
    sessionStorage.removeItem('curbside_order_details');
  }

  getFutureOrderDisplayDateTimeMessage(){
    var dateTime = this.getFutureOrderDateTime();
    let result = this.translate.instant("Pickup order at") + " " + this.getFutureOrderDisplayTime(dateTime.hour, dateTime.min);
    result += " on " + this.getFutureOrderDisplayDate(dateTime.year, dateTime.month, dateTime.day);

    return result;
  }

  getFutureOrderDisplayDate(year: number, month: number, day: number){
    return month + "/" + day + "/" + year;
  }

  getFutureOrderDisplayTime(hour: number, min: number){
    let displayHour = hour % 12;
    if (displayHour == 0 && (hour == 12 || hour  == 0)){
      displayHour = 12;
    }

    let displayMin = min.toString().padStart(2, "0");

    if (hour >= 12) {
      //pm date
      return displayHour + ":" + displayMin + " PM";
    } else {
      //am date
      return displayHour + ":" + displayMin + " AM";
    }
  }

  getDeliveryOrderDetails(){
    let futureOrderDate = sessionStorage.getItem('delivery_order_details');
    let result = new DeliveryDetails();
    if (futureOrderDate){
      result = JSON.parse(futureOrderDate)
    }

    return result;
  }

  setDeliveryOrderDetails(deliveryDetails: DeliveryDetails){
    sessionStorage.setItem('delivery_order_details', JSON.stringify(deliveryDetails));
  }

  clearDeliveryOrderDetails(){
    sessionStorage.removeItem('delivery_order_details');
  }

  get hideCatalogWhenNotOpen() : boolean {
    return (sessionStorage.getItem('hide_catalog_when_not_open') == '1') ? true : false;
  }

  get askedForFutureOrder():boolean{
    return (sessionStorage.getItem('asked_for_future_order') == 'true') ? true : false;
  }
  
  set askedForFutureOrder(value:boolean){
      sessionStorage.setItem("asked_for_future_order", value.toString());
  }

  get orderAheadEnabled():boolean{
    return (sessionStorage.getItem('order_ahead_enabled') == 'true') ? true : false;
  }
  
  set orderAheadEnabled(value:boolean){
      sessionStorage.setItem("order_ahead_enabled", value.toString());
  }

  get curbSidePickUpEnabled():boolean{
    return (sessionStorage.getItem('curb_side_pickup_enabled') == 'true') ? true : false;
  }
  
  set curbSidePickUpEnabled(value:boolean){
      sessionStorage.setItem("curb_side_pickup_enabled", value.toString());
  }

  get disableInStorePickUp():boolean{
    return (sessionStorage.getItem('disable_in_store_pickup') == 'true') ? true : false;
  }
  
  set disableInStorePickUp(value:boolean){
      sessionStorage.setItem("disable_in_store_pickup", value.toString());
  }

  get disableASAPOrders():boolean{
    return (sessionStorage.getItem('disable_ASAP_orders') == 'true') ? true : false;
  }
  
  set disableASAPOrders(value:boolean){
      sessionStorage.setItem("disable_ASAP_orders", value.toString());
  }

  get deliveryEnabled():boolean{
    return (sessionStorage.getItem('delivery_enabled') == 'true') ? true : false;
  }
  
  set deliveryEnabled(value:boolean){
      sessionStorage.setItem("delivery_enabled", value.toString());
  }

  get minimumOrderTotalForDelivery():number{
      return Number(sessionStorage.getItem("minimum_order_for_delivery"));
  }
  
  set minimumOrderTotalForDelivery(value: number){
      if (value) {
          sessionStorage.setItem("minimum_order_for_delivery", value.toString());
      }
      else {
          sessionStorage.removeItem("minimum_order_for_delivery");
      }
  }

  get isExternalDelivery():boolean{
    return (sessionStorage.getItem('is_external_delivery') == 'true') ? true : false;
  }
  
  set isExternalDelivery(value:boolean){
      sessionStorage.setItem("is_external_delivery", value.toString());
  }

  clearFutureOrderSettingAndData(){
    sessionStorage.removeItem("order_ahead_enabled");
    sessionStorage.removeItem("curb_side_pickup_enabled");
    sessionStorage.removeItem("disable_in_store_pickup");
    sessionStorage.removeItem("delivery_enabled");
    sessionStorage.removeItem("is_external_delivery");
    sessionStorage.removeItem("asked_for_future_order");
    sessionStorage.removeItem("asked_for_order_type");
    sessionStorage.removeItem("future_order_date");
    sessionStorage.removeItem("is_future_order");
    sessionStorage.removeItem("is_curbside_order");
    sessionStorage.removeItem('curbside_order_details');
    sessionStorage.removeItem('is_delivery_order');
    sessionStorage.removeItem('delivery_order_details');
    sessionStorage.removeItem('delivery_instruction_radio');
    sessionStorage.removeItem('allow_multiple_modifier_quantity');
  }

  getTipDetails(){
    let tipDetails = new TipDetails();

    //if no tip data available in session storage then return null  
    if (this.tipOption == -3){
        return null;
    }

    if (this.tipOption >= 0){
      tipDetails.type = TipType.PERCENTAGE;
      tipDetails.value = this.tipValue;
    } else {
      tipDetails.type = TipType.AMOUNT;
      tipDetails.value = this.tipValue;
    }

    return tipDetails;
  }  

  get tipValue():number{
    var result = 0;
    var item = sessionStorage.getItem("tip_value");
    if (item){
      result = Number(item);
    }
    return result;
  }
  
  set tipValue(value:number){
      sessionStorage.setItem("tip_value", value.toString());
  }

  get tipOption():number{
    var result = -3; //after custom and none
    var item = sessionStorage.getItem("tip_option");
    if (item){
      result = Number(item);
    }
    return result;
  }
  
  set tipOption(value:number){
      sessionStorage.setItem("tip_option", value.toString());
  }

  clearTipData(){
    sessionStorage.removeItem("tip_option");
    sessionStorage.removeItem("tip_value");
  }

  clearLastSelectedCategoryId(){
    sessionStorage.removeItem("last_selected_category_id");
   
  }

  getCartOrderId(){
    return this.cart.orderId;
  }

  getCartTotal(){
    return this.order.details.totalPrice;
  }
  
  getAvailableWalletBalance(){
    return this.order.availableWalletBalance;
  }


  /* Changes */
   loadOrderAheadSettings() 
   {
      if (this.askedForFutureOrder == false) {
        this.spinner.show();

        this.dataService.GetOrderAheadSetting(this.customerSession.locationId).subscribe(
            (data) => {
                this.askedForFutureOrder = true;

                if (data.orderAheadEnabled == true) {
                    this.orderAheadEnabled = true;
                } else {
                    this.orderAheadEnabled = false;
                }

                if (data.curbSidePickUpEnabled == true) {
                    this.curbSidePickUpEnabled = true;
                } else {
                    this.curbSidePickUpEnabled = false;
                }

                if (data.disableInStorePickUp == true) {
                    this.disableInStorePickUp = true;
                } else {
                    this.disableInStorePickUp = false;
                }

                if (data.disableASAPOrders == true) {
                  this.disableASAPOrders = true;
                } else {
                  this.disableASAPOrders = false;
                }

                if (data.deliveryEnabled == true) {
                    this.deliveryEnabled = true;                        
                    this.minimumOrderTotalForDelivery = data.minimumOrderTotalForDelivery;
                } else {
                    this.deliveryEnabled = false;
                }

                if (data.isExternalDelivery == true) {
                    this.isExternalDelivery = true;
                } else {
                    this.isExternalDelivery = false;
                }

                if (data.catalogsTimeFrames) {
                    this.setCatalogsTimeFrames(data.catalogsTimeFrames);
                }

                if (data.guestCheckoutEnabled == true) {
                    sessionStorage.setItem("guest_checkout_enabled", "1");
                } else {
                    sessionStorage.removeItem("guest_checkout_enabled");
                }
               
                this.spinner.hide();
                this.orderRouting.goToOrderAhead();  
            },
            (err) => {
                console.log(err);
                this.spinner.hide();
            }
        );
      } 
      else
      {
        this.orderRouting.goToOrderAhead();
      }   
  }

  loadOrderAheadSettingsV2() 
  {
     if (this.askedForFutureOrder == false) {
       this.setOrderAheadSetting().then((result) => {
        if(result) 
        {
          /* As per new logic - take user yto catalog picker */
          this.orderRouting.goToCatalogPicker();      
        }
      }, error => {
        console.log(error);
      }).catch(error => {      
        console.log(error);
      });     
      
     } 
     else
     {
        this.orderRouting.goToCatalogPicker();   
     }   
 }

 loadOrderAheadSettingsForURLChange() 
 {
  this.setOrderAheadSetting().then((result) => {
    if(result) 
    {
     console.log(result);
    }
  }, error => {
    console.log(error);
  }).catch(error => {      
    console.log(error);
  });     
 }

  public getOrderAheadSettingPromise(): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      if (!this.orderAhedSettings$) {
        this.dataService.GetOrderAheadSetting(this.customerSession.locationId).subscribe((data) => {
          this.orderAhedSettings$ = data;
          resolve(data);
        }, error => {
          reject(error);
        });
      }
      else 
      {
        resolve(this.orderAhedSettings$);
      }      
    });
  }

  setOrderAheadSetting() : Promise<any>
    {
      return new Promise<any>(async (resolve, reject) => 
      {       
          this.spinner.show();
          await this.getOrderAheadSettingPromise().then((data) => {
            this.askedForFutureOrder = true;
            if (data.orderAheadEnabled == true) {
              this.orderAheadEnabled = true;
            } else {
                this.orderAheadEnabled = false;
            }

            if (data.curbSidePickUpEnabled == true) {
              this.curbSidePickUpEnabled = true;
            } else {
                this.curbSidePickUpEnabled = false;
            }

            if (data.disableInStorePickUp == true) {
              this.disableInStorePickUp = true;
            } else {
                this.disableInStorePickUp = false;
            }

            if (data.disableASAPOrders == true) {
              this.disableASAPOrders = true;
            } else {
              this.disableASAPOrders = false;
            }

            if (data.deliveryEnabled == true) {
              this.deliveryEnabled = true;                        
              this.minimumOrderTotalForDelivery = data.minimumOrderTotalForDelivery;
            } else {
                this.deliveryEnabled = false;
            }

            if (data.isExternalDelivery == true) {
              this.isExternalDelivery = true;
            } else {
                this.isExternalDelivery = false;
            }

            if (data.catalogsTimeFrames) {
                this.setCatalogsTimeFrames(data.catalogsTimeFrames);
              } 

              if (data.disabledUntil) {
                  this.catalogDisabledTime = data.disabledUntil;
                  this.catalogDisabledTimeText = data.disabledUntilText;
              }
              else {
                  this.catalogDisabledTime = undefined;
              }

              if (data.locationGuestCheckoutEnabled == true) {
                sessionStorage.setItem("guest_checkout_enabled", "1");
              } else {
                  sessionStorage.removeItem("guest_checkout_enabled");
              }

            this.spinner.hide();

          }, error => {
            
            this.spinner.hide();
            reject(error);

          }).then(() => {

            resolve(true);

          });         
        
      });
      
  }


    get catalogDisabledTime(): Date{
        let st = sessionStorage.getItem("disabled_catalog_until_" + this.customerSession.locationId);
        if (st) {
            let dt = new Date(st);
            if (dt > (new Date())) {
                return dt;
            }
            else {
                this.catalogDisabledTime = undefined;
                return undefined;
            }
        }
        return undefined;
    }

    set catalogDisabledTime(value) {
        if (value) {
            sessionStorage.setItem("disabled_catalog_until_" + this.customerSession.locationId, value.toString());
        }
        else {
            sessionStorage.removeItem("disabled_catalog_until_" + this.customerSession.locationId);
            this.catalogDisabledTimeText = undefined;
        }
        
    } 

    get catalogDisabledTimeText(): string {
        if (this.catalogDisabledTime) {
            return sessionStorage.getItem("disabled_catalog_until_text_" + this.customerSession.locationId);
        }
        return null;
    }

    set catalogDisabledTimeText(value) {
        if (value) {
            sessionStorage.setItem("disabled_catalog_until_text_" + this.customerSession.locationId, value);
        }
        else {
            sessionStorage.removeItem("disabled_catalog_until_text_" + this.customerSession.locationId);
        }
    } 

  setEditBtnText() {

    let orderTypePicked = sessionStorage.getItem('order_type_picked');

    if (orderTypePicked && orderTypePicked != "") {
        this.editBtnText = this.translate.instant(orderTypePicked);
    } else {
        this.editBtnText = this.translate.instant("In-Store Pickup");
    }      

    this.editbuttonText$.next(this.editBtnText);
   
  }

  getNotesForOrder(){
    let notes = "";
    
    //add notes if any
    if (this.getSpecialInstructions()){
      notes = this.getSpecialInstructions();
    }

    //add utenstils required
    if (this.getUtensilsRequired()){
      if (notes){
        notes += ". Utensils Required.";
      } else {
        notes = "Utensils Required.";
      }
    }

    return notes;
  }

}
