import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { clearInterval } from 'timers';
import { CartService } from '../cart.service';
import { CatalogService } from '../catalog.service';
import { DataService } from '../data.service';
import { CatalogLocation } from '../models/catalog-location';
import { CatalogsTimeFrames } from '../models/catalogs-time-frames';
import { OrderRoutingService } from '../order-routing.service';
import { UtilService } from '../util.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-catalog-picker',
  templateUrl: './catalog-picker.component.html',
  styleUrls: ['./catalog-picker.component.scss']
})
export class CatalogPickerComponent implements OnInit {

  catalogsTimeFrames: CatalogsTimeFrames[];
  showCatalogSelectorPopup: boolean;
  selectedcatalogId: number;
  selectedcatalogName: string = "";
  isComponentLoading: boolean = false;
  logoUrl: string = null;
  selectedLocation: CatalogLocation;
  locations: CatalogLocation[];

  asapStatusForAllTimeFrames: CatalogsTimeFrames[] = [];
  isAsapClosed: boolean = false;
  orderAheadEnabled: boolean = false;
  selectedCatalogTimeFrame: CatalogsTimeFrames;

  disabledUntil: string;
  
  constructor(private cartService: CartService, 
    private catalogService: CatalogService, 
    private orderRouting: OrderRoutingService,
    private utilService: UtilService, 
    private dataService: DataService,
    private authService: AuthService) { }


  ionViewWillEnter() {
    sessionStorage.removeItem("backToGuestOrderStatus");
    this.utilService.postAppSpecificRequestsToFrame();  
    this.isComponentLoading = true;
    this.logoUrl = sessionStorage.getItem("logoUrl");
    this.catalogsTimeFrames = this.cartService.getCatalogsTimeFrames();  
    let lastSelectedCatalogId = this.catalogService.getLastSelectedCatalogId();
    let catalogChangeRequested = this.catalogService.getCatalogChangeRequested();
    this.getCatalogLocations();
    this.selectedLocation = JSON.parse(sessionStorage.getItem("selected_location_details"));
    this.orderAheadEnabled = this.cartService.orderAheadEnabled;    

      
      if (this.cartService.catalogDisabledTime) {      
          this.disabledUntil = "UNTIL " + this.cartService.catalogDisabledTimeText;

          let timerMS = this.cartService.catalogDisabledTime.getTime() - (new Date()).getTime();
          setTimeout(() => {
              this.disabledUntil = undefined;
          }, timerMS);
      }
      else {
          this.disabledUntil = undefined;          
      }

    /* CONDITION 1: Only One Catalog Available */
    if (this.catalogsTimeFrames.length == 1) {
      lastSelectedCatalogId = this.catalogsTimeFrames[0].catalogId;
      this.selectedcatalogId = lastSelectedCatalogId;
      this.onCatalogSelected();
    }    
    
    /* Based on the above conditional check -> lastSelectedCatalogId should be 0 or > than 0 */
    /* Also check - user has clicked change button - if they want to change catalog */
    if(lastSelectedCatalogId && lastSelectedCatalogId > 0 && !catalogChangeRequested) 
    {     
      this.selectedcatalogId = lastSelectedCatalogId;
      this.mapAsapSettingForCatalogs();
      this.onCatalogSelected();
      return;
    }
    /* Highlight div if catalog is already selected */
    if(lastSelectedCatalogId && lastSelectedCatalogId > 0 && catalogChangeRequested) 
    {
        this.selectedcatalogId = lastSelectedCatalogId;        
        this.mapAsapSettingForCatalogs();
        this.catalogService.setCatalogChangeRequested(false);
    }

    if(lastSelectedCatalogId == 0)  
    {
      /* Before going to order picker check if tagetcatalogId exists */
      if (this.catalogService.getTargetCatalogId()) {
        this.selectedcatalogId = this.catalogService.getTargetCatalogId();
        this.onCatalogSelected();
        return;
      }
      else
      {
        this.orderRouting.goToCatalogPicker();
      }      
    }
    this.isComponentLoading = false;
  }

  getCatalogLocations() {
    /* Get Catalog Locations */
    this.dataService.getCatalogLocations().subscribe(async (data) => {
        if (data) {
            this.locations = data;
            if (this.locations != undefined && this.locations != null) {
                for (let location of this.locations) {
                    if (location.merchantLocationId == Number(sessionStorage.getItem("locationId"))) {
                        sessionStorage.setItem("selected_location_details", JSON.stringify(location));
                        this.selectedLocation = location;
                    }
                }
            }
        }
    });
  }

  /* Known Ionic 4 BUG- ngOnInit not  being called always - do not place anthyng here  */
  ngOnInit() {  
    
  }

  back() 
  {
    if((<any>this.utilService.activatedRoute) && (<any>this.utilService.activatedRoute).component && (<any>this.utilService.activatedRoute.component).name ) 
    {
      console.log("Routed back to => " + (<any>this.utilService.activatedRoute.component).name);
      if((<any>this.utilService.activatedRoute.component).name == "CatalogComponent") 
      {
        this.orderRouting.goToCatalog();
        return;
      }
      if((<any>this.utilService.activatedRoute.component).name == "LocationComponent") 
      {
        this.orderRouting.goToLocation();
        return;
      }
      if((<any>this.utilService.activatedRoute.component).name == "OrderAheadPickerComponent") 
      {
        this.orderRouting.goToOrderAheadPicker();
        return;
      }
      else
      {
        this.orderRouting.goToLocation();
        return;
      }       
    }
    else 
    {
      this.orderRouting.goToLocation();
      return;
    }
   
  }

  async onChangeHandler($event) {        
    this.selectedcatalogId = Number($event.currentTarget.id);   
    let catlogChangeResult = await this.catalogService.onCatalogSelectedChange(this.selectedcatalogId); 
    if(catlogChangeResult) 
    {
      this.onCatalogSelected();
    }  
    
  }

  async onCatalogSelected() 
  {
      if(this.selectedcatalogId > 0) 
      {
          var result = this.catalogsTimeFrames.filter(x => x.catalogId == this.selectedcatalogId);
  
          if(result && result.length > 0) 
          {
              this.selectedcatalogName = result[0].catalogName;                   
              await this.continue();
          }
  
      }            
  }

  async continue()
  {  
    if(this.selectedcatalogId >  0)  
    {
      var lastSelectedCatalogId = this.catalogService.getLastSelectedCatalogId();
      this.catalogService.setLastSelectedCatalogId(this.selectedcatalogId);             
      this.catalogService.reloadCatalog = true;  
      
      var isGuest: Boolean = sessionStorage.getItem("authType") == "GUEST" ? true : false;
      if (isGuest && this.selectedcatalogId != lastSelectedCatalogId) {
        this.authService.removeUserData();
      }
      this.orderRouting.goToCatalog();
    }    
  }

  pickLocation() 
  {
    this.orderRouting.goToLocation();
  }

  
  /* LOGICAL METHODS => To Move to Util service as it is being called from many components */
  async mapAsapSettingForCatalogs() {

    if(this.catalogsTimeFrames && this.catalogsTimeFrames.length > 0) 
    {
        console.log( this.catalogsTimeFrames);
        this.asapStatusForAllTimeFrames = this.catalogsTimeFrames.filter(x => x.isAsapAvailable == true);
        console.log( this.asapStatusForAllTimeFrames);
  
        if( this.asapStatusForAllTimeFrames &&  this.asapStatusForAllTimeFrames.length > 0) 
        {
            this.isAsapClosed = false;                                         
        }
        else
        {
            this.isAsapClosed = true;                          
        }
        
        this.selectedCatalogTimeFrame = this.catalogsTimeFrames.find(x => x.catalogId == this.selectedcatalogId);
        console.log(this.selectedCatalogTimeFrame);
    }        
  }
}


