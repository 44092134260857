import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { OrderRoutingService } from '../order-routing.service';
import { CatalogService } from '../catalog.service';
import { AssetService } from '../merchant-assets/asset.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MembershipRoutingService } from '../membership-routing.service';
import { DataService } from '../data.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    constructor(private authService: AuthService,
        private orderRouting: OrderRoutingService,
        private membershipRouting: MembershipRoutingService,
        private catalogService: CatalogService,
        private assetService: AssetService,
        private spinner: NgxSpinnerService,
        private dataService: DataService) { }
  email = '';
  password = '';
  isUnloading = false;
  CURRENT_VIEW_ENTERED = "login";
  membershipModule: boolean = false;
  guestCheckoutEnabled: boolean = false;
  ignoreGuest: boolean = false;
  showGuestCheckout: boolean = false;

  siteKey: string = environment.recaptchaSiteKey;

  async ionViewWillEnter() 
  {
    this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;
    this.guestCheckoutEnabled = sessionStorage.getItem("guest_checkout_enabled") == "1" ? true : false;
    this.ignoreGuest = sessionStorage.getItem("ignoreGuest") == "1" ? true : false;
    this.showGuestCheckout = this.guestCheckoutEnabled && !this.ignoreGuest;
    this.isUnloading = false;
    /* LOAD CUSTOM STYLE IF PROVIDED - <param - component name> */     
    if (sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) && sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) !== "") {
      this.assetService.getCustomCSS(sessionStorage.getItem(this.CURRENT_VIEW_ENTERED), this.CURRENT_VIEW_ENTERED);
    }       
  }

  async ionViewWillLeave() 
  {    
    this.spinner.show(); 
    this.isUnloading = true;    
    const clearCustomCss = () => new Promise<boolean>(async (resolve, reject) => { $("#" + this.CURRENT_VIEW_ENTERED + "_CUSTOM_CSS").remove(); return resolve(true)});  
    await clearCustomCss().then((result) => console.log("CLEARED CUSTOM CSS FOR " + this.CURRENT_VIEW_ENTERED)).then(() => {
      setTimeout(() => {
        this.spinner.hide();
      }, 1000); 
    });      
  }

  async login(){
    this.spinner.show();

    // get action token
    const recaptchaAction = 'LoginOnlineOrder'
    const token = await (<any>window).grecaptcha.enterprise.execute(this.siteKey, {action: recaptchaAction});

    this.authService.authenticateCustomer(this.email, this.password, token, recaptchaAction).subscribe((data) => {
      console.log(data);

      if (this.membershipModule) {
        let membershipForPurchase: number = this.membershipRouting.membershipSelectedForPurchase;
        sessionStorage.removeItem("membershipSelectedForPurchase");
        let membershipToUpdate: number = this.membershipRouting.membershipToUpdate;
        sessionStorage.removeItem("membershipToUpdate");
        let customerId: number = +sessionStorage.getItem('customerId');
      
        if (membershipForPurchase != NaN && membershipForPurchase > 0) {
          // for navigating when customer selects membership for purchase before logging in
          // check whether selected membership is available to customer after login
          this.dataService.getMembershipAvailibility(membershipForPurchase, customerId).subscribe(
            (result) => {
              if (result.isSuccess) {
                this.membershipRouting.goBack();
              } 
            },
            (err) => {
              this.spinner.hide();
              if (err.error.type == "GENERAL") {
                alert(err.error.message);
              } else {
                this.membershipRouting.membershipError = JSON.stringify(err.error);

                if (err.error.type == "BADREQUEST") {
                  this.membershipRouting.redirectToDetails = true;
                  this.membershipRouting.goToMembershipDetails(membershipForPurchase);
                } else {
                  this.membershipRouting.redirectToList = true;
                  this.membershipRouting.goToMembershipList();
                }
              }
            }
          );
        } else if (membershipToUpdate != NaN && membershipToUpdate) {
          // for navigation when customer is directed to specific membership via URL, but was not previously logged in
          // after login, check if membership exists (for merchant or customer) and redirect accordingly
          this.dataService.getMembership(membershipToUpdate).subscribe(
            (data) => {
              this.membershipRouting.goToMembershipDetails(membershipToUpdate);
            },
            (err) => {
              this.spinner.hide();
              console.log(err);
              this.membershipRouting.redirectToList = true;
              this.membershipRouting.goToMembershipList();
            }
          );
        } 
        else {
          this.membershipRouting.goToMembershipList();
        }
      } else {
        let redirectToOrderStatus = sessionStorage.getItem("redirectToOrderStatus") == "1" ? true : false;
        if (redirectToOrderStatus) {
          sessionStorage.removeItem("redirectToOrderStatus");
          let orderId = sessionStorage.getItem("orderGuid");
          if (orderId == null || orderId == undefined) {
            this.orderRouting.goToMyOrders();
          }
          else {
            sessionStorage.removeItem("orderGuid");
            this.orderRouting.goToOrderStatus(orderId);
          }
        }
        else {
          this.catalogService.reloadCatalog = true;
          this.orderRouting.goBack(); 
        }
      }
    }, (err) => {
      this.spinner.hide();
      console.log('got an error trying to authenticate customer', err);      
      if (err.status != 401){
        alert(err.error.message);
      }
    });
  }

  async proceedToCheckout() {
    this.spinner.show()

    // get action token
    const recaptchaAction = 'GuestCheckoutOnlineOrder'
    const token = await (<any>window).grecaptcha.enterprise.execute(this.siteKey, {action: recaptchaAction});

    this.authService.authenticateGuest(token, recaptchaAction).subscribe((data) => {
      this.catalogService.reloadCatalog = true;
      this.orderRouting.goBack(); 
    }, (err) => {
      this.spinner.hide();
      console.log('error authenticating guest for online ordering', err);
      if (err.status != 401) {
        alert(err.error.message);
      }
    });
  }

  goToRegister(){
    console.log('go to register');
    if (this.membershipModule) {
      this.membershipRouting.goToRegister();
    } else {
      this.orderRouting.goToRegister();
    }
  }

  goToForgotPassword(){
    console.log('go to forgot password');
    if (this.membershipModule) {
      this.membershipRouting.goToForgotPassword();
    } else {
      this.orderRouting.goToForgotPassword();
    }
  }

  goToConnectInStore() {
    console.log('go to connect in-store');
    if (this.membershipModule) {
      this.membershipRouting.goToConnectInStore();
    } else {
      this.orderRouting.goToConnectInStore();
    }
  }

  back() {
    if (this.membershipModule) {
      let membershipForPurchase: number = this.membershipRouting.membershipSelectedForPurchase;
      if (membershipForPurchase != NaN && membershipForPurchase > 0) {
        // if customer exits login page after selecting a membership for purchase, return to membership details page
        sessionStorage.removeItem("membershipSelectedForPurchase");
        this.membershipRouting.goToMembershipDetails(membershipForPurchase);
      } else {
        if (this.membershipRouting.membershipToUpdate) sessionStorage.removeItem("membershipToUpdate");
        this.membershipRouting.goToMembershipList();
      }
    } else {
      let backToGuestOrderStatus = sessionStorage.getItem("backToGuestOrderStatus") == "1" ? true : false; // set when redirected to login page from guest order status
      let redirectToOrderStatus = sessionStorage.getItem("redirectToOrderStatus") == "1" ? true : false; // set when guest order status page found the order belongs to a customer
      if (backToGuestOrderStatus && !redirectToOrderStatus) {
        sessionStorage.removeItem("backToGuestOrderStatus");
        let orderId = sessionStorage.getItem("orderGuid");
        if (orderId == null || orderId == undefined) {
          this.orderRouting.goToCatalog();
        }
        else {
          this.orderRouting.goToGuestOrderStatus(orderId);
        }
      }
      else {
        sessionStorage.removeItem("backToGuestOrderStatus");
        sessionStorage.removeItem("redirectToOrderStatus");
        this.orderRouting.goToCatalog();
      }
    }
  }

}
