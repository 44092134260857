import { CartItemModifier } from "../cart/cart.model";

export class OrderModifier {
    constructor(modifierId:number, itemModifierGroupId: number, modifierModifierGroupId: number, modifierGroupId: number, quantity: number, modifiers: CartItemModifier[]){
        this.id = modifierId;
        this.quantity = quantity ? quantity : 1;
        this.itemModifierGroupId = itemModifierGroupId;
        this.modifierModifierGroupId = modifierModifierGroupId;
        this.modifierGroupId = modifierGroupId;
        this.modifiers = this.GetNestedOrderModifiers(modifiers);
    }
    id: number;
    quantity: number;
    itemModifierGroupId: number;
    modifierModifierGroupId: number;
    modifierGroupId: number;
    modifiers: OrderModifier[];

    private GetNestedOrderModifiers(modifiers: CartItemModifier[]): OrderModifier[] {
        if (!modifiers || modifiers.length == 0) return [];
        let orderModifiers = [];
        modifiers.forEach(m => orderModifiers.push(new OrderModifier(m.id, m.itemModifierGroupId, m.modifierModifierGroupId, m.modifierGroupId, m.quantity, m.modifiers)));
        return orderModifiers;
    }
}