import { CartItem } from "../cart/cart.model";

export class FutureOrderDateTime {
    year: number;
    month: number;
    day: number;
    hour: number;
    min: number;
}

export class ValidateFutureOrderRequest {
    catalogId: number;
    merchantLocationId: number;
    futureOrderDateTime: FutureOrderDateTime;
    isFutureOrder: boolean;
    items: CartItem[];
}