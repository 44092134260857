import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { OrderRoutingService } from '../order-routing.service';
import { CardCaptureComponent } from '../common/card-capture/card-capture.component';
import { PaymentService } from '../payment.service';
import { MembershipPaymentService } from '../membership-payment.service';
import { MembershipRoutingService } from '../membership-routing.service';
import { ApplePayLineItem } from '../models/apple-payment-data';
import { ToastApplePayService } from '../toast-apple-pay.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-checkout',
  templateUrl: './payment-checkout.component.html',
  styleUrls: ['./payment-checkout.component.scss']
})
export class PaymentCheckoutComponent implements OnInit {

    buttonText: string;
    membershipModule: boolean = false;

    showPaymentConfirmation: boolean = false;

    merchantGuid: string;
    merchantName: string = "";

    constructor(private orderRouting: OrderRoutingService,
        public alertController: AlertController,
        private paymentService: PaymentService,
        private membershipPaymentService: MembershipPaymentService,
        private membershipRouting: MembershipRoutingService,
        private toastApplePayService: ToastApplePayService,
        private translate: TranslateService) { }    

    capturePurpose = this.translate.instant("Please provide your payment info.");
    @ViewChild('cardCapture') cardCapture: CardCaptureComponent;    


    ngOnInit() {
        this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;
        if (this.membershipModule) {
            this.buttonText = this.membershipPaymentService.getPaymentBtnText();
        } else {
            this.buttonText = this.paymentService.getActionText();
        }

        this.merchantGuid = this.orderRouting.guid;
        this.merchantName = sessionStorage.getItem("merchantName");
    }

    async processPayment() {

        let cardDetails = this.cardCapture.getCardDetails();

        if (cardDetails.valid) {            
            if (this.membershipModule) {
                this.membershipPaymentService.paymentDetails.CardNumber = cardDetails.cardNumber;
                this.membershipPaymentService.paymentDetails.CardExpirationMonth = +cardDetails.expiryMonth;
                this.membershipPaymentService.paymentDetails.CardExpirationYear = +cardDetails.expiryYear;
                this.membershipPaymentService.paymentDetails.CardCVV = cardDetails.cvv;
                this.membershipPaymentService.paymentDetails.CardHolderName = cardDetails.cardHolderName;
                this.membershipPaymentService.paymentDetails.PayWithSavedCreditCard = false;
                this.membershipPaymentService.paymentDetails.PostalCode = cardDetails.postal;
                this.membershipPaymentService.paymentDetails.CountryCode = cardDetails.countryCode;
                this.processMembershipPayment();
            } else {
                this.paymentService.request.cardNumber = cardDetails.cardNumber;
                this.paymentService.request.cardExpirationMonth = +cardDetails.expiryMonth;
                this.paymentService.request.cardExpirationYear = +cardDetails.expiryYear;
                this.paymentService.request.cardCVV = cardDetails.cvv;
                this.paymentService.request.cardHolderName = cardDetails.cardHolderName;
                this.paymentService.request.saveCreditCard = cardDetails.saveCardDetail;
                this.paymentService.request.payWithSavedCreditCard = false;
                this.paymentService.request.postalCode = cardDetails.postal;
                this.paymentService.request.countryCode = cardDetails.countryCode;
                this.paymentService.processPayment();
            }
        }
        else {
            return;
        }                
    }

    async processMembershipPayment() {
        await this.membershipPaymentService.processPayment()
            .then((result) => {
                if(result) {
                    this.showPaymentConfirmation = true;
                }
            })
            .catch(error => {
                console.log(error);
            }); 
    }  

    continue() {
        this.membershipRouting.goToMembershipList();
    }

    back() {
        if (this.membershipModule) {
            this.membershipRouting.goToCheckout();
        } else {
            this.orderRouting.goToCheckout();
        }
    }  
}
