import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { GoogleAnalyticService } from '../google-analytic.service';
import { LoadingController } from '@ionic/angular';
import { OrderRoutingService } from "../order-routing.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'order-receipt',
  templateUrl: './order-receipt.component.html',
  styleUrls: ['./order-receipt.component.scss']
})
export class OrderReceiptComponent {
  receipt: any;
  status: any;
  statusDate : string;
  statusTime : string;
  pickupTSDate : string;
  pickupTSTime : string;
  placedTSDate : string;
  placedTSTime : string;
  pickUpType : string;
  pickUpInfo : string;
  deliveryAddress: string;
  guid: string = "";
  merchantNotes: string;
  currencySymbol: any;

  constructor(public route: ActivatedRoute, 
    private dataService: DataService, 
    private location: Location,
    private ga: GoogleAnalyticService,
    private loadingController: LoadingController,
    private orderRouting: OrderRoutingService,
    private translate: TranslateService) { }

  async ionViewDidEnter() {
    this.guid = this.route.snapshot.params['guid'];  

    const loading = await this.loadingController.create({
      message: this.translate.instant('Loading...')
    });

    await loading.present();
    //call api to get the data for receipt
    if (this.guid){
      this.dataService.getOrderDetails(this.guid)
        .subscribe(
          (data) => { 
              console.log(data);
              this.receipt = data.receipt;
              this.currencySymbol = this.receipt.currencySymbol;
              this.status = data.lastStatus;
              this.statusDate = data.lastStatusUpdatedOnDate;
              this.statusTime =  (data.lastStatusUpdatedOnTime != null) ? data.lastStatusUpdatedOnTime.toString().replace(/^0+/, "") : "";
              this.pickupTSDate = data.pickupTSDate;
              this.pickupTSTime = (data.pickupTSTime != null) ?  data.pickupTSTime.toString().replace(/^0+/, "") : "";
              this.placedTSDate = data.placedTSDate;
              this.placedTSTime =  (data.placedTSTime != null) ?  data.placedTSTime.toString().replace(/^0+/, "") : "";
              this.pickUpType = data.pickUpType;
              this.pickUpInfo = data.pickUpInfo;
              this.deliveryAddress = data.deliveryAddress;
              this.merchantNotes = data.merchantNotes;
              this.ga.sendEvent('receipt_viewed','');
              loading.dismiss()
          },
          (err) => { 
            loading.dismiss();
            alert(err.message);
          } 
        )
    }
  }

  back(){
    if (this.status == "NEW" || this.status == "ACCEPTED" || this.status == "FULLFILLED") {
      if (sessionStorage.getItem("authType") == "GUEST") {
        this.orderRouting.goToGuestOrderStatus(this.guid);
      }
      else {
        this.orderRouting.goToOrderStatus(this.guid);
      }
    } 
    else {
      this.orderRouting.goToMyOrders();
    }
  }
}
