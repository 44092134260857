export class OrderProgress {
    steps : OrderStep[];
    currentStep : OrderStep;
}

export class OrderStep {
    icon :string;
    title :string;
    subtitle :string;
    iconSrc : string;
    stepNumber : number;
    prevStepStatus : string;
    completed : boolean;
}