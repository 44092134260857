import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl, ValidatorFn } from '@angular/forms';
import { CustomerSessionService } from '../../customer-session.service';
import { DataService } from '../../data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorHandlerService } from '../../error-handler.service';
import { TranslateService } from '@ngx-translate/core';
//import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'card-capture',
    templateUrl: './card-capture.component.html',
    styleUrls: ['./card-capture.component.scss']
})
export class CardCaptureComponent implements OnInit {
    @Input() capturePurpose: string;

    secureBy: string;

    cardCaptureForm: FormGroup;
    cardNumber: FormControl;
    expiry: FormControl;
    cvv: FormControl;
    postal: FormControl;
    cardHolderName: FormControl;
    countryCode: FormControl;
    saveCardDetail: FormControl;
    errors: string[] = [];
    isStoredCardEnabled: boolean;
    isCountryRequired: boolean = false;
    defaultCountryCode: string = 'USA';
    countries: any;
    membershipModule: boolean = false;

    cardNumberMask: Array<any> = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/,];
    expiryMask: Array<any> = [/[0-1]/, /\d/, '/', /\d/, /\d/];
    cvvMask: Array<any> = [/\d/, /\d/, /\d/, /\d/, /\d/];

    constructor(private fb: FormBuilder,
        private dataService: DataService,
        private spinner: NgxSpinnerService,
        private errorHandlerService: ErrorHandlerService,
        private customerSession: CustomerSessionService,
        private el: ElementRef,
        private translate: TranslateService) { }

    async ngOnInit() {
        this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;
        this.secureBy = await this.customerSession.getPaymentProvider(this.membershipModule);
        this.isStoredCardEnabled = await this.customerSession.getStoredCardEnabledSettings();

        console.log(this.secureBy);
        if (this.secureBy == 'Toast' || this.secureBy == "TOAST") {
            await this.getCountriesAlpha3();
            this.isCountryRequired = true;
        }

        this.cardNumber = new FormControl('', [Validators.required]);
        this.expiry = new FormControl('', [Validators.required]);
        this.cvv = new FormControl('', [Validators.required]);
        this.postal = new FormControl('', [Validators.required]);
        this.cardHolderName = new FormControl('', [Validators.required]);
        if (this.membershipModule) {
            this.saveCardDetail = new FormControl(true)
        } else {
            this.saveCardDetail = new FormControl(false);
        }
        if (this.isCountryRequired) this.countryCode = new FormControl('', [Validators.required]);

        this.cardCaptureForm = this.fb.group({
            'cardNumber': this.cardNumber,
            'expiry': this.expiry,
            'cvv': this.cvv,
            'postal': this.postal,
            'cardHolderName': this.cardHolderName,
            'saveCardDetail': this.saveCardDetail,
            'countryCode': this.countryCode
        });

        if (this.isCountryRequired) {
            this.cardCaptureForm.controls['countryCode'].setValue(this.defaultCountryCode, { onlySelf: true });            
        }        

        //to autoscroll to the default selected option when ion-select alert opens.
        window.addEventListener('ionAlertDidPresent', e => {
            const selected = (e.target as HTMLElement).querySelector('[aria-checked="true"]');
            selected && selected.scrollIntoView();
        });
    }

    async getCountriesAlpha3() {
        this.spinner.show();
        let locationId = this.customerSession.locationId;
        return this.dataService.getCountriesAlpha3(locationId).toPromise().then(
            (result) => {
                console.log(result);
                this.spinner.hide();
                if (result.isSuccess) {
                    this.countries = result.data.countries;      
                    this.setDefaultMerchantContry(result.data.merchantCountryCode);
                    console.log(this.countries);
                } else {
                    this.countries = null;
                    this.errorHandlerService.alertMessage(result.message);
                }
            }, (err) => {
                console.log(err);
                this.spinner.hide();
                this.errorHandlerService.alertMessage(this.translate.instant("Something went wrong while getting the Countries."));
            }
        );
    }
    setDefaultMerchantContry(merchantCountryCode: string) {
        if (merchantCountryCode != "" && merchantCountryCode != null && merchantCountryCode != undefined) {
            this.defaultCountryCode = merchantCountryCode;
        }
    }

    getCardDetails(): cardCaptureDetails {
        return this.ValidateCardDetails();
    }

    ValidateCardDetails(): cardCaptureDetails {
        let cardDetails = new cardCaptureDetails();
        this.errors = [];

        this.validateCardNumber(cardDetails);
        this.validateExpiry(cardDetails);
        this.validateCVV(cardDetails);


        //validate postal
        let postalCode: string = this.cardCaptureForm.value.postal;
        if (postalCode.length > 0) {
            //if (postalCode.length >= 4) {
            cardDetails.postal = postalCode;
            //}
            //else {
            //    cardDetails.valid = false;
            //    this.errors.push("Zip/Postal code is invalid.");
            //}
        }
        else {
            cardDetails.valid = false;
            this.errors.push(this.translate.instant("Zip/Postal code required."));
        }

        if (this.isCountryRequired) {
            cardDetails.countryCode = this.cardCaptureForm.value.countryCode;
        }

        cardDetails.cardHolderName = this.cardCaptureForm.value.cardHolderName;
        cardDetails.saveCardDetail = this.cardCaptureForm.value.saveCardDetail;

        if (!cardDetails.valid) {
            this.showCustomModal();
        }       

        return cardDetails;
    }

    showCustomModal() {
        $('#errorModal').show();
        $(".main-div").css({ opacity: 0.5 });
    }

    closeCustomModal() {
        $('#errorModal').hide();
        $(".main-div").css({ opacity: 1 });
    }

    validateCardNumber(cardDetails = new cardCaptureDetails()) {
        //Validate card number
        let crdNumber = this.cardCaptureForm.value.cardNumber.replace(/\s/g, "");
        if (crdNumber.length > 0) {
            if (crdNumber.length >= 12) {
                cardDetails.cardNumber = crdNumber;
                return true;
            }
            else {
                cardDetails.valid = false;
                this.errors.push(this.translate.instant("Card number is invalid."));
                return false;
            }
        }
        else {
            cardDetails.valid = false;
            this.errors.push(this.translate.instant("Card number required."));
            return false;
        }
    }

    validateExpiry(cardDetails = new cardCaptureDetails()) {
        //Validate Expiry
        let exp: string = this.cardCaptureForm.value.expiry;
        if (exp.length > 0) {
            if (exp.length == 5 && exp.indexOf('/') > 0) {
                let month: number = +(exp.split('/')[0]);
                let year: number = +("20" + exp.split('/')[1]);
                let datetime = new Date();
                if (month > 0 && month < 13) {
                    if (year > datetime.getFullYear()) {
                        cardDetails.expiryMonth = month.toString();
                        cardDetails.expiryYear = year.toString().slice(2, 4);
                        return true;
                    }
                    else if (year == datetime.getFullYear()) {
                        if (month >= datetime.getMonth()) {
                            cardDetails.expiryMonth = month.toString();
                            cardDetails.expiryYear = year.toString().slice(2, 4);
                            return true;
                        }
                        else {
                            cardDetails.valid = false;
                            this.errors.push(this.translate.instant("Expiry date is invalid."));
                            return false;
                        }
                    }
                    else {
                        cardDetails.valid = false;
                        this.errors.push(this.translate.instant("Expiry date is invalid."));
                        return false;
                    }
                }
                else {
                    cardDetails.valid = false;
                    this.errors.push(this.translate.instant("Expiry date is invalid."));
                    return false;
                }
            }
            else {
                cardDetails.valid = false;
                this.errors.push(this.translate.instant("Expiry date is invalid."));
                return false;
            }
        }
        else {
            cardDetails.valid = false;
            this.errors.push(this.translate.instant("Expiry date required."));
            return false;
        }
    }

    validateCVV(cardDetails = new cardCaptureDetails()) {
        //validate CVV
        let cvvNumber: string = this.cardCaptureForm.value.cvv;
        if (cvvNumber.length > 0) {
            if (cvvNumber.length >= 3) {
                cardDetails.cvv = cvvNumber;
                return true;
            }
            else {
                cardDetails.valid = false;
                this.errors.push(this.translate.instant("CVV is invalid."));
                return false;
            }
        }
        else {
            cardDetails.valid = false;
            this.errors.push(this.translate.instant("CVV number required."));
            return false;
        }
    }

    //cardNumberValidator(): ValidatorFn {
    //    return (control: AbstractControl): { [key: string]: boolean } | null => {
    //        if (!this.validateCardNumber()) {
    //            return { 'invalid': true }
    //        }
    //        return null;
    //    };
    //}

    //expiryValidator(): ValidatorFn {
    //    return (control: AbstractControl): { [key: string]: boolean } | null => {
    //        if (!this.validateExpiry()) {
    //            return { 'invalid': true }
    //        }
    //        return null;
    //    };
    //}

    //cvvValidator(): ValidatorFn {
    //    return (control: AbstractControl): { [key: string]: boolean } | null => {
    //        if (!this.validateCVV()) {
    //            return { 'invalid': true }
    //        }
    //        return null;
    //    };
    //}

    goToNextElement(evnt, nextElement) {
        if (nextElement.name == "expiry" && evnt.target.value.length >= 19) {
            nextElement.setFocus();
        }
        else if (nextElement.name == "cvv" && evnt.target.value.length >= 5) {
            nextElement.setFocus();
        }
        else if (nextElement.name == "postal" && evnt.target.value.length >= 5) {
            nextElement.setFocus();
        }
        else if (nextElement.name == "cardHolderName" && evnt.target.value.length >= 7) {
            nextElement.setFocus();
        }
    }
}
    
export class cardCaptureDetails {
    cardNumber = "";
    expiryMonth = "";
    expiryYear = "";
    cvv = "";
    postal = "";
    cardHolderName = "";
    saveCardDetail = false;
    valid = true;  
    countryCode = "";
}
