import { Component, Input, OnInit } from "@angular/core";
import { CustomerSessionService } from "../customer-session.service";
import { AssetService } from "../merchant-assets/asset.service";

@Component({
  selector: "merchant-header",
  templateUrl: "./merchant-header.component.html",
  styleUrls: ['./merchant-header.component.scss']
})
export class MerchantHeaderComponent implements OnInit {
  @Input() useMerchant: boolean;
  @Input() selectedLocation: any;

  name: string = "";
  description: string = "";
  address: string = "";
  headerImageUrl: string = "";
  CURRENT_VIEW_ENTERED = "merchant-header";

  constructor(
    private customerSession: CustomerSessionService,
    private assetService: AssetService
  ) {}

  ngOnInit() { 
    /* LOAD CUSTOM STYLE IF PROVIDED - <param - component name> */ 
    if (sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) && sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) !== "") {
      this.assetService.getCustomCSS(sessionStorage.getItem(this.CURRENT_VIEW_ENTERED), this.CURRENT_VIEW_ENTERED);
    }    
    console.log("MERCHANT HEADER INIT COMPONENT");
    this.initComponent();
  }

  initComponent() {
    console.log("MERCHANT HEADER INIT FUNCTION CALLED");
    let currentLocationId = this.customerSession.locationId;
    if (currentLocationId > 0 && this.useMerchant !== true) {
      let location = this.customerSession.locations.find(
        (location) => currentLocationId === location.merchantLocationId
      );
      if (location) {
        this.name = location.businessDetails.name;
        this.address = location.businessDetails.address;
        this.description = location.businessDetails.location;
      }
      this.setHeaderImage();
    } else {
      this.assetService.getMerchantAssets().subscribe((assets) => {
        this.name = assets.headerName;
        this.setHeaderImage();
      });
    }
  }

  setHeaderImage() {
    this.assetService.getMerchantAssets().subscribe((assets) => {
      if (assets.headerImgUrl) {
        this.headerImageUrl = assets.headerImgUrl;
      }
    });
  }
}
