import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '../cart.service';
import { CartItem } from '../cart/cart.model';
import { CatalogService } from '../catalog.service';
import { Item } from '../models/item';
import { Modifier } from '../models/modifier';
import { ModifierGroup } from '../models/modifier-group';
import { GoogleAnalyticService } from '../google-analytic.service';
import { OrderRoutingService } from '../order-routing.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertPopupComponent } from '../common/alert-popup/alert-popup.component';
import { UtilService } from '../util.service';
import { CustomerSessionService } from '../customer-session.service';
import { SpecialInstructionsComponent } from '../special-instructions/special-instructions.component';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectedModifier } from '../models/selected-modifier';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})


export class ItemComponent implements OnInit, AfterViewInit, OnDestroy {

  item: Item;
  public quantity: number = 1;
  valid :boolean = false;
  modifierRequiredCount = 0; 
  totalPrice :number;
  specialInstructions: string;
  catalogHasImage: boolean = false;
  currencySymbol: any;
  expandModifer: boolean = false;
  hideItemPageSpecialInstruction = false;
  placeHolderImageUrl: string = '/assets/imgs/placeHolder.png';
  multipleModifierQuantity: boolean = false;
  source: string;
  isRecommendation: boolean = false;
  isUpdate: boolean = false;
  isAutoAdd: boolean = false;
  updateItemAddedToCart: boolean = false;
  backButtonType: string = "close";
  updateItem: Item;
  plusorminus: string;
  missingSelectedModifiers: SelectedModifier[];
  includedSelectedModifiers: SelectedModifier[];
 
  
  @ViewChild('alertPopup') alertPopup: AlertPopupComponent; 
  
  public modalOpen: boolean = false;
  public closeResult: string;
  @ViewChild("speciaInstructionModal") SpeciaInstructionModal: TemplateRef<any>;

  constructor(public route: ActivatedRoute, 
              public catalogService: CatalogService,
              public cartService: CartService, 
              private customerSession: CustomerSessionService,
              public router: Router,
              private ga: GoogleAnalyticService,
              private utilService: UtilService,
              private orderRouting: OrderRoutingService,
              private el: ElementRef,
              private modalService: NgbModal,
              private spinner: NgxSpinnerService,
              private translate: TranslateService) { }

    async ngOnInit() {}

    ngAfterViewInit() {
   
    }
  
    ngOnDestroy() {
      if(this.modalOpen){
        this.modalService.dismissAll();
      }
      
      sessionStorage.removeItem("autoAddItemWithModifierIndex");
      if(this.isAutoAdd && !this.updateItemAddedToCart) {
        this.removeAutoAddItemFromCart()
      }
    }

    ionViewWillEnter(){
                
      if(sessionStorage.getItem("placeHolderImgUrl") && sessionStorage.getItem("placeHolderImgUrl") != "") 
      {
        var splitArray = sessionStorage.getItem("placeHolderImgUrl").split(sessionStorage.getItem("guid"));
        var sizeParameter = "250xAUTO";
        var new_url = splitArray[0] + `${sessionStorage.getItem("guid")}/` + sizeParameter + splitArray[1];
        this.placeHolderImageUrl = new_url;
      }
  
      this.item = null;   
    }      
              
    async ionViewDidEnter() {

        await this.spinner.show();
        this.multipleModifierQuantity = await this.catalogService.isAllowMultipleModifierQuantity(this.customerSession.locationId);
        await this.spinner.hide();


        console.log("Entered View");
        let id = this.route.snapshot.params['id'];

        this.route.queryParams.subscribe(params => {
          this.source = params['source'];

          if (this.source && this.source == "recommendation"){
            this.isRecommendation = true;
          } else {
            this.isRecommendation = false;
          }
        });

      console.log("isRecommendation: " + this.isRecommendation);

      this.catalogService.getItemDetailsById(id).subscribe((itemDetail) => {
        //if we dont have catalog loaded
        if(itemDetail == null){
          this.orderRouting.goToCatalog();
          return;
        }      

        //another way to deep clone
        let item = JSON.parse(JSON.stringify(itemDetail));
        this.item = item;
          this.item.modifierGroups.forEach(mg => {
              this.onModifierQuantityChanged(mg, this.item.modifierGroups)

              if (mg.isIncluded) {
                  mg.modifiers.forEach(m => {
                      if (m.soldOut) {
                          m.selected = false;
                          m.quantity = 0;
                      }
                  })
              }
          });
        this.currencySymbol = this.catalogService.currencySymbol;
        let checkPreselected = true;
        this.missingSelectedModifiers = [];
        this.includedSelectedModifiers = [];
        this.route.queryParams.subscribe(params => {
          this.source = params['source'];  
          if(this.source && (this.source == "edit" || this.source == "auto-add")) {
            this.isUpdate = true;
            let id = this.route.snapshot.params['id'];
            let uniqueLineItemId = params['uniqueLineItemId'];
            this.updateItem = this.cartService.order.details.lines.find(x => x.uniqueLineItemId == uniqueLineItemId && x.itemId == id);
            checkPreselected = false;
            if(this.updateItem) {
              if ((<any>this.updateItem).notes) {
                this.specialInstructions = (<any>this.updateItem).notes;
              }              
              this.quantity = (<any>this.updateItem).quantity; 
              this.setModifiersForUpdate(this.item.modifierGroups, (<any>this.updateItem).modifiers);
              console.log(this.item);              
            }
            if(this.source == "auto-add") {
              checkPreselected = true;
              this.isAutoAdd = true;
              this.backButtonType = "auto-add";
              let lineItemIndex = params['lineItemIndex'];
              sessionStorage.setItem("autoAddItemWithModifierIndex", lineItemIndex);
            }
            console.log(this.updateItem);
          } else if (this.source && this.source == "recently-ordered") {           
            this.catalogService.getRecentlyOrderedItemModifiersByItemId(id).subscribe((modifier) => {
              if(modifier) {
                this.quantity = modifier.quantity;
                if (modifier.notes) {
                  this.specialInstructions = modifier.notes;
                } 
                if(modifier.selectedModifiers) {
                  checkPreselected = false;
                }
                this.setModifiersForUpdate(this.item.modifierGroups, modifier.selectedModifiers);

                // automatically select modifiers from included modifier group that were not previously unselected (newly added included modifiers)
                let unselectedModifierNames = modifier.selectedModifiers
                  .filter(modifier => modifier.id == 0 && (modifier.name.startsWith("No Add or Extra ") || modifier.name.startsWith("No ")))
                  .map(modifier => modifier.name.replace("No ", "").replace("Add or Extra ", ""));

                let includedModifierGroup = this.item.modifierGroups.find(group => group.isIncluded);
                  if (includedModifierGroup && includedModifierGroup.modifiers) {
                    includedModifierGroup.modifiers.forEach((modifier: any) => {
                        if (!unselectedModifierNames.includes(modifier.name)) {
                            modifier.selected = true
                            this.onModifierSelected(modifier, includedModifierGroup, this.item.modifierGroups)
                        }
                    });
                }

                // warn about missing modifiers from previous order
                let missingModifiers = this.missingSelectedModifiers.filter((value, index, self) => {
                   if(!this.includedSelectedModifiers.map(e => e.id).includes(value.id)) {
                    // check if included modifiers contain the same name as the missing modifier (i.e. ID has changed but name is the same)
                    if (!includedModifierGroup.modifiers.map(e => e.name).includes(value.name.replace("Add or Extra ", ""))) {
                      return self.indexOf(value) === index;
                    }
                  }
                });

                let missingModifierString = missingModifiers.map(e => e.name).join(", ");
                if(missingModifierString.length > 0) {
                    this.alertPopup.show(this.translate.instant("Warning!"), this.translate.instant("Oops! It looks like the following modifier(s) are no longer available for this item: {missing_modifiers}. Please choose another modifier or item.", { missing_modifiers: missingModifierString}));
                }
               }
            });
          }
        });

        this.updateItemStatus();     
        if(this.item.modifierGroups.length == 1) 
        {
          this.expandModifer = true;
        }  
        if (checkPreselected) {
          this.preSelectIncluded(this.item.modifierGroups);
        } 
      
      });

      this.ga.sendEvent('menu_item_viewed', this.item.name);
      this.catalogHasImage = (<any>this.item).hasImage;
      //this.categoryTrackedService.getSelectedCatalogHasImages().subscribe((result) => {
      //  this.catalogHasImage = result;
      //});
      this.getHideItemPageSpecialInstruction();
  }

  private setModifiersForUpdate(modifierGroups: ModifierGroup[], modifiers: any){
    if (!modifierGroups || modifierGroups.length == 0 || !modifiers || modifiers.length == 0) return;

    modifierGroups.map((modifiergroup: ModifierGroup) => {   
      modifiers.forEach(element => {
        let findModifierInUpdateItem = modifiergroup.isIncluded 
          ? modifiergroup.modifiers.find(x => element.hideOnCartAndOrder && Math.abs(x.id) == Math.abs(element.id) && x.soldOut != true)
          : modifiergroup.modifiers.find(x => x.id == element.id && x.soldOut != true);
        if(findModifierInUpdateItem) {
          console.log(findModifierInUpdateItem);
          findModifierInUpdateItem.selected = true;
          if (modifiergroup.selectionMin == 1 && modifiergroup.selectionMax == 1) {
            modifiergroup.modifierSelected = findModifierInUpdateItem;
          }
          if (element.quantity) {
            findModifierInUpdateItem.quantity = element.quantity;   
          }                   
          this.onModifierSelected(findModifierInUpdateItem, modifiergroup, modifierGroups);  
          
          this.setModifiersForUpdate(findModifierInUpdateItem.modifierGroups, (element.modifiers ? element.modifiers : element.selectedModifiers));
          this.includedSelectedModifiers.push(element);
        } else {
          if (element.id !== 0)
            this.missingSelectedModifiers.push(element);
        }
      }); 
    })  
  }
  
  getHideItemPageSpecialInstruction() {
        if (sessionStorage.getItem("hide_item_page_special_instruction") == "1") {
            this.hideItemPageSpecialInstruction = true;
        }
  }  

  preSelectIncluded(modifierGroups: ModifierGroup[]) {
    if (modifierGroups && modifierGroups.length > 0) {
      modifierGroups.forEach((mg) => {
          mg.modifiers.forEach((m) => {
              if (m.preselect == true && m.soldOut != true) {
                  m.selected = true;
                  this.onModifierSelected(m, mg, modifierGroups);
              }
              this.preSelectIncluded(m.modifierGroups);
          });
      });
    }
  }
  
  onModifierSelected(modifier :Modifier, group:ModifierGroup, siblingGroups:ModifierGroup[]){
    if(group.modifierSelected){ //update selected from radio group for max selection == 1
      group.modifiers.forEach((m) =>{
          m.selected = false;
          m.quantity = 0;
          if (m.id === group.modifierSelected.id) {
              m.selected = true;
              m.quantity = 1;
          }          
      })
    }
    let modifiers = group.modifiers.filter((m) => m.selected == true);  
    if (!group.isIncluded) {

        if (this.multipleModifierQuantity == false) {
            group.modifiers.forEach(m => {
                if (m.selected) m.quantity = 1;
                else m.quantity = 0;
            });
        }

        let allQuantity = modifiers.map(x => x.quantity);
        let modifiersCount = allQuantity.length > 0 ? allQuantity.reduce((prev, next) => prev + next) : 0;

        if (modifiersCount  === group.selectionMax) {
            group.hasMaxSelected = true;
        }
        if (modifiersCount > group.selectionMax) {
            modifier.selected = false;
            return;
        }
        else if (modifiersCount < group.selectionMax) {//if previously had max selected
            group.hasMaxSelected = false;
        }
    }
      
    if (siblingGroups.some(mg => mg.isIncluded)) {
        this.checkMaxWithIncluded(group, siblingGroups);
    }
    else {
        this.updateItemStatus();

        let allQuantity = modifiers.map(x => (x.quantity == undefined || x.quantity == null) ? 1 : x.quantity );
        let modifiersCount = allQuantity.length > 0 ? allQuantity.reduce((prev, next) => prev + next) : 0;
        group.totalSelected = modifiersCount;
    }
  }
  
  updateItemStatus() { 
    this.valid = this.isValid();
    this.totalPrice = this.getTotalPrice();
    // this.modifierRequiredCount = this.getRequiredCount();
  }

  isValid(){
    let valid = true;
    this.item.modifierGroups.forEach(group => valid = valid && this.isModifierGroupValid(group));
    return valid;
  }

  isModifierGroupValid(group: ModifierGroup) : boolean {
    let valid = true;
    if (group != null && !group.isIncluded && group.modifiers.length > 0) {
      let modifiers = group.modifiers.filter((modifier) => modifier.selected == true);
      let totalQuantity = 0;      
      modifiers.forEach(m => {
        totalQuantity = totalQuantity + m.quantity;
        m.modifierGroups.forEach(mg => valid = valid && this.isModifierGroupValid(mg));
      });
      valid = valid && (totalQuantity >= group.selectionMin && group.selectionMax >= totalQuantity);
    }
    return valid;
  }

  // getRequiredCount(){
  //   let totalRequiredCount = 0;
  //   this.item.modifierGroups.forEach(group => {     
  //     if (group.selectionMin > 0){    
  //       let modifiers = group.modifiers.filter((modifier) => modifier.selected == true);
  //       let requiredCount = group.selectionMin - modifiers.length;
  //       if (requiredCount <= 0){
  //         requiredCount = 0;
  //       }
  //       totalRequiredCount += requiredCount;
  //     }
  //   });
  //   return totalRequiredCount;
  // }

  getTotalPrice(){
    let itemPrice = this.item.price;
    let additionalTotal = this.getModifiersPrice(this.item.modifierGroups);
    let itemTotal = itemPrice + additionalTotal;
    return itemTotal * this.quantity;
  }

  private getModifiersPrice(modifierGroups: ModifierGroup[]): number {
    let total = 0;
    if (modifierGroups && modifierGroups.length > 0) {
      modifierGroups.forEach(group => {
        let modifiers = group.modifiers.filter((modifier) => modifier.selected == true);
        modifiers.forEach((modifier) => {
          if(modifier.price > 0) {
            total += modifier.price;
          }
          total += this.getModifiersPrice(modifier.modifierGroups);
        })
      });
    } 
    return total;
  }

  increaseQuantity(){
    this.plusorminus = 'increased';
    this.quantity++;
    this.totalPrice = this.getTotalPrice();  
    //this.cartService.setCartSubTotal();
  }
  
  decreaseQuantity(){
    this.plusorminus = 'decreased';
    if(this.quantity == 1) return;
    this.quantity--;
    this.totalPrice = this.getTotalPrice();
    //this.cartService.setCartSubTotal();
  }

  back(){
    if (this.isRecommendation) {
      this.orderRouting.goToCart();
    } else {
      this.orderRouting.goToCatalog();
    }
  }

  async addToCart(){

    if(this.valid === false) return;
    let selectedModifiers = [];
    this.item.modifierGroups.forEach(group => {
      let groupModifiers =  group.modifiers.filter((modifier) => modifier.selected == true);
      groupModifiers.forEach(modifier => {
        modifier.itemModifierGroupId = group.itemModifierGroupId;
        modifier.modifierModifierGroupId = group.modifierModifierGroupId;
        modifier.modifierGroupId = group.id;
        modifier.modifierGroups = this.updateNestedModifierGroups(modifier.modifierGroups);
      });
 
      selectedModifiers = selectedModifiers.concat(groupModifiers);
    });

    let itemSource = this.isRecommendation ? "recommendation" : ""; 
    let cartItem = new CartItem(this.item, this.quantity, selectedModifiers, this.specialInstructions, this.item.name, itemSource);

    if (this.item.imageUrl) {
      cartItem.image = this.item.imageUrl;
    }
    else
    {
      cartItem.image = null;
    }

    if (cartItem.image == null) {
      cartItem.image = this.placeHolderImageUrl;
    }
   

    let catalogId = this.catalogService.getCurrentCatalog().id

    this.spinner.show();

    if (await this.cartService.addToCart(cartItem, catalogId) != true) {        
      this.spinner.hide();
      this.alertPopup.show(this.translate.instant("Error!"), this.translate.instant("Failed to add the Item to the cart. Please try again."));
      return;
    }

    this.spinner.hide();
    this.ga.sendEvent('menu_item_added_to_cart', '');
     

    /*[Deprecated] => Forced to Picke Order Type on adding first Item */
    // let askedForOrderType = this.cartService.askedForOrderType; 
    // if(!askedForOrderType) 
    // {
    //   this.orderRouting.goToOrderTypePicker();
    // }
    // else 
    // {
    //   this.orderRouting.goToCatalog();
    // }

    this.back();
  }

  async updateCart(){

    if(this.valid === false) return;
    let selectedModifiers = [];
    this.item.modifierGroups.forEach(group => {
      let groupModifiers =  group.modifiers.filter((modifier) => modifier.selected == true);
      groupModifiers.forEach(modifier => {
        modifier.itemModifierGroupId = group.itemModifierGroupId;
        modifier.modifierModifierGroupId = group.modifierModifierGroupId;
        modifier.modifierGroupId = group.id;
        modifier.modifierGroups = this.updateNestedModifierGroups(modifier.modifierGroups);
      });
 
      selectedModifiers = selectedModifiers.concat(groupModifiers);
    });

    let itemSource = this.isRecommendation ? "recommendation" : ""; 
    let cartItem = new CartItem(this.item, this.quantity, selectedModifiers, this.specialInstructions, this.item.name, itemSource);
  
    if (this.item.imageUrl) {
      cartItem.image = this.item.imageUrl;
    }
    else
    {
      cartItem.image = null;
    }

    if (cartItem.image == null) {
      cartItem.image = this.placeHolderImageUrl;
    }
   

    let catalogId = this.catalogService.getCurrentCatalog().id

    this.spinner.show();

    this.route.queryParams.subscribe(async params => {
      let oldLineItemIndex = params['lineItemIndex'];
      if (await this.cartService.updateCart(cartItem, catalogId, oldLineItemIndex) != true) {        
        this.spinner.hide();
          this.alertPopup.show(this.translate.instant("Error!"), this.translate.instant("Failed to update the Item to the cart. Please try again."));
        return;
      }

      this.spinner.hide();
      this.ga.sendEvent('menu_item_added_to_cart', '');
      this.updateItemAddedToCart = true;
      this.orderRouting.goToCart();
    });
  }

  removeAutoAddItemFromCart() {
    this.route.queryParams.subscribe(async params => {
      let lineItemIndex = params['lineItemIndex'];
      if(this.source == "auto-add") {
        this.spinner.show();
        if (await this.cartService.removeFromCart(lineItemIndex, true) != true) {        
          this.spinner.hide();
            this.alertPopup.show(this.translate.instant("Error!"), this.translate.instant("Failed to update the Item to the cart. Please try again."));
          return;
        }
        this.spinner.hide();
        this.ga.sendEvent('menu_item_removed_from_cart', '');
      }
    });
  }

  goToCart() {   
    this.orderRouting.goToCart();
  }

    increaseModQuantity(modifier: Modifier, group: ModifierGroup, siblingGroups: ModifierGroup[]) {
        if (group.hasMaxSelected) return;

        if (modifier.soldOut) return;

        if (modifier.quantity == undefined || modifier.quantity == null) {
            modifier.quantity = 1;
            modifier.selected = true;
            this.onModifierQuantityChanged(group, siblingGroups);
        }
        else {
            modifier.quantity++;
            modifier.selected = true;
            this.onModifierQuantityChanged(group, siblingGroups);
        }
    }

    decreaseModQuantity(modifier: Modifier, group: ModifierGroup, siblingGroups: ModifierGroup[]) {
        if (modifier.soldOut) return;

        if (modifier.quantity && modifier.quantity > 0) {
            modifier.quantity--;
            if (modifier.quantity == 0) {
                modifier.selected = false;
            }
            this.onModifierQuantityChanged(group, siblingGroups);
        }
    }

    onModifierQuantityChanged(group: ModifierGroup, siblingGroups: ModifierGroup[]) {
        let modifiers = group.modifiers.filter((modifier) => modifier.quantity > 0);
        let totalQuantity: number = 0;
        if (!group.isIncluded) {

            modifiers.forEach(m => totalQuantity = totalQuantity + m.quantity);
            if (totalQuantity === group.selectionMax) {
                group.hasMaxSelected = true;
            }
            else if (totalQuantity < group.selectionMax) {//if previously had max selected
                group.hasMaxSelected = false;
            }
        }
        if (siblingGroups.some(mg => mg.isIncluded)) {
            this.checkMaxWithIncluded(group, siblingGroups);
        }
        else {
            this.updateItemStatus();
            group.totalSelected = totalQuantity;
        }
    }

    checkMaxWithIncluded(group: ModifierGroup, siblingGroups: ModifierGroup[]) {
        let totalQuantity: number = 0;

        if (!group.isIncluded) {
            let modifiers = group.modifiers.filter((modifier) => modifier.quantity > 0);
            if (group.maxWithIncluded) {
                let selectionCount = 0;
                modifiers.forEach(m => { selectionCount = selectionCount + m.quantity; });
                group.modifiers.forEach(m => {
                    let includedGroup = siblingGroups.find(mg => mg.isIncluded);
                    if (includedGroup) {
                        includedGroup.modifiers.forEach(m2 => {
                            if ((m2.id * -1) == m.id && m2.selected) {
                                selectionCount++;
                            }
                            else if ((m2.id * -1) == m.id && m2.selected == false && selectionCount >= group.selectionMax) {
                                m2.disabledForMax = true;
                            }
                            else if ((m2.id * -1) == m.id && m2.selected == false && selectionCount < group.selectionMax) {
                                m2.disabledForMax = false;
                            }
                        });
                    }
                });
                if (selectionCount >= group.selectionMax) {
                    group.hasMaxSelected = true;
                }
                else {
                    group.hasMaxSelected = false;
                }
                totalQuantity = selectionCount;
            }
            else {
                modifiers.forEach(m => totalQuantity = totalQuantity + m.quantity);
                if (totalQuantity === group.selectionMax) {
                    group.hasMaxSelected = true;
                }
                else if (totalQuantity < group.selectionMax) {//if previously had max selected
                    group.hasMaxSelected = false;
                }
            }
        }
        else if (group.isIncluded && siblingGroups.some(mg => mg.isIncluded)) {  
            group.modifiers.forEach(m => {
                if (m.selected) {
                    totalQuantity++;
                }
            });
            siblingGroups.forEach(mg => {
                if (mg.maxWithIncluded) {                    
                    let selectionCount = 0;
                    mg.modifiers.forEach(m2 => {
                        if (m2.quantity) {
                            selectionCount = selectionCount + m2.quantity;
                        }
                    });
                    let grpMods = mg.modifiers.map(m2 => m2.id);
                    if (grpMods && grpMods.length > 0) {
                        group.modifiers.forEach(m2 => {
                            if (m2.selected && grpMods.some(gm => (gm * -1) == m2.id)) {
                                selectionCount++;
                            }
                        });
                        mg.totalSelected = selectionCount;
                        if (selectionCount >= mg.selectionMax) {
                            mg.hasMaxSelected = true;
                            group.modifiers.forEach(m2 => {
                                if (m2.selected == false && grpMods.some(gm => (gm * -1) == m2.id)) {
                                    m2.disabledForMax = true;
                                }
                            });
                        }
                        else {
                            mg.hasMaxSelected = false;
                            group.modifiers.forEach(m2 => {
                                if (m2.selected == false && grpMods.some(gm => (gm * -1) == m2.id)) {
                                    m2.disabledForMax = false;
                                }
                            });
                        }
                    }
                }
            });            
        }
        this.updateItemStatus();
        group.totalSelected = totalQuantity;
    }

    openspecialInstructionModal() {
      console.log(this.specialInstructions);
      this.modalService.open(this.SpeciaInstructionModal, {
        size: 'lg',
        ariaLabelledBy: 'NewsLetter-Modal',
        centered: true,
        windowClass: 'template-modal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Modal Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      $("#specialInstructionsHeader").trigger('focus');
    }

    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }

    closeSpecialInstructionModal() {
      this.modalService.dismissAll();
    }

    saveSpecialInstructions() {
      console.log(this.specialInstructions);
      this.modalService.dismissAll();
    }

    private updateNestedModifierGroups(modifierGroups: ModifierGroup[]): ModifierGroup[] {
      if (modifierGroups) {
        modifierGroups.forEach(group => {
          let groupModifiers =  group.modifiers.filter((modifier) => modifier.selected == true);
          groupModifiers.forEach(modifier => {
            modifier.modifierModifierGroupId = group.modifierModifierGroupId;
            modifier.modifierGroupId = group.id;
            modifier.modifierGroups = this.updateNestedModifierGroups(modifier.modifierGroups);
          });
        });
      }
      return modifierGroups;
    }

    /* Accessibility Implementation for Key Press */
    eventHandler(event) {
      try {
        if (event.currentTarget.id == "increaseQuantity" && event.keyCode == 13) {                  
            this.increaseQuantity();
        }
        if (event.currentTarget.id == "decreaseQuantity" && event.keyCode == 13) {
          this.decreaseQuantity();
        }          
      } catch (error) {
          
      }      
    }

}
