import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { CartService } from './cart.service';
import { DataService } from './data.service';
import { Catalog } from './models/catalog';
import { Item } from './models/item';
import Swal from 'sweetalert2';
import { RecentlyOrderedItemModifier } from './models/recently-ordered-item-modifier';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class CatalogService {

    private locationId: number = null;
    locationCatalogs: Catalog[];

    private _catalog = new BehaviorSubject<Catalog>(null);
    catalog: Observable<Catalog> = this._catalog.asObservable().pipe(filter((catalog) => catalog != null));

    refreshCatalogOptions: boolean = false;

    catalogChangeRequested: boolean = false;
        

    constructor(private cartService: CartService,
        private dataService: DataService,
        private translate: TranslateService) { }


    /* Subscribeable GetCatalog */    
    getCatalogs(locationId: number, reload = false): Observable<Catalog[]> {
        //use data in memory if already loaded from http for the same location
        if (reload == false && this.locationCatalogs && this.locationCatalogs.length > 0 && locationId === this.locationId) {
            return of(this.locationCatalogs)
        }
        this.locationCatalogs = [];
        this._catalog.next(new Catalog());
        return this.dataService.getCatalogs(locationId)
            .pipe(
                tap((data) => {
                    this.locationId = locationId;
                    this.locationCatalogs = data;
                    this.scanCatalogs(this.locationCatalogs);
                    //set catalog to show on load
                    let selectedCatalog = null;

                    //check if need to load targeted catalog
                    if (this.getTargetCatalogId()) {
                        let catalog = this.locationCatalogs.find((catalog) => catalog.id === this.getTargetCatalogId())
                        if (catalog) {
                            selectedCatalog = catalog;
                            this.cartService.clearCart();
                            this.setTargetCatalogId(0);    
                            this.cartService.askedForOrderType = false;                            
                            this.cartService.loadOrderAheadSettingsForURLChange();                         
                        }
                    }

                    //if cart in progress, show this catalog
                    if (this.locationCatalogs.length > 1 && this.cartService.cart.catalogId) {
                        let catalog = this.locationCatalogs.find((catalog) => catalog.id === this.cartService.cart.catalogId)
                        if (catalog) {
                            selectedCatalog = catalog;
                        }
                        else {
                            this.cartService.clearCart();
                        }
                    }

                    //choose default catalog or lastSelectedCatalogId. TODO: choose based on availability
                    if (selectedCatalog == null) {
                        let lastSelectedCatalogId = this.getLastSelectedCatalogId();
                        let lastSelectedCatalog = this.locationCatalogs.find((catalog) => catalog.id === lastSelectedCatalogId);

                        if (lastSelectedCatalogId && lastSelectedCatalog) {
                            selectedCatalog = lastSelectedCatalog;
                        } else {
                            selectedCatalog = this.locationCatalogs[0];
                            // this.setLastSelectedCatalogId(this.locationCatalogs[0].id);
                        }
                    }

                    if (selectedCatalog) {
                        this.setLastSelectedCatalogId(selectedCatalog.id);
                    }

                    this._catalog.next(selectedCatalog);
                })
            )
    }

    /* Promisible GetCatalog => Use this for async request */
    getCatalogsPromise(locationId: number, reload = false): Promise<any> 
    {
        return new Promise<any>((resolve, reject) => {
            if (reload == false && this.locationCatalogs && this.locationCatalogs.length > 0 && locationId === this.locationId) {
                resolve(this.locationCatalogs);
            }
            else
            {
                this.locationCatalogs = [];
                this._catalog.next(new Catalog());
                this.dataService.getCatalogs(locationId).subscribe((data) => {
                    this.locationId = locationId;
                    this.locationCatalogs = data;
                    this.scanCatalogs(this.locationCatalogs);
                    //set catalog to show on load
                    let selectedCatalog = null;

                    //check if need to load targeted catalog
                    if (this.getTargetCatalogId()) {
                        let catalog = this.locationCatalogs.find((catalog) => catalog.id === this.getTargetCatalogId())
                        if (catalog) {
                            selectedCatalog = catalog;
                            this.cartService.clearCart();
                            this.setTargetCatalogId(0);    
                            this.cartService.askedForOrderType = false;                            
                            this.cartService.loadOrderAheadSettingsForURLChange();                         
                        }
                    }

                    //if cart in progress, show this catalog
                    if (this.locationCatalogs.length > 1 && this.cartService.cart.catalogId) {
                        let catalog = this.locationCatalogs.find((catalog) => catalog.id === this.cartService.cart.catalogId)
                        if (catalog) {
                            selectedCatalog = catalog;
                        }
                        else {
                            this.cartService.clearCart();
                        }
                    }

                    //choose default catalog or lastSelectedCatalogId. TODO: choose based on availability
                    if (selectedCatalog == null) {
                        let lastSelectedCatalogId = this.getLastSelectedCatalogId();
                        let lastSelectedCatalog = this.locationCatalogs.find((catalog) => catalog.id === lastSelectedCatalogId);

                        if (lastSelectedCatalogId && lastSelectedCatalog) {
                            selectedCatalog = lastSelectedCatalog;
                        } else {
                            selectedCatalog = this.locationCatalogs[0];
                            // this.setLastSelectedCatalogId(this.locationCatalogs[0].id);
                        }
                    }

                    if (selectedCatalog) {
                        this.setLastSelectedCatalogId(selectedCatalog.id);
                    }

                    this._catalog.next(selectedCatalog);
                
                    resolve(this._catalog.getValue());
                })           
            }           
        }); 
    }



    private catalogHasImage = false;
    scanCatalogs(catalogs: Catalog[]) {
        catalogs.forEach(catalog => {
            this.catalogHasImage = false;
            this.scanCatalogForImages(catalog);
            (<any>catalog).hasImage = this.catalogHasImage;
        });
        console.log(catalogs);
    }

    scanCatalogForImages(obj: any) {
        if (this.catalogHasImage) {
            return;
        }

        //check for root category
        if (obj.parentCategoryId == null) {
            (<any>obj).isRoot = true;
        }

        //check if category/obj has image
        if (obj.imageUrl != null && obj.imageUrl.trim() != "") {
            this.catalogHasImage = true;
            return; // breaks
        }          

        //check if item has images
        if (obj.items && obj.items.length > 0) {
            obj.items.forEach(itm => {
                if (itm.imageUrl != null && itm.imageUrl.trim() != "") {
                    this.catalogHasImage = true;
                    return; // breaks
                }
            });
            if (this.catalogHasImage) return;
        }

        // recurse to subcategory
        if (obj.categories && obj.categories.length > 0) {
            obj.categories.forEach(subcat => {
                this.scanCatalogForImages(subcat);
            });
        }
    }

    getCurrentCatalog(): Catalog {
        return this._catalog.getValue();
    }

    setCurrentCatalog(selectedCatlogId: number)  {
       
        let currentCatalog = this.locationCatalogs.filter(x => x.id == selectedCatlogId);
        if(currentCatalog && currentCatalog.length > 0) 
        {
            this._catalog.next(currentCatalog[0])
        }       
    }

    getCatalogHasImage() : boolean {
        let result: boolean = false;
        let catalog = this.getCurrentCatalog();
        try{
            if (catalog && (<any>catalog).hasImage){
                result = true;
            }
        } catch (e) {
            console.log(e);
            result = false;
        }
        
        return (result);
    }


    changeCatalog(catalogId: number) {
        if (this.locationCatalogs && this.locationCatalogs.length > 0) {
            this.setLastSelectedCatalogId(catalogId);
            let selectedCatalog = this.locationCatalogs.find((catalog) => catalog.id === catalogId);
            this._catalog.next(selectedCatalog);
        }
    }


    private itemToSelectById: Item;
    getItemDetailsById(id): Observable<Item> {        
        let catalog = this._catalog.getValue();
        this.itemToSelectById = null;
        this.scanForItem(catalog, id);
        if ((<any>this.itemToSelectById)) {
         (<any>this.itemToSelectById).hasImage = true;
        }        
        return of(this.itemToSelectById);        
    }

    scanForItem(obj, id) {
        if (this.itemToSelectById) return;
        if (obj && obj.items && obj.items.length > 0) {
            let result = obj.items.find(i => i.id == id)
            if (result) {
                this.itemToSelectById = result;
                return;
            }
        }
        if (obj.categories && obj.categories.length > 0) {
            obj.categories.forEach(subcat => {
                this.scanForItem(subcat, id);
            });
        }
    }

    getRecentlyOrderedItemModifiersByItemId(id): Observable<RecentlyOrderedItemModifier> {
        let catalog = this._catalog.getValue();
        let modifier = this.getRecentlyOrderedItemModifiers(catalog, id);
        return of(modifier); 
    }

    getRecentlyOrderedItemModifiers(obj, itemId) {
        if(obj && obj.recentlyOrderedItemModifiers) {
            return obj.recentlyOrderedItemModifiers.find(i => i.itemId == itemId);
        }
        return null;
    }

    setLastSelectedCatalogId(catalogId) {
        sessionStorage.setItem('lastSelectedCatalogId', catalogId);
    }

    getLastSelectedCatalogId() {
        let lastSelectedCatalogId = sessionStorage.getItem('lastSelectedCatalogId');
        if (lastSelectedCatalogId) {
            return parseInt(lastSelectedCatalogId)
        } else {
            return 0;
        }
    }

    

    setCatalogChangeRequested(value) 
    {
        sessionStorage.setItem("catalog_change_requested", value.toString());
    }

    getCatalogChangeRequested() 
    {
        return (sessionStorage.getItem('catalog_change_requested') == 'true') ? true : false;
    }
   
    setTargetCatalogId(catalogId) {
        sessionStorage.setItem('target_catalog_id', catalogId);
    }

    getTargetCatalogId() {
        let targetCatalogId = sessionStorage.getItem('target_catalog_id');
        if (targetCatalogId) {
            return parseInt(targetCatalogId)
        } else {
            return 0;
        }
    }

    refreshCatalog() {
        this.refreshCatalogOptions = true;
        this.getCatalogs(this.locationId, true).subscribe();
    }    

    defaultlastSelectedCategoryId() 
    {
        this.lastSelectedCategoryId = -1;
    }

    async onCatalogSelectedChange(catalogId) : Promise<any>  {
        return new Promise<any>(async (resolve, reject) => {
            let selectedCatalog = this.getCurrentCatalog();
            if( this.locationCatalogs && this.locationCatalogs.length > 0) 
            {
                if (selectedCatalog.id != catalogId)                 {
                    
                    selectedCatalog = this.locationCatalogs.find(x => x.id == catalogId);
                   
                    if (this.cartService.cart.catalogId && this.cartService.cart.catalogId != selectedCatalog.id) 
                    {                        
                        if(this.cartService.getCartCount() > 0 ) 
                        {
                            
                            await Swal.fire({
                                title: this.translate.instant("Changing menus will empty your cart. Are you sure you want to change menus?"),
                                showConfirmButton: true,
                                confirmButtonText: this.translate.instant('YES'),
                                showCancelButton: true,
                                cancelButtonText: this.translate.instant('NO'),
                                backdrop: false,
                                allowOutsideClick: false,
                              }).then(async (result) => {
                                if (result.dismiss) {                               
                                    this.setCatalogChangeRequested(false);
                                    resolve(false);
                                } 
                                else 
                                {
                                    await Swal.fire({
                                        title: `${this.translate.instant('Loading')} ${selectedCatalog.name}....`,  
                                        timerProgressBar: true,                              
                                        backdrop: false,
                                        allowOutsideClick: false,
                                        onBeforeOpen: () => 
                                        {
                                            Swal.showLoading();
                                        },
                                        timer: 1500
                                    }).then(async () => {
                                        await this.cartService.clearSessionForNewOrder().then((result) => {
                                            if (result) {
                                                console.log('session cleared for new order');                                        
                                            }
                                        });
                                    });   
                                }
                            });                           
                        }
                        else
                        {
                            await Swal.fire({
                                title: `${this.translate.instant('Loading')} ${selectedCatalog.name}....`,  
                                timerProgressBar: true,                              
                                backdrop: false,
                                allowOutsideClick: false,
                                onBeforeOpen: () => 
                                {
                                    Swal.showLoading();
                                },
                                timer: 1500
                            }).then(async () => {
                                await this.cartService.clearSessionForNewOrder().then((result) => {
                                    if (result) {
                                        console.log('session cleared for new order');                                        
                                    }
                                });
                            });   
                        }                       
                    }                    
                    this.defaultlastSelectedCategoryId();     
                }                
            }
            
            this.setCatalogChangeRequested(false);
            resolve(true); 
        });        
    }

    /* GETTERS & SETTERS */
    get reloadCatalog(): boolean {
        var result = false;
        var item = sessionStorage.getItem("reload_catalog");
        if (item == "true") {
            result = true;
        }
        return result;
    }

    set reloadCatalog(value: boolean) {
        sessionStorage.setItem("reload_catalog", value.toString());
    }

    get currencySymbol():string{
        let result = "$";
        var cs = sessionStorage.getItem("currency_symbol");
        if (cs){
        result = cs;
        }
        return result;
    }

    set currencySymbol(value:string){
        sessionStorage.setItem("currency_symbol", value.toString());
    }

    get lastSelectedCategoryId():number{
        let result = -1;
        var cs = sessionStorage.getItem("last_selected_category_id");
        if (cs){
        result = Number(cs);
        }
        return result;
    }

    set lastSelectedCategoryId(value:number){
        sessionStorage.setItem("last_selected_category_id", value.toString());
    }

    async isAllowMultipleModifierQuantity(locationID): Promise<boolean> {
        let result: boolean = false;
        var val = sessionStorage.getItem("allow_multiple_modifier_quantity");
        if (val != null) {
            result = (val === 'true'); 
        }
        else {
            await this.dataService.getLocationSettings(locationID).toPromise().then(async res => {
                result = res.allowMultipleModifierQuantity;
                await sessionStorage.setItem("allow_multiple_modifier_quantity", result.toString());
            }, async err => {
                    console.error("Unable to get the Location Settings.");
            });
        }
        return result;
    }

}
