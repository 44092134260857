import { Injectable } from '@angular/core';
import { AssetService } from './merchant-assets/asset.service';
import { Assets } from './merchant-assets/assets.model';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticService {
  private merchantAssets: Assets
  constructor(private assetService: AssetService, private util: UtilService) { }

  sendEvent(action, label){
    //get merchant name via assets
    this.assetService.getMerchantAssets()
        .subscribe((assets) => { 
          this.merchantAssets = assets;
    });
    
    let category = "Unknown";
    //check if it exists
    if (this.merchantAssets && this.merchantAssets.headerName){
      category = this.merchantAssets.headerName;
    } 

    (<any>window).ga('send', 'event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    });

    let mobileAppResult = this.util.checkIfMobileApp();

    if (mobileAppResult.isMobile && mobileAppResult.isMobileApp) {
      console.log('POSTING MESSAGE TO TRACK GA4 EVENT TO MOBILE APP');
      parent.postMessage(
        {
          action: 'send_ga_event',
          event_name: action,
          page_path: '',
          send_to_default: false
        }, 
        sessionStorage.getItem("hostingapp")
      ); 
    }

  }

  sendEventWithValue(action, label, value){
    //get merchant name via assets
    this.assetService.getMerchantAssets()
        .subscribe((assets) => { 
          this.merchantAssets = assets;
    });
    
    let category = "Unknown";
    //check if it exists
    if (this.merchantAssets && this.merchantAssets.headerName){
      category = this.merchantAssets.headerName;
    } 

    (<any>window).ga('send', 'event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value
    });
  }
}
