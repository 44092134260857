import { Http } from '@angular/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { AssetService } from './merchant-assets/asset.service';

export class TranslationLoaderService implements TranslateLoader {

    constructor(private http: Http,
        private assetService: AssetService) {
    }

    getTranslation(lang: string): Observable<any> {
        let languageJson: string = this.assetService.getLanguageJSON(lang);
        if (languageJson && languageJson.trim()) {
            return of(JSON.parse(languageJson));
        }
        else{
            return of(JSON.parse('{}'));
        }        
    }
}