import { PaymentMode } from './payment-mode';

export class LoadWalletRequest {
    amount: number;
    merchantLocationId: number;
    cardNumber: string;
    cardExpirationMonth: number;
    cardExpirationYear: number;
    cardCVV: string;
    saveCreditCard: boolean = false;
    payWithSavedCreditCard: boolean = true;
}

export class LoadWalletRequestV2 {
    amount: number;
    merchantLocationId: number;
    cardNumber: string;
    cardExpirationMonth: number;
    cardExpirationYear: number;
    cardCVV: string;
    saveCreditCard: boolean = false;
    payWithSavedCreditCard: boolean = true;
    token: string;
    squareVerificationToken: string;
    cardHolderName: string;
    postalCode: string;
    countryCode: string;
    isApplePay: boolean;
    applePayToken: string;
    applePayPaymentId: string;
    applePayApplicationData: string;
}