import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Location } from "@angular/common";



@Injectable({
    providedIn: 'root'
})
export class OrderRoutingService {
    guid = '';
    base = '';
    isRoutingInitialized = new BehaviorSubject<boolean>(null);
    currentRoute = '';

    constructor(private router: Router) { }

    setGuid(guid) {
        this.guid = guid;
        this.base = 'merchant/' + this.guid + '/order';
        this.isRoutingInitialized.next(true);
    }

    goToAbsoluteRoute(route) {
        this.router.navigate([route]);
    }

    goToCatalog() {
        this.currentRoute = 'catalog';
        this.router.navigate([this.base, 'catalog']);
    }


    goToLocation() {
        this.currentRoute = 'location';
        this.router.navigate([this.base, 'location']);
    }

    goToEmailVerification() {
        this.currentRoute = 'email-verification';
        this.router.navigate([this.base, 'email-verification']);
    }

    goToPhoneVerification() {
        this.currentRoute = 'phone-verification';
        this.router.navigate([this.base, 'phone-verification']);
    }

    goToItem(id, source?: string) {
        this.currentRoute = 'catalog';
        if (source) {
            this.router.navigate([this.base, 'catalog', 'item', id], { queryParams: {source: source}});
        } 
        else {
            this.router.navigate([this.base, 'catalog', 'item', id]);
        }
    }
    goToEditItem(id, source: string, uniqueLineItemId: string, lineItemIndex: number) {
        this.currentRoute = 'catalog';
        this.router.navigate([this.base, 'catalog', 'item', id], { queryParams: {source: source, uniqueLineItemId: uniqueLineItemId, lineItemIndex: lineItemIndex}});   
    }

    goToRecentlyOrderedItem(id: number) {
        this.currentRoute = 'catalog';
        this.router.navigate([this.base, 'catalog', 'item', id], { queryParams: {source: "recently-ordered"}});
    }

    goToLogin() {
        this.currentRoute = 'login';
        this.router.navigate([this.base, 'login']);
    }

    goToRegister() {
        this.currentRoute = 'register';
        this.router.navigate([this.base, 'register']);
    }

    goToForgotPassword() {
        this.currentRoute = 'forgot-password';
        this.router.navigate([this.base, 'forgot-password']);
    }

    goToConnectInStore() {
        this.currentRoute = 'connect-instore';
        this.router.navigate([this.base, 'connect-instore']);
    }

    goToRegisterKeytag() {
        this.currentRoute = 'register-keytag';
        this.router.navigate([this.base, 'register-keytag']);
    }

    goToCart() {
        this.currentRoute = 'cart';
        this.router.navigate([this.base, 'cart']);
    }

    goToCartRouter() {
        this.currentRoute = 'route-cart';
        this.router.navigate([this.base, 'route-cart']);
    }

    goToRedeemables() {
        this.currentRoute = 'redeemables';
        this.router.navigate([this.base, 'redeemables']);
    }

    goToSpecialInstructions() {
        this.currentRoute = 'special-instructions';
        this.router.navigate([this.base, 'special-instructions']);
    }

    goToCatalogPicker() {
        this.currentRoute = 'catalog-picker';
        this.router.navigate([this.base, 'catalog-picker']);
    }

    goToOrderTypePicker() 
    {
        this.currentRoute = 'order-type-picker';
        this.router.navigate([this.base, 'order-type-picker']);
    }

    goToOrderAheadPicker() 
    {
        this.currentRoute = 'order-ahead-picker';
        this.router.navigate([this.base, 'order-ahead-picker']);
    }

    goToOrderAhead() {
        this.currentRoute = 'order-ahead';
        this.router.navigate([this.base, 'order-ahead']);
    }

    goToPaymentCard() {
        this.currentRoute = 'payment-card';
        this.router.navigate([this.base, 'payment-card']);
    }

    goToPaymentCheckout() {
        this.currentRoute = 'payment-checkout';
        this.router.navigate([this.base, 'payment-checkout']);
    }

    goToPaymentManage() {
        this.currentRoute = 'payment-manage';
        this.router.navigate([this.base, 'payment-manage']);
    }

    goToStripePaymentForm() {
        this.currentRoute = 'payment-add';
        this.router.navigate([this.base, 'payment-add']);
    }

    goToSquarePaymentForm() {
        this.currentRoute = 'square-payment';
        this.router.navigate([this.base, 'square-payment']);
    }

    goToPaymentCheckoutForm() {
        this.currentRoute = 'payment-checkout';
        this.router.navigate([this.base, 'payment-checkout']);
    }

    goToCloverPaymentForm() {
        this.currentRoute = 'clover-payment';
        this.router.navigate([this.base, 'clover-payment']);
    }

    goToNMIPaymentForm() {
        this.currentRoute = 'nmi-payment';
        this.router.navigate([this.base, 'nmi-payment'])
    }

    goToLoadWallet() {
        this.currentRoute = 'reload-wallet';
        this.router.navigate([this.base, 'reload-wallet']);
    }

    goToMyOrders() {
        this.currentRoute = 'my-orders';
        this.router.navigate([this.base, 'my-orders']);
    }

    goToOrderReceipt(orderGuid) {
        this.currentRoute = 'order-receipt';
        this.router.navigate([this.base, 'order-receipt', orderGuid]);
    }

    gotToGuestOrderReceipt(orderGuid) {
        this.currentRoute = "receipt";
        this.router.navigate([this.base, 'receipt', orderGuid]);
    }

    goToOrderStatus(orderGuid, showPsa = false) {
        this.currentRoute = 'order-status';
        this.router.navigate([this.base, 'order-status', orderGuid, showPsa]);
    }

    goToGuestOrderStatus(orderGuid) {
        this.currentRoute = 'status';
        this.router.navigate([this.base, 'status', orderGuid]);
    }

    goToCheckout() {
        this.currentRoute = 'checkout';
        this.router.navigate([this.base, 'checkout']);
    }



    goBack() {
        let returnRoute = sessionStorage.getItem('return_url');
        if (returnRoute) {
            console.log('login - returnRoute: ' + returnRoute);
            sessionStorage.removeItem('return_url');
            this.goToAbsoluteRoute(returnRoute);
        } else {
            this.goToCatalog();
        }
    }

    redirectOnError(redirectTo: string) {
        if (redirectTo && redirectTo != "") {

            redirectTo = redirectTo.toLowerCase();

            if (this.currentRoute.toLowerCase() == redirectTo) {
                this.router.navigateByUrl(this.base + '/refresh', { skipLocationChange: true }).then(() => {
                    this.router.navigate([this.base, this.currentRoute]);
                });
            }
            else if (redirectTo == "checkout") {
                this.goToCheckout();
            }
            else if (redirectTo == "cart") {
                this.goToCart();
            }
            else if (redirectTo == "catalog") {
                sessionStorage.setItem("reload_catalog", true.toString());
                this.goToCatalog();
            }
            else if (redirectTo == "reload_wallet") {
                this.goToLoadWallet();
            }
            else if (redirectTo == "order_ahead") {               
                this.goToOrderTypePicker();
            }
            else if (redirectTo == "email_verification") {
                this.goToEmailVerification();
            }
            else if (redirectTo == "phone_verification") {
                this.goToPhoneVerification();
            }
        }
    }
}
