import { Component, OnInit, Input, Output, EventEmitter, HostListener, AfterContentInit, AfterViewInit } from '@angular/core';
import { MenuController, Platform, PopoverController } from '@ionic/angular';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { CartService } from '../cart.service';
import { CartItem } from '../cart/cart.model';
import { UtilService } from '../util.service';

@Component({
    selector: 'app-nav-toolbar',
    templateUrl: './nav-toolbar.component.html',
    styleUrls: ['./nav-toolbar.component.scss']
})
export class NavToolbarComponent implements OnInit  {
    @Input() BackBtnType: string;
    @Output() BackBtnClick = new EventEmitter<string>();
    logoUrl: string = null;
    messageEvent: Subscription;
    languages: string[];
    selectedLanguage: string;
   
    
    constructor(private util: UtilService,
        public menuCtrl: MenuController) 
    {
    }

    async ngOnInit() {
        this.logoUrl = sessionStorage.getItem("logoUrl");
    } 

    callParentBackBtnClick(): void {
        this.BackBtnClick.next();
    }

    toggleMenu() {
        this.menuCtrl.toggle().then(() => {                   
            document.getElementById("closeMenu").focus();
        });      
    }

    /* Accessibility Implementation for Key Press */  
    eventHandler(event) 
    {
        try {
            if (event.currentTarget.id == "hamburgerMenuButton" && event.keyCode == 13) {
                this.menuCtrl.toggle().then(() => {                   
                    document.getElementById("closeMenu").focus();
                });                
                
            }
            if (event.currentTarget.id == "backMenuButton" && event.keyCode == 13) {
                this.callParentBackBtnClick();
            }          
        } catch (error) {
            
        }      
    }
}
