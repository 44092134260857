
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AssetService } from './asset.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AssetResolver implements Resolve<any> {
  
  constructor(private assetService: AssetService) {}
  
  resolve(): Observable<any> {
    //load colors once customer authorized, continue if this fails using default colors.
    return this.assetService.getMerchantAssets()
        .pipe( 
            map(() => null),
            catchError(() => { return of(null)})
        )
  }
}