import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { MembershipRoutingService } from '../membership-routing.service';
import { OrderRoutingService } from '../order-routing.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  email = '';
  membershipModule: boolean = false;

    constructor(private authService: AuthService,
        private orderRouting: OrderRoutingService,
        private membershipRouting: MembershipRoutingService,
        private translate: TranslateService) { }

  ngOnInit() { 
    this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;
  }

  forgotPassword(){
    this.authService.forgotPassword(this.email).subscribe((data) => {
      console.log(data);
      //alert password reset - go back to login
      alert(this.translate.instant('You will receive a reset link if your email is registered with us'));
      this.orderRouting.goToLogin();
    }, (err) => {
      console.log('got an error trying to forgot password', err);
      if (err.status != 401){
        alert(err.error.message);
      }
    });
  }

  back() {
    if (this.membershipModule) {
      this.membershipRouting.goToLogin();
    } else {
      this.orderRouting.goToLogin();
    }
  }

}
