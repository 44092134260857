import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import {Location} from '@angular/common';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { PreparedOrder } from '../models/prepared-order.model';
import { CardPayment } from './card-payment';
import { CartService } from '../cart.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import {map} from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { OrderRoutingService } from '../order-routing.service';
import { GoogleAnalyticService } from '../google-analytic.service';
import { CatalogService } from '../catalog.service';
import { CategoryTrackedService } from '../common/category-tracked.service';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss']
})
export class PaymentCardComponent implements OnInit {

  constructor(private router: Router, 
    private loadingController: LoadingController,  
    private location: Location, 
    private fb: FormBuilder, 
    private cartService: CartService, 
    private categorytrackedService: CategoryTrackedService,
    private orderRouting: OrderRoutingService,
    private ga: GoogleAnalyticService,
    private catalogService: CatalogService,
    public alertController: AlertController) { }

  companyLogo: string = "";
  paymentAmount: number = 0.00;
  currency: string = "";
  currencySymbol: string = '$';
  orderAmount: number = 0.00;
  processingFees: number = 0.00;
  serviceFees: number = 0.00;
  deliveryFees: number = 0.00;
  taxAmount: number = 0.00;
  tip: number = 0.00;
  taxRate: string = "";
  discountAmount: number = 0.00;
  supportedPaymentTypes: string[];
  orderId: string = "";
  customerId: string = "";
  notes: string = "";
  payViaPOS: boolean = false;
  isServiceFees: boolean = false;
  isProcFees: boolean = false;
  isDeliveryFees: boolean = false;
  applywallet: boolean = false;
  remainingTotal : string = "0.00";
  remainingPayment : string = "0.00";


  paymentForm : FormGroup;
  customerName: FormControl;
  cardNumber: FormControl;
  expiryMonth: FormControl;
  expiryYear: FormControl;
  cvv: FormControl;

  paymentDetails : CardPayment;


  ngOnInit() {
    this.customerName = new FormControl('', [Validators.required, Validators.maxLength(50)]);
    this.cardNumber = new FormControl('', [Validators.required, Validators.pattern("^[0-9]{12,16}$")]);
    this.expiryMonth = new FormControl('', [Validators.required, Validators.maxLength(2), Validators.minLength(2), Validators.pattern("^01|02|03|04|05|06|07|08|09|10|11|12$")]);
    this.expiryYear = new FormControl('', [Validators.required, Validators.maxLength(2), Validators.minLength(2), Validators.pattern("^20|21|22|23|24|25|26|27|28|29|30|31$")]);
    this.cvv = new FormControl('', [Validators.required, Validators.maxLength(4), Validators.minLength(3), Validators.pattern("^[0-9]{3,4}$")]);

      this.paymentForm = this.fb.group({
        'customerName' : this.customerName,
        'cardNumber' : this.cardNumber,
        'expiryMonth' : this.expiryMonth,
        'expiryYear' : this.expiryYear,
        'cvv' : this.cvv
      });

      //this.cardPaymentService.cardPaymentDetails.subscribe(result => {
      //  console.log(result);
      //  this.orderId = result.orderId;
      //  this.companyLogo = result.companyLogo;
      //  this.paymentAmount= Number(result.totalPayment);
      //  this.orderAmount = Number(result.orderTotal);
      //  this.serviceFees = Number(result.serviceFees);
      //  this.processingFees = Number(result.processingFees);
      //  this.deliveryFees = Number(result.deliveryFees);
      //  this.taxAmount = Number(result.tax);
      //  this.tip = Number(result.tip);
      //  this.discountAmount = Number(result.discount);
      //  this.taxRate = result.taxRate;
      //  this.supportedPaymentTypes = result.supportedPaymentTypes; 
      //  this.currency = result.currency;  
      //  this.currencySymbol = result.currencySymbol;  
      //  this.customerId = result.customerId;  
      //  this.notes = result.notes; 
      //  this.payViaPOS = result.payViaPOS;
      //  this.applywallet = result.applyWallet;
      //  this.remainingPayment = result.remainingPayment;
      //  this.remainingTotal = result.remainingTotal;

      //  if (this.processingFees > 0) 
      //  {
      //    this.isProcFees = true;
      //  }
      //  if(this.serviceFees > 0) 
      //  {
      //    this.isServiceFees = true;
      //  }
      //  if (this.deliveryFees > 0){
      //    this.isDeliveryFees = true;
      //  }
      //});
  }

  back(){
    this.orderRouting.goToCheckout(); 
  }

 async onSubmit() 
  {
    let cardDetails = this.paymentForm.value;

    this.paymentDetails = 
    {
        "companyLogo" : this.companyLogo,
        "discount" :  this.discountAmount.toString(),
        "orderId" : this.orderId,
        "orderTotal" : this.orderAmount.toString(),
        "totalPayment" : this.paymentAmount.toString(),
        "processingFees" : this.processingFees.toString(),
        "serviceFees" : this.serviceFees.toString(),
        "deliveryFees" : this.deliveryFees.toString(),
        "currency" :  this.currency,
        "currencySymbol" :  this.currencySymbol,
        "customerId" : this.customerId,
        "notes" : this.notes,
        "taxRate" : this.taxRate,
        "tip" : this.tip.toString(),
        "supportedPaymentTypes" : this.supportedPaymentTypes,
        "cardHolderName" : cardDetails.customerName,
        "cardNumber" : cardDetails.cardNumber,
        "expiryMonth" : cardDetails.expiryMonth,
        "expiryYear" : cardDetails.expiryYear,
        "cvv" : cardDetails.cvv,
        "tax" : this.taxAmount.toString(),
        "payViaPOS" : this.payViaPOS,
        "applyWallet" : this.applywallet,
        "remainingTotal": this.remainingTotal,
        "remainingPayment" : this.remainingPayment

    }
    console.log(this.paymentDetails);

    const loading = await this.loadingController.create({
      message: 'Placing order'
      });

      await loading.present();

    // this.cartService.placeOrderForPOS(this.applywallet, this.paymentDetails).subscribe((result) => {      
    //       console.log((<any>result));
    //       if((<any>result).isSuccess) 
    //       {           
    //         loading.message = "Payment Successful...";            
    //         let paymentMethodsApplied = this.cartService.getPaymentMethodsApplied();
    //         loading.message = "Placing Order..."; 
    //         this.ga.sendEventWithValue('order_placed', paymentMethodsApplied, Math.round(Number(this.paymentDetails.totalPayment)));
    //         this.cartService.clearCart();
    //         loading.message = "Redirecting to track order..."; 
    //         this.catalogService.reloadCatalog = true;
    //         this.catalogService.lastSelectedCategoryId = -1;
    //         this.categorytrackedService.catalogComponent.setProperty('selectedCategoryId', -1);
    //         this.categorytrackedService.catalogComponent.setProperty('subCategoryViewOpen', false);
    //         this.orderRouting.goToOrderStatus((<any>result).orderId);
    //         loading.dismiss();
    //       }          
    // }, 
    // (error) => {
    //   loading.dismiss();
    //     this.handleError(error)
    // });
  }


  gotoNextField(nextElement) 
  {
    if(nextElement.name == "expiryMonth") 
    {
      if(this.cardNumber.value.length >= 16) 
      {
        this.cardNumber.value.replace(/\s/g, "");
        nextElement.setFocus();
      }    
    }
    if(nextElement.name == "expiryYear") 
    {
      if(this.expiryMonth.value.length >= 2) 
      {
        this.expiryMonth.value.replace(/\s/g, "");
        nextElement.setFocus();
      }   
    }
    if(nextElement.name == "cvv") 
    {
      if(this.expiryYear.value.length >= 2) 
      {
        this.expiryYear.value.replace(/\s/g, "");
        nextElement.setFocus();
      }   
    }    
  }

  async errorAlertPOS(errorMsg: string) 
  {
    const alert = await this.alertController.create({
      header: 'ERROR',
      subHeader: errorMsg,
      buttons: [
        {
          text: 'OK',
          cssClass: 'alertButton'
        }
      ]
    });

    await alert.present();
  }
 


  async errorAlert(errorMsg: string) 
  {
    const alert = await this.alertController.create({
      header: 'ERROR',
      subHeader: errorMsg,
      message: 'Please review details and try again.',
      buttons: [
        {
          text: 'OK',
          cssClass: 'alertButton'
        }
      ]
    });

    await alert.present();
  }

  async paymentErrorAlert() 
  {
    const alert = await this.alertController.create({
      header: 'PAYMENT ERROR',
      subHeader: "Payment could not be processed.",
      message: 'Please review payment details and try again.',
      buttons: [
        {
          text: 'OK',
          cssClass: 'alertButton'
        }
      ]
    });

    await alert.present();
  }

  async cartEpiredErrorAlert() 
  {
    const alert = await this.alertController.create({
      header: 'CART EXPIRED',
      subHeader: "Your cart is expired.",
      message: 'Please review order and try again.',
      buttons: [
        {
          text: 'OK',
          cssClass: 'alertButton',
          handler: () => 
          {
            alert.dismiss().then(() => { this.back()});
          }
        }
      ]
    });

    await alert.present();
  }

  private handleError(errorResponse : HttpErrorResponse)
  {
    let errorMsg : string;

    if (errorResponse.status != undefined && errorResponse.status != 401){
      errorMsg = "Error : Please Try again.";
      this.errorAlert(errorMsg);
    }
    if ((<any>errorResponse).type == "PAYMENT" && (<any>errorResponse).source == "CREDIT_CARD"){
      errorMsg = "Payment Error : CREDIT_CARD";
      this.paymentErrorAlert();
    }
    if ((<any>errorResponse).type == "POS" && this.payViaPOS){

      let locationPhone = sessionStorage.getItem("locationPhone");
      if(locationPhone != null && locationPhone != "") 
      {
        errorMsg = "We were unable to submit your order to the store, please contact the merchant for support. Store Number - " + locationPhone;
      }
      else 
      {
        errorMsg = "Unable to submit your order to the store. Please try again later";
      }     
      this.errorAlertPOS(errorMsg);
    }
    if ((<any>errorResponse).type == "CARTEXPIRED")
    {
      this.cartEpiredErrorAlert();
    }    
    return throwError(errorMsg);
  }


}
