import { Component } from "@angular/core";
import { OrderRoutingService } from "../order-routing.service";
import { AuthService } from "../auth/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { EmailVerificationGuardService } from "./email-verification-guard.service";
import Swal from "sweetalert2";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.scss']
  })

export class EmailVerificationComponent {

    showFinalizationText: boolean;

    constructor(private orderRoutingService: OrderRoutingService,
        private auth: AuthService,
        private spinner: NgxSpinnerService,
        public emailVerification: EmailVerificationGuardService,
        private translate: TranslateService) {
            if (sessionStorage.getItem('phone_verification_required') == '1') {
                this.showFinalizationText = false;
            } else {
                this.showFinalizationText = true;
            }
        }
    
    

    continue() {       
        this.spinner.show();
        // check if email has been verified
        this.auth.customerRequiresEmailVerification().subscribe(
            async (data) => {
            this.spinner.hide();
            if (data) {
                this.emailVerification.emailVerificationRequired = data.result;
                if (!data.result) {
                    this.orderRoutingService.goToCart();
                } else {
                    await Swal.fire({
                        title: this.translate.instant("Oops! Your email is still not verified. Please click the verification link in your inbox and try again."),
                        showConfirmButton: true,
                        confirmButtonText: this.translate.instant('Ok'),
                        showCancelButton: false,
                        backdrop: false,
                        allowOutsideClick: false,
                      }).then(async (result) => {

                      });
                }
            }
        },
            (err) => {
                console.log(err);
                this.spinner.hide();
        });
    }
}