import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { OrderRoutingService } from '../order-routing.service';

@Component({
  selector: 'app-route-helper',
  templateUrl: './route-helper.component.html',
  styleUrls: ['./route-helper.component.scss']
})
export class RouteHelperComponent implements OnInit {

  constructor(private orderRouting: OrderRoutingService) { }

  ngOnInit() {
    this.orderRouting.goToCart();    
  }

}
