import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';
import {Location} from '@angular/common';
import { OrderRoutingService } from '../order-routing.service';

@Component({
  selector: 'app-special-instructions',
  templateUrl: './special-instructions.component.html',
  styleUrls: ['./special-instructions.component.scss']
})
export class SpecialInstructionsComponent implements OnInit {
  specialInstructions: string;

  constructor(private cartService: CartService, private location: Location) { 
    this.specialInstructions = this.cartService.getSpecialInstructions();
  }

  ngOnInit() { }

  saveSpecialInstructions(){
    console.log(this.specialInstructions);
    this.cartService.setSpecialInstructions(this.specialInstructions);
    this.back();
  }

  back(){
    this.location.back();
  }

}
