import { Component, OnInit } from '@angular/core';
import { catchError, tap } from "rxjs/operators";
import { throwError } from "rxjs";
import { OrderRoutingService } from "../order-routing.service";
import { OrderProgress, OrderStep } from '../order-status/order-progress.model';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { GoogleAnalyticService } from '../google-analytic.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController } from '@ionic/angular';
import { PaymentService } from '../payment.service';
import { CustomerSessionService } from '../customer-session.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth/auth.service';


@Component({
  selector: 'status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class GuestOrderStatusComponent implements OnInit {
  orderId: string;
  order: any;
  canCancelOrder: boolean = false;
  canNotifyStoreForPickUp: boolean = false;
  needToCallStoreToNotifyForPickup: boolean = false;
  isDelivery: boolean = false;
  isDineIn: boolean = false;
  orderProgress: OrderProgress;
  cancelClicked: boolean = false;
  currentStep: OrderStep;
  getDirectionUri: string;
  googleMapsURI = "https://www.google.com/maps/dir/?api=1&destination=";
  logoUrl: string = null;
  isMobile: boolean;
  locationPhone: string;
  autoComplete: boolean = false;
  forceImHere: boolean = false;
  externalDeliveryTrackingUrl: string = null;
  loading: boolean = true;

  appleApplink: string = null;
  googleAppLink: string = null;
  customerPortalLink: string = null;
  hasApp: boolean = false;
  merchantGuid: string = null;
  isGuest: boolean = false;
  guestLoyaltyCTA: any = { 
    title: this.translate.instant("Join Our Loyalty Program!"),
    description: this.translate.instant("Become a member today - it's fast and free!"),
    firstPrompt: this.translate.instant("Get birthday offers"),
    secondPrompt: this.translate.instant("Get access to discounts"),
    thirdPrompt: this.translate.instant("Earn with every purchase")
  }

  constructor(
    private orderRouting: OrderRoutingService,
    public route: ActivatedRoute,
    private dataService: DataService,
    private ga: GoogleAnalyticService,
    private translate: TranslateService,
    private loadingController: LoadingController,
    private paymentService: PaymentService,
    private customerSession: CustomerSessionService,
    private spinner: NgxSpinnerService,
    private authService: AuthService
  ) { }

  async ngOnInit() {
    let paymentProvider = await this.customerSession.getPaymentProvider();
    this.needToCallStoreToNotifyForPickup = paymentProvider == 'Toast';
  }

  ionViewWillEnter() {
    this.isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;
    if (this.isGuest) {
      // need to reload page to re-initialize dashboard and clear guest authentication
      window.location.reload();
    }
    this.orderId = null;
    this.order = null;
    this.logoUrl = sessionStorage.getItem("logoUrl");
    if (sessionStorage.getItem("send_purchase_tracking") && sessionStorage.getItem("send_purchase_tracking") == "true") {
      this.paymentService.sendTrackingEvent();
      sessionStorage.removeItem("send_purchase_tracking");
      sessionStorage.removeItem("send_purchase_total");
      sessionStorage.removeItem("lead_submission");
      sessionStorage.removeItem("lead_submission_timestamp");
    }  
  }

  ionViewDidEnter() {
    sessionStorage.setItem("backToGuestOrderStatus", "1");
    this.spinner.show()
    this.customerSession.mobileMode.subscribe((data) => {
      console.log("DASHBOARD COMPONENT SUBSCRIPTION FOR MOBILE MODE: " + data);
      if (data) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });

    this.loadData().subscribe((data) => {
      if (this.canNotifyStoreForPickUp) {
        //override needToCallStoreToNotifyForPickup
        if (this.forceImHere){
          this.needToCallStoreToNotifyForPickup = false;
        }

        if (this.needToCallStoreToNotifyForPickup) {
          alert(
            `${this.translate.instant('After arriving near the store, please call')} ${this.locationPhone}.`
          );
        } else {
          alert(
            this.translate.instant("After arriving near the store, please click the I'm Here button to notify the store.")
          );
        }
      }    

    });  
    this.spinner.hide()
  }

  loadData() {
    let orderGuid = this.route.snapshot.params["orderGuid"];
    sessionStorage.setItem("orderGuid", orderGuid);

    if (orderGuid) {
      this.orderId = orderGuid;
      this.merchantGuid = sessionStorage.getItem("guid");
      return this.dataService.getGuestOrderDetails(this.orderId, this.merchantGuid).pipe(
        tap(async (order) => {
          this.order = order;
          this.order.etaTime = order.etaTime.replace(/^0+/, "");
          this.canCancelOrder = order.lastStatus == "NEW";
          this.canNotifyStoreForPickUp = order.pickUpType == "CURBSIDE";
          this.isDelivery = order.pickUpType == "DELIVERY";
          this.isDineIn = order.pickUpType == "DINEIN";
          let continueLoading = this.updateProgress(this.isDelivery);
          this.ga.sendEvent("order_status_viewed", "");
          this.getDirectionUri = !this.isDelivery
            ? this.googleMapsURI +
              order.receipt.businessLatitude +
              "," +
              order.receipt.businessLongitude
            : "";
          this.locationPhone = order.receipt.businessPhone;
          this.autoComplete = order.autoComplete;
          this.forceImHere = order.forceImHere;
          
          if (order.guestLoyaltyCallToAction != null) {
            this.guestLoyaltyCTA.title = order.guestLoyaltyCallToAction.title ? order.guestLoyaltyCallToAction.title : this.translate.instant("Join Our Loyalty Program!")
            this.guestLoyaltyCTA.description = order.guestLoyaltyCallToAction.description ? order.guestLoyaltyCallToAction.description : this.translate.instant("Become a member today - it's fast and free!")
            this.guestLoyaltyCTA.firstPrompt = order.guestLoyaltyCallToAction.firstPrompt ? order.guestLoyaltyCallToAction.firstPrompt : this.translate.instant("Get birthday offers")
            this.guestLoyaltyCTA.secondPrompt = order.guestLoyaltyCallToAction.secondPrompt ? order.guestLoyaltyCallToAction.secondPrompt : this.translate.instant("Get access to discounts")
            this.guestLoyaltyCTA.thirdPrompt = order.guestLoyaltyCallToAction.thirdPrompt ? order.guestLoyaltyCallToAction.thirdPrompt : this.translate.instant("Earn with every purchase")
          }

          if (this.isDelivery && order.externalDeliveryTrackingUrl != null) {
            this.externalDeliveryTrackingUrl = order.externalDeliveryTrackingUrl
          }

          this.customerPortalLink = order.customerPortalLink;
          this.appleApplink = order.appleAppLink;
          this.googleAppLink = order.googleAppLink;
          this.hasApp = this.appleApplink != null || this.googleAppLink != null;

          this.loading = continueLoading;
        }),
        catchError((err) => {
          let authenticated = sessionStorage.getItem("id_token") ? true : false;

          if (err.message == "The order belongs to a loyalty customer.") {
            if (authenticated && !this.isGuest) {
              this.orderRouting.goToOrderStatus(this.orderId);
            }
            else {
              sessionStorage.setItem("redirectToOrderStatus", "1");
                sessionStorage.setItem("ignoreGuest", "1");
                this.orderRouting.goToLogin();
            }
          }
          else {
            alert(err.message);
            if (authenticated && !this.isGuest) {
              this.orderRouting.goToMyOrders();
            }
            else {
              this.orderRouting.goToLocation();
            }
            return throwError(err);
          }
        })
      );
    }
  }

  private updateProgress(isDelivery) {
    if (
      this.order.lastStatus === "CANCELLED" ||
      this.order.lastStatus === "REJECTED" ||
      this.order.lastStatus === "COMPLETED"
    ) {
      let status: string = this.order.lastStatus;
      alert(this.translate.instant("Your order has been") + " " + this.translate.instant(status.toLowerCase()));
      this.orderRouting.gotToGuestOrderReceipt(this.order.orderId);
      return true;
    }
    this.initSteps(isDelivery);
    this.currentStep = this.orderProgress.steps.find(
      (step) => step.prevStepStatus === this.order.lastStatus
    );
    this.orderProgress.steps.forEach((step) => {
      if (step.stepNumber <= this.currentStep.stepNumber) step.completed = true;
    });
    return false;
  }

  private initSteps(isDelivery) {
    this.orderProgress = new OrderProgress();
    this.orderProgress.steps = new Array<OrderStep>();
    let steps = Array<OrderStep>();
    if (isDelivery) {
      steps = [
        {
          icon: "",
          title: "Order Placed",
          subtitle:
            this.translate.instant("Order Placed at") + " " +
            this.order.placedTSTime
              .toString()
              .replace(/^0+/, "")
              .replace(" PM", "pm")
              .replace(" AM", "am"),
          iconSrc: "/assets/svg/placed-order.svg",
          stepNumber: 1,
          prevStepStatus: "NEW",
          completed: false,
        },
        {
          icon: "",
          title: "Preparing Order",
          subtitle: "Now completing your order",
          iconSrc: "/assets/svg/preparing-order.svg",
          stepNumber: 2,
          prevStepStatus: "ACCEPTED",
          completed: false,
        },
        {
          icon: "",
          title: "Order Ready for Delivery",
          subtitle: "Your order is ready for delivery!",
          iconSrc: "/assets/svg/inprogress-order.svg",
          stepNumber: 3,
          prevStepStatus: "FULLFILLED",
          completed: false,
        },
        {
          icon: "",
          title: "Order Out for Delivery",
          subtitle: "Your order is out for delivery!",
          iconSrc: "/assets/svg/car.svg",
          stepNumber: 4,
          prevStepStatus: "OUTFORDELIVERY",
          completed: false,
        },
      ];
    } else if (this.isDineIn) {
        steps = [
            {
                icon: "",
                title: "Order Placed",
                subtitle: this.order.placedFriendlyDescription,
                iconSrc: "/assets/svg/placed-order.svg",
                stepNumber: 1,
                prevStepStatus: "NEW",
                completed: false,
            },
            {
                icon: "",
                title: "Preparing Order",
                subtitle: "Now completing your order",
                iconSrc: "/assets/svg/preparing-order.svg",
                stepNumber: 2,
                prevStepStatus: "ACCEPTED",
                completed: false,
            }
        ];
    } else {
      steps = [
        {
          icon: "",
          title: "Order Placed",
          subtitle: this.order.placedFriendlyDescription,
          iconSrc: "/assets/svg/placed-order.svg",
          stepNumber: 1,
          prevStepStatus: "NEW",
          completed: false,
        },
        {
          icon: "",
          title: "Preparing Order",
          subtitle: "Now completing your order",
          iconSrc: "/assets/svg/preparing-order.svg",
          stepNumber: 2,
          prevStepStatus: "ACCEPTED",
          completed: false,
        },
        {
          icon: "",
          title: "Order Ready for Pickup",
          subtitle: "Your order is ready!",
          iconSrc: "/assets/svg/inprogress-order.svg",
          stepNumber: 3,
          prevStepStatus: "FULLFILLED",
          completed: false,
        },
      ];
    }

    this.orderProgress.steps = steps;
  }

  doRefresh(event) {
    this.loadData().subscribe(
      (data) => (event ? event.target.complete() : ""),
      (err) => (event ? event.target.complete() : "")
    );
  }

  async doRefreshByButton(showLoading = true) {
    const loading = await this.loadingController.create({
      message: this.translate.instant("Refreshing Order Details..."),
    });

    if (showLoading) await loading.present();
    this.loadData().subscribe(
      (data) => {
        loading.dismiss();
      },
      (err) => {
        loading.dismiss();
      }
    );
  }

  cancelOrder() {
    if (this.cancelClicked == true) return;
    if (!confirm(this.translate.instant("Are you sure you want to cancel this order?"))) return;
    this.cancelClicked = true;
    this.spinner.show();
    this.dataService.cancelGuestOrder(this.orderId, this.merchantGuid).subscribe(
      (result) => {
        this.spinner.hide();
        alert(this.translate.instant("Your order has now been cancelled"));
        this.ga.sendEvent("order_cancelled", "");
        this.orderRouting.goToLocation();
      },
      (err) => {
        this.spinner.hide();
        alert(err.message);
      }
    );
  }

  notifyStore() {
    if (this.autoComplete && !this.forceImHere){
        alert(this.translate.instant("Please call store ({phone}), to let them know you are here.", { phone: this.locationPhone}))
    } else {
      this.spinner.show();
      this.dataService.guestNotifystore(this.orderId, this.merchantGuid).subscribe(
        (result) => {
          this.spinner.hide();
          alert(this.translate.instant("Notified the store."));
        },
        (err) => {
          this.spinner.hide();
          alert(err.message);
        }
      );
    }
  }

  redirectToSignup() {
    if (this.customerPortalLink != null) {
      window.open(this.customerPortalLink, "_blank");
    }
  }

  redirectToAppleApp() {
    if (this.appleApplink != null) {
      window.open(this.appleApplink, "_blank");
    }
  }

  redirectToGoogleApp() {
    if (this.googleAppLink != null) {
      window.open(this.googleAppLink, "_blank");
    }
  }

  navigateToReceipt() {
    this.orderRouting.gotToGuestOrderReceipt(this.orderId);
  }

  navigateToDirection() {
    if (this.isMobile) {
      window.open(this.getDirectionUri, "_system");
    } else {
      window.open(this.getDirectionUri, "_blank");
    }
  }

  navigateToDeliveryTracking() {
    if (this.isMobile) {
      window.open(this.externalDeliveryTrackingUrl, "_system");
    } else {
      window.open(this.externalDeliveryTrackingUrl, "_blank");
    }
  }

}
