
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrderRoutingService } from './order-routing.service';
import { CustomerSessionService } from './customer-session.service';

@Injectable()
export class MerchantGuidGuardService implements CanActivateChild {
 
  constructor(private customerSession: CustomerSessionService, 
              public router: Router,
              private spinner: NgxSpinnerService,
              private aroute: ActivatedRoute,
              private orderRouting: OrderRoutingService) {}
  
  canActivateChild(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.orderRouting.guid){
      console.log('merchant-guid-guard - guid from orderRouting:' + this.orderRouting.guid);
      return true;
    } else {
      let guid = state.root.children[0].params['guid'];
      console.log('merchant-guid-guard - guid from state: ' + guid);
      if (guid){
        if (state.url.includes('/order/status/')) {
          sessionStorage.setItem("redirectToGuestOrderStatus", "1");
          // merchant > orders > status
          sessionStorage.setItem("orderGuid", state.root.children[0].children[0].children[0].params['orderGuid']);
        }
        this.router.navigate(['/merchant', guid]);
      } else {
        console.log('merchant-guid-guard - ERROR!!!!!');
        //TODO go to some other page?
      }
    }
  }
}