import { Injectable } from '@angular/core';
import { PaymentAction } from './models/enum';
import { LoadingController, AlertController } from '@ionic/angular';
import { LoadWalletRequestV2 } from './models/load-wallet-request';
import { CustomerSessionService } from './customer-session.service';
import { GoogleAnalyticService } from './google-analytic.service';
import { DataService } from './data.service';
import { OrderRoutingService } from './order-routing.service';
import { PlaceOrderRequest } from './models/place-order-request';
import { OrderPayment, MOPaymentType } from './models/order-payment';
import { CartService } from './cart.service';
import { ProcessPaymentRequest } from './models/process-payment-request';
import { CatalogService } from './catalog.service';
import { CategoryTrackedService } from './common/category-tracked.service';
import { DecimalPipe } from '@angular/common';
import { stringify } from 'querystring';
import { ErrorHandlerService } from './error-handler.service';
import { FacebookPixelService } from './facebook-pixel.service';
import { GoogleTagManagerService } from './google-tag-manager.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  request: ProcessPaymentRequest = new ProcessPaymentRequest();

  public siteKey: string = environment.recaptchaSiteKey;

  constructor(private loadingController: LoadingController,
    private alertController: AlertController,
    private customerSession: CustomerSessionService,
    private ga: GoogleAnalyticService,
    private dataService: DataService,
    private orderRouting: OrderRoutingService,
    private cartService: CartService,
    private catalogService: CatalogService,
    private categoryTrackedService: CategoryTrackedService,
    private errorHandlerService: ErrorHandlerService,
    private decimalPipe: DecimalPipe,
    private gtmService: GoogleTagManagerService, private fbPixelService: FacebookPixelService,
    private translate: TranslateService) { }

  getActionText(){
    //ensure action is set
      if (this.request.action == PaymentAction.LOADWALLET) {
          return this.translate.instant("Load Wallet") + " " + this.request.currencySymbol + this.decimalPipe.transform(this.request.amount, '1.2-2');
    } else if (this.request.action == PaymentAction.PLACEORDER){
          return this.translate.instant("Place Order") + " " + this.request.currencySymbol + this.decimalPipe.transform(this.request.amount, '1.2-2');
    } else {
      return "";
    }
  }

  async processPayment(){
    //based on action decide what call to make
    if (this.request.action == PaymentAction.LOADWALLET){
      //create loader
      const loadingWallet = await this.loadingController.create({
        message: this.translate.instant('Loading Wallet...')
      });

      

      //present loader
      await loadingWallet.present();
      
      //start building request v2 
      let request = new LoadWalletRequestV2();
      request.amount = this.request.amount;
      request.merchantLocationId = Number(this.customerSession.locationId);
      request.squareVerificationToken = this.request.squareVerificationToken;

      if (this.request.payWithSavedCreditCard){
        request.payWithSavedCreditCard = true;
      } else {        
        request.token = this.request.token;
        request.cardNumber = this.request.cardNumber;
        request.cardExpirationMonth = this.request.cardExpirationMonth;
        request.cardExpirationYear = this.request.cardExpirationYear;
        request.cardCVV = this.request.cardCVV;
        request.cardHolderName = this.request.cardHolderName;
        request.payWithSavedCreditCard = false;
        request.saveCreditCard = this.request.saveCreditCard;
        request.postalCode = this.request.postalCode;
        request.countryCode = this.request.countryCode;
        if (this.request.isApplePay) {
          request.isApplePay = this.request.isApplePay;
          request.applePayApplicationData = this.request.applePayApplicationData;
          request.applePayPaymentId = this.request.applePayPaymentId;
          request.applePayToken = this.request.applePayToken;
        }
      }

      //call request + handle error
      this.dataService.loadWalletV2(request).subscribe((result) => {
        console.log(result);
        if (result.isSuccess){
          this.ga.sendEvent('wallet_reloaded', '');
          loadingWallet.dismiss();         
          //todo: verify go to checkout now?
          this.orderRouting.goToCheckout();
        } else {          
            loadingWallet.dismiss();
            if (result.errors.length > 0) {
                console.log(result.errors[0]);
                this.errorHandlerService.handleError(result.errors[0]);
            }
            else {
                console.log(result);
                alert(result.message);
            }
        }
      }, (err) => {
        console.log(err);
        loadingWallet.dismiss();
        this.errorHandlerService.handleError(err);
      });

    } else if (this.request.action == PaymentAction.PLACEORDER){
     

      //create loader
      const loadingOrder = await this.loadingController.create({
        message: this.translate.instant('Placing Order....')
      });
      await loadingOrder.present();
      //get request data
      let orderPayments: OrderPayment[] = [];
      let total = this.cartService.getCartTotal();
      let availableWalletBalance = this.cartService.getAvailableWalletBalance();

      //reset paymentMethodsApplied
      let paymentMethodsApplied = "";
      
      if (total == 0){
        //do not send any payments
      } else {
        let remainingBalance = total;
        if (this.request.applyGiftCard == true && this.request.giftCardBalance >= 0) {
          //charge gift card
          let giftCardAmount = this.request.giftCardBalance > remainingBalance ? remainingBalance : this.request.giftCardBalance;
          let giftCardPayment = this.getGiftCardPayment(giftCardAmount, this.request.giftCardId, this.request.giftCardPin);
          orderPayments.push(giftCardPayment);
          remainingBalance = Math.round(remainingBalance * 100 - giftCardAmount * 100) / 100;  

          paymentMethodsApplied = "GIFT_CARD";
          if (remainingBalance > 0) paymentMethodsApplied += ",";
        } 
        
        if (remainingBalance > 0)
        {
          if (this.request.applyWallet == true && availableWalletBalance >= remainingBalance) {
            //charge wallet
            let walletPayment = this.getWalletPayment(remainingBalance);
            orderPayments.push(walletPayment);

            paymentMethodsApplied += "TAPMANGO_WALLET";
          }
          else if (this.request.applyWallet == true && availableWalletBalance < remainingBalance) {
            //charge wallet
            let walletPayment = this.getWalletPayment(availableWalletBalance);
            orderPayments.push(walletPayment);

            //difference
            let chargeCardAmount = Math.round(remainingBalance * 100 - availableWalletBalance * 100) / 100;

            //charge card
            let cardPayment = this.getCardPayment(chargeCardAmount);
            orderPayments.push(cardPayment);

            paymentMethodsApplied += "TAPMANGO_WALLET, CREDIT_CARD";
          } 
          else {
            //charge card
            let cardPayment = this.getCardPayment(remainingBalance);
            orderPayments.push(cardPayment);

            paymentMethodsApplied += "CREDIT_CARD";
          }
        }
      }

      //build request
      const recaptchaAction = 'placeOrder'
      const token = await (<any>window).grecaptcha.enterprise.execute(this.siteKey, {action: recaptchaAction});

      let placeOrderRequest = new PlaceOrderRequest();
      let orderId = this.cartService.getCartOrderId();
      placeOrderRequest.OrderId = orderId;
      placeOrderRequest.Payments =  orderPayments;  
      placeOrderRequest.Token = token;
      placeOrderRequest.RecaptchaAction = recaptchaAction;
      
      if (this.request.guestDetails != null) {
        placeOrderRequest.GuestDetails = this.request.guestDetails;
      }

      //result object TBD
      this.dataService.placeOrder(placeOrderRequest).subscribe(async (result) => {
        this.ga.sendEventWithValue("order_placed", paymentMethodsApplied, Math.round(total));       
        sessionStorage.setItem("send_purchase_total", total.toString());
        this.cartService.clearCart();
        this.catalogService.reloadCatalog = true;
        this.catalogService.lastSelectedCategoryId = -1;
        this.categoryTrackedService.catalogComponent.setProperty("selectedCategoryId", -1);
        this.categoryTrackedService.catalogComponent.setProperty("subCategoryViewOpen", false);        
        loadingOrder.dismiss();            

        let isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;
        await this.cartService.clearSessionForNewOrder().then((result) => {
          if (result) {
            sessionStorage.setItem("send_purchase_tracking", "true");
            if (isGuest) {
              this.orderRouting.goToGuestOrderStatus(orderId);
            }
            else {
              this.orderRouting.goToOrderStatus(orderId, true);
            }
          }
        });      
        
      }, (err) => {
        //to do handle it better
        loadingOrder.dismiss();
        console.log(err);
        this.errorHandlerService.handleError(err);        
      });
    } 
  }

  //helper
  getWalletPayment(amount) : OrderPayment {
    let walletPayment = new OrderPayment();
    walletPayment.PaymentType = MOPaymentType.TAPMANGO_WALLET;
    walletPayment.Amount = amount;

    return walletPayment;
  }

  //helper
  getGiftCardPayment(amount, giftCardId, giftCardPin) : OrderPayment {
    let gcPayment = new OrderPayment();
    gcPayment.PaymentType = MOPaymentType.GIFT_CARD;
    gcPayment.Amount = amount;
    gcPayment.GiftCardId = giftCardId;
    gcPayment.GiftCardPin = giftCardPin;

    return gcPayment;
  }

  getCardPayment(amount) : OrderPayment{
    let cardPayment = new OrderPayment();
    cardPayment.PaymentType = MOPaymentType.CREDIT_CARD;
    cardPayment.Amount = parseFloat(amount.toFixed(2));   
    cardPayment.SquareVerificationToken = this.request.squareVerificationToken;
    cardPayment.TempNotes = this.request.tempNotes;

    if (this.request.payWithSavedCreditCard){
      cardPayment.PayWithSavedCreditCard = true;
      cardPayment.SaveCreditCard = false;
    } else {
      //pass token/details + save CC
      cardPayment.PayWithSavedCreditCard = false;
      cardPayment.SaveCreditCard = this.request.saveCreditCard;
      cardPayment.CardHolderName = this.request.cardHolderName;
      cardPayment.PostalCode = this.request.postalCode;
      cardPayment.CountryCode = this.request.countryCode;
          
      if (this.request.token){
        cardPayment.Token = this.request.token;
      } else {
        cardPayment.CardNumber = this.request.cardNumber;
        cardPayment.CardCVV = this.request.cardCVV;
        cardPayment.CardExpirationMonth = this.request.cardExpirationMonth;
        cardPayment.CardExpirationYear = this.request.cardExpirationYear;
      }
      if (this.request.isApplePay) {
        cardPayment.IsApplePay = true;
        cardPayment.ApplePayPaymentId = this.request.applePayPaymentId;
        cardPayment.ApplePayToken = this.request.applePayToken;
        cardPayment.ApplePayApplicationData = this.request.applePayApplicationData;
      }
    }

    return cardPayment;
  }


  removeCard(): Promise<boolean> {

        return new Promise(async (resolve, reject) => {
            const confirm = (await this.alertController.create({
                header: this.translate.instant('Confirm deletion'),
                message: this.translate.instant('Please confirm you want to delete your card.'),
                buttons: [
                    {
                        text: this.translate.instant('Cancel'),
                        role: 'cancel',
                        handler: async () => {
                            console.log('Confirm Cancel');
                            resolve(false);
                        }
                    }, {
                        text: this.translate.instant('Okay'),
                        handler: async () => {
                            console.log('Confirm Okay');
                            const removingCardLoad = await this.loadingController.create({
                                message: this.translate.instant('Removing Card...')
                            });

                            //present loader
                            await removingCardLoad.present();
                            await this.dataService.removeCard(this.customerSession.locationId, this.customerSession.paymentMode).toPromise().then((result) => {
                                removingCardLoad.dismiss();
                                if (result.isSuccess) {
                                    resolve(true);
                                }
                                else {
                                    this.errorHandlerService.alertMessage(this.translate.instant("Something went wrong. Unable to remove the card."));
                                    resolve(false);
                                }
                            });
                            return false;
                        }
                    }
                ]
            })).present();
        });

  }

   sendTrackingEvent() {
    let total = sessionStorage.getItem("send_purchase_total");    
    let currencyCode = sessionStorage.getItem("currencyCode");
    this.gtmService.trackEvent(
      {
        eventName: 'Purchase',
        eventCategory: "Click Events", 
        eventAction: "Pay Button Clicked", 
        eventLabel: "Pay", 
        eventValue: total, 
        eventLocation: "Checkout Page",
        eventApp: "TapMango Online Order",
        currency: currencyCode
      });      
      this.fbPixelService.sendEvent('Purchase', total); 
   }

   sendSlackMessage(message): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.dataService.sendSlackMessage(message).subscribe((result) => {
        if (result && !result.isError) {
          resolve(true);
        }
        else {
          resolve(false);
        }
      })
    })
    
   }

   getEtaMessage() {
      if(this.cartService.etaTimeMessage) {
        return this.cartService.etaTimeMessage;
      }
      else {
        return "NO_ETA"
      }
     
   }
   getOrderType() {
    if(this.cartService.editbuttonText$.value) {
      return this.cartService.editbuttonText$.value;
    }
    else {
      return "NO_ORDER_TYPE"
    }   
  }

  getMerchantLocationId() {
    return Number(this.customerSession.locationId);
  }
  getMerchantId() {
    let locations = this.customerSession.locations;
    let merchantId = 0;
    if (locations && locations.length > 0) {
      let location = locations.filter(x => x.merchantLocationId == this.getMerchantLocationId());
      if (location && location.length > 0 && location[0].merchantId) {
        merchantId = location[0].merchantId
      }
    }
    return merchantId;
  }
  getcustomerId() {
    return Number(this.customerSession.customerId);
  }
}
