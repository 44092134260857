import { Location } from "@angular/common";
import { AfterViewInit, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { throwError, timer } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { DataService } from "../data.service";
import { OrderProgress, OrderStep } from "./order-progress.model";
import { GoogleAnalyticService } from "../google-analytic.service";
import { OrderRoutingService } from "../order-routing.service";
import { LoadingController } from "@ionic/angular";
import { CustomerSessionService } from "../customer-session.service";
import { UtilService } from "../util.service";
import { Psa } from "../models/psa"
import { InstantWin } from "../models/instant-win"
import { PaymentService } from "../payment.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "order-status",
  templateUrl: "./order-status.component.html",
  styleUrls: ["./order-status.component.scss"],
})
export class OrderStatusComponent implements AfterViewInit {
  orderId: string;
  order: any;
  canCancelOrder: boolean = false;
  canNotifyStoreForPickUp: boolean = false;
  needToCallStoreToNotifyForPickup: boolean = false;
  isDelivery: boolean = false;
  isDineIn: boolean = false;
  orderProgress: OrderProgress;
  cancelClicked: boolean = false;
  currentStep: OrderStep;
  getDirectionUri: string;
  googleMapsURI = "https://www.google.com/maps/dir/?api=1&destination=";
  logoUrl: string = null;
  isMobile;
  locationPhone: string;
  autoComplete: boolean = false;
  showPsaPopup: boolean;  
  psa: Psa;
  isGenericPsa: boolean;
  isReferralPsa: boolean;
  isInstantWinPsaStep1: boolean;
  isInstantWinPsaStep2: boolean;
  isInstantWinPsaStep3: boolean;
  countdown: number;
  countdownInterval: any;
  instantWin: InstantWin;
  forceImHere: boolean = false;
  externalDeliveryTrackingUrl: string = null;

  constructor(
    public route: ActivatedRoute,
    private dataService: DataService,
    private location: Location,
    private router: Router,
    private spinner: NgxSpinnerService,
    private ga: GoogleAnalyticService,
    private loadingController: LoadingController,
    private customerSession: CustomerSessionService,
    private orderRouting: OrderRoutingService,
    private utilService: UtilService,
    private paymentService: PaymentService,
    private translate: TranslateService
  ) {}

  doRefresh(event) {
    this.loadData(true).subscribe(
      (data) => (event ? event.target.complete() : ""),
      (err) => (event ? event.target.complete() : "")
    );
  }

  async doRefreshByButton(showLoading = true) {
    const loading = await this.loadingController.create({
      message: this.translate.instant("Refreshing Order Details..."),
    });

    if (showLoading) await loading.present();
    this.loadData(true).subscribe(
      (data) => {
        loading.dismiss();
      },
      (err) => {
        loading.dismiss();
      }
    );
  }

  ngAfterViewInit(): void {
    
  }

  ionViewWillEnter() {
    this.orderId = null;
    this.order = null;
    this.logoUrl = sessionStorage.getItem("logoUrl");
    if (sessionStorage.getItem("send_purchase_tracking") && sessionStorage.getItem("send_purchase_tracking") == "true") {
      this.paymentService.sendTrackingEvent();
      sessionStorage.removeItem("send_purchase_tracking");
      sessionStorage.removeItem("send_purchase_total");
      sessionStorage.removeItem("lead_submission");
      sessionStorage.removeItem("lead_submission_timestamp");
    }  
  }

  ionViewDidEnter() {
    this.customerSession.mobileMode.subscribe((data) => {
      console.log("DASHBOARD COMPONENT SUBSCRIPTION FOR MOBILE MODE: " + data);
      if (data) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });

    this.loadData(false).subscribe((data) => {
      if (this.canNotifyStoreForPickUp) {
        //override needToCallStoreToNotifyForPickup
        if (this.forceImHere){
          this.needToCallStoreToNotifyForPickup = false;
        }

        if (this.needToCallStoreToNotifyForPickup) {
          alert(
            `${this.translate.instant('After arriving near the store, please call')} ${this.locationPhone}.`
          );
        } else {
          alert(
            this.translate.instant("After arriving near the store, please click the I'm Here button to notify the store.")
          );
        }
      }    

    });  
  }

  refreshTimer: any;    

  ionViewDidLeave() {
      // console.log("ionViewDidLeave for auto refresh");
      // this.clearRefreshTimer();        
  }

  // clearRefreshTimer() {
  //     if (this.refreshTimer) {
  //         clearTimeout(this.refreshTimer);
  //         this.refreshTimer = 0;
  //     }
  // }
  
  // activateAutoRefresh() {         
  //     this.refreshTimer =  setTimeout(() => {
  //         console.log("Auto refresh running");
  //         this.doRefreshByButton(false);
  //         this.activateAutoRefresh();
  //     }, 30000);
  // }

  async ngOnInit() {
    let paymentProvider = await this.customerSession.getPaymentProvider();
    this.needToCallStoreToNotifyForPickup = paymentProvider == 'Toast';    
  }

  loadData(isRefresh) {
    let guid = this.route.snapshot.params["guid"];
    let includePsa = !isRefresh && this.route.snapshot.params["psa"] == "true";
    if (guid) {
      this.orderId = guid;
      return this.dataService.getOrderDetails(this.orderId).pipe(
        tap(async (order) => {
          this.order = order;
          this.order.etaTime = order.etaTime.replace(/^0+/, "");
          this.canCancelOrder = order.lastStatus == "NEW";
          this.canNotifyStoreForPickUp = order.pickUpType == "CURBSIDE";
          this.isDelivery = order.pickUpType == "DELIVERY";
          this.isDineIn = order.pickUpType == "DINEIN";
          this.updateProgress(this.isDelivery);
          this.ga.sendEvent("order_status_viewed", "");
          this.getDirectionUri = !this.isDelivery
            ? this.googleMapsURI +
              order.receipt.businessLatitude +
              "," +
              order.receipt.businessLongitude
            : "";
          this.locationPhone = order.receipt.businessPhone;
          this.autoComplete = order.autoComplete;
          this.forceImHere = order.forceImHere;

          if (this.isDelivery && order.externalDeliveryTrackingUrl != null) {
            this.externalDeliveryTrackingUrl = order.externalDeliveryTrackingUrl
          }
          
          if (includePsa) {
            const loading = await this.loadingController.create({
              message: this.translate.instant('Finalizing....')
            });
            await loading.present();

            this.dataService.getPsa(this.order.merchantLocationId).subscribe(
              (data) => { 
                console.log(data.data);
                this.psa = data.data;
                this.setPsa();
                this.showPsaPopup = true;
                loading.dismiss().then(() => {
                  if (this.isGenericPsa) {
                    $("#psa-generic-header").trigger('focus');
                    console.log($("#psa-generic-header"));
                  }
                  else if(this.isReferralPsa) {
                    $("#psa-referral-header").trigger('focus');
                    console.log($("#psa-referral-header"));
                  }
                });               
               
              },
              (err) => { 
                this.setPsa();
                this.showPsaPopup = true;
                loading.dismiss().then(() => {
                  if (this.isGenericPsa) {
                    $("#psa-generic-header").trigger('focus');
                    console.log($("#psa-generic-header"));
                  }
                  else if(this.isReferralPsa) {
                    $("#psa-referral-header").trigger('focus');
                    console.log($("#psa-referral-header"));
                  }
                });   
              } 
            );               
          }

        }),
        catchError((err) => {
          alert(err.message);
          let isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;
          if (isGuest) {
            this.orderRouting.goToLocation();
          }
          else {
            this.orderRouting.goToMyOrders();
          }
          return throwError(err);
        })
      );
    }
  }

  private updateProgress(isDelivery) {
    if (
      this.order.lastStatus === "CANCELLED" ||
      this.order.lastStatus === "REJECTED" ||
      this.order.lastStatus === "COMPLETED"
    ) {
      let status: string = this.order.lastStatus;
      // this.clearRefreshTimer();
      alert(this.translate.instant("Your order has been") + " " + this.translate.instant(status.toLowerCase()));
      this.orderRouting.goToMyOrders();
      return;
    }
    this.initSteps(isDelivery);
    this.currentStep = this.orderProgress.steps.find(
      (step) => step.prevStepStatus === this.order.lastStatus
    );
    this.orderProgress.steps.forEach((step) => {
      if (step.stepNumber <= this.currentStep.stepNumber) step.completed = true;
    });
  }

  private initSteps(isDelivery) {
    this.orderProgress = new OrderProgress();
    this.orderProgress.steps = new Array<OrderStep>();
    let steps = Array<OrderStep>();
    if (isDelivery) {
      steps = [
        {
          icon: "",
          title: "Order Placed",
          subtitle:
            this.translate.instant("Order Placed at") + " " +
            this.order.placedTSTime
              .toString()
              .replace(/^0+/, "")
              .replace(" PM", "pm")
              .replace(" AM", "am"),
          iconSrc: "/assets/svg/placed-order.svg",
          stepNumber: 1,
          prevStepStatus: "NEW",
          completed: false,
        },
        {
          icon: "",
          title: "Preparing Order",
          subtitle: "Now completing your order",
          iconSrc: "/assets/svg/preparing-order.svg",
          stepNumber: 2,
          prevStepStatus: "ACCEPTED",
          completed: false,
        },
        {
          icon: "",
          title: "Order Ready for Delivery",
          subtitle: "Your order is ready for delivery!",
          iconSrc: "/assets/svg/inprogress-order.svg",
          stepNumber: 3,
          prevStepStatus: "FULLFILLED",
          completed: false,
        },
        {
          icon: "",
          title: "Order Out for Delivery",
          subtitle: "Your order is out for delivery!",
          iconSrc: "/assets/svg/car.svg",
          stepNumber: 4,
          prevStepStatus: "OUTFORDELIVERY",
          completed: false,
        },
      ];
    } else if (this.isDineIn) {
        steps = [
            {
                icon: "",
                title: "Order Placed",
                subtitle: this.order.placedFriendlyDescription,
                iconSrc: "/assets/svg/placed-order.svg",
                stepNumber: 1,
                prevStepStatus: "NEW",
                completed: false,
            },
            {
                icon: "",
                title: "Preparing Order",
                subtitle: "Now completing your order",
                iconSrc: "/assets/svg/preparing-order.svg",
                stepNumber: 2,
                prevStepStatus: "ACCEPTED",
                completed: false,
            }
        ];
    } else {
      steps = [
        {
          icon: "",
          title: "Order Placed",
          subtitle: this.order.placedFriendlyDescription,
          iconSrc: "/assets/svg/placed-order.svg",
          stepNumber: 1,
          prevStepStatus: "NEW",
          completed: false,
        },
        {
          icon: "",
          title: "Preparing Order",
          subtitle: "Now completing your order",
          iconSrc: "/assets/svg/preparing-order.svg",
          stepNumber: 2,
          prevStepStatus: "ACCEPTED",
          completed: false,
        },
        {
          icon: "",
          title: "Order Ready for Pickup",
          subtitle: "Your order is ready!",
          iconSrc: "/assets/svg/inprogress-order.svg",
          stepNumber: 3,
          prevStepStatus: "FULLFILLED",
          completed: false,
        },
      ];
    }

    this.orderProgress.steps = steps;
  }

  cancelOrder() {
    if (this.cancelClicked == true) return;
    if (!confirm(this.translate.instant("Are you sure you want to cancel this order?"))) return;
    this.cancelClicked = true;
    // this.clearRefreshTimer();
    this.spinner.show();
    this.dataService.cancelOrder(this.orderId).subscribe(
      (result) => {
        this.spinner.hide();
        alert(this.translate.instant("Your order has now been cancelled"));
        this.ga.sendEvent("order_cancelled", "");
        this.orderRouting.goToMyOrders();
      },
      (err) => {
        this.spinner.hide();
        alert(err.message);
      }
    );
  }

  notifyStore() {
    if (this.autoComplete && !this.forceImHere){
        alert(this.translate.instant("Please call store ({phone}), to let them know you are here.", { phone: this.locationPhone}))
    } else {
      this.spinner.show();
      // this.clearRefreshTimer();
      this.dataService.notifyStore(this.orderId).subscribe(
        (result) => {
          this.spinner.hide();
          // tell them we have notified the store
          alert(this.translate.instant("Notified the store."));
        },
        (err) => {
          this.spinner.hide();
          alert(err.message);
        }
      );
    }
  }

  back() {
    this.orderRouting.goToMyOrders();
  }

  navigateToReceipt() {
    this.orderRouting.goToOrderReceipt(this.orderId);
  }

  navigateToDirection() {
    if (this.isMobile) {
      window.open(this.getDirectionUri, "_system");
    } else {
      window.open(this.getDirectionUri, "_blank");
    }
  }
  
  navigateToDeliveryTracking() {
    if (this.isMobile) {
      window.open(this.externalDeliveryTrackingUrl, "_system");
    } else {
      window.open(this.externalDeliveryTrackingUrl, "_blank");
    }
  }

  closePsaModal() {
    this.showPsaPopup = false;
  }

  copyReferralLink() {
    const link = this.psa.referralLink;
    this.psa.referralLink = "copied";
    const tempBox = document.createElement('textarea');
    tempBox.style.position = 'fixed';
    tempBox.style.left = '0';
    tempBox.style.top = '0';
    tempBox.style.opacity = '0';
    tempBox.value = link;
    document.body.appendChild(tempBox);
    tempBox.focus();
    tempBox.select();/* for mobile devices */
    document.execCommand('copy');
    document.body.removeChild(tempBox);
    setTimeout(() => this.psa.referralLink = link, 500);
    $("#referral-link").trigger('focus');         
  }

  private setPsa(){
    this.isGenericPsa = false;
    this.isReferralPsa = false;
    this.isInstantWinPsaStep1 = false;
    this.isInstantWinPsaStep2 = false;
    this.isInstantWinPsaStep3 = false;

    if (this.psa == null) {
      this.psa = new Psa();
      this.psa.type = "GENERIC";
      this.psa.message = this.translate.instant("Your order is being processed. Enjoy and we hope to see you again soon!");
    }

    switch (this.psa.type) {
      case "GENERIC":
        this.isGenericPsa = true;
        break;
      case "REFERRAL":
        this.isReferralPsa = true;
        break;
      case "INSTANT_WIN":
        this.isInstantWinPsaStep1 = true;
        break;
    }
  }

  play(){
    this.isInstantWinPsaStep1 = false;
    this.isInstantWinPsaStep2 = true;
    this.countdown = 3;
    this.countdownInterval = setInterval(() => {
      this.countdown -= 1;
      if (this.countdown == 0) this.doPlay();
    }, 1000);
  }

  private async doPlay(){
    clearInterval(this.countdownInterval);
    this.isInstantWinPsaStep2 = false;
    this.isInstantWinPsaStep3 = true;
    this.isInstantWinPsaStep3 = true;
    
    const loading = await this.loadingController.create({});
    await loading.present();

    this.dataService.playInstantGame(this.order.merchantLocationId).subscribe(
      (data) => { 
          console.log(data);
          this.instantWin = data;
          loading.dismiss();
      },
      (err) => { 
        loading.dismiss();
        alert(err.message);
      } 
    );

  }

 /* Accessibility Implementation for Key Press */
  eventHandler(event) 
  {
      try {
          if (event.currentTarget.id == "referral-link" && event.keyCode == 13) {
            document.getElementById("screenReaderTapMessage").focus();              
          }               
      } catch (error) {
          
      }      
  }
}
