import { Component, Input, OnInit, ChangeDetectorRef, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { AlertController } from '@ionic/angular';
import { CustomerSessionService } from '../customer-session.service';
import { GoogleAnalyticService } from '../google-analytic.service';
import { PaymentService } from '../payment.service';
import { PaymentMode } from '../models/payment-mode';

@Component({
  selector: 'payment-manage',
  templateUrl: './payment-manage.component.html',
  styleUrls: ['./payment-manage.component.scss']
})
export class PaymentManageComponent implements OnInit {
  membershipModule: boolean = false;

  hasCard: boolean = false;
  cardType: string;
  cardLast4: string;
  cardImgSrc: string;
  hasLoaded: boolean = false;
  cardTypeDescription: string;
  cardExpiry: string;
  cardHolderName: string;

  constructor(
    private dataService: DataService,
    public alertController: AlertController,
    private changeDetectorRef: ChangeDetectorRef,
    private customerSession: CustomerSessionService,
    private ga: GoogleAnalyticService,
    private paymentService: PaymentService,
    ) {
     
  }

    async ionViewWillEnter() {
      this.customerSession.paymentMode = PaymentMode.NONE;
      this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;

      this.dataService.getCard(this.customerSession.locationId, this.customerSession.paymentMode).subscribe((result) => {
        this.hasLoaded = true;
        console.log('data received from getCard', result);
        if (result.isSuccess){
          if (result.data.cardIsPopulated){
            this.cardType = result.data.cardType;
            this.cardLast4 = result.data.cardLast4;
            this.hasCard = true;

            let imgFile = "";
            this.cardTypeDescription = "";
            if(this.cardType.toUpperCase() === "VISA"){
              imgFile = "visa.png";
              this.cardTypeDescription = "VISA";
            }
            else if(this.cardType.toUpperCase() === "MASTERCARD"){
              imgFile = "mastercard.png";
              this.cardTypeDescription = "MASTERCARD";
            }
            else if(this.cardType.toUpperCase() === "AMERICAN_EXPRESS"){
              imgFile = "american-express.png";
              this.cardTypeDescription = "AMERICAN EXPRESS";
            }
            else{
              imgFile = "payment-card.png";
            }
              
            this.cardExpiry = result.data.cardExpirationMonth + "/" + result.data.cardExpirationYear;
            this.cardHolderName = result.data.cardHolderName;
            this.cardImgSrc = `/assets/imgs/${imgFile}`;
          }
        }
      });
    }

    ngOnInit() {
    
    }
      
    async removeCard() {
        await this.paymentService.removeCard().then((result) => {
            if (result) {
                this.hasLoaded = true;
                console.log('remove card response is: ', result);
                this.hasCard = false;
                this.changeDetectorRef.detectChanges();
                this.ga.sendEvent('payment_card_removed', '');
            }
        });
    }
}
