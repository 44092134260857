import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { CustomerSessionService } from '../customer-session.service';
import { DataService } from '../data.service';
import { OrderRoutingService } from '../order-routing.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AlertPopupComponent } from '../common/alert-popup/alert-popup.component';
import { AssetService } from '../merchant-assets/asset.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MembershipRoutingService } from '../membership-routing.service';
import { UtilService } from '../util.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    registerForm : FormGroup;
    customerName : FormControl;
    email : FormControl;
    cpassword : FormControl;
    password : FormControl;
    phone : FormControl;
    merchantLocationId : FormControl;
    merchantLocations;
    askForLocation = false;
    agreeToCommunication : FormControl;
    agreeToTerms : FormControl;
    @ViewChild('locationSelect') private locationSelectEle: any;
    locationId = 0;
    isUnloading = false;
    CURRENT_VIEW_ENTERED = "register";
    merchantGuid: string;
    membershipModule: boolean = false;

    siteKey: string = environment.recaptchaSiteKey;

    @ViewChild('alertPopup') alertPopup: AlertPopupComponent;

    constructor(private authService: AuthService,
        private customerSessionService: CustomerSessionService,
        private dataService: DataService,
        private assetService: AssetService, private spinner: NgxSpinnerService,
        private orderRouting: OrderRoutingService, private fb: FormBuilder,
        private membershipRouting: MembershipRoutingService,
        private utilService: UtilService,
        private translate: TranslateService) {
        
    }

    ngOnInit() {
        this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;

        this.customerName = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(2)]);
        this.email = new FormControl('', [Validators.required, Validators.email]);
        this.password = new FormControl('', [Validators.required, Validators.maxLength(15), Validators.minLength(5)]);
        this.cpassword = new FormControl('', [Validators.required, this.authService.MustMatch(this.password)]);
        this.phone = new FormControl('', [Validators.required, this.utilService.validatePhoneNumber]);
        this.merchantLocationId = new FormControl('', [Validators.required]);
        this.agreeToTerms = new FormControl('false', [Validators.requiredTrue]);
        this.agreeToCommunication = new FormControl('false', [Validators.requiredTrue]);
        if (this.membershipModule) {
            this.merchantGuid = this.membershipRouting.guid;
        } else {
            this.merchantGuid = this.orderRouting.guid;
        }

        this.registerForm = this.fb.group({
            'customerName' : this.customerName,
            'email' : this.email,
            'password' : this.password,
            'cpassword' : this.cpassword,
            'phone' : this.phone,
            'merchantLocationId'  : this.merchantLocationId,
            'agreeToTerms' : this.agreeToTerms,
            'agreeToCommunication' : this.agreeToCommunication       
          });

        if (this.membershipModule) {
            this.askForLocation = true;
            console.log('Register Component (membership component) - getting locations from API');
            this.dataService.getMerchantLocations().subscribe(
                (data) => {
                    if (data) {
                        this.merchantLocations = data;
                        console.log('Register Component (membership component) - Merchant Locations are: ', this.merchantLocations);
                    }
                },
                (err) => {
                    console.log(err);
                });
        } else {
            if (!this.customerSessionService.locationId) {
                this.askForLocation = true;
                console.log('Register Component - we need to get the location');
                if (this.customerSessionService.locations) {
                    console.log('Register Component - got locations from Customer Session Service');
                    this.merchantLocations = this.customerSessionService.locations;
                    console.log('Register Component - Merchant Locations are: ', this.merchantLocations);
                } else {
                    //get locations
                    console.log('Register Component - Need to get Locations via API');
                    this.dataService.getCatalogLocations().subscribe((data) => {
                        if (data) {
                            this.merchantLocations = data;
                            console.log('Register Component - Merchant Locations are: ', this.merchantLocations);
                        }
                    });
                }
            } else {
                console.log('Register Component - current location selected is: ' + this.customerSessionService.locationId);
                this.registerForm.controls['merchantLocationId'].setValue(this.customerSessionService.locationId);
            }
        }
    }

    async ionViewWillEnter() {
        this.removeOpacity();
        this.isUnloading = false;   
        /* LOAD CUSTOM STYLE IF PROVIDED - <param - component name> */     
        if (sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) && sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) !== "") {
            this.assetService.getCustomCSS(sessionStorage.getItem(this.CURRENT_VIEW_ENTERED), this.CURRENT_VIEW_ENTERED);
        }
    }

    async ionViewWillLeave() 
    {
        this.spinner.show();
        this.isUnloading = true;   
        const clearCustomCss = () => new Promise<boolean>(async (resolve, reject) => { $("#" + this.CURRENT_VIEW_ENTERED + "_CUSTOM_CSS").remove(); return resolve(true)});  
        await clearCustomCss().then((result) => console.log("CLEARED CUSTOM CSS FOR " + this.CURRENT_VIEW_ENTERED)).then(() => {
            setTimeout(() => {
                this.spinner.hide();
            }, 1000); 
        });    
    }

    removeOpacity() {
        if (!this.locationSelectEle || this.locationSelectEle.el.shadowRoot.querySelector('.select-placeholder') === null) {
            return;
        }
        this.locationSelectEle.el.shadowRoot.querySelector('.select-placeholder').setAttribute('style', 'opacity:1;');
        this.locationSelectEle.el.shadowRoot.querySelector('.select-icon-inner').setAttribute('style', 'margin-top: 0;opacity: 1!important;');
    }

    onSubmit() 
    {
        console.log("userDetails");
    }
    async register() {
        let userDetails = this.registerForm.value;
        console.log(userDetails);
        if (this.askForLocation == true && !this.registerForm.controls['merchantLocationId'].value) {
            alert(this.translate.instant('Please pick your location'));
            return;
        } else if (this.askForLocation == true) {
            //set location id - might need to clear cart and some other stuff not sure yet
            this.customerSessionService.locationId =  this.registerForm.controls['merchantLocationId'].value;
        }

        if (this.registerForm.controls['agreeToCommunication'].value == false) {
            $("#agreeToCommunicationlbl").addClass("text-danger");
            return;
            
        }
        
        if (this.registerForm.controls['agreeToTerms'].value == false) {
            $("#agreeToTermslbl").addClass("text-danger");
            return;
        }

        // get action token
        const recaptchaAction = 'RegisterCustomerOO'
        const token = await (<any>window).grecaptcha.enterprise.execute(this.siteKey, {action: recaptchaAction});

        //validation - ends
        var createCustomerRequest = {
            name: userDetails.customerName,
            email: userDetails.email,
            password: userDetails.password,
            phone: userDetails.phone,
            merchantLocationId: userDetails.merchantLocationId,
            recaptchaToken: token,
            recaptchaAction: recaptchaAction
        }
        console.log('register request - ', createCustomerRequest);

        this.authService.createCustomer(createCustomerRequest).subscribe((data) => {
            console.log(data);
            if (this.membershipModule) {
                this.membershipRouting.goBack();
            } else {
                this.orderRouting.goBack();
            }
        }, (err) => {
            console.log('got an error trying to register customer', err);
            if (err.status != 401) {
                this.alertPopup.show(this.translate.instant("Registration Unsuccessful"), err.error.message);                
            }
        });
    }

    goToConnectInStore() {
        console.log('go to connect in-store');
        if (this.membershipModule) {
            this.membershipRouting.goToConnectInStore();
        } else {
            this.orderRouting.goToConnectInStore();
        }
    }

    back() {
        if (this.membershipModule) {
            this.membershipRouting.goToLogin();
        } else {
            this.orderRouting.goToLogin();
        }
    }

}
