import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerDirective, BsDatepickerInputDirective } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { CartService } from '../cart.service';
import { CatalogService } from '../catalog.service';
import { CustomerSessionService } from '../customer-session.service';
import { DataService } from '../data.service';
import { ErrorHandlerService } from '../error-handler.service';
import { CatalogsTimeFrames } from '../models/catalogs-time-frames';
import { DeliveryDetails } from '../models/delivery-details.model';
import { ValidateDeliveryOrderRequest } from '../models/validate-delivery-order-request';
import { FutureOrderDateTime, ValidateFutureOrderRequest } from '../models/validate-future-order-request';
import { OrderRoutingService } from '../order-routing.service';
import { UtilService } from '../util.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-ahead-picker',
  templateUrl: './order-ahead-picker.component.html',
  styleUrls: ['./order-ahead-picker.component.scss']
})
export class OrderAheadPickerComponent implements OnInit {

  /* Date-Time-Picker Variables */
  @ViewChild('datePicker') datePicker;
  @ViewChild('timePicker') timePicker;
  @ViewChild('auto') auto;

  year: number;
  month: number;
  day: number;
  hour: number;
  min: number;

  displayFutureDate: any;
  displayFutureTime: any;

  minDate: any;
  maxDate : any;

 /* Date-Time-Picker Variables */

  catalogsTimeFrames: CatalogsTimeFrames[];
  asapStatusForAllTimeFrames: CatalogsTimeFrames[] = [];
  isAsapClosed: boolean = false;
  orderAheadEnabled: boolean = false;
  selectedCatalogTimeFrame: CatalogsTimeFrames;

  disabledUntil: string;

  futureOrder: boolean;
  futureOrderDate: any;
  futureOrderTime: any;
  
  curbSideOrder: boolean;
  curbSideOrderDetails: any;

  deliveryOrder: boolean;
  deliveryOrderDetails: any;
  deliveryAddress: string;
  deliveryPhone: string;
  deliveryLongitude: number;
  deliveryLatitude: number;
  deliveryInstructionRadio: string;
  deliveryTime: string;
  deliveryEstimateDate: string;
  deliveryEstimateTime: string;
  verifiedDeliveryRequest: ValidateDeliveryOrderRequest;
  curbSidePickUpEnabled: boolean = false;
  disableInStorePickUp: boolean = false;
  disableASAPOrders: boolean = false;
  deliveryEnabled: boolean = false;
  isExternalDelivery: boolean = false;

  selectedOrderType: number;
  orderTypes = [
    { name: "Delivery", description: "Please enter your address for delivery" },
    { name: "In-Store Pickup", description: "Would you like to pick up your items ASAP or at a later date or time?" },
    { name: "Curbside", description: "Place your order and we'll bring it to the curbside" }
  ];
  selectedOrderTypeDescription: string;
  selectedOrderTypeName: string;

  showSaveButton: boolean = false;
  selectedcatalogId: number;
  isDateTimeSelected: boolean = false;
  hyperLinkText: string = null;
  hyperLinkUrl: string = null;
  selectLocationText: string = null;
  selectLocationUrl: string = null;

    //address search
    keyword = 'text';
    data = [];
    isLoading: boolean = false;
    placeHolderText: string = "Please enter your address";


  isComponentLoading: boolean = false;
  logoUrl: string = null;
  
  constructor(private cartService: CartService, 
    private dataService: DataService,
    private catalogService: CatalogService,
    private spinner: NgxSpinnerService,
    private utilService: UtilService, 
    private orderRouting: OrderRoutingService,
    private customerSession: CustomerSessionService,
    private errorHandlerService: ErrorHandlerService,
    private translate: TranslateService) { }

  ngOnInit() {}  

  async ionViewWillEnter() {  
    this.logoUrl = sessionStorage.getItem("logoUrl");
    this.isComponentLoading = true;    
    this.selectedOrderType = this.cartService.selectedOrderType;
    this.catalogsTimeFrames = this.cartService.getCatalogsTimeFrames(); 
    this.selectedcatalogId  = this.catalogService.getLastSelectedCatalogId();
    this.selectedOrderTypeDescription = this.orderTypes[this.selectedOrderType].description;
    this.selectedOrderTypeName = this.orderTypes[this.selectedOrderType].name;
    this.futureOrder = this.cartService.isFutureOrder;
    this.curbSideOrder = this.cartService.isCurbSideOrder;
    this.deliveryOrder = this.cartService.isDeliveryOrder;   
    this.orderAheadEnabled = this.cartService.orderAheadEnabled;
    this.curbSidePickUpEnabled = this.cartService.curbSidePickUpEnabled;
    this.disableInStorePickUp = this.cartService.disableInStorePickUp;
    this.disableASAPOrders = this.cartService.disableASAPOrders;
    this.deliveryEnabled = this.cartService.deliveryEnabled;
    this.isExternalDelivery = this.cartService.isExternalDelivery;

    if ( this.selectedcatalogId == 0) {
      this.orderRouting.goToCatalogPicker();
      return;
    }

    this.saveButtonCheck();

    let savedDateTime = this.cartService.getFutureOrderDateTime();
    if (savedDateTime.year > 0) {
      this.year = savedDateTime.year;
      this.month = savedDateTime.month;
      this.day = savedDateTime.day;

      this.bsfutureOrderDate = new NgbDate(this.year, this.month, this.day)

      this.hour = savedDateTime.hour;
      this.min = savedDateTime.min;

      this.futureOrderDate = this.year + "-" + this.getZeroAdded(this.month) + "-" + this.getZeroAdded(this.day);
      this.futureOrderTime = this.getZeroAdded(this.hour) + ":" + this.getZeroAdded(this.min);

      this.generateDisplayDate();
      this.generateDisplayTime();
    }

    this.minDate = "2020-01-01";
    this.maxDate = "2021";

    this.curbSideOrderDetails = this.cartService.getCurbSideOrderDetails();

    //get delivery details and set it
    let deliveryDetails = this.cartService.getDeliveryOrderDetails();
    if (deliveryDetails.address) {
        //set only if we have something
        this.deliveryAddress = deliveryDetails.address;
        this.deliveryPhone = deliveryDetails.phone;
        this.deliveryLongitude = deliveryDetails.longitude;
        this.deliveryLatitude = deliveryDetails.latitude;
        this.deliveryOrderDetails = deliveryDetails.details;

        var estimateRequest: ValidateDeliveryOrderRequest = {
            address: this.deliveryAddress,
            phone: this.deliveryPhone,
            deliveryLatitude: this.deliveryLatitude,
            deliveryLongitude: this.deliveryLongitude,
            merchantLocationId: this.customerSession.locationId,
            orderAheadDate: null,
            validatePhone: false,
            items: this.cartService.cart.items,
            catalogId: this.selectedcatalogId,
            orderAheadDeliveryDate: "",
            orderAheadDeliveryTime: ""
        };

        this.validateDeliveryOrder(estimateRequest);
    }

    this.deliveryInstructionRadio = this.cartService.deliveryInstruction;

     // Check if merchant has provided hyperlinks in portal OO settings 
     var gethyperLinkUrl = sessionStorage.getItem('hyperLinkUrl');
     var gethyperLinkText = sessionStorage.getItem('hyperLinkText');
     var getSelectLocationText = sessionStorage.getItem('selectLocationText');
     var getSelectLocationUrl = sessionStorage.getItem('selectLocationUrl');
     var isMobile = this.customerSession.isMobile();     
    if (getSelectLocationText && getSelectLocationUrl && getSelectLocationText !== "" && getSelectLocationUrl !== "" && !isMobile) {
      this.selectLocationText = getSelectLocationText;
      this.selectLocationUrl = getSelectLocationUrl;
    }
    if (gethyperLinkText && gethyperLinkUrl && gethyperLinkText !== "" && gethyperLinkUrl !== "" && !isMobile) {
         this.hyperLinkText = gethyperLinkText;
         this.hyperLinkUrl = gethyperLinkUrl;
    }

    if (this.selectedOrderType >= 0) {
      /* Order Ahead Picker - should be displayed only if order ahead is enabled */   
      await this.mapAsapSettingForCatalogs();      
    } 
    
    if((this.isAsapClosed || this.disableASAPOrders) && this.orderAheadEnabled) 
    {
      this.saveButtonCheck();
    }
    this.isComponentLoading = false;
  }

  /* LOGICAL METHODS */
  async mapAsapSettingForCatalogs() {

    if(this.catalogsTimeFrames && this.catalogsTimeFrames.length > 0) 
    {
        console.log( this.catalogsTimeFrames);
        this.asapStatusForAllTimeFrames = this.catalogsTimeFrames.filter(x => x.isAsapAvailable == true);
        console.log( this.asapStatusForAllTimeFrames);
  
        if( this.asapStatusForAllTimeFrames &&  this.asapStatusForAllTimeFrames.length > 0) 
        {
            this.isAsapClosed = false;                                         
        }
        else
        {
            this.isAsapClosed = true;                          
        }

        if(this.orderAheadEnabled && (this.isAsapClosed || this.disableASAPOrders)) 
        {
            this.futureOrder = true;
        }        

        this.selectedCatalogTimeFrame = this.catalogsTimeFrames.find(x => x.catalogId == this.selectedcatalogId);

        if (this.cartService.catalogDisabledTime) {
            this.disabledUntil = "UNTIL " + this.cartService.catalogDisabledTimeText;

            let timerMS = this.cartService.catalogDisabledTime.getTime() - (new Date()).getTime();
            setTimeout(() => {
                this.disabledUntil = undefined;
            }, timerMS);
        }
        else {
            this.disabledUntil = undefined;
        }
    }        
  }

  pickDate() {
    this.datePicker.open();
  }

  updateDate() {
    let futureOrderDateString = this.futureOrderDate as string;
    try {
        this.year = parseInt(futureOrderDateString.split('T')[0].split('-')[0]);
        this.month = parseInt(futureOrderDateString.split('T')[0].split('-')[1]);
        this.day = parseInt(futureOrderDateString.split('T')[0].split('-')[2]);

        this.generateDisplayDate();

        if (this.displayFutureTime) {
            this.checkAddressAndVerifyAddress();
        }
    }
    catch (err) {
        console.log(err);
        this.utilService.showErrorAlert(this.translate.instant("Sorry, something went wrong. Please contact support"));
    }
  }


  generateDisplayDate() {
    this.displayFutureDate = this.cartService.getFutureOrderDisplayDate(this.year, this.month, this.day);

    this.saveButtonCheck();
  }

  checkAddressAndVerifyAddress() {
    if (this.deliveryAddress && this.deliveryLongitude && this.deliveryLatitude) {
        var estimateRequest: ValidateDeliveryOrderRequest = {
            address: this.deliveryAddress,
            phone: this.deliveryPhone,
            deliveryLatitude: this.deliveryLatitude,
            deliveryLongitude: this.deliveryLongitude,
            merchantLocationId: this.customerSession.locationId,
            orderAheadDate: null,
            validatePhone: false,
            items: this.cartService.cart.items,
            catalogId: this.selectedcatalogId,
            orderAheadDeliveryDate: "",
            orderAheadDeliveryTime: ""
        };

        this.validateDeliveryOrder(estimateRequest);
    }
  }

  saveButtonCheck() {
    this.showSaveButton = false;

    if (this.futureOrder == true) {
        if (this.displayFutureDate && this.displayFutureTime) {
            this.showSaveButton = true;
        }
    } else {
        this.showSaveButton = true;
    }    
  }

  async validateDeliveryOrder(deliveryRequest: ValidateDeliveryOrderRequest): Promise<boolean> {
    let returnValue = false; 

    if (this.futureOrder) 
    {
        deliveryRequest.orderAheadDate = new Date(this.displayFutureDate + ' ' + this.displayFutureTime);
        deliveryRequest.orderAheadDeliveryDate = this.displayFutureDate;
        deliveryRequest.orderAheadDeliveryTime = this.displayFutureTime;
     // deliveryRequest.orderAheadDate = new Date(this.futureOrderDate + ' ' + this.futureOrderTime);
    }       

    this.spinner.show();

    await this.dataService.validateDeliveryOrder(deliveryRequest).toPromise().then((data) => {
        console.log(data);
                                    
        this.verifiedDeliveryRequest = deliveryRequest;
        
        if (data && data.deliveryEstimateDate && data.deliveryEstimateTime) {
            this.deliveryEstimateDate = data.deliveryEstimateDate;
            this.deliveryEstimateTime = data.deliveryEstimateTime;
        }

        this.isLoading = false;
        returnValue = true;
    },
    (err) => {
        console.log(err);
        //need to clear address!        
        this.removeAddress();
        this.deliveryLongitude = 0;
        this.deliveryLatitude = 0;
        if (err.type == "DELIVERY" && err.action && err.action != "") {
          this.errorHandlerService.handleError(err);
        } else {
          this.utilService.showErrorAlert(err.message); 
        }
        
        this.isLoading = false;     
        returnValue = false;
        });
    this.spinner.hide();    
    return returnValue;
  }

  removeAddress() {
    this.deliveryAddress = undefined;
  }

  changeAddress() {
    console.log('changeAddress');
    this.removeAddress();
    //clear address
    this.auto.clear();
    this.auto.close();
    this.auto.focus();
  }

  pickTime() {
    this.timePicker.open();
  }

  updateTime() {
    let futureOrderTimeString = this.futureOrderTime as string;

    try {
        if (futureOrderTimeString.indexOf('T') > -1) {
            this.hour = parseInt(futureOrderTimeString.split('T')[1].split(':')[0]);
            this.min = parseInt(futureOrderTimeString.split('T')[1].split(':')[1]);
        } else if (futureOrderTimeString.length == 5 && futureOrderTimeString.indexOf(':') > -1) {
            this.hour = parseInt(futureOrderTimeString.split(':')[0]);
            this.min = parseInt(futureOrderTimeString.split(':')[1]);
        } else {
            throw this.translate.instant("Sorry, something went wrong. Please contact support");
        }

        this.generateDisplayTime();

        if (this.displayFutureDate) {
            this.checkAddressAndVerifyAddress();
        }

        this.isDateTimeSelected = true;
    }
    catch (err) {
        console.log(err);
        this.utilService.showErrorAlert(this.translate.instant("Sorry, something went wrong. Please contact support"));
    }
  }

  generateDisplayTime() {
    this.displayFutureTime = this.cartService.getFutureOrderDisplayTime(this.hour, this.min);

    this.saveButtonCheck();
  }

  setFutureOrder(value) {
    console.log(value);
    this.futureOrder = value;
    this.saveButtonCheck();
  }

  async save() {   
    this.spinner.show();
    // first check if catalog is available and user is making an asap order - as user can click back 
    // and land here after clearing session
    if (this.selectedcatalogId <= 0) {
      this.spinner.hide();
      this.orderRouting.goToCatalogPicker();
      return;
    }   
    
    /* INITIAL CHECK PASSED */
    //if curbside - ensure info is added
    if (this.curbSideOrder) {
      let data: string = this.curbSideOrderDetails;
      if (data.trim().length == 0) {
        this.spinner.hide();
        this.utilService.showErrorAlert(this.translate.instant("Please describe your car"));                 
        return;
      }
    }
    
    //if future order - ensure date/time is provided
    if (this.futureOrder) {
      if (!this.displayFutureDate) {
          this.spinner.hide();
          this.utilService.showErrorAlert(this.translate.instant("Please pick a date"));
          return;
      }
      if (!this.displayFutureTime) {
        this.spinner.hide();
        this.utilService.showErrorAlert(this.translate.instant("Please pick a time"));
        return;
      }
    }

    //if delivery order - ensure address is valid
    if (this.deliveryOrder) {
      if (!this.deliveryAddress || (this.deliveryAddress && this.deliveryAddress.trim().length == 0)) {
        this.spinner.hide();
        this.utilService.showErrorAlert(this.translate.instant("Please select the correct address"));             
        return;
      }
    }

    if(this.deliveryOrder && this.isExternalDelivery){
      if (!this.deliveryPhone || (this.deliveryPhone && this.deliveryPhone.trim().length == 0)) {
        this.spinner.hide();
        this.utilService.showErrorAlert(this.translate.instant("Please enter your phone number"));           
        return;
      }
  
      if (this.deliveryPhone != this.verifiedDeliveryRequest.phone) {
          this.verifiedDeliveryRequest.phone = this.deliveryPhone;
          this.verifiedDeliveryRequest.validatePhone = true;
          if (await this.validateDeliveryOrder(this.verifiedDeliveryRequest) == false) {
              this.spinner.hide();
              return;
          }
      }
    }

    //validate this is the right time by checking with the server
    let futureOrderDateTimeRequest = new FutureOrderDateTime();
    futureOrderDateTimeRequest.year = this.year;
    futureOrderDateTimeRequest.month = this.month;
    futureOrderDateTimeRequest.day = this.day;
    futureOrderDateTimeRequest.hour = this.hour;
    futureOrderDateTimeRequest.min = this.min;

    let request = new ValidateFutureOrderRequest();

    request.catalogId = this.selectedcatalogId;
    request.merchantLocationId = this.customerSession.locationId;
    request.futureOrderDateTime = futureOrderDateTimeRequest;
    request.isFutureOrder = this.futureOrder;   
    request.items = this.cartService.cart.items;

    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));   
    try {
      await delay(1000).then(async () => {
        this.spinner.show();
        await this.validateFutureOrder(request, futureOrderDateTimeRequest).then((result) => {
                  if (result) {
                    this.cartService.setEditBtnText();
                    this.catalogService.setLastSelectedCatalogId(this.selectedcatalogId);                  
                    this.catalogService.reloadCatalog = true;  
                    this.cartService.askedForOrderType = true; 
                    this.spinner.hide(); 
                   
                    if(this.cartService.isCheckoutPage) 
                    {
                      console.log("Navigating => Checkout from Order Picker");
                      this.orderRouting.goToCheckout()
                      return;
                    }   
                    else
                    {
                      console.log("Navigating => Cart from Order Picker");
                      this.orderRouting.goToCart()
                    }            
                                                 
                  }
        }, error => {
          console.log(error);
          let errorMessage = this.translate.instant("Error while validating your order. Please try again")
          if (error.message && error.message != "") {
            if (error.message != "General Error") {
              errorMessage = error.message;
            }                     
          }
          this.spinner.hide();
          this.utilService.showErrorAlert(errorMessage);         
        }).catch(error => {  
          this.spinner.hide();      
          console.log(error);
        });
      });
      
    } catch (error) {
      this.spinner.hide();
      console.log(error);
    }  
  }


  validateFutureOrder(request : ValidateFutureOrderRequest, futureOrderDateTimeRequest: FutureOrderDateTime): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {

      this.dataService.validateFutureOrder(request).subscribe(data => {
        console.log(data);
        //if all good then save in cart service for prepare order request
        this.cartService.isFutureOrder = this.futureOrder;
        if (this.futureOrder == true) {
          this.cartService.setFutureOrderDateTime(futureOrderDateTimeRequest);
        } else {
            this.cartService.clearFutureOrderDateTime();
        }

        //curbside
        this.cartService.isCurbSideOrder = this.curbSideOrder;
        if (this.curbSideOrder) {
            this.cartService.setCurbSideOrderDetails(this.curbSideOrderDetails);
            sessionStorage.setItem("order_type_picked", "Curbside");
        } else {
            this.cartService.clearCurbSideOrderDetails();
        }

        //delivery
        this.cartService.isDeliveryOrder = this.deliveryOrder;
        if (this.deliveryOrder) {
            //set delivery data
            //create object and serialize
            let deliveryDetails = new DeliveryDetails();
            deliveryDetails.address = this.deliveryAddress;
            deliveryDetails.phone = this.deliveryPhone;
            deliveryDetails.longitude = this.deliveryLongitude;
            deliveryDetails.latitude = this.deliveryLatitude;                                
            deliveryDetails.details = this.deliveryOrderDetails;

            this.cartService.setDeliveryOrderDetails(deliveryDetails);
            sessionStorage.setItem("order_type_picked", "Delivery");
        } else {
            //clear delivery data
            this.cartService.clearDeliveryOrderDetails();
        }  
        
        if (!this.deliveryOrder && !this.curbSideOrder) {
          sessionStorage.removeItem("order_type_picked");
        }

        resolve(true);
      }, error => {
        reject(error);
      });
    });
  }

  getZeroAdded(val : number): string {
    if (val < 10) {
        return "0" + val;
    }
    return "" + val;
  }

  changeCatalog() 
  {
    Swal.fire({
      title: this.translate.instant("Changing menus will empty your cart. Are you sure you want to change menus?"),
      showConfirmButton: true,
      confirmButtonText: this.translate.instant('YES'),
      showCancelButton: true,
      cancelButtonText: this.translate.instant('NO'),
      backdrop: false,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.dismiss) {
         
      } 
      else 
      {   
        await this.cartService.clearSessionForNewOrder().then(async (result) => {
              if (result) {
                  console.log('session cleared');                 
                  Swal.close();
                  this.catalogService.setLastSelectedCatalogId(0); 
                  this.orderRouting.goToCatalogPicker();
                  return;
              }
        }).catch(error => {
          console.log(error);
        });
      }
    })

    
  }

  onFocused(e) {
    
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    // console.log('auto complete search');
    // console.log(val);
    if (val.trim().length == 0) {
        //no search query -- set data to null
        this.data = [];
    } else {
        this.isLoading = true;
        this.dataService.searchAddress(val).subscribe((data) => {
            if (data && data.suggestions) {
                this.data = data.suggestions;
            } else {
                this.data = [];
            }

            this.isLoading = false;
        }, (err) => {
            console.log(err);
            this.data = [];
            this.isLoading = false;
        });
    }
  }

  selectEvent(item) {
    // do something with selected item
    // console.log('auto complete item selected');
    // console.log(item);
    this.isLoading = true;

    //need to call the second api to get coordinates
    this.dataService.getDetailsOfAddress(item.text, item.magicKey).subscribe((data) => {
        console.log(data);
        if (data.candidates) {

            //if we have candidates take first one
            this.deliveryLongitude = data.candidates[0].location.x;
            this.deliveryLatitude = data.candidates[0].location.y;
            this.deliveryAddress = item.text;
            console.log("Address is: " + this.deliveryAddress + ", Longitude is: " + this.deliveryLongitude+ ", Latitude is: " + this.deliveryLatitude);

            //maybe we need to tell them right away if ordering is available or not
            let deliveryRequest = new ValidateDeliveryOrderRequest();
            deliveryRequest.deliveryLongitude = this.deliveryLongitude;
            deliveryRequest.deliveryLatitude = this.deliveryLatitude;
            deliveryRequest.address = this.deliveryAddress;
            deliveryRequest.phone = this.deliveryPhone;
            deliveryRequest.merchantLocationId = this.customerSession.locationId;
            deliveryRequest.validatePhone = this.isExternalDelivery;
            deliveryRequest.items = this.cartService.cart.items;
            deliveryRequest.catalogId = this.selectedcatalogId;
            
            this.validateDeliveryOrder(deliveryRequest);
        }
    },
        (err) => {
            console.log(err);
        });
  }

  deliveryInstructionRadioChange() {
    this.cartService.deliveryInstruction = this.deliveryInstructionRadio;
  }

  back() 
  {
    this.utilService.back();    
  }

  changeLocation() 
  {
    this.utilService.clearSessionForLocationChange().then((result) => {
      if (result) {
        this.orderRouting.goToLocation();
      }
    });   
  }

  /* NEW DATE PICKER METHODS AND VARIABLES */                                                                                                                                                                                           
  bsDatepickerModalOpen: boolean = false;
  bsfutureOrderDate: NgbDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  bsminDate = new Date();
  bsfutureOrderTime: any;
  @ViewChild('picker') picker;
  @ViewChild('dp') bsDatepicker: BsDatepickerDirective;
  
  // dateCreated($event){  
    
  //   this.bsfutureOrderDate = $event;
  //   this.saveButtonCheck();
  // } 

  updateBsDate(val) {

    try { 

        if(this.bsfutureOrderDate) 
        {
          this.year = this.bsfutureOrderDate.year;
          this.month = this.bsfutureOrderDate.month;
          this.day = this.bsfutureOrderDate.day;
        }        

        this.generateDisplayDate();

        if (this.displayFutureTime) {
            this.checkAddressAndVerifyAddress();
        }
        this.bsDatepickerModalOpen = false;
    }
    catch (err) {
        console.log(err);
        this.utilService.showErrorAlert(this.translate.instant("Sorry, something went wrong. Please contact support"));
    }
    this.saveButtonCheck();
  }
  
  openBsTimepicker() {
    document.getElementById("bstimepickbtn").click();
  }

  updateBsTime() 
  {
    let amPmstring = ('0' + this.picker.selectedHour.time).slice(-2) + ":" + ('0' + this.picker.selectedMinute.time).slice(-2) + " " + this.picker.selectedPeriod;
    this.bsfutureOrderTime =  this.getTwentyFourHourTime(this.displayFutureDate, amPmstring);
    let futureOrderTimeString = this.bsfutureOrderTime as string;

    try {
        this.hour = parseInt(futureOrderTimeString.split(':')[0]);
        this.min = parseInt(futureOrderTimeString.split(':')[1]);

        this.generateDisplayTime();

        if (this.displayFutureDate) {
            this.checkAddressAndVerifyAddress();
        }

        this.isDateTimeSelected = true;
    }
    catch (err) {
        console.log(err);
        this.utilService.showErrorAlert(this.translate.instant("Sorry, something went wrong. Please contact support"));
    }

  }

  getTwentyFourHourTime(dateString, amPmString) { 
    var d = new Date(dateString + " " + amPmString); 
    return d.getHours() + ':' + d.getMinutes(); 
  }

  openBsDatePicker() 
  {
    /* TODO - if date there display it or else todays date */
    
    this.bsDatepickerModalOpen = true;
    $(this.bsDatepicker).trigger('click');
    
    
  }

  closeBsDatePicker() 
  {
    this.bsDatepickerModalOpen = false;
  }

}
