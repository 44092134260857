export class saveCustomerCardRequest {
    merchantLocationId: number;
    cardNumber: string;
    cardExpirationMonth: number;
    cardExpirationYear: number;
    cardCVV: string;
    token: string;
    cardHolderName: string;
    postalCode: string;
    countryCode: string;
}