export enum MOPaymentType {
    TAPMANGO_WALLET = <any>"TAPMANGO_WALLET",
    CREDIT_CARD = <any>"CREDIT_CARD",
    GIFT_CARD = <any>"GIFT_CARD"
}

export class OrderPayment{
    PaymentType: MOPaymentType;
    Amount: number;
    Token: string;
    SquareVerificationToken: string;
    TempNotes: string;
    Currency: string;
    CardCVV: string;
    CardExpirationMonth: number;
    CardExpirationYear: number;    
    CardNumber: string;
    SaveCreditCard: boolean = false;
    PayWithSavedCreditCard: boolean = true;
    CardHolderName: string;  
    PostalCode:string;
    CountryCode:string;
    GiftCardId: string;
    GiftCardPin: string;
    IsApplePay: boolean;
    ApplePayToken: string;
    ApplePayPaymentId: string;
    ApplePayApplicationData: string;
}


export class POSOrderPayment{
    PaymentType: MOPaymentType;
    Amount: number;
    Token: string;
    Currency: string;
    CardCVV: string;
    CardExpirationMonth: number;
    CardExpirationYear: number;
    NameOnCard: string;  
    CardNumber: string;  
}

declare global {
    interface Window {
        CollectJS: any;
    }
}

