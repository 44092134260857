import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';
import { CartService } from '../cart.service';
import { CatalogService } from '../catalog.service';
import { CustomerSessionService } from '../customer-session.service';
import { Catalog } from '../models/catalog';
import { CategoryTrackedService } from '../common/category-tracked.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'catalog-option',
    templateUrl: './catalog-option.component.html',
    styleUrls: ['./catalog-option.component.scss']
})
export class CatalogOptionComponent implements OnInit {

    catalogs: Catalog[];
    selectedCatalog: Catalog;
    @Output() onCatalogChanged = new EventEmitter();

    constructor(private catalogService: CatalogService,
        private cartService: CartService,
        private customerSession: CustomerSessionService,
        private spinner: NgxSpinnerService,
        private categoryTrackedService: CategoryTrackedService,
        private translate: TranslateService) { }

    ngOnInit() {
        console.log("CATALOG OPTION INIT");
        this.initComponent();
    }

    initComponent() {
        console.log("CATALOG OPTION INIT FUNCTION CALLED");        
        this.spinner.show();
        this.loadData().subscribe(
            () => this.spinner.hide(),
            (err) => this.spinner.hide()
        );
    }


    loadData(refresh = false) {
        return this.catalogService.getCatalogs(this.customerSession.locationId, refresh)
            .pipe(
                tap((catalogs) => {
                    this.catalogs = catalogs
                    this.selectedCatalog = this.catalogService.getCurrentCatalog();
                })
            )
    }

    reload(event) {
        this.loadData(true).subscribe(
            (data) => event.target.complete(),
            (err) => event.target.complete()
        )
    }

    reloadHard() {
        this.loadData(true).subscribe();
    }

    onCatalogSelectedChange(catalogId) {
        if (this.selectedCatalog.id != catalogId) {
            this.selectedCatalog = this.catalogs.find(x => x.id == catalogId);
            if (this.cartService.getCartCount() > 0 && this.cartService.cart.catalogId != this.selectedCatalog.id) {
                if (!confirm(this.translate.instant("Changing menus will empty your cart. Are you sure you want to change menus?"))) {
                    return;
                }
                this.cartService.clearCart();
            }
            this.catalogService.defaultlastSelectedCategoryId();
            this.catalogService.changeCatalog(this.selectedCatalog.id);
            this.onCatalogChanged.emit();
        }
    }

    onCatalogSelected(catalog) {
        console.log(catalog);
        if (this.selectedCatalog.id != catalog.id) {
            this.selectedCatalog = catalog;
            if (this.cartService.getCartCount() > 0 && this.cartService.cart.catalogId != this.selectedCatalog.id) {
                if (!confirm(this.translate.instant("Changing menus will empty your cart. Are you sure you want to change menus?"))) {
                    return;
                }
                this.cartService.clearCart();
            }
            this.cartService.clearFutureOrderSettingAndData();
            this.catalogService.changeCatalog(this.selectedCatalog.id);
        }
    }

    compareWithFn(c1: Catalog, c2: Catalog): boolean {
        return c1 && c2 ? c1.id === c2.id : false;
    }

    refresh() {
        console.log('refresh catalog options');
        this.loadData(true).subscribe();
    }
}
