
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot, ActivationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrderRoutingService } from './order-routing.service';
import { CustomerSessionService } from './customer-session.service';
import { MembershipRoutingService } from './membership-routing.service';

@Injectable()
export class CustomerGuardService implements CanActivate {
 
  constructor(private customerSession: CustomerSessionService, 
              public router: Router,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private orderRouting: OrderRoutingService,
              private membershipRouting: MembershipRoutingService) {}
  
  canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean{
    console.log('customer guard - checking if logged in')
    let hasToken: boolean = (sessionStorage.getItem('id_token')) ? true : false;
    
    if (hasToken){
      console.log('customer guard - logged in')
      return true;
    } else {
      console.log('customer guard - state url atm is: ' + state.url);
      sessionStorage.setItem('return_url', state.url);
      console.log('customer guard - not logged in! redirecting to login');
      if (state.url.includes("memberships")) {
        sessionStorage.setItem('memberships_module', "true");
        this.membershipRouting.goToLogin();
      } else {
        if (state.url.includes('my-orders') || state.url.includes('payment-manage')) {
          sessionStorage.setItem("ignoreGuest", "1");
        }
        else {
          sessionStorage.removeItem("ignoreGuest");
        }
        this.orderRouting.goToLogin();
      }
    }
  }
}