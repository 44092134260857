import { Category } from './category';
import { LocationSetting } from './location-settings';
import { RecentlyOrderedItemModifier } from './recently-ordered-item-modifier';

export class Catalog {
    id: number;
    name: string;
    categories: Category[];
    locationSetting: LocationSetting;
    recentlyOrderedItemModifiers: RecentlyOrderedItemModifier[] = [];
}