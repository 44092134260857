export class MembershipPurchasePayment {
    Amount: number;
    CardNumber: string;
    PayWithSavedCreditCard: boolean;
    CardExpirationMonth: number;
    CardExpirationYear: number;
    CardCVV: string;
    Token: string;
    CardHolderName: string;
    PostalCode: string;
    CountryCode: string;
}