import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';
import { OrderRoutingService } from '../order-routing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../util.service';

@Component({
    selector: 'eta-message',
    templateUrl: './eta-message.component.html',
    styleUrls: ['./eta-message.component.scss']
})
export class EtaMessageComponent implements OnInit {

    constructor(public cartService: CartService,
        private orderRouting: OrderRoutingService,
        private utilService: UtilService) { }
        
    ngOnInit() {        
        this.cartService.setEditBtnText();    
        $( "#orderTypeEdit" ).keydown((e) => {
            if(e.keyCode == 13) {
                this.navigateToOrderAhead();
            }
         
         });       
    }

    navigateToOrderAhead() {  
        console.log((<any>this.utilService.activatedRoute.component).name);

        if (this.cartService.isDineInOrder) {
            return;
        }

        if (this.cartService.orderAheadEnabled || this.cartService.curbSidePickUpEnabled || this.cartService.deliveryEnabled) {
         
           this.orderRouting.goToOrderTypePicker();
        }
    }
    
}
