import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { CatalogService } from '../catalog.service';
import { CustomerSessionService } from '../customer-session.service';
import { CartService } from '../cart.service';
import { ValidateFutureOrderRequest, FutureOrderDateTime } from '../models/validate-future-order-request';
import { DataService } from '../data.service';
import { AlertController } from '@ionic/angular';
import { DeliveryDetails } from '../models/delivery-details.model';
import { ValidateDeliveryOrderRequest } from '../models/validate-delivery-order-request';
import { CatalogsTimeFrames } from '../models/catalogs-time-frames';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderRoutingService } from '../order-routing.service';
import { ErrorHandlerService } from '../error-handler.service';

@Component({
    selector: 'order-ahead',
    templateUrl: './order-ahead.component.html',
    styleUrls: ['./order-ahead.component.scss']
})
export class OrderAheadComponent implements OnInit {
    @ViewChild('datePicker') datePicker;
    @ViewChild('timePicker') timePicker;
    @ViewChild('auto') auto;

    futureOrder: boolean;
    futureOrderDate: any;
    futureOrderTime: any;
    oderTypeSelected: boolean = false;

    curbSideOrder: boolean;
    curbSideOrderDetails: any;

    deliveryOrder: boolean;
    deliveryOrderDetails: any;
    deliveryAddress: string;
    deliveryPhone: string;
    deliveryLongitude: number;
    deliveryLatitude: number;
    deliveryInstructionRadio: string;
    deliveryTime: string;
    deliveryEstimateDate: string;
    deliveryEstimateTime: string;

    verifiedDeliveryRequest: ValidateDeliveryOrderRequest;

    year: number;
    month: number;
    day: number;
    hour: number;
    min: number;

    displayFutureDate: any;
    displayFutureTime: any;
    showSaveButton: boolean = false;
    minDate: any;
    maxDate : any;

    orderAheadEnabled: boolean = false;
    curbSidePickUpEnabled: boolean = false;
    disableInStorePickUp: boolean = false;
    deliveryEnabled: boolean = false;
    isExternalDelivery: boolean = false;

    //address search
    keyword = 'text';
    data = [];
    isLoading: boolean = false;
    placeHolderText: string = "Please enter your address";

    showOrderTypes = true;
    selectedOrderType: number;
    orderTypes = [
        { name: "Delivery", description: "Please enter your address for delivery" },
        { name: "In-Store Pickup", description: "Would you like to pick up your items ASAP or at a later date or time" },
        { name: "Curbside", description: "Place your order and we'll bring it to the curbside" }
    ];

    catalogsTimeFrames: CatalogsTimeFrames[];
    asapStatusForAllTimeFrames: CatalogsTimeFrames[] = [];
    isAsapClosed: boolean = false;
    showCatalogSelectorPopup: boolean;
    isDateTimeSelected: boolean = false;
    selectedcatalogId: number;
    selectedcatalogName: string = "";

    hyperLinkText: string = null;
    hyperLinkUrl: string = null;
    selectLocationText: string = null;
    selectLocationUrl: string = null;
    ismodalOpen: boolean = false;
    logoUrl: string = null;

    constructor(private location: Location, private catalogService: CatalogService,
        private customerSession: CustomerSessionService,
        private cartService: CartService,
        private dataService: DataService,
        private alertController: AlertController,
        private spinner: NgxSpinnerService, 
        private orderRouting: OrderRoutingService,
        private errorHandlerService: ErrorHandlerService) {

        this.logoUrl = sessionStorage.getItem("logoUrl");

        this.futureOrder = this.cartService.isFutureOrder;
        this.curbSideOrder = this.cartService.isCurbSideOrder;
        this.deliveryOrder = this.cartService.isDeliveryOrder;

        this.orderAheadEnabled = this.cartService.orderAheadEnabled;
        this.curbSidePickUpEnabled = this.cartService.curbSidePickUpEnabled;
        this.disableInStorePickUp = this.cartService.disableInStorePickUp;
        this.deliveryEnabled = this.cartService.deliveryEnabled;
        this.isExternalDelivery = this.cartService.isExternalDelivery;

        this.saveButtonCheck();

        let savedDateTime = this.cartService.getFutureOrderDateTime();
        if (savedDateTime.year > 0) {
            this.year = savedDateTime.year;
            this.month = savedDateTime.month;
            this.day = savedDateTime.day;
            this.hour = savedDateTime.hour;
            this.min = savedDateTime.min;

            this.futureOrderDate = this.year + "-" + this.getZeroAdded(this.month) + "-" + this.getZeroAdded(this.day);
            this.futureOrderTime = this.getZeroAdded(this.hour) + ":" + this.getZeroAdded(this.min);

            this.generateDisplayDate();
            this.generateDisplayTime();
        }   

        this.minDate = "2020-01-01";
        this.maxDate = "2021";

        this.curbSideOrderDetails = this.cartService.getCurbSideOrderDetails();

        //get delivery details and set it
        let deliveryDetails = this.cartService.getDeliveryOrderDetails();
        if (deliveryDetails.address) {
            //set only if we have something
            this.deliveryAddress = deliveryDetails.address;
            this.deliveryPhone = deliveryDetails.phone;
            this.deliveryLongitude = deliveryDetails.longitude;
            this.deliveryLatitude = deliveryDetails.latitude;
            this.deliveryOrderDetails = deliveryDetails.details;

            var estimateRequest: ValidateDeliveryOrderRequest = {
                address: this.deliveryAddress,
                phone: this.deliveryPhone,
                deliveryLatitude: this.deliveryLatitude,
                deliveryLongitude: this.deliveryLongitude,
                merchantLocationId: this.customerSession.locationId,
                orderAheadDate: null,
                validatePhone: false,
                items: this.cartService.cart.items,
                catalogId: this.selectedcatalogId,
                orderAheadDeliveryDate: "",
                orderAheadDeliveryTime: ""
            };

            this.validateDeliveryOrder(estimateRequest);
        }
        this.deliveryInstructionRadio = this.cartService.deliveryInstruction;

        this.catalogsTimeFrames = this.cartService.getCatalogsTimeFrames();       

        this.mapAsapSettingForCatalogs();

        if(this.catalogsTimeFrames &&  this.catalogsTimeFrames.length == 1) 
        {
            /* We would select the default catalog as only one available */           
            this.selectedcatalogId = this.catalogsTimeFrames[0].catalogId;
            this.catalogService.setLastSelectedCatalogId(this.selectedcatalogId)        
        }

        // console.log(this.minDate);
        var gethyperLinkUrl = sessionStorage.getItem('hyperLinkUrl');
        var gethyperLinkText = sessionStorage.getItem('hyperLinkText');
        var getSelectLocationText = sessionStorage.getItem('selectLocationText');
        var getSelectLocationUrl = sessionStorage.getItem('selectLocationUrl');
        if (getSelectLocationText && getSelectLocationUrl && getSelectLocationText !== "" && getSelectLocationUrl !== "") {
            this.selectLocationText = getSelectLocationText;
            this.selectLocationUrl = getSelectLocationUrl;
        }
        if (gethyperLinkText && gethyperLinkUrl && gethyperLinkText !== "" && gethyperLinkUrl !== "") {
            this.hyperLinkText = gethyperLinkText;
            this.hyperLinkUrl = gethyperLinkUrl;
        }
        
        this.selectedcatalogId =  this.catalogService.getLastSelectedCatalogId();
        this.onCatalogSelected();
       
    }

    async mapAsapSettingForCatalogs() {

        if(this.catalogsTimeFrames && this.catalogsTimeFrames.length > 0) 
        {
            console.log( this.catalogsTimeFrames);
            this.asapStatusForAllTimeFrames = this.catalogsTimeFrames.filter(x => x.isAsapAvailable == true);
            console.log( this.asapStatusForAllTimeFrames);
      
            if( this.asapStatusForAllTimeFrames &&  this.asapStatusForAllTimeFrames.length > 0) 
            {
                this.isAsapClosed = false;                                         
            }
            else
            {
                this.isAsapClosed = true;                          
            }

            if(this.orderAheadEnabled && this.isAsapClosed) 
            {
                this.futureOrder = true;
            }
        }        
    }

    ngOnInit() 
    {

    }

    /* Changes */
    async ionViewWillEnter()  
    {      
        
    }

    openSelectCatalogModal(value: boolean) 
    {    
        this.showCatalogSelectorPopup = true;
    }
  
    closeSelectCatalogModal() 
    {
      this.showCatalogSelectorPopup = false;
    }

    onChangeHandler($event) {        
        this.selectedcatalogId = $event.target.value;   
        this.catalogService.onCatalogSelectedChange(this.selectedcatalogId);    
        this.onCatalogSelected();
    }

    onCatalogSelected() 
    {
        if(this.selectedcatalogId > 0) 
        {
            var result = this.catalogsTimeFrames.filter(x => x.catalogId == this.selectedcatalogId);
    
            if(result && result.length > 0) 
            {
                this.selectedcatalogName = result[0].catalogName;                    
                
                this.showSaveButton = true;
            }
    
        }      
        this.showCatalogSelectorPopup = false;  
    }



    /* Changes End */

    getZeroAdded(val : number): string {
        if (val < 10) {
            return "0" + val;
        }
        return "" + val;
    }

    back(exit = false) {
        if (!exit && !this.showOrderTypes) {
            //this.removeAddress();
            this.showOrderTypes = true;
            return;
        }

        this.location.back();
    }

    async save() {
        if(this.selectedcatalogId >  0) 
        {
            //if curbside - ensure info is added
            if (this.curbSideOrder) {
                let data: string = this.curbSideOrderDetails;
                if (data.trim().length == 0) {
                    alert("Please describe your car");
                    return;
                }
            }

            //if future order - ensure date/time is provided
            if (this.futureOrder) {
                if (!this.displayFutureDate) {
                    alert("Please pick a date");
                    return;
                }
                if (!this.displayFutureTime) {
                    alert("Please pick a time");
                    return;
                }
            }

            //if delivery order - ensure address is valid
            if (this.deliveryOrder) {
                if (!this.deliveryAddress || (this.deliveryAddress && this.deliveryAddress.trim().length == 0)) {
                    alert("Please select the correct address");
                    return;
                }
            }

            if(this.deliveryOrder && this.isExternalDelivery){
                if (!this.deliveryPhone || (this.deliveryPhone && this.deliveryPhone.trim().length == 0)) {
                    alert("Please enter your phone number");
                    return;
                }

                if (this.deliveryPhone != this.verifiedDeliveryRequest.phone) {
                    this.verifiedDeliveryRequest.phone = this.deliveryPhone;
                    this.verifiedDeliveryRequest.validatePhone = true;
                    if (await this.validateDeliveryOrder(this.verifiedDeliveryRequest) == false) {
                        return;
                    }
                }
            }

            //validate this is the right time by checking with the server
            let futureOrderDateTimeRequest = new FutureOrderDateTime();
            futureOrderDateTimeRequest.year = this.year;
            futureOrderDateTimeRequest.month = this.month;
            futureOrderDateTimeRequest.day = this.day;
            futureOrderDateTimeRequest.hour = this.hour;
            futureOrderDateTimeRequest.min = this.min;

            let request = new ValidateFutureOrderRequest();

            //request.catalogId = this.catalogService.getCurrentCatalog().id;
            request.catalogId = this.selectedcatalogId;
            request.merchantLocationId = this.customerSession.locationId;
            request.futureOrderDateTime = futureOrderDateTimeRequest;
            request.isFutureOrder = this.futureOrder;
            request.items = this.cartService.cart.items;

            this.dataService.validateFutureOrder(request).subscribe((data) => {
                //if all good then save in cart service for prepare order request
                this.cartService.isFutureOrder = this.futureOrder;
                if (this.futureOrder == true) {
                    this.cartService.setFutureOrderDateTime(futureOrderDateTimeRequest);
                } else {
                    this.cartService.clearFutureOrderDateTime();
                }

                //curbside
                this.cartService.isCurbSideOrder = this.curbSideOrder;
                if (this.curbSideOrder) {
                    this.cartService.setCurbSideOrderDetails(this.curbSideOrderDetails);
                } else {
                    this.cartService.clearCurbSideOrderDetails();
                }

                //delivery
                this.cartService.isDeliveryOrder = this.deliveryOrder;
                if (this.deliveryOrder) {
                    //set delivery data
                    //create object and serialize
                    let deliveryDetails = new DeliveryDetails();
                    deliveryDetails.address = this.deliveryAddress;
                    deliveryDetails.phone = this.deliveryPhone;
                    deliveryDetails.longitude = this.deliveryLongitude;
                    deliveryDetails.latitude = this.deliveryLatitude;                                
                    deliveryDetails.details = this.deliveryOrderDetails;

                    this.cartService.setDeliveryOrderDetails(deliveryDetails);
                } else {
                    //clear delivery data
                    this.cartService.clearDeliveryOrderDetails();
                }

                this.catalogService.setLastSelectedCatalogId(this.selectedcatalogId);              
                // sessionStorage.setItem("catalogSelectedByUser", "true");    
                this.catalogService.reloadCatalog = true;        
                this.orderRouting.goToCatalog();
                //this.back(true);
            },
            (err) => {
                console.log(err);                               
                this.alertMessage(err.message);
            });

        }
        else 
        {
            if (this.isAsapClosed && this.futureOrder) {
                if (!this.isDateTimeSelected) {
                    this.alertMessage("Please select date and time");
                }
                else 
                {
                    this.alertMessage("Please select a catalog");
                }
            } else {
                this.alertMessage("Please select a catalog");
            }
            
        }
        
    }

    async alertMessage(message) {
        const alert = await this.alertController.create({
            header: 'Oops',
            message: message,
            buttons: ['OK']
        });
        await alert.present();
    }

    pickDate() {
        this.datePicker.open();
    }

    updateDate() {
        let futureOrderDateString = this.futureOrderDate as string;
        try {
            this.year = parseInt(futureOrderDateString.split('T')[0].split('-')[0]);
            this.month = parseInt(futureOrderDateString.split('T')[0].split('-')[1]);
            this.day = parseInt(futureOrderDateString.split('T')[0].split('-')[2]);

            this.generateDisplayDate();

            if (this.displayFutureTime) {
                this.checkAddressAndVerifyAddress();
            }
        }
        catch (err) {
            console.log(err);
            alert("Sorry, something went wrong. Please contact support");
        }

    }
    checkAddressAndVerifyAddress() {
        if (this.deliveryAddress
            && this.deliveryLongitude
            && this.deliveryLatitude) {
            var estimateRequest: ValidateDeliveryOrderRequest = {
                address: this.deliveryAddress,
                phone: this.deliveryPhone,
                deliveryLatitude: this.deliveryLatitude,
                deliveryLongitude: this.deliveryLongitude,
                merchantLocationId: this.customerSession.locationId,
                orderAheadDate: null,
                validatePhone: false,
                items: this.cartService.cart.items,
                catalogId: this.selectedcatalogId,
                orderAheadDeliveryDate: "",
                orderAheadDeliveryTime: ""
            };

            this.validateDeliveryOrder(estimateRequest);
        }
    }

    pickTime() {
        this.timePicker.open();
    }

    updateTime() {
        let futureOrderTimeString = this.futureOrderTime as string;
        try {
            if (futureOrderTimeString.indexOf('T') > -1) {
                this.hour = parseInt(futureOrderTimeString.split('T')[1].split(':')[0]);
                this.min = parseInt(futureOrderTimeString.split('T')[1].split(':')[1]);
            } else if (futureOrderTimeString.length == 5 && futureOrderTimeString.indexOf(':') > -1) {
                this.hour = parseInt(futureOrderTimeString.split(':')[0]);
                this.min = parseInt(futureOrderTimeString.split(':')[1]);
            } else {
                throw "Sorry, something went wrong. Please contact support";
            }

            this.generateDisplayTime();

            if (this.displayFutureDate) {
                this.checkAddressAndVerifyAddress();
            }

            this.isDateTimeSelected = true;

            /*TODO => If only one catalog for location - then select by default  and disable catalog change button*/
        }
        catch (err) {
            console.log(err);
            alert("Sorry, something went wrong. Please contact support");
        }
    }

    generateDisplayDate() {
        this.displayFutureDate = this.cartService.getFutureOrderDisplayDate(this.year, this.month, this.day);

        this.saveButtonCheck();
    }

    generateDisplayTime() {
        this.displayFutureTime = this.cartService.getFutureOrderDisplayTime(this.hour, this.min);

        this.saveButtonCheck();
    }

    saveButtonCheck() {
        this.showSaveButton = false;

        if (this.futureOrder == true) {
            if (this.displayFutureDate && this.displayFutureTime) {
                this.showSaveButton = true;
            }
        } else {
            this.showSaveButton = true;
        }

        // if (this.deliveryOrder == true){
        //   //ensure we got the delviery details
        // }
    }

    futureOrderOptionChanged(ev: any) {
        let newValue = ev.detail.value == "true";
        console.log("future order option changed is: " + newValue);
        this.futureOrder = newValue;
        this.saveButtonCheck();
    }

    setCurbSide(value) {
        this.curbSideOrder = value;
    }

    setFutureOrder(value) {
        console.log(value);
        this.oderTypeSelected = true;
        this.futureOrder = value;


        this.saveButtonCheck();
    }

    setDeliveryOrder(value){
        this.deliveryOrder = value;
        if (this.deliveryOrder){
          //ensure curbside is false
          this.curbSideOrder = false;
        }
        //need to check save button
    }

    //test
    selectEvent(item) {
        // do something with selected item
        // console.log('auto complete item selected');
        // console.log(item);
        this.isLoading = true;

        //need to call the second api to get coordinates
        this.dataService.getDetailsOfAddress(item.text, item.magicKey).subscribe((data) => {
            console.log(data);
            if (data.candidates) {

                //if we have candidates take first one
                this.deliveryLongitude = data.candidates[0].location.x;
                this.deliveryLatitude = data.candidates[0].location.y;
                this.deliveryAddress = item.text;
                console.log("Address is: " + this.deliveryAddress + ", Longitude is: " + this.deliveryLongitude+ ", Latitude is: " + this.deliveryLatitude);

                //maybe we need to tell them right away if ordering is available or not
                let deliveryRequest = new ValidateDeliveryOrderRequest();
                deliveryRequest.deliveryLongitude = this.deliveryLongitude;
                deliveryRequest.deliveryLatitude = this.deliveryLatitude;
                deliveryRequest.address = this.deliveryAddress;
                deliveryRequest.phone = this.deliveryPhone;
                deliveryRequest.merchantLocationId = this.customerSession.locationId;
                deliveryRequest.validatePhone = this.isExternalDelivery;
                deliveryRequest.items = this.cartService.cart.items;
                deliveryRequest.catalogId = this.selectedcatalogId;

                this.validateDeliveryOrder(deliveryRequest);
            }
        },
            (err) => {
                console.log(err);
            });
    }

    async validateDeliveryOrder(deliveryRequest: ValidateDeliveryOrderRequest): Promise<boolean> {
        let returnValue = false; 

        if (this.futureOrder) {
            deliveryRequest.orderAheadDate = new Date(this.futureOrderDate + ' ' + this.futureOrderTime);
            deliveryRequest.orderAheadDeliveryDate = this.futureOrderDate;
            deliveryRequest.orderAheadDeliveryTime = this.futureOrderTime;
        }
        
        this.spinner.show();
        await this.dataService.validateDeliveryOrder(deliveryRequest).toPromise().then((data) => {
            this.spinner.hide();
            console.log(data);
                                        
            this.verifiedDeliveryRequest = deliveryRequest;

            if (data && data.deliveryEstimateDate && data.deliveryEstimateTime) {
                this.deliveryEstimateDate = data.deliveryEstimateDate;
                this.deliveryEstimateTime = data.deliveryEstimateTime;
            }

            this.isLoading = false;
            returnValue = true;
        },
        (err) => {
            this.spinner.hide();
            console.log(err);
            //need to clear address!
            this.removeAddress();
            this.deliveryLongitude = 0;
            this.deliveryLatitude = 0;
            if (err.type == "DELIVERY" && err.action && err.action != "") {
                this.errorHandlerService.handleError(err);
              } else {
                this.alertMessage(err.message);
              }
            
            this.isLoading = false;
            returnValue = false;
            });
        return returnValue;
    }

    onChangeSearch(val: string) {
        // fetch remote data from here
        // And reassign the 'data' which is binded to 'data' property.
        // console.log('auto complete search');
        // console.log(val);
        if (val.trim().length == 0) {
            //no search query -- set data to null
            this.data = [];
        } else {
            this.isLoading = true;
            this.dataService.searchAddress(val).subscribe((data) => {
                if (data && data.suggestions) {
                    this.data = data.suggestions;
                } else {
                    this.data = [];
                }

                this.isLoading = false;
            }, (err) => {
                console.log(err);
                this.data = [];
                this.isLoading = false;
            });
        }
    }

    onFocused(e) {
        // do something when input is focused
        // console.log('auto complete focused');
        // console.log(e);
    }

    onCleared() {
        console.log('auto complete cleared');

    }

    changeOrderType(orderType: number) {
        this.selectedOrderType = orderType;        

        switch (this.selectedOrderType) {
            case 0:
                this.deliveryOrder = true;
                this.curbSideOrder = false;
                break;
            case 1:
                this.deliveryOrder = false;
                this.curbSideOrder = false;                
                break;
            case 2:
                this.deliveryOrder = false;
                this.curbSideOrder = true;
                break;
        }

     
        this.showOrderTypes = false;
        this.oderTypeSelected = true;
        
    }

    changeAddress() {
        console.log('changeAddress');
        this.removeAddress();
        //clear address
        this.auto.clear();
        this.auto.close();
        this.auto.focus();
    }

    removeAddress() {
        this.deliveryAddress = undefined;
    }

    deliveryInstructionRadioChange() {
        this.cartService.deliveryInstruction = this.deliveryInstructionRadio;
    }
}
