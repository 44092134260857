import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderRoutingService } from './order-routing.service';

@Injectable()
export class OrderGuidRedirectResolver implements Resolve<any> {
  constructor(private authService: AuthService, private router: Router, private orderRouting: OrderRoutingService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    console.log('OrderGuidRedirectResolver: The router url is = '+ window.location.href);
    console.log('OrderGuidRedirectResolver: Auth Service has the following GUID = ' + this.authService.orderGuid);
    if (this.authService.orderGuid){
      let orderGuid = this.authService.orderGuid;
      console.log('OrderGuidRedirectResolver: consuming following order GUID = ' + orderGuid);
      this.authService.orderGuid = '';
      console.log('OrderGuidRedirectResolver: redirecting to order status');
      //we need to create another one? for datetime param?
      // this.router.navigate(['/order-status', orderGuid], { queryParams: { r: (new Date()).getTime() + Math.floor(Math.random() * 1000000) } });
      this.orderRouting.goToOrderStatus(orderGuid);
      return null;
    }
    
    return null;
  }
}