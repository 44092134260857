import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from './models/api-response.model';
import { Catalog } from './models/catalog';
import { PrepareOrderRequest } from './models/prepare-order-request';
import { PreparedOrder } from './models/prepared-order.model';
import { PlaceOrderRequest, PlaceOrderRequestPOS } from './models/place-order-request';
import { Assets } from './merchant-assets/assets.model';
import { CatalogLocation } from './models/catalog-location';
import { ValidateFutureOrderRequest } from './models/validate-future-order-request';
import { ValidateDeliveryOrderRequest } from './models/validate-delivery-order-request';
import { CalculateCartPriceRequest } from './models/calculate-cart-price-request';
import { RenewableCache } from './reneable-cache.observable';
import { PaymentMode } from './models/payment-mode';
import { CustomerDetails } from './models/customer-details.model';
import { MembershipPurchasePayment } from './models/membership-purchase-payment';
import { GiftCardBalanceRequest } from './models/gift-card-balance-request';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  rootUrl = environment.apiUrl;

  constructor(private http : HttpClient) { }
 
  private handleResponse<T>(observable: Observable<ApiResponse<T>>){
    return observable.pipe( 
      switchMap((response) => { 
        if(response.isSuccess){
            if (response.hasOwnProperty("data")) {
                return of(response.data)
            }
            else {
                return of(<any>response)
            }
         }
        else{
          let errorMsg = 'error';
          let errorSrc = '';
          let errorType = '';
          let errorAction: string = null;
          let errorRedirectTo: string = null;
          let errorRefnum: string = null;

          //TODO:different errors need to be handled, such as catalog expired needing reload
          if(response.errors.length > 0){
            errorMsg = response.errors[0].message;
            errorSrc = response.errors[0].source;
            errorType = response.errors[0].type;
            errorAction = response.errors[0].action;
            errorRedirectTo = response.errors[0].redirectTo;
            errorRefnum = response.errors[0].refnum;

            response.errors.forEach((error) => { 
              console.error(`Got ERROR ${error.type} - ${error.message}`)
            })
          }
          //message to display to client
            return throwError({
                message: errorMsg,
                source: errorSrc,
                type: errorType,
                action: errorAction,
                redirectTo: errorRedirectTo,
                refnum: errorRefnum
            });
        }
      })
    )
  }
  
  searchAddress(searchKey): Observable<any>{
    const params = new HttpParams()
          .set('text', searchKey)
          .set('f', 'json');
    return this.http.get<any>('https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest', { headers: { 'Anonymous': 'true' }, params: params });
  }

  getDetailsOfAddress(text, magicKey): Observable<any>{
    const params = new HttpParams()
          .set('SingleLine', text)
          .set('magicKey', magicKey)
          .set('f', 'json');
    return this.http.get<any>('https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates', { headers: { 'Anonymous': 'true' }, params: params });
  }

  getAssets(): Observable<Assets>{
    return this.http.get<Assets>(this.rootUrl +'/catalog/GetAssets');
  }

  getDashboardData() : Observable<any> {
    return this.http.get<any>(this.rootUrl +'/catalog/GetDashboardData');
  } 

  //======== getCatalogLocations region ===========
  getCatalogLocationCache = new RenewableCache();
  getCatalogLocations() {        
    return this.getCatalogLocationCache.renewableCacheAfterTimer(this.getCatalogLocationsApi(), 300000);
  }
  getCatalogLocationsApi() : Observable<CatalogLocation[]> {
    return this.http.get<CatalogLocation[]>(this.rootUrl +'/catalog/GetLocations');
  }
    //======== getCatalogLocations region ===========

  getCatalogs(locationId) : Observable<Catalog[]> {
    const params = new HttpParams().set('locationId' , locationId.toString());
    return this.http.get<Catalog[]>(this.rootUrl + '/catalog/GetCatalogsForLocation', { params });
  }

    getLocationSettings(locationId): Observable<any> {
        const params = new HttpParams().set('locationId', locationId.toString());
        return this.http.get<Catalog[]>(this.rootUrl + '/catalog/GetLocationSettings', { params });
    }  

  GetOrderAheadSetting(locationId) : Observable<any> {
    const params = new HttpParams().set('locationId' , locationId.toString());
    return this.http.get<Catalog[]>(this.rootUrl + '/catalog/GetOrderAheadSetting', { params });
  }

  getCard(locationId, paymentMode: PaymentMode) : Observable<any>{
    // const params = new HttpParams().set('', );
    const params = new HttpParams()
                  .set('locationId', locationId.toString())
                  .set('paymentMode', paymentMode.toString())
    return this.http.get<any>(this.rootUrl + '/paymentmanagement/getcard', { params });  
  }

  getWalletLoadAmounts(locationId, paymentMode: PaymentMode) : Observable<any>{
    // const params = new HttpParams().set('', );
    const params = new HttpParams()
                  .set('locationId', locationId.toString())
                  .set('paymentMode', paymentMode.toString())
    return this.http.get<any>(this.rootUrl + '/paymentmanagement/getwalletloadamounts', { params })
        .pipe(switchMap(response => this.handleResponse(of(response))));;  
  }

  removeCard(locationId, paymentMode: PaymentMode) : Observable<any> {
    //const params = new HttpParams().set('locationId', locationId.toString());
    const params = new HttpParams()
                  .set('locationId', locationId.toString())
                  .set('paymentMode', paymentMode.toString())
    return this.http.delete<any>(this.rootUrl + '/paymentmanagement/deletecard', { params });  
  }

  loadWallet(loadWalletRequest) : Observable<any> {
    return this.http.post<any>(this.rootUrl + '/paymentmanagement/loadwallet', loadWalletRequest);  
  }

  loadWalletV2(loadWalletV2Request) : Observable<any> {
    return this.http.post<any>(this.rootUrl + '/paymentmanagement/loadwalletv2', loadWalletV2Request);  
  }

  saveCustomerCard(saveCustomerCardRequest): Observable<any> {
      return this.http.post<any>(this.rootUrl + '/paymentmanagement/savecustomercard', saveCustomerCardRequest)
          .pipe(switchMap(response => this.handleResponse(of(response))));
  }

  getWalletBalance() : Observable<any>{
    return this.http.get<any>(this.rootUrl + '/paymentmanagement/getwalletbalance');  
  }

  addCard(token, name, billingAddress, locationId) : Observable<any> {
    let request = {
      PaymentGatewayToken: token,
      Name: name,
      BillingAddress: billingAddress,
      MerchantLocationId: locationId
    }
    return this.http.post<any>(this.rootUrl + '/paymentmanagement/storecard', request);  
  }

  getCountries() : Observable<any>{
    return this.http.get<any>(this.rootUrl + '/paymentmanagement/getcountries');  
  }

  getCountriesAlpha3(locationId: number) : Observable<any>{
      const params = new HttpParams()
          .set('locationId', locationId.toString())
    return this.http.get<any>(this.rootUrl + '/paymentmanagement/getcountriesalpha3', { params });  
  }

  getIntegrationSettings(locationId, paymentMode: PaymentMode) : Observable<any>{
    // const params = new HttpParams().set('locationId', locationId.toString());
    const params = new HttpParams()
                  .set('locationId', locationId.toString())
                  .set('paymentMode', paymentMode.toString())
    return this.http.get<any>(this.rootUrl + '/paymentmanagement/getintegrationsettings', { params });  
  }

  getLastOrderedItems(catalogId: number, merchantLocationId: number){
    var GetLastOrderedDto = {"catalogId" : catalogId, "merchantLocationId" : merchantLocationId};
    return this.http.post<ApiResponse<any>>(this.rootUrl + '/orders/getLastOrderedItems',  GetLastOrderedDto )
      .pipe(switchMap(response => this.handleResponse(of(response))));
  }

  getOrdersHistory(limit:number, offset:number){
    return this.http.post<ApiResponse<any>>(this.rootUrl + '/orders/history', {limit: limit, offset: offset })
      .pipe(switchMap(response => this.handleResponse(of(response))));
  }
  
  getOrderDetails(orderId){
    return this.http.post<ApiResponse<any>>(this.rootUrl + '/orders/view', {orderId: orderId})
      .pipe(switchMap(response => this.handleResponse(of(response))));
  }

  getGuestOrderDetails(orderId: string, merchantGuid: string) { 
    return this.http.post<ApiResponse<any>>(this.rootUrl + '/orders/guestView', {orderId: orderId, merchantGuid: merchantGuid})
    .pipe(switchMap(response => this.handleResponse(of(response))));
  }

  cancelOrder(orderId){
    return this.http.post<ApiResponse<any>>(this.rootUrl + '/orders/cancel', {orderId: orderId})
      .pipe(switchMap(response => this.handleResponse(of(response))));  
  }

  cancelGuestOrder(orderId: string, merchantGuid: string) {
    return this.http.post<ApiResponse<any>>(this.rootUrl + '/orders/cancelGuestOrder', {orderId: orderId, merchantGuid: merchantGuid})
    .pipe(switchMap(response => this.handleResponse(of(response))));
  }

  notifyStore(orderId){
    return this.http.post<ApiResponse<any>>(this.rootUrl + '/orders/notifystore', {orderId: orderId})
      .pipe(switchMap(response => this.handleResponse(of(response))));  
  }

  guestNotifystore(orderId: string, merchantGuid: string){
    return this.http.post<ApiResponse<any>>(this.rootUrl + '/orders/guestNotifystore', {orderId: orderId, merchantGuid: merchantGuid})
      .pipe(switchMap(response => this.handleResponse(of(response))));  
  }

  getCustomerDetails(){
      return this.http.post<ApiResponse<any>>(this.rootUrl + '/orders/GetCustomerDetails', {})
      .pipe(switchMap(response => this.handleResponse(of(response))));  
  }

  setCustomerDetails(customerDetails: CustomerDetails){
    return this.http.post<ApiResponse<any>>(this.rootUrl + '/orders/SetCustomerDetails', customerDetails)
      .pipe(switchMap(response => this.handleResponse(of(response))));  
  }

  prepareOrder(request: PrepareOrderRequest) {
    return this.http.post<ApiResponse<PreparedOrder>>(this.rootUrl + '/orders/prepare', request)
      .pipe(switchMap(response => this.handleResponse(of(response)))); 
  }

  calculateCartPrice(request: CalculateCartPriceRequest) {
    return this.http.post<ApiResponse<any>>(this.rootUrl + '/catalog/CalculateCartPrice', request)
      .pipe(switchMap(response => this.handleResponse(of(response)))); 
  }

  getGiftCardBalance(locationId: number, giftCardId: string, giftCardPin: string, token: string, recaptchaAction: string) {
    const params = new HttpParams()
          .set('locationId', locationId.toString())
          .set('giftCardId', giftCardId.toString())
          .set('giftCardPin', giftCardPin.toString())
          .set('token', token.toString())
          .set('recaptchaAction', recaptchaAction.toString());
    return this.http.get<any>(this.rootUrl + '/paymentmanagement/getgiftcardbalance', { params }); 
  }

  postGiftCardBalance(request: GiftCardBalanceRequest) {
    return this.http.post<ApiResponse<any>>(this.rootUrl + '/paymentmanagement/getgiftcardbalance', request);
  }

  placeOrder(request: PlaceOrderRequest){
    return this.http.post<any>(this.rootUrl + '/orders/submit', request)
      .pipe(switchMap(response => this.handleResponse(of(response)))); 
  }
    
  placeOrderPOS(request: PlaceOrderRequestPOS){
    return this.http.post<any>(this.rootUrl + '/orders/submit', request)
      .pipe(switchMap(response => this.handleResponse(of(response)))); 
  }

  validateFutureOrder(request: ValidateFutureOrderRequest){
    return this.http.post<any>(this.rootUrl + '/catalog/ValidateFutureOrder', request)
      .pipe(switchMap(response => this.handleResponse(of(response)))); 
  }

  validateDeliveryOrder(request: ValidateDeliveryOrderRequest){
    return this.http.post<ApiResponse<any>>(this.rootUrl + '/catalog/ValidateDeliveryOrder', request)
      .pipe(switchMap(response => this.handleResponse(of(response)))); 
  }

  getPsa(merchantLocationId: number){
    const params = new HttpParams()
          .set('merchantLocationId', merchantLocationId.toString());
    return this.http.get<any>(this.rootUrl + '/orders/psa', { params });
  }

  playInstantGame(merchantLocationId: number){
    const params = new HttpParams()
          .set('merchantLocationId', merchantLocationId.toString());
    return this.http.get<any>(this.rootUrl + '/orders/play', { params });
  }

  getMemberships(locationId: number = null, imageWidth: number = null){
    const params = new HttpParams()
          .set('locationId', locationId != null ? locationId.toString() : null)
          .set('imageWidth', imageWidth != null ? imageWidth.toString() : null);
    return this.http.get<any>(this.rootUrl + '/memberships', { params });
  }

  getMembership(membershipId: number, locationId: number = null, imageWidth: number = null){
    const params = new HttpParams()
      .set('locationId', locationId != null ? locationId.toString() : null)
      .set('imageWidth', imageWidth != null ? imageWidth.toString() : null);
    return this.http.get<any>(this.rootUrl + '/memberships/' + membershipId, { params });
  }

  getCardByIntegrationId(integrationId: number) : Observable<any> {
    const params = new HttpParams()
      .set('integrationId', integrationId.toString());
    return this.http.get<any>(this.rootUrl + '/paymentmanagement/getcardbyintegrationid', { params });
  }

  deleteCardByIntegrationId(integrationId: number) : Observable<any> {
    const params = new HttpParams()
      .set('integrationId', integrationId.toString());
    return this.http.delete<any>(this.rootUrl + "/paymentmanagement/deleteCardByIntegrationId", { params });
  }

  getMembershipAvailibility(membershipId: number, customerId: number) {
    const params = new HttpParams()
      .set('customerId', customerId.toString());
    return this.http.get<any>(this.rootUrl + '/memberships/' + membershipId + '/membershipAvailibility', { params });
  }

  getPaymentSetup(membershipId: number, locationId: number = null) : Observable<any> {
    const params = new HttpParams()
    .set('locationId', locationId != null ? locationId.toString() : null);
    return this.http.get<any>(this.rootUrl + '/memberships/' + membershipId + '/paymentsetup', { params });
  }

  cancelMembership(membershipId: number, reason: string) {
    let MembershipCancelRequestDto = {MembershipId: membershipId, Reason: reason};
    return this.http.post<any>(this.rootUrl + '/memberships/cancel', MembershipCancelRequestDto);
  }

  purchaseMembership(membershipId: number, payment: MembershipPurchasePayment, locationId: number = null) : Observable<any> {
    let MembershipPurchaseRequestDto = {MembershipId: membershipId, PurchaseDetails: payment, MerchantLocationId: locationId};
    return this.http.post<any>(this.rootUrl + '/memberships/purchase', MembershipPurchaseRequestDto);
  }

  getMerchantAmendments(type: string) {
    const params = new HttpParams()
    .set('type', type);
    return this.http.get<any>(this.rootUrl + "/memberships/getMerchantAmendments", { params });
  }

  getMerchantLocations() {
    return this.http.get<any>(this.rootUrl + "/memberships/getMerchantLocations");
  }

  createStripePaymentIntent(createPaymentIntentRequest) : Observable<any> {
    return this.http.post<any>(this.rootUrl + '/applepaytest/create-payment-intent', createPaymentIntentRequest);  
  }

  sendSlackMessage(message) : Observable<any> {
    return this.http.post<any>(this.rootUrl + '/applepaytest/send-slack-message', message);  
  }
}
