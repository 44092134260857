import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplePaymentData } from './models/apple-payment-data';
import { PaymentService } from './payment.service';
import { PaymentAction } from './models/enum';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})

export class ToastApplePayService {
  private applePayAvailable$ = new BehaviorSubject<boolean>(false);
  private token$ = new BehaviorSubject<any>(null);
  private applePaymentData$ = new BehaviorSubject<ApplePaymentData>(null);
  private puid$ = new BehaviorSubject<any>(null);
  isMobile: boolean;
  isMobileApp: boolean;

  constructor(private paymentService: PaymentService, private util: UtilService) 
  {
    this.util.postAppSpecificRequestsToFrame();
    this.checkIfMobileApp();
  }

  checkIfMobileApp() {
    let mobileAppResult = this.util.checkIfMobileApp();
    this.isMobile = mobileAppResult.isMobile;
    this.isMobileApp = mobileAppResult.isMobileApp;   
  }

  checkApplePaySessionAvailable() {
    if ((<any>window).ApplePaySession) {
      console.log('The Apple Pay JS API is available');
      this.applePayAvailable$.next(true);
    }   
    else {
      console.log('No Appe Pay Session Found');
      this.applePayAvailable$.next(false);
    }
  }

  processApplepPay() {    
      if (!this.getApplePayAvailable()) {
        return;
      }    
      var merchantId = this.paymentService.getMerchantId();
      var merchantLocationId = this.paymentService.getMerchantLocationId();    
      var customerId = this.paymentService.getcustomerId();         

      const session = new (<any>window).ApplePaySession(3, this.applePaymentData$.getValue());
      session.onvalidatemerchant = async event => {
        var logMessage = `Initiated ApplePay Session for Web OO - CustomerId: ${customerId} | MerchantId: ${merchantId} | MerchantLocationId: ${merchantLocationId} with ApplicationData: ${JSON.stringify(this.applePaymentData$.getValue().applicationData)} and PaymentId: ${JSON.stringify(this.puid$.getValue())}`;
        console.log(logMessage);  
        await fetch(environment.POST_LOG_SLACK_MESSAGE, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              Message: logMessage,
              SendMessageToSlack: false,
              SlackEndpoint: null
          })
        }).then(res => res.json()) .then(res => {             
          console.log('LOGGED INITIAL INFORMATION TO SERVER');
          console.log(JSON.stringify(res));        
        });

        const merchantSession = await fetch(environment.FETCH_APPLE_PAY_SESSION + '?merchantId=' + merchantId + '&merchantLocationId=' + merchantLocationId, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                    }
        }).then(res => res.json()) .then(res => {             
          console.log('MERCHANT SESSION FOR TOAST APPLE PAY SUCCESSFULLY GENERATED');
          console.log(JSON.stringify(res));        
          session.completeMerchantValidation(res);
        }).catch(err => {
          console.log('ERROR WHILE FETCHING MERCHANT SESSION');
          console.log(err);
          session.abort();                    
        });  
      };  
      session.onpaymentmethodselected = event => {
          console.log(event);
          const paymentMethod = event.paymentMethod;
          paymentMethod.newTotal = this.applePaymentData$.getValue().total;
          paymentMethod.newLineItems = this.applePaymentData$.getValue().lineItems;
          session.completePaymentMethodSelection(paymentMethod);
      };  
      session.onshippingmethodselected = event => {       
        const update = {};
        session.completeShippingMethodSelection(update);
      };  
      session.onshippingcontactselected = event => {        
         const update = {};
         session.completeShippingContactSelection(update);
      };  
      session.onpaymentauthorized = async event => {
          console.log(event);     
          if (event.payment) {
            this.token$.next(event.payment);
            await this.processPayment().then((res) => {
              if (res) {            
                session.completePayment((<any>window).ApplePaySession.STATUS_SUCCESS);
              }         
              else {
                session.completePayment((<any>window).ApplePaySession.STATUS_FAILURE);
              }     
            }, err => {              
              session.completePayment((<any>window).ApplePaySession.STATUS_FAILURE);
            });           
          }                 
      };  
      session.oncouponcodechanged = event => {};            
      session.begin();
  }

  getApplePayAvailable() {
    return this.applePayAvailable$.getValue();
  } 
  
  async processApplePayV3(applePayToken: any) {
    this.token$.next(applePayToken);
    await this.processPayment().then((res) => {
      if (res) {            
        parent.postMessage(
          {
            action: 'applepay_token_authorized_success'           
          }, 
          sessionStorage.getItem("hostingapp")
        );
      }         
      else {
        parent.postMessage(
          {
            action: 'applepay_token_authorized_failure'           
          }, 
          sessionStorage.getItem("hostingapp")
        );
      }     
    }, err => {              
      parent.postMessage(
        {
          action: 'applepay_token_authorized_failure'           
        }, 
        sessionStorage.getItem("hostingapp")
      );
    });     
  }

  public processPayment(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (this.token$.getValue()) {      
        this.paymentService.request.isApplePay = true;        
        this.paymentService.request.applePayToken = Buffer.from(JSON.stringify(this.token$.getValue())).toString("base64");
        this.paymentService.request.applePayPaymentId = this.puid$.getValue().puid;
        this.paymentService.request.applePayApplicationData = this.applePaymentData$.getValue().applicationData;
        if (this.token$.getValue().billingContact) {
          this.paymentService.request.countryCode = this.token$.getValue().billingContact.countryCode;  
          this.paymentService.request.postalCode = this.token$.getValue().billingContact.postalCode;         
        }        
        console.log(this.paymentService.request);      
        await this.paymentService.processPayment().then(() => {
          resolve(true)
        }, error => {
          resolve(false);
        });               
      }
      else 
      {
        resolve(false);
      }      
    });
  }

  processApplepPayV3(applePaymentData: ApplePaymentData) {
    var puid = { "puid": this.generateUUID() };
    let objJsonStr = JSON.stringify(puid);     
    let objJsonB64 =  (<any>window).global.Buffer.from(objJsonStr).toString("base64");
    applePaymentData.applicationData = objJsonB64;
    this.applePaymentData$.next(applePaymentData);
    this.puid$.next(puid);
    this.checkIfMobileApp();
    if (this.isMobile && this.isMobileApp && this.paymentService.request.action == PaymentAction.PLACEORDER) {
      parent.postMessage(
        {
          action: 'initiate_apple_pay',
          paymentData: JSON.stringify(this.applePaymentData$.getValue()),
          puid: JSON.stringify(this.puid$.getValue()),
          etaMessage: this.paymentService.getEtaMessage(),
          orderType: this.paymentService.getOrderType()
        }, 
        sessionStorage.getItem("hostingapp")
      );
    }  
    else if (this.isMobile && this.isMobileApp && this.paymentService.request.action == PaymentAction.LOADWALLET) {
      parent.postMessage(
        {
          action: 'initiate_apple_pay_wallet',
          paymentData: JSON.stringify(this.applePaymentData$.getValue()),
          puid: JSON.stringify(this.puid$.getValue()),
          etaMessage: this.paymentService.getEtaMessage(),
          orderType: this.paymentService.getOrderType()
        }, 
        sessionStorage.getItem("hostingapp")
      );
    }
    else {
      this.processApplepPay();
    }  
  }

  generateUUID() {
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  setPuidReceivedfromMobileApp(puid: any) {
    console.log("SETTING PUID RECEIVED FROM MOBILE APP");
    this.puid$.next(puid);
  }
  setApplicationDataReceivedfromMobileApp(applicationData: any) {
    console.log("SETTING APPLICATION DATA RECIVED RECEIVED FROM MOBILE APP");
    let applePaymentData =  this.applePaymentData$.getValue();
    if (applePaymentData) {
      applePaymentData.applicationData = applicationData;
    }
    this.applePaymentData$.next(applePaymentData);
  }

}
