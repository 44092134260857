import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataService } from '../data.service';
declare var Stripe: any;

@Component({
  selector: 'app-pay-with-apple-pay',
  templateUrl: './pay-with-apple-pay.component.html',
  styleUrls: ['./pay-with-apple-pay.component.scss']
})
export class PayWithApplePayComponent implements OnInit {

  elementsOptions: ElementsOptions = {
    locale: 'es',
  };
  constructor(private dataservice: DataService) { }

  ngOnInit() {
    const stripe = Stripe('pk_test_SJll8oij4pUjhmk1jqpEGzbU');  
    timer(0).subscribe(x => {
      console.log('Apple Pay Container Loaded');          
      // Create the payment request
      var paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: 1000,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        shippingOptions: [
          {
            id: 'free-shipping',
            label: 'Free shipping',
            detail: 'Delivery in 5-7 business days',
            amount: 0,
          },
        ],
      });


      const elements = stripe.elements();
      const prButton = (<any>elements).create('paymentRequestButton', {
        paymentRequest: paymentRequest
      });

      paymentRequest.canMakePayment().then((result) => {
        if (result) {
          prButton.mount('#payment-request-button');
        }
        else {
          document.getElementById('payment-request-button').style.display = 'none';
          console.log('Apple Pay is unavailable');
        }
      })

      paymentRequest.on('paymentmethod', async (e) => {
        // Make a call to the server to create a new
        // payment intent and store its client_secret.
        this.dataservice.createStripePaymentIntent({
          "amount": 1000,
          "currency": "usd"
        }).subscribe(async result => {
          if (result && result.client_secret) {
            console.log(`Client secret returned.`);
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11" instead
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (result.status === 'requires_payment_method') {
              console.log("Requires Action: CALLING Confirm Card payment");
              // Let Stripe.js handle the rest of the payment flow.
              await stripe.confirmCardPayment(
                result.client_secret, 
                {
                  payment_method: e.paymentMethod.id
                },
                {handleActions: false}                
              ).then(async (res) => {
                if (res.error) {
                  console.log(res.error.message);
                  e.complete('fail');  
                  return;
                }
                if(res.paymentIntent) {
                  console.log(`Payment Successful ${res.paymentIntent.status}: ${res.paymentIntent.id}`);
                  // Report to the browser that the confirmation was successful, prompting
                  // it to close the browser payment method collection interface.
                  e.complete('success'); 
                  if (res.paymentIntent.status === "requires_action") {
                    // Let Stripe.js handle the rest of the payment flow.
                    const {error} = await stripe.confirmCardPayment(result.client_secret);
                    if (error) {
                     console.log('Please provide new payment method');
                    } else {
                      console.log('The payment has succeeded');
                    }
                  } else {
                    console.log('The payment has succeeded');
                  }                            
                }                
              }, err => {
                // The payment failed -- ask your customer for a new payment method.
                console.log(err.message);
                e.complete('fail');  
                return;
              });             
            }        
            console.log(`Payment ${result.status}: ${result.id}`);           
          }
        }, error => {
          console.log(error.message);
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          e.complete('fail');
          return;
        });
      });
    });    
  }


  postMessage() {
    console.log('POSTING MESSAGE TO OPEN APPLE PAY PROMPT')
    parent.postMessage({action: 'makeApplePayPayment'}, sessionStorage.getItem("hostingapp"));
  }

 

}
