import { TipDetails } from './tip-details.model';
export class PreparedOrder{
    constructor(){
        this.details = new PreparedOrderDetails();
    }
    orderId: string;
    totalPrice:number;
    details : PreparedOrderDetails;
    availableWalletBalance:number;
    redeemables : any[];
    supportedPaymentTypes: any[];
    eta: number;
    isTipEnabled: boolean;
    tipDetails: TipDetails;
    availableTipableAmounts: any[]; 
    payViaPOS: boolean;
    items: any[];
    isPaymentWithGiftCardAvailable: boolean;
    recommendedItems : any[];
    showAutoAddItemId?: number;
    promoCode: PreparedPromoCode;
}

export class PreparedOrderDetails {
    constructor(){
        this.lines = [];
    }
    lines : any[];
    price : number;
    tax : number;
    totalPrice : number;
    discountPrice: number;
    discount: any;
    tip: number;
    currencySymbol: any;
    externalDeliveryId: any;
    businessName: any;
}

export class PreparedPromoCode {
    isPromoCodesEnabled: boolean;
    promoCodeDisplayMessage: string;
    promoCodeIsValid: boolean;
}