import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CustomerSessionService } from '../customer-session.service';
import { DataService } from '../data.service';
import { GoogleAnalyticService } from '../google-analytic.service';
import { LoadingController } from '@ionic/angular';
import { PaymentService } from '../payment.service';
import { MembershipPaymentService } from '../membership-payment.service';
import { MembershipRoutingService } from '../membership-routing.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderRoutingService } from '../order-routing.service';

@Component({
    selector: 'clover-payment',
    templateUrl: './clover-payment.component.html',
    styleUrls: ['./clover-payment.component.scss']
})
export class CloverPaymentComponent implements OnInit {
    isMobile = sessionStorage.getItem('is_mobile');
    buttonText: string;
    clover: any;
    cardHolderName: string;
    cloverMerchantKey = '';
    customerValidated: boolean = false;
    saveCreditCard: boolean = false;
    membershipModule: boolean = false;
    isGuest: boolean = false;

    showPaymentConfirmation: boolean = false;

    merchantGuid: string;
    merchantName: string = "";

    constructor(
        private dataService: DataService,
        private location: Location,
        private customerSession: CustomerSessionService,
        private ga: GoogleAnalyticService,
        private loadingController: LoadingController,
        private paymentService: PaymentService,
        private membershipPaymentService: MembershipPaymentService,
        private membershipRouting: MembershipRoutingService,
        private translate: TranslateService,
        private orderRouting: OrderRoutingService
    ) { }

    async ngOnInit() {
        this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;
        this.isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;
        let paymentSetup = await this.customerSession.getPaymentSetupInfo();       
        this.merchantGuid = this.orderRouting.guid;
        this.merchantName = sessionStorage.getItem("merchantName");

        if (paymentSetup) {
            this.cloverMerchantKey = paymentSetup.cloverMerchantKey;            
        }

        if (this.cloverMerchantKey == '') {
            alert(this.translate.instant('Sorry! Looks like we are experiencing some difficulty with our payment form. Please try again later.'));
            this.back();
        }

        if (this.membershipModule) {
            this.buttonText = this.membershipPaymentService.getPaymentBtnText();
            this.saveCreditCard = true;
            this.customerValidated = true;
        } else {
            this.buttonText = this.paymentService.getActionText();
            this.customerValidated = paymentSetup.storedCardEnabled;
        }

        this.setupClover();
    }

    async getClvrToken() {
        const loading = await this.loadingController.create({
            message: this.translate.instant('Processing...')
        });

        await loading.present();

        this.clover.createToken()
            .then((result) => {
                if (result.errors) {
                    loading.dismiss();
                    Object.values(result.errors).forEach(function (value) {
                        alert(value);
                    });
                } else if (this.membershipModule) {
                    loading.dismiss();
                    this.membershipPaymentService.paymentDetails.Token = result.token;
                    this.membershipPaymentService.paymentDetails.CardHolderName = this.cardHolderName;
                    this.processMembershipPayment();
                } else {
                    loading.dismiss();
                    this.paymentService.request.token = result.token;
                    this.paymentService.request.cardHolderName = this.cardHolderName;
                    this.paymentService.request.saveCreditCard = this.saveCreditCard;
                    this.paymentService.request.payWithSavedCreditCard = false;
                    this.paymentService.processPayment();
                }
            });
    }

    async processMembershipPayment() {
        await this.membershipPaymentService.processPayment()
            .then((result) => {
                if(result) {
                    this.showPaymentConfirmation = true;
                }
            })
            .catch(error => {
                console.log(error);
            }); 
    }

    back() {
        this.location.back();
    }

    setupClover() {
        this.clover = new (<any>window).Clover(this.cloverMerchantKey);
        const elements = this.clover.elements();

        const elementStyle = {
            'box-sizing': 'border-box',
            'border': '1px solid #DEE0E1',
            'border-radius': '3px',
            'height': '30px',
            'margin-bottom': '10px',
            'padding': '14px',
            'width': '100%'
        };

        const styles = {
            'card-number input': elementStyle,
            'card-date input': elementStyle,
            'card-cvv input': elementStyle,
            'card-postal-code input': elementStyle
        };

        const cardNumber = elements.create('CARD_NUMBER', styles);
        const cardDate = elements.create('CARD_DATE', styles);
        const cardCvv = elements.create('CARD_CVV', styles);
        const cardPostalCode = elements.create('CARD_POSTAL_CODE', styles);

        cardNumber.mount('#card-number');
        cardDate.mount('#card-date');
        cardCvv.mount('#card-cvv');
        cardPostalCode.mount('#card-postal-code');

        //const cardResponse = document.getElementById('card-response');
        //const displayCardNumberError = document.getElementById('card-number-errors');
        //const displayCardDateError = document.getElementById('card-date-errors');
        //const displayCardCvvError = document.getElementById('card-cvv-errors');
        //const displayCardPostalCodeError = document.getElementById('card-postal-code-errors');

        // Handle real-time validation errors from the card element
        cardNumber.addEventListener('change', function (event) {
            console.log(`cardNumber changed ${JSON.stringify(event)}`);
        });

        cardNumber.addEventListener('blur', function (event) {
            console.log(`cardNumber blur ${JSON.stringify(event)}`);
        });

        cardDate.addEventListener('change', function (event) {
            console.log(`cardDate changed ${JSON.stringify(event)}`);
        });

        cardDate.addEventListener('blur', function (event) {
            console.log(`cardDate blur ${JSON.stringify(event)}`);
        });

        cardCvv.addEventListener('change', function (event) {
            console.log(`cardCvv changed ${JSON.stringify(event)}`);
        });

        cardCvv.addEventListener('blur', function (event) {
            console.log(`cardCvv blur ${JSON.stringify(event)}`);
        });

        cardPostalCode.addEventListener('change', function (event) {
            console.log(`cardPostalCode changed ${JSON.stringify(event)}`);
        });

        cardPostalCode.addEventListener('blur', function (event) {
            console.log(`cardPostalCode blur ${JSON.stringify(event)}`);
        });
    }

    saveCreditCardChecked(event) {
        if (event.detail.checked) {
            this.saveCreditCard = true;
        } else {
            this.saveCreditCard = false;
        }
    }

    continue() {
        this.membershipRouting.goToMembershipList();
    }
}
