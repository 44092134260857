import { Directive } from '@angular/core';

@Directive({
  selector: '[appCatalogDropdown]'
})
export class CatalogDropdownDirective {

  constructor() { }

}
