import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxSpinnerModule } from 'ngx-spinner';
// Import your library
import { NgxStripeModule } from 'ngx-stripe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { CartComponent } from './cart/cart.component';
import { CatalogOptionComponent } from './catalog-option/catalog-option.component';
import { CatalogComponent } from './catalog/catalog.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MembershipsComponent } from './memberships/dashboard.component';
import { ItemComponent } from './item/item.component';
import { LocationGuardService } from './location/location-guard.service';
import { LocationComponent } from './location/location.component';
import { AssetResolver } from './merchant-assets/asset.resolver';
import { MerchantHeaderComponent } from './merchant-header/merchant-header.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { NavToolbarComponent } from './nav-toolbar/nav-toolbar.component';
import { OrderGuidRedirectResolver } from './order-guid-redirect-resolver';
import { OrderReceiptComponent } from './order-receipt/order-receipt.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { PaymentAddComponent } from './payment-add/payment-add.component';
import { PaymentManageComponent } from './payment-manage/payment-manage.component';
import { RedeemablesComponent } from './redeemables/redeemables.component';
import { SpecialInstructionsComponent } from './special-instructions/special-instructions.component';
import { SquarePaymentComponent } from './square-payment/square-payment.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ReloadWalletComponent } from './reload-wallet/reload-wallet.component';
import { OrderRoutingService } from './order-routing.service';
import { CustomerGuardService } from './customer-guard.service';
import { LoginComponent } from './login/login.component';
import { MerchantInitComponent } from './merchant-init/merchant-init.component';
import { MerchantGuidGuardService } from './merchant-guid-guard.service';
import { RegisterComponent } from './register/register.component';
import { OrderAheadComponent } from './order-ahead/order-ahead.component';
import { PaymentCardComponent } from './payment-card/payment-card.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AddCardComponent } from './add-card/add-card.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NoArrowDirective } from './no-arrow.directive';
import { CatalogDropdownDirective } from './custom-directives/catalog-dropdown.directive';
import { LoadWalletComponent } from './load-wallet/load-wallet.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SubCategoryViewComponent } from './common/sub-category-view/sub-category-view.component';
import { ConnectInStoreComponent } from './connect-in-store/connect-in-store.component';
import { RegisterKeytagComponent } from './register-keytag/register-keytag.component';
import { NMIPaymentComponent } from './nmi-payment/nmi-payment.component';
import { EtaMessageComponent } from './eta-message/eta-message.component';
import { CloverPaymentComponent } from './clover-payment/clover-payment.component';
import { CardCaptureComponent } from './common/card-capture/card-capture.component';
import { IonMask } from './custom-directives/ion-mask.directive';
import { PaymentCheckoutComponent } from './payment-checkout/payment-checkout.component';
import { AlertPopupComponent } from './common/alert-popup/alert-popup.component';
//import { TranslatePipe } from './translate.pipe';
import { PaymentService } from './payment.service';
import { DecimalPipe } from '@angular/common';
import { ErrorHandlerService } from './error-handler.service';
import { RefreshComponent } from './common/refresh/refresh.component';
import { CatalogPickerComponent } from './catalog-picker/catalog-picker.component';
import { OrderTypePickerComponent } from './order-type-picker/order-type-picker.component';
import { OrderAheadPickerComponent } from './order-ahead-picker/order-ahead-picker.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ShoppingWidgetComponent } from './shopping-widget/shopping-widget.component';
import { RewardsModalComponent } from './rewards-modal/rewards-modal.component';
import { RouteHelperComponent } from './route-helper/route-helper.component';
import { ModalItemEditComponent } from './modals/modal-item-edit/modal-item-edit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MembershipsListComponent } from './memberships/list/memberships-list.component';
import { MembershipRoutingService } from './membership-routing.service';
import { MembershipDetailsComponent } from './memberships/details/membership-details.component';
import { MembershipCheckoutComponent } from './memberships/checkout/membership-checkout.component';
import { MembershipCancellationComponent } from './memberships/cancellation/membership-cancellation.component';
import { ModalLocationConfirmationComponent } from './modals/modal-location-confirmation/modal-location-confirmation.component';
import { PayWithApplePayComponent } from './pay-with-apple-pay/pay-with-apple-pay.component';
import { EmailVerificationGuardService } from './email-verification/email-verification-guard.service';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ConnectionBackend, Http, HttpModule } from '@angular/http';
import { TranslationLoaderService } from './translation-loader.service';
import { AssetService } from './merchant-assets/asset.service';
import { PhoneVerificationComponent } from './phone-verification/phone-verification.component';
import { SquarePaymentPopupComponent } from './square-payment-popup/square-payment-popup.component';
import { GuestOrderStatusComponent } from './guest-order-status/status.component';
import { GuestOrderReceiptComponent } from './guest-order-receipt/receipt.component';

@Injectable()
export class CustomMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams): string {
        return `${params.key}`;
    }
}

@NgModule({
    declarations: [
        AppComponent,
        ItemComponent,
        CartComponent,
        MyOrdersComponent,
        PaymentManageComponent,
        OrderReceiptComponent,
        OrderStatusComponent,
        PaymentAddComponent,
        RedeemablesComponent,
        CatalogComponent,
        LocationComponent,
        DashboardComponent,
        MembershipsComponent,
        MembershipsListComponent,
        UnauthorizedComponent,
        MerchantHeaderComponent,
        NavToolbarComponent,
        CatalogOptionComponent,
        SpecialInstructionsComponent,
        SquarePaymentComponent,
        SquarePaymentComponent,
        ReloadWalletComponent,
        LoginComponent,
        MerchantInitComponent,
        RegisterComponent,
        OrderAheadComponent,
        PaymentCardComponent,
        CheckoutComponent,
        AddCardComponent,
        NoArrowDirective,
        CatalogDropdownDirective,
        IonMask,
        LoadWalletComponent,
        ForgotPasswordComponent,
        SubCategoryViewComponent,
        ConnectInStoreComponent,
        RegisterKeytagComponent,
        NMIPaymentComponent,
        EtaMessageComponent,
        CloverPaymentComponent,
        CardCaptureComponent,
        PaymentCheckoutComponent,
        AlertPopupComponent,
        //TranslatePipe,
        RefreshComponent,
        CatalogPickerComponent,
        OrderTypePickerComponent,
        OrderAheadPickerComponent,
        ShoppingWidgetComponent,
        RewardsModalComponent,
        RouteHelperComponent,
        ModalItemEditComponent,
        MembershipDetailsComponent,
        MembershipCheckoutComponent,
        MembershipCancellationComponent,
        ModalLocationConfirmationComponent,
        PayWithApplePayComponent,
        EmailVerificationComponent,
        PhoneVerificationComponent,
        SquarePaymentPopupComponent,
        GuestOrderStatusComponent,
        GuestOrderReceiptComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({ mode: 'md' }),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxStripeModule.forRoot(),
        NgxSpinnerModule,
        AutocompleteLibModule,
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        NgxMaterialTimepickerModule,
        NgbModule,
        HttpModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [Http, AssetService]
        }})
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },    
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthGuardService,
    LocationGuardService,
    AssetResolver,
    OrderGuidRedirectResolver,
    OrderRoutingService,
    CustomerGuardService,
    MerchantGuidGuardService,
    PaymentService,
    ErrorHandlerService,
    DecimalPipe,   
    MembershipRoutingService,
    EmailVerificationGuardService,
    {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ShoppingWidgetComponent, RewardsModalComponent, SquarePaymentPopupComponent],
})
export class AppModule { }

export function createTranslateLoader(http: Http, assetService: AssetService): TranslationLoaderService {
    return new TranslationLoaderService(http, assetService);
}
