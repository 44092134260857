import { Component, OnInit } from '@angular/core';
import { OrderRoutingService } from '../order-routing.service';
import { AuthService } from '../auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerSessionService } from '../customer-session.service';
import Swal from "sweetalert2";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CartService } from '../cart.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss']
})
export class PhoneVerificationComponent implements OnInit {

  public requiresPhoneValidation: boolean = false;
  public lastValidationSentTime: string;
  public customerPhoneNumber: string;
  public locationPhoneNumber: string|null;
  public isLoading: boolean;
  public vForm: FormGroup;
  public state: number = 0;
  public currentDaySendCount: number = 0;
  public isGuest: boolean = false;

  constructor(private orderRoutingService: OrderRoutingService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private customerSession: CustomerSessionService,
    private formBuilder: FormBuilder,
    private cartService: CartService,
    private location: Location) { }

  ngOnInit(): void {
    this.vForm = this.formBuilder.group({
      verificationCode: new FormControl('', [Validators.required, Validators.pattern("^[1-9][0-9]*$"), Validators.minLength(6)]),
    });
  }
    
    async ionViewWillEnter() {
      this.isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;
      await this.handlePhoneVerificationData(false);
    }

    async resendCode() {
      await this.handlePhoneVerificationData(true).then(
        async (result) => {
          if (result) {
            await Swal.fire({
              title: "We have sent a verification code to your number " + this.customerPhoneNumber + ".",
              showConfirmButton: true,
              confirmButtonText: 'Ok',
              showCancelButton: false,
              backdrop: false,
              allowOutsideClick: false,
            }).then(async (result) => { });
          }
        }
      );
    }
    async sendCode() {
      await this.handlePhoneVerificationData(true);
    }

    continue() {  
      let code = this.vForm.controls['verificationCode'].value
      if (!code || code.length != 6) {
        return;
      }
      this.spinner.show();
      // check if phone has been verified
      if (this.isGuest) {
        let orderGuid = this.cartService.cart.orderId;
        this.auth.verifyGuestPhoneNumber(orderGuid, code).subscribe(
          async (data) => {
          this.spinner.hide();
          if (data) {
              if (data.isVerified) {
                this.location.replaceState(this.orderRoutingService.base +  '/checkout') // set location to checkout so for back() on payment form
                this.customerSession.navigateToPaymentPage();
              } else {
                  await Swal.fire({
                      title: "Oops! Your phone # is still not verified. Please verify your verification code and try again.",
                      showConfirmButton: true,
                      confirmButtonText: 'Ok',
                      showCancelButton: false,
                      backdrop: false,
                      allowOutsideClick: false,
                    }).then(async (result) => { });
              }
          }
        },
            (err) => {
                console.log(err);
                this.spinner.hide();
        });
      }
      else {
        this.auth.verifyCustomerPhoneNumber(code).subscribe(
          async (data) => {
          this.spinner.hide();
          if (data) {
              if (data.isVerified) {
                  this.orderRoutingService.goToCart();
              } else {
                  await Swal.fire({
                      title: "Oops! Your phone # is still not verified. Please verify your verification code and try again.",
                      showConfirmButton: true,
                      confirmButtonText: 'Ok',
                      showCancelButton: false,
                      backdrop: false,
                      allowOutsideClick: false,
                    }).then(async (result) => { });
              }
          }
        },
            (err) => {
                console.log(err);
                this.spinner.hide();
        });
      }
    }

    private async handlePhoneVerificationData(sendCode: boolean): Promise<boolean> {
      this.isLoading = true;
      this.spinner.show();
      var locationId = this.customerSession.locationId;
      this.locationPhoneNumber = sessionStorage.getItem("locationPhone");
      // check if phone has been verified
      if (this.isGuest) {
        let orderGuid = this.cartService.cart.orderId;
        return this.auth.guestPhoneVerification(orderGuid, locationId, sendCode).toPromise().then(
          (data) => {
          this.spinner.hide();
          this.isLoading = false;
          if (data && data.success) {
            this.requiresPhoneValidation = data.data.requiresPhoneVerification;
            this.lastValidationSentTime = data.data.latestVerificationTime;
            this.customerPhoneNumber = data.data.customerPhoneNumber;
            this.currentDaySendCount = data.data.currentDaySendCount;
            if (!this.lastValidationSentTime || this.currentDaySendCount == 0) {
              this.state = 1;
            } else {
              this.state = 2;
            }
          } else {
            this.orderRoutingService.goToCheckout();
          }
          return true;
        },
          (err) => {
              console.log(err);
              this.spinner.hide();
              this.isLoading = false;
              this.orderRoutingService.goToCheckout();
              return false;
        });
      }
      else {
        return this.auth.customerPhoneVerification(locationId, sendCode).toPromise().then(
          (data) => {
          this.spinner.hide();
          this.isLoading = false;
          if (data && data.success) {
            this.requiresPhoneValidation = data.data.requiresPhoneVerification;
            this.lastValidationSentTime = data.data.latestVerificationTime;
            this.customerPhoneNumber = data.data.customerPhoneNumber;
            this.currentDaySendCount = data.data.currentDaySendCount;
            if (!this.lastValidationSentTime || this.currentDaySendCount == 0) {
              this.state = 1;
            } else {
              this.state = 2;
            }
          } else {
            this.orderRoutingService.goBack();
          }
          return true;
        },
          (err) => {
              console.log(err);
              this.spinner.hide();
              this.isLoading = false;
              this.orderRoutingService.goBack();
              return false;
        });
      }
    }

    back() {
      this.orderRoutingService.goToCheckout();
    }  
}
