import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { ToastController, LoadingController, ActionSheetController } from '@ionic/angular';
import * as geolib from 'geolib';
import { NgxSpinnerService } from 'ngx-spinner';
import { fromEvent, Subscription, BehaviorSubject, of, Observable } from 'rxjs';
import { CartService } from '../cart.service';
import { CatalogService } from '../catalog.service';
import { CustomerSessionService } from '../customer-session.service';
import { DataService } from '../data.service';
import { CatalogLocation } from '../models/catalog-location';
import { OrderRoutingService } from '../order-routing.service';
import { tap, delay } from 'rxjs/operators';
import { CategoryTrackedService } from '../common/category-tracked.service';
import Swal from 'sweetalert2';
import { UtilService } from '../util.service';
import { AssetService } from '../merchant-assets/asset.service';
import { forEach } from '@angular/router/src/utils/collection';
import { TranslateService } from '@ngx-translate/core';

declare var google;

@Component({
  selector: 'location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  
  locations : CatalogLocation[];
  locations$ = new BehaviorSubject<CatalogLocation[]>([]) ;
  messageEvent : Subscription; 
  platform : string = "browser";
  showLocations = true;
  orders: any[] = [];
  limit: number;
  offset: number;
  recentlyVisitedStoreId: number;
  externalLink: string = null;

  @ViewChild('map') mapElement: ElementRef;
  isMap = false;
  map: any;
  infowindow: any;
  options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };
  closestmarker: any;
  closestplace: any;
  currentLat: any;
  currentLng: any;
  locationQuery: string;  
  locationSearchResults: CatalogLocation[] = [];
  showSearchResults: boolean = false;
  locationPermissionDenied: boolean = false;
  sortByOption: string = "near_by";
  previousIW;
  CURRENT_VIEW_ENTERED = "location";
  selectLocationText: string = "Don't see location? Shop online on our website";
  selectLocationUrl: string = null;
  isMobile: boolean = false;

  constructor(
    private loadingController: LoadingController, 
    private dataService: DataService, 
    private toastController: ToastController,  
    private customerSession: CustomerSessionService,  
    private cartService: CartService, 
    private catalogService: CatalogService, 
    private assetService: AssetService,
    public actionSheetController: ActionSheetController,
    private util: UtilService,
    private translate: TranslateService,
    private utilService: UtilService,
    private router: Router) { }

  ngOnInit() {
   
  }

  checkIfMobileDevice() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);   
    // Fix for iPadOS not being detected as mobile on safari (known bug)
     if (!this.isMobile) {
       let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
       if (isIpad) {
         this.isMobile = true;
       }
     } 
  }

  async ionViewWillEnter() {
    sessionStorage.removeItem("backToGuestOrderStatus");
    let guestTokenExpired = sessionStorage.getItem("guestTokenExpired");
    if (guestTokenExpired != null && guestTokenExpired != undefined) {
      if (guestTokenExpired == "1" ) { 
        sessionStorage.setItem("guestTokenExpired", "2");
        this.reloadCurrentRoute(); // need to reload when guest token has expired so prevent stuck loading message
      }
      else if (guestTokenExpired == "2") {
        sessionStorage.removeItem("guestTokenExpired");
        alert(this.translate.instant("Guest session has expired."));
      }
    }
    this.checkIfMobileDevice();
    sessionStorage.removeItem('shared_lat');
    sessionStorage.removeItem('shared_long');     
    if (this.isMobile) {
      parent.postMessage({action: 'getPlatform'}, sessionStorage.getItem("hostingapp")); 
      parent.postMessage({action: 'getApplePayAvailable'}, sessionStorage.getItem("hostingapp"));       
    }
    /* LOAD CUSTOM STYLE IF PROVIDED - <param - component name> */     
    if (sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) && sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) !== "") {
      this.assetService.getCustomCSS(sessionStorage.getItem(this.CURRENT_VIEW_ENTERED), this.CURRENT_VIEW_ENTERED);
    }   

    this.locationQuery = null;   
    await this.getLocations().then(() => {
      this.initMap();
    });

    if (sessionStorage.getItem("selectLocationText") && sessionStorage.getItem("selectLocationUrl") !== "") {
      this.selectLocationText = sessionStorage.getItem("selectLocationText");
      this.selectLocationUrl = sessionStorage.getItem("selectLocationUrl");
    } 
  
  }

  async ionViewDidEnter() {
    let hasToken: boolean = (sessionStorage.getItem('id_token')) ? true : false;
    this.limit = 10;
    this.offset = 0;   
    this.externalLink = sessionStorage.getItem('hyperLinkUrl');
    if (!this.externalLink || this.externalLink == "") {
      this.externalLink = null;
    }

   this.messageEvent = fromEvent(window, 'message').subscribe(e=> this.onMessage(e));
    
  }

  private onMessage(e){
    console.log('order app - got message from origin' + e.origin, e.data);  
    if(e.data.platform) {
      this.platform = e.data.platform
      console.log("platorm is " + this.platform);    
      sessionStorage.setItem('isMobileApp', 'true');
      console.log("IsMobileApp is " +  sessionStorage.getItem('isMobileApp')); 
      sessionStorage.removeItem('shared_lat');
      sessionStorage.removeItem('shared_long'); 
      parent.postMessage({action: 'getLocation'}, sessionStorage.getItem("hostingapp"));
    }
    if(e.data.location) {
      if(e.data.location.success && e.data.location.position) {    
        console.log('Received Location Co-Ordinates');  
        console.log(e.data.location);
        if ( e.data.location.position && e.data.location.position.coords && e.data.location.position.coords.latitude && e.data.location.position.coords.longitude) {
          console.log('Storing Received Location Co-Ordinates to Session Storage');
          sessionStorage.setItem('shared_lat', e.data.location.position.coords.latitude);
          sessionStorage.setItem('shared_long',  e.data.location.position.coords.longitude);
          this.initMap();
        }        
      }
      else if(e.data.location.success == false && e.data.location.error) {
        sessionStorage.removeItem('shared_lat');
        sessionStorage.removeItem('shared_long');        
      }
      else {
        
      }
    }   
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

  /* Location Search Implementation */
  changeView() {
    this.isMap = !this.isMap;
    if (this.isMap) {
      //console.log("Initializing Google Map");
      this.initMap();
    }
  }

  initMap() {    
    if (this.locations && this.locations.length > 0) {
      this.checkIfMobileDevice();
      var isMobileApp =  sessionStorage.getItem('isMobileApp');
      if (this.isMobile && isMobileApp) {
        console.log("LOADING ORDER APP VIA IFRAME - TRYING TO GET PERMISSION FROM SESSION");
        var shared_lat =  sessionStorage.getItem('shared_lat');
        var shared_long =  sessionStorage.getItem('shared_long');
        if (shared_lat != null && shared_long != null) {
          console.log("FOUND SHARED LAT AND LONGITUDE");
          this.loadFromSharedGeoCoOrdinates(shared_lat, shared_long)
        }
        else 
        {
          console.log("NOT FOUND SHARED LAT AND LONGITUDE");
          this.isMap = false;
          this.locationPermissionDenied = true;
          sessionStorage.removeItem('sortby');
          sessionStorage.removeItem('shared_lat');
          sessionStorage.removeItem('shared_long');
          this.sortByOption = null;
          var nestedSort = (prop1, prop2 = null, direction = 'asc') => (e1, e2) => {
            var a = prop2 ? e1[prop1][prop2] : e1[prop1],
                b = prop2 ? e2[prop1][prop2] : e2[prop1],
                sortOrder = direction === "asc" ? 1 : -1        
                a =  a.replace(/[^a-zA-Z 0-9]+/g,'').replace(/\s{2,}/g, ' ');   
                b =  b.replace(/[^a-zA-Z 0-9]+/g,'').replace(/\s{2,}/g, ' ');  
            return (a < b) ? -sortOrder : (a > b) ? sortOrder : 0;
          } 
          this.locations.sort(nestedSort('businessDetails', "name"));    
        }
      }
      else 
      {        
        navigator.geolocation.getCurrentPosition(async (location) => {
          // this.isMap = true;
           console.log(location);
           let unit = this.getUnit(this.locations);
           this.currentLat = location.coords.latitude;
           this.currentLng = location.coords.longitude;
           this.locationPermissionDenied = false;
           this.locations.map(x => {
             x.distance = this.distance(this.currentLat, this.currentLng, x.businessDetails.latitude, x.businessDetails.longitude, unit).toFixed(2);
             x.distanceUnit = unit;
           });     
           let hasToken: boolean = (sessionStorage.getItem('id_token')) ? true : false;     
           if(hasToken && sessionStorage.getItem('sortby') == 'last_ordered') {
             await this.getOrderHistory(10, 0); 
           }
           else {
             this.sortByOption = "near_by";
             sessionStorage.setItem('sortby', 'near_by');
             this.sortByDistance(); 
           }
           
           if(!this.mapElement && this.isMap) {
             await this.ionViewWillEnter();
           }   
           
           if (this.isMap) {
             this.map = new google.maps.Map(this.mapElement.nativeElement, {
               center: { lat: location.coords.latitude, lng: location.coords.longitude },
               zoom: 15,
               zoomControl: false,  
               streetViewControl: false,
               fullscreenControl: false,
               mapTypeControl: false
             });
       
             this.map.set('styles', [
               {
                   "featureType": "landscape.natural",
                   "elementType": "all",
                   "stylers": [
                       {
                           "visibility": "on"
                       }
                   ]
               },
               {
                   "featureType": "landscape.natural",
                   "elementType": "labels",
                   "stylers": [
                       {
                           "visibility": "off"
                       }
                   ]
               },
               {
                   "featureType": "poi",
                   "elementType": "labels",
                   "stylers": [
                       {
                           "visibility": "simplified"
                       }
                   ]
               },
               {
                   "featureType": "poi",
                   "elementType": "labels.text",
                   "stylers": [
                       {
                           "visibility": "simplified"
                       }
                   ]
               },
               {
                   "featureType": "poi",
                   "elementType": "labels.icon",
                   "stylers": [
                       {
                           "visibility": "off"
                       }
                   ]
               },
               {
                   "featureType": "poi.park",
                   "elementType": "all",
                   "stylers": [
                       {
                           "visibility": "on"
                       }
                   ]
               },
               {
                   "featureType": "poi.park",
                   "elementType": "labels",
                   "stylers": [
                       {
                           "visibility": "off"
                       }
                   ]
               },
               {
                   "featureType": "road",
                   "elementType": "all",
                   "stylers": [
                       {
                           "visibility": "simplified"
                       }
                   ]
               },
               {
                   "featureType": "road",
                   "elementType": "labels",
                   "stylers": [
                       {
                           "visibility": "on"
                       }
                   ]
               },
               {
                   "featureType": "road",
                   "elementType": "labels.text.fill",
                   "stylers": [
                       {
                           "visibility": "on"
                       }
                   ]
               },
               {
                   "featureType": "road",
                   "elementType": "labels.text.stroke",
                   "stylers": [
                       {
                           "visibility": "on"
                       }
                   ]
               },
               {
                   "featureType": "road",
                   "elementType": "labels.icon",
                   "stylers": [
                       {
                           "visibility": "off"
                       }
                   ]
               },
               {
                   "featureType": "transit",
                   "elementType": "all",
                   "stylers": [
                       {
                           "visibility": "simplified"
                       }
                   ]
               }
             ]);
       
             this.infowindow = new google.maps.InfoWindow();
             for (var i = 0; i < this.locations.length; i++) {
               let place = {
                 name: this.locations[i].businessDetails.name,
                 address: this.locations[i].businessDetails.address,
                 country: this.locations[i].businessDetails.country,
                 email: this.locations[i].businessDetails.email,
                 state: this.locations[i].businessDetails.state,
                 distanceUnit: this.locations[i].distanceUnit,
                 distance: this.locations[i].distance,
                 location: {
                     lat: this.locations[i].businessDetails.latitude,
                     lng: this.locations[i].businessDetails.longitude
                 }         
               }
       
               let isClosest = false;
               if (i == 0) {
                 isClosest = true;
               }
               this.createMarker(place, isClosest);       
             }
       
             this.infowindow = new google.maps.InfoWindow(); 
             this.map.setCenter(this.closestmarker.getPosition());     
             this.infowindow.setContent(
               `
                 <div class="font-weight-bold color-primary">${this.closestplace.name}</div>
                 <div class="font-weight-bold">${this.closestplace.address}</div>
                 <div class="font-weight-bold color-primary">
                 ${this.distance(location.coords.latitude, location.coords.longitude, this.closestplace.location.lat, this.closestplace.location.lng, this.closestplace.distanceUnit).toFixed(2)}KM
                 </div>
               `
             );      
             this.previousIW =  this.infowindow; 
             this.infowindow.open(this.map, this.closestmarker);
           }        
     
         }, (error) => {
           console.log(error);
           this.isMap = false;
           this.locationPermissionDenied = true;
           this.handleLocationError(error);
           sessionStorage.removeItem('sortby');
           this.sortByOption = null;  
           var nestedSort = (prop1, prop2 = null, direction = 'asc') => (e1, e2) => {
            var a = prop2 ? e1[prop1][prop2] : e1[prop1],
                b = prop2 ? e2[prop1][prop2] : e2[prop1],
                sortOrder = direction === "asc" ? 1 : -1        
                a =  a.replace(/[^a-zA-Z 0-9]+/g,'').replace(/\s{2,}/g, ' ');   
                b =  b.replace(/[^a-zA-Z 0-9]+/g,'').replace(/\s{2,}/g, ' ');  
            return (a < b) ? -sortOrder : (a > b) ? sortOrder : 0;
          } 
          this.locations.sort(nestedSort('businessDetails', "name"));         
         }, this.options); 
      }     
    }   
    
    this.locations$.next(this.locations);
  }



  async loadFromSharedGeoCoOrdinates(latitude, longitude) 
  {
    console.log(location);
    let unit = this.getUnit(this.locations);
    this.currentLat = Number(latitude);
    this.currentLng = Number(longitude);
    this.locationPermissionDenied = false;
    this.locations.map(x => {
      x.distance = this.distance(this.currentLat, this.currentLng, x.businessDetails.latitude, x.businessDetails.longitude, unit).toFixed(2);
      x.distanceUnit = unit;
    });     
    let hasToken: boolean = (sessionStorage.getItem('id_token')) ? true : false;     
    if(hasToken && sessionStorage.getItem('sortby') == 'last_ordered') {
      await this.getOrderHistory(10, 0); 
    }
    else {
      this.sortByOption = "near_by";
      sessionStorage.setItem('sortby', 'near_by');
      this.sortByDistance(); 
    }
    
    if(!this.mapElement && this.isMap) {
      await this.ionViewWillEnter();
    }   
    
    if (this.isMap) {
             this.map = new google.maps.Map(this.mapElement.nativeElement, {
               center: { lat: latitude, lng: longitude },
               zoom: 15,
               zoomControl: false,  
               streetViewControl: false,
               fullscreenControl: false,
               mapTypeControl: false
             });
       
             this.map.set('styles', [
               {
                   "featureType": "landscape.natural",
                   "elementType": "all",
                   "stylers": [
                       {
                           "visibility": "on"
                       }
                   ]
               },
               {
                   "featureType": "landscape.natural",
                   "elementType": "labels",
                   "stylers": [
                       {
                           "visibility": "off"
                       }
                   ]
               },
               {
                   "featureType": "poi",
                   "elementType": "labels",
                   "stylers": [
                       {
                           "visibility": "simplified"
                       }
                   ]
               },
               {
                   "featureType": "poi",
                   "elementType": "labels.text",
                   "stylers": [
                       {
                           "visibility": "simplified"
                       }
                   ]
               },
               {
                   "featureType": "poi",
                   "elementType": "labels.icon",
                   "stylers": [
                       {
                           "visibility": "off"
                       }
                   ]
               },
               {
                   "featureType": "poi.park",
                   "elementType": "all",
                   "stylers": [
                       {
                           "visibility": "on"
                       }
                   ]
               },
               {
                   "featureType": "poi.park",
                   "elementType": "labels",
                   "stylers": [
                       {
                           "visibility": "off"
                       }
                   ]
               },
               {
                   "featureType": "road",
                   "elementType": "all",
                   "stylers": [
                       {
                           "visibility": "simplified"
                       }
                   ]
               },
               {
                   "featureType": "road",
                   "elementType": "labels",
                   "stylers": [
                       {
                           "visibility": "on"
                       }
                   ]
               },
               {
                   "featureType": "road",
                   "elementType": "labels.text.fill",
                   "stylers": [
                       {
                           "visibility": "on"
                       }
                   ]
               },
               {
                   "featureType": "road",
                   "elementType": "labels.text.stroke",
                   "stylers": [
                       {
                           "visibility": "on"
                       }
                   ]
               },
               {
                   "featureType": "road",
                   "elementType": "labels.icon",
                   "stylers": [
                       {
                           "visibility": "off"
                       }
                   ]
               },
               {
                   "featureType": "transit",
                   "elementType": "all",
                   "stylers": [
                       {
                           "visibility": "simplified"
                       }
                   ]
               }
             ]);
       
             this.infowindow = new google.maps.InfoWindow();
             for (var i = 0; i < this.locations.length; i++) {
               let place = {
                 name: this.locations[i].businessDetails.name,
                 address: this.locations[i].businessDetails.address,
                 country: this.locations[i].businessDetails.country,
                 email: this.locations[i].businessDetails.email,
                 state: this.locations[i].businessDetails.state,
                 distanceUnit: this.locations[i].distanceUnit,
                 distance: this.locations[i].distance,
                 location: {
                     lat: this.locations[i].businessDetails.latitude,
                     lng: this.locations[i].businessDetails.longitude
                 }         
               }
       
               let isClosest = false;
               if (i == 0) {
                 isClosest = true;
               }
               this.createMarker(place, isClosest);       
             }
       
             this.infowindow = new google.maps.InfoWindow(); 
             this.map.setCenter(this.closestmarker.getPosition());     
             this.infowindow.setContent(
               `
                 <div class="font-weight-bold color-primary">${this.closestplace.name}</div>
                 <div class="font-weight-bold">${this.closestplace.address}</div>
                 <div class="font-weight-bold color-primary">
                 ${this.distance(latitude, longitude, this.closestplace.location.lat, this.closestplace.location.lng, this.closestplace.distanceUnit).toFixed(2)}KM
                 </div>
               `
             );      
             this.previousIW =  this.infowindow; 
             this.infowindow.open(this.map, this.closestmarker);
    }        
  }

  createMarker(place, isClosest) {
    var placeLoc = place.location;
    var marker = new google.maps.Marker({
      map: this.map,
      position: placeLoc,
      icon: {
        url: "../../assets/svg/google-pin.svg"       
      }          
    });    

    google.maps.event.addListener(marker, 'click', async () => {   
      if (this.previousIW) {
        await this.previousIW.close();
      }
      this.infowindow = new google.maps.InfoWindow();  
      this.infowindow.setContent(
        `
          <div class="font-weight-bold color-primary">${place.name}</div>
          <div class="font-weight-bold">${place.address}</div>
          <div class="font-weight-bold color-primary">
          ${this.distance(this.currentLat, this.currentLng, place.location.lat, place.location.lng, "K").toFixed(2)}KM
          </div>
        `
      );        
      this.previousIW =  this.infowindow; 
      this.infowindow.open(this.map, marker);
    });

    if (isClosest) {
      this.closestmarker = marker;
      this.closestplace = place;
    }
  }

  async getLocations() 
  {
    const loading = await this.loadingController.create({
        message: this.translate.instant('Loading Locations...')
    });
    await loading.present();
    await this.dataService.getCatalogLocations().toPromise().then((data) => {
      if(data) 
      {
        this.locations = data; 
      }    
    }, (error) => {
      console.log(error);
    }) 
    loading.dismiss();
  }

  distance(lat1, lon1, lat2, lon2, unit) {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      let radlat1 = (Math.PI * lat1) / 180;
      let radlat2 = (Math.PI * lat2) / 180;
      let theta = lon1 - lon2;
      let radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }

  sortByDistance() {
    if (this.locationPermissionDenied) {
        this.toast(this.translate.instant("Permission to access location was denied on your device."));
    }
    if (!this.showSearchResults) {
      if (this.currentLat !== 0 && this.currentLng !== 0) {     
        var sortedStoreList: CatalogLocation[] = this.locations.sort((n1,n2) => {
          if (Number(n1.distance) > Number(n2.distance)) {
            return 1;
          }
      
          if (Number(n1.distance) < Number(n2.distance)) {
              return -1;
          }    
          return 0
        });
  
        this.locations = sortedStoreList;
        
      } 
    } else {
      if (this.currentLat !== 0 && this.currentLng !== 0) {     
        var sortedStoreList: CatalogLocation[] = this.locationSearchResults.sort((n1,n2) => {
          if (Number(n1.distance) > Number(n2.distance)) {
            return 1;
          }
      
          if (Number(n1.distance) < Number(n2.distance)) {
              return -1;
          }    
          return 0
        });
  
        this.locationSearchResults = sortedStoreList;
        console.log(this.locationSearchResults)
        
      } 
    }
    
  }

  searchLocation() {
    this.showSearchResults = true;
    this.locationSearchResults = [];
    this.locationSearchResults = this.locations.filter( (x) => (x.businessDetails.address.toLowerCase().concat(" ", x.businessDetails.name.toLowerCase())).includes(this.locationQuery.trim().toLowerCase()));  
    console.log(this.locationSearchResults)
    if (this.locationQuery && this.locationQuery.length > 0) {
      let ele = document.getElementById("btnSortLocation");
      ele.style.display = 'none';
      return;
    }
    else {
      let ele = document.getElementById("btnSortLocation");
      ele.style.display = '';
    }

  }

  private async toast(message:string){
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  private handleLocationError(error:any){
    switch (error.code) {
      case 1:
          this.toast(this.translate.instant('Location permission denied'));
          console.error('error: Permission Denied : ' + error.message);
          break;
      case 2:
          this.toast(this.translate.instant('Position unavailable'));
          console.error('error: Position Unavailable message:' + error.message);
          break;
      case 3:
          this.toast(this.translate.instant('Error retrieving location'));
          console.error('error: Timeout :' + error.message);
          break;
    };
  }

  async selectLocation(selectedLocation:CatalogLocation) 
  {
    await this.selectLocationPromise(selectedLocation).then(async (result) => {
       if(result) 
       {
          this.customerSession.locationId = selectedLocation.merchantLocationId;    
          this.cartService.askedForOrderType = false;
          this.cartService.refreshOrderAheadSetting = true;

          await sessionStorage.removeItem('allow_multiple_modifier_quantity');
          await this.catalogService.isAllowMultipleModifierQuantity(selectedLocation.merchantLocationId);    
           
          this.cartService.loadOrderAheadSettingsV2();
          this.cartService.isCorrectOrderLocation = false;
       }
    });
  }

  selectLocationPromise(selectedLocation:CatalogLocation) : Promise<any> 
  {
    return new Promise<any>(async (resolve, reject) => {
        if(this.customerSession.locationId && this.customerSession.locationId != selectedLocation.merchantLocationId) 
        {
          if (this.cartService.getCartCount() > 0) {
            await Swal.fire({
              title: this.translate.instant("Starting a new order will empty your cart. Are you sure you want to start a new order?"),
              showConfirmButton: true,
              confirmButtonText: this.translate.instant('YES'),
              showCancelButton: true,
              cancelButtonText: this.translate.instant('NO'),
              backdrop: false,
              allowOutsideClick: false,
            }).then(async (result) => {
              if (result.dismiss) {
                resolve(false);              
              }
              else 
              {
                this.cartService.clearCart();
              } 
            });
          } else {
            this.cartService.clearCart();
          }
        }
        if (this.customerSession.locationId && this.customerSession.locationId != selectedLocation.merchantLocationId) 
        {
          this.catalogService.setLastSelectedCatalogId('');
          this.cartService.clearFutureOrderSettingAndData();
          this.catalogService.defaultlastSelectedCategoryId();
          this.customerSession.removePaymentSetupInfo();
        }        
        Swal.close();
        resolve(true);
    });
  }
 
  private getUnit(locations:CatalogLocation[]){
    let unit = "K"
    let useMiles = locations.some((location) => {
      return location.businessDetails.country && location.businessDetails.country.toUpperCase() === "USA";
    });
    if(useMiles)
      unit = "N";
    return unit;
  }

  async sortBy()  {   
    this.sortByOption = sessionStorage.getItem('sortby');
    var nearest_store_lbl = this.translate.instant("Nearest Store");
    var last_ordered_lbl = this.translate.instant("Last Ordered");
    var nearby_store = nearest_store_lbl + "  ✔︎";
    var last_ordered = last_ordered_lbl + "  ✔︎";
    const actionSheet = await this.actionSheetController.create({
      cssClass: "action-sheet-sort-by",
      header: this.translate.instant("Sort Location Results By"),
      buttons: [
        {
          text: this.sortByOption == 'near_by' ? nearby_store : nearest_store_lbl,
          cssClass: this.sortByOption == 'near_by' ? 'action-option-selected-active' : 'action-option-selected',
          role: 'button',        
          handler: () => {
            sessionStorage.setItem('sortby', 'near_by');
            this.initMap();
          },
        },
        {
          text: this.sortByOption == 'last_ordered' ? last_ordered : last_ordered_lbl,
          cssClass: this.sortByOption == 'last_ordered' ? 'action-option-selected-active' : 'action-option-selected',
          role: 'button',
          handler: async () => {
            sessionStorage.setItem('sortby', 'last_ordered');
            await this.getOrderHistory(10, 0);                       
          },
        },
      ]
    });
    await actionSheet.present();
  }

  private sortLocationsByLastOrdered(recentlyVisitedStoreId) 
  {
    this.locations.map((v, k) => {
      if(v.merchantLocationId ===  recentlyVisitedStoreId) 
      {
        v["recentlyVisited"] = true;                      
      }
      else
      {
        v["recentlyVisited"]= false;
      }     
    })

    if(this.locations) 
    {
      var sortedStoreList: CatalogLocation[] = this.locations.sort((x, y) => {  
        return (x["recentlyVisited"] === y["recentlyVisited"]) ? 0 : x? -1 : 1;            
      });
      let recentlyVisitedIndex = this.locations.findIndex(x => x.merchantLocationId == recentlyVisitedStoreId);
      this.arraymove(this.locations, recentlyVisitedIndex, 0);     
      localStorage.setItem("recentlyVisitedStore", recentlyVisitedStoreId);
      console.log(`Rectly Visited Store => ${localStorage.getItem('recentlyVisitedStore')}`);
      this.locations$.next(this.locations);
      this.showSearchResults = false;
      this.locationQuery = null;
      console.log(this.locations);
    } 
  }

  async getOrderHistory(limit: number, offset: number) 
  {
    const loading = await this.loadingController.create({
      message: this.translate.instant('Loading Last Ordered Location...')
    });

    await loading.present();

    await this.dataService.getOrdersHistory(limit, offset).toPromise().then(data => {
      if(data) 
      {
        this.orders = data.orders;

        if (this.orders && this.orders.length > 0) {
          this.orders.sort((a, b) => {
            return a.lastStatusUpdatedOn - b.lastStatusUpdatedOn;
          });
  
          if(this.orders[0] && this.orders[0].orderLocationId !== undefined) 
          {
            this.recentlyVisitedStoreId = this.orders[0].orderLocationId;
            this.sortLocationsByLastOrdered(this.recentlyVisitedStoreId);
          }
        }    
        else {
          this.toast(this.translate.instant('No recent orders found.')).then(() => {
            sessionStorage.setItem('sortby', 'near_by');
            this.initMap();
          });
        }    
  
      }    
    }, (error) => {
      console.log(error);
    }) 
    loading.dismiss();
  }

  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  getLocationsSubject() {
    return this.locations$;
  }

  visitExternalLink() {
    if(this.externalLink) 
    {
      if (this.selectLocationUrl && this.selectLocationUrl != "" ) {
        this.externalLink = this.selectLocationUrl;
      }
      let url = this.util.addhttp(this.externalLink);   

      console.log(`Opening external link with system defult browser => ${url}`);
      if (this.platform === "ios" || this.platform=="android") {
        this.util.openWithSystemBrowser(url);
      } else {            
        window.open(url, '_blank');
      }      
    }    
  }
  
}
