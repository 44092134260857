import { Injectable } from '@angular/core';
import { AssetService } from './merchant-assets/asset.service';
import { Assets } from './merchant-assets/assets.model';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {
  private merchantAssets: Assets
  constructor(private util: UtilService) { }

  loadFaceBookPixel() 
  {
    var ma = this.util.getMerchantAssets();
    ma.then((assets) => {
      this.merchantAssets = assets
    }).then(() => {
      if(this.facebookPixelAppIdAvailable) 
      {    
        var facebookPixelAppId = this.merchantAssets.facebookPixelAppId;      
        if(facebookPixelAppId !== undefined &&  facebookPixelAppId !== null) 
        {
          if(!(window as any).fbq)
          {
            (function (f: any, b, e, v, n, t, s) {
              if (f.fbq) return; n = f.fbq = function () {
                  n.callMethod ?
                      n.callMethod.apply(n, arguments) : n.queue.push(arguments)
              }; if (!f._fbq) f._fbq = n;
              n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
              t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
            (window as any).fbq('init', `${facebookPixelAppId}`);
          }            
        }
        else 
        {
          console.log("FB Pixel Tracking not enabled");
        }       
      }  
    });   
  }

  loadFaceBookPixelDomainVerificationContent() {
    var ma = this.util.getMerchantAssets();
    ma.then((assets) => {
      this.merchantAssets = assets
    }).then(() => {
      if(this.facebookPixelDomainVerificationAvailable) {
        var facebookPixelDomainVerificationContent = this.merchantAssets.facebookPixelDomainVerificationContent;
        this.util.set_FB_Pixel_Verification_Meta(facebookPixelDomainVerificationContent);
      }
      else 
      {
        console.log("FB Pixel Domain Verification not available");
      }  
    });
  }

  get facebookPixelAppIdAvailable():boolean{
    if(sessionStorage.getItem('facebookPixelAppId')) 
    {
      return true;
    }
    else 
    {
      return false;
    }
  }

  get facebookPixelDomainVerificationAvailable():boolean{
    if(sessionStorage.getItem('facebookPixelDomainVerificationContent')) 
    {
      return true;
    }
    else 
    {
      return false;
    }
  }

  sendEvent(action, value) 
  {
    let currencyCode = sessionStorage.getItem("currencyCode");

    if(this.facebookPixelAppIdAvailable)  
    {
      try {
        if((window as any).fbq !== undefined && (window as any).fbq !== null) 
        {
          (window as any).fbq('track', action, {value: value, currency: currencyCode});
        } 
        
      } catch (error) {
        console.log("FB Pixel Error => event not tracked for : " + action)
        console.log(error)
      }            
    }
  }

  sendCustomEvent(action, value) 
  {
    let currencyCode = sessionStorage.getItem("currencyCode");

    if(this.facebookPixelAppIdAvailable)  
    {
      try {
        if((window as any).fbq !== undefined && (window as any).fbq !== null) 
        {
          (window as any).fbq('trackCustom', action, {value: value, currency: currencyCode});
        } 
        
      } catch (error) {
        console.log("FB Pixel Error => event not tracked for : " + action)
        console.log(error)
      }            
    }
  }

  sendLeadEvent(action, location) 
  {

    if(this.facebookPixelAppIdAvailable)  
    {
      try {
        if((window as any).fbq !== undefined && (window as any).fbq !== null) 
        {          
          (window as any).fbq('trackCustom', action, {storeLocation: location});
        } 
        
      } catch (error) {
        console.log("FB Pixel Error => event not tracked for : " + action)
        console.log(error)
      }            
    }
  }

}
