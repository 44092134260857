import { Component } from '@angular/core';
import { CustomerSessionService } from "../../customer-session.service";
import { DataService } from "../../data.service";
import { PaymentService } from '../../payment.service';
import { MembershipRoutingService } from '../../membership-routing.service';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { getCurrencySymbol, Location } from '@angular/common';
import { MembershipPaymentService } from 'src/app/membership-payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { timer } from 'rxjs';

@Component({
  selector: 'membership-details',
  templateUrl: './membership-details.component.html',
  styleUrls: ['./membership-details.component.scss']
})
export class MembershipDetailsComponent {
  locationId: number = 0;
  imageWidth: number = 0;

  membership: any;
  membershipId: number;
  membershipDateLabel: string ="Renews";
  currencySymbol: string = "$";
  hasCard: boolean = false;
  isLoading: boolean = true;
  statusColor: any;
  buttonText: string = "Purchase Membership";
  buttonClass: string = "btn-primary";

  cardType: string;
  cardLast4: string;
  cardImgSrc: string;

  landingPage: boolean = false;

  constructor(
    private membershipRouting: MembershipRoutingService,
    private dataService: DataService,
    private customerSession: CustomerSessionService,
    private activatedRoute: ActivatedRoute,
    private alertController : AlertController,
    private membershipPaymentService: MembershipPaymentService,
    private spinner: NgxSpinnerService,
  ) { }

  async ionViewWillEnter() {
    this.landingPage = this.membershipRouting.detailsLandingPage;
    let currencyCode = sessionStorage.getItem("currencyCode");
    this.currencySymbol = getCurrencySymbol(currencyCode, "narrow");
    if (this.currencySymbol == currencyCode) {
        this.currencySymbol = "$";
    }

    this.membershipId = +this.activatedRoute.snapshot.paramMap.get("membershipId");
    this.spinner.show();
    await this.getMembership()
      .then(async () => {
        if (this.membership.isCustomerMembership) {
          await this.applyMembershipTextAndStyling();
          
          if (this.membership.customerMembership.status == "SUSPENDED") {
            await this.getStoredCard();
          }
        }
        this.spinner.hide();
        this.isLoading = false;
        console.log(this.membership);
        timer(0).subscribe(x => {
          if (this.membership.longDescriptionHTML) {
            $('.membership-longdescription').html(this.membership.longDescriptionHTML);
          } 
        });
        
      });
  }

  ionViewDidEnter() {
      if (this.membershipRouting.updatePaymentRequired && this.membershipRouting.membershipToUpdate > 0) {
        sessionStorage.removeItem('updatePaymentRequired');
        sessionStorage.removeItem('membershipToUpdate');
      } 

      if (this.membershipRouting.redirectToDetails) {
        sessionStorage.removeItem("redirectToDetails");

        var message: string = "";
        var membershipError: any = JSON.parse(this.membershipRouting.membershipError);
        sessionStorage.removeItem("membershipError");

        if (membershipError.type == "BADREQUEST") {
          message = "The selected membership has already been purchased.";
        } else {
            message = membershipError.message;
        }
        this.alertMessage(message);
      }
  }

  async getMembership(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.dataService.getMembership(this.membershipId)
      .subscribe(
        (data) => {
          this.membership = data;
          resolve(true);
        },
        (err) => {
          this.spinner.hide();
          this.isLoading = false;
          this.membershipRouting.membershipError = JSON.stringify(err.error);
          this.membershipRouting.redirectToList = true;
          this.membershipRouting.goToMembershipList();
          resolve(false);
        }
      )}
    )
  }

  async applyMembershipTextAndStyling() {
    return new Promise<any>(async (resolve, reject) => {
      // type of date to display
      if (this.membership.customerMembership.status == "ACTIVE" && this.membership.customerMembership.nextRenewalDate != null) {
        this.membership.hasRenewalDate = true;
      } else if (this.membership.customerMembership.status == "CANCELLED" && this.membership.customerMembership.validUntilDate != null) {
        this.membership.hasValidUntilDate = true;
      } else {
        this.membership.hasRenewalDate = false;
        this.membership.hasValidUntilDate = false;
      }

      // text and styling
      switch (this.membership.customerMembership.status) {
        case "ACTIVE":
          this.buttonText = "Cancel Membership";
          this.buttonClass = "btn-cancel";
        case "AUTOMATIC":
          this.statusColor = {"color": "#22C55E"};
          break;
        case "CANCELLED":
          this.statusColor = {"color": "#F59E0B"};
          break;
        case "SUSPENDED":
          this.buttonText = "Update Payment Method";
          this.buttonClass = "btn-update-payment";
          this.statusColor = {"color": "#EF4444"};
          break;
      }          
      resolve(true);
    })
  }

  getStoredCard() {
    return new Promise<any>(async (resolve, reject) => {
      this.dataService.getCardByIntegrationId(this.membership.paymentIntegrationId).subscribe( 
        (result) => {
          console.log("data received from getCardByIntegrationId", result);
          if (result.isSuccess) {
            if (result.data.cardIsPopulated) {
              this.cardType = result.data.cardType;
              this.cardLast4 = result.data.cardLast4;
              this.hasCard = true;
              let imgFile = "";
              if (this.cardType.toUpperCase() === "VISA") imgFile = "visa.png";
              else if (this.cardType.toUpperCase() === "MASTERCARD")
                imgFile = "mastercard.png";
              else if (this.cardType.toUpperCase() === "AMERICAN_EXPRESS")
                imgFile = "american-express.png";
              else imgFile = "payment-card.png";
  
              this.cardImgSrc = `/assets/imgs/${imgFile}`;
            } else {
              this.hasCard = false;
            }
            resolve(true);
          }
        },
        (err) => {
          console.log("get card -- error is: ", err);
          resolve(true);
        }
      );
    })
  }

  buttonFunction() {
    if (this.membership.isCustomerMembership) {
      switch (this.membership.customerMembership.status) {
        case "ACTIVE":
          this.goToCancellation();
          break;
        case "SUSPENDED":
          this.updatePayment();
          break;
      }
    } else {
      this.goToCheckout();
    }
  }

  async goToCheckout() {
    this.membershipRouting.membershipSelectedForPurchase = this.membership.membershipId;
    this.membership.currencySymbol = this.currencySymbol;
    this.membershipRouting.setMembership(this.membership);
    this.membershipRouting.goToCheckout();
  }

  async goToCancellation() {
    this.membershipRouting.setMembership(this.membership);
    this.membershipRouting.goToCancellation(this.membership.membershipId);
  }

  async updatePayment() {
    console.log("UPDATE PAYMENT")
    this.membershipPaymentService.membership = this.membership;
    this.membershipPaymentService.paymentDetails.Amount = this.membership.total;
    this.membershipPaymentService.paymentDetails.PayWithSavedCreditCard = false;
    this.customerSession.navigateToPaymentPage(this.membership.membershipId);
  }

  async alertMessage(message) {
    const alert = await this.alertController.create({
        header: 'Oops',
        message: message,
        buttons: ['OK']
    });
    await alert.present();
   }

  back() {
    this.membershipRouting.goToMembershipList();
  }
}
