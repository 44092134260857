import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merchant-init',
  templateUrl: './merchant-init.component.html',
  styleUrls: ['./merchant-init.component.scss']
})
export class MerchantInitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
