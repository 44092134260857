import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { DataService } from '../data.service';
import { CustomerSessionService } from '../customer-session.service';
import { Location } from '@angular/common';
import { LoadingController } from '@ionic/angular';
import { GoogleAnalyticService } from '../google-analytic.service';
import { OrderRoutingService } from '../order-routing.service';
import { CartService } from '../cart.service';
import { PaymentService } from '../payment.service';
import { MembershipPaymentService } from '../membership-payment.service';
import { MembershipRoutingService } from '../membership-routing.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'payment-add',
    templateUrl: './payment-add.component.html',
    styleUrls: ['./payment-add.component.scss']
})
export class PaymentAddComponent implements OnInit {
    elements: Elements;
    card: StripeElement;    
    isMobile = sessionStorage.getItem('is_mobile');

    // optional parameters
    elementsOptions: ElementsOptions = {
        locale: 'auto'
    };
    stripe: FormGroup;
    buttonText: string;   
    membershipModule: boolean = false;
    showPaymentConfirmation: boolean = false;
    isGuest: boolean = false;

    constructor(private router: Router,
        public cartService: CartService,
        private fb: FormBuilder,
        private stripeService: StripeService,
        private dataService: DataService,
        private customerSession: CustomerSessionService,
        private location: Location,
        private loadingController: LoadingController,
        private ga: GoogleAnalyticService,
        private orderRouting: OrderRoutingService,
        private paymentService: PaymentService,
        private membershipPaymentService: MembershipPaymentService,
        private membershipRouting: MembershipRoutingService,
        private translate: TranslateService) { }

    async ngOnInit() {        
        this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;
        this.isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;
        let paymentSetup = await this.customerSession.getPaymentSetupInfo();
        let stripePublicKey = '';

        if (paymentSetup) {
            stripePublicKey = paymentSetup.stripePublicKey;
        }

        if (stripePublicKey == '') {
            alert(this.translate.instant('Sorry! Looks like we are experiencing some difficulty with our payment form. Please try again later.'));
            this.back();
        }

        //set button text
        if (this.membershipModule) {
            this.buttonText = this.membershipPaymentService.getPaymentBtnText();
        } else {
            this.buttonText = this.paymentService.getActionText();
        }

        this.stripeService.setKey(stripePublicKey);
        this.stripe = this.fb.group({
            name: ['', [Validators.required]],
            saveCreditCard: [this.membershipModule && !this.isGuest]
        });
        this.stripeService.elements(this.elementsOptions)
            .subscribe(elements => {
                this.elements = elements;
                // Only mount the element the first time
                if (!this.card) {
                    this.card = this.elements.create('card', {
                        style: {
                            base: {
                                iconColor: '#666EE8',
                                color: '#31325F',
                                lineHeight: '40px',
                                fontWeight: 300,
                                fontSize: '18px',
                                '::placeholder': {
                                    color: '#CFD7E0'
                                }
                            }
                        },
                        hidePostalCode: true
                    });
                    this.card.mount('#card-element');
                }
            });
    }

    async processPayment() {
        const name = this.stripe.get('name').value;
        if (!name.replace(/\s/g, '').length) {
            alert(this.translate.instant("Card Holder Name is Required"));
            return;
        }

        const saveCreditCard = this.stripe.get('saveCreditCard').value;
        console.log("saveCreditCard is: " + saveCreditCard);

        this.stripeService
            .createToken(this.card, { name })
            .subscribe(result => {
                if (result.token) {
                    console.log(result.token);
                    if(this.membershipModule) {
                        this.membershipPaymentService.paymentDetails.Token = result.token.id;
                        this.membershipPaymentService.paymentDetails.CardHolderName = name;
                        this.processMembershipPayment();
                    } else {
                        this.paymentService.request.token = result.token.id;
                        this.paymentService.request.cardHolderName = name;
                        this.paymentService.request.saveCreditCard = saveCreditCard;
                        this.paymentService.request.payWithSavedCreditCard = false;
                        this.paymentService.processPayment();
                    }
                } else if (result.error) {
                    // loading.dismiss();
                    // Error creating the token
                    console.log(result.error.message);
                    alert(result.error.message);
                }
            });
    }

    async processMembershipPayment() {
        await this.membershipPaymentService.processPayment()
            .then((result) => {
                if(result) {
                    this.showPaymentConfirmation = true;
                }
            })
            .catch(error => {
                console.log(error);
            }); 
    }

    continue() {
        this.membershipRouting.goToMembershipList();
    }

    back() {
        this.location.back();
    }
}
