import { Component, ViewChild, ChangeDetectorRef, AfterViewInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, PopoverController } from '@ionic/angular';
import { CartService } from '../cart.service';
import { CustomerSessionService } from '../customer-session.service';
import { DataService } from '../data.service';
import { PreparedOrder } from '../models/prepared-order.model';
import { GoogleAnalyticService } from '../google-analytic.service';
import { OrderRoutingService } from '../order-routing.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertPopupComponent } from '../common/alert-popup/alert-popup.component';
import { ErrorHandlerService } from '../error-handler.service';
import { DecimalPipe } from '@angular/common';
import { CustomerDetails } from '../models/customer-details.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { PaymentMode } from '../models/payment-mode';
import { FacebookPixelService } from '../facebook-pixel.service';
import { GoogleTagManagerService } from '../google-tag-manager.service';
import { UtilService } from '../util.service';
import { RewardsModalComponent } from '../rewards-modal/rewards-modal.component';
import { CatalogService } from '../catalog.service';
import { ModalItemEditComponent } from '../modals/modal-item-edit/modal-item-edit.component';
import { CartItem } from './cart.model';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {
  
  order: PreparedOrder;
  showRedeemables : boolean = false;
  availableRedeemablesCount: number = 0;
  cartHasItems : boolean = false;
  specialInstructions: string;
  disclaimer: string;
  autoShowDisclaimer = false;
  showMinimumOrderTotalAlert = false;
  minimumOrderTotalForDelivery = "$1000.00";
  isLoaded: boolean = false; 
  currencySymbol: any;
  paymentSetup: any;
  askCustomerDetails = false;
  customerDetails: CustomerDetails;
  detailsReasonText = "";
  customerDetailsForm: FormGroup;
  email: FormControl;
  phone: FormControl;
  phoneRequired: boolean;
  emailRequired: boolean;
  isComponentLoading: boolean = false;
  isRedeemableSelected: boolean;
  isGuest: boolean = false;

  hideOrderLevelSpecialInstruction: boolean = false;
  utensilsRequired: boolean = false;
  showUtensilsRequired = false;

  catalogHasImage: boolean = false;
  recommenededItems: any[];
  placeHolderImageUrl: string = '/assets/imgs/placeHolder.png';
  editItemModal: boolean = false; // Default False
  lineItemIndex: number;

  promoCode: string;

  public modalOpen: boolean = false;
  public closeResult: string;

  @ViewChild('alertPopup') alertPopup: AlertPopupComponent;
  @ViewChild("editItemModal") EditItemModal: ModalItemEditComponent;
  @ViewChild("speciaInstructionModal") SpeciaInstructionModal: TemplateRef<any>;

  constructor(public cartService: CartService, 
    private customerSession :CustomerSessionService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private loadingController: LoadingController,
    private ga: GoogleAnalyticService,
    private orderRouting: OrderRoutingService, 
    private errorHandlerService: ErrorHandlerService,
    private spinner: NgxSpinnerService,
    private changeDetectorRef: ChangeDetectorRef,
    private decimalPipe: DecimalPipe,
    private utilService: UtilService,
    private fb: FormBuilder,
    public popoverController: PopoverController,
    private gtmService: GoogleTagManagerService, 
    private fbPixelService: FacebookPixelService,
    private catalogService: CatalogService,
    private modalService: NgbModal,
    private translate: TranslateService) { }

  async ionViewWillEnter() {    
    this.cartHasItems = this.cartService.getCartCount() > 0;
    this.getHideOrderLevelSpecialInstruction();
    this.specialInstructions = this.cartService.getSpecialInstructions();
    this.utensilsRequired = this.cartService.getUtensilsRequired();
    this.isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;

    let showUtensilsRequiredSetting = sessionStorage.getItem("showUtensilsRequired");
    if (showUtensilsRequiredSetting && showUtensilsRequiredSetting == 'true') {
      this.showUtensilsRequired = true;
    }

    if (!this.cartHasItems) {
      this.orderRouting.goToLocation();
    }
    else
    {     
      this.promoCode = this.cartService.getAppliedPromoCode()
      
      if (!this.cartService.askedForOrderType) {
                this.orderRouting.goToOrderTypePicker();
                return;
      }  
      await this.loadingController.create({
        message: this.translate.instant("Calculating Total..."),
        spinner: 'bubbles',
      }).then(a => {
        a.present().then(() => {  
          
          this.initializeComponent().then(async (result) => {
            if (result) {
              console.log(result);
              console.log("initializeComponent done for cart");        
              this.CheckAutoAddItemToCartShowItem(this.order.showAutoAddItemId, this.order.items);       
              a.dismiss().then(() => {
                console.log('abort presenting');
              });                           
            }
          }).then(() => {
            a.dismiss();         
          });
        })
      }); 
    }    
    this.editItemModal = true;
  }

  async ngAfterViewInit() {
     
  }


  async initializeComponent(): Promise<any> {   
    return new Promise<any>(async (resolve, reject) => {
      this.utilService.documentActivatedRoute(this.activatedRoute);
      this.isComponentLoading = true;
      this.order = new PreparedOrder();
      this.cartHasItems = this.cartService.getCartCount() > 0;
      this.cartService.isCheckoutPage = false;
      this.customerSession.paymentMode = PaymentMode.ORDER;
  
      if(this.cartHasItems){
          //get latest integration setting for fields required
          //change this to another endpoint
          await this.customerSession.getIntegrationSettings();
          this.paymentSetup = this.customerSession.getPaymentSetupInfo();
          if (this.paymentSetup == null) {
              //can remove later
              await this.customerSession.getIntegrationSettings();
              this.paymentSetup = this.customerSession.getPaymentSetupInfo();
          }        
          if (this.paymentSetup && (this.paymentSetup.emailRequired || this.paymentSetup.phoneRequired)) {
              let locationPhone = sessionStorage.getItem("locationPhone");
              if (locationPhone != null && locationPhone != "") {
                  this.detailsReasonText = this.translate.instant("Please fill in the missing details below to complete your profile. Please contact the store at {phone_number} if you have any questions.", { phone_number: locationPhone});
              }
              else {
                  this.detailsReasonText = this.translate.instant("Please fill in the missing details below to complete your profile. Please contact the store if you have any questions.");
              }
  
              this.customerDetails = this.customerSession.getCustomerDetails();
              if (this.customerDetails == null) {
                  await this.getCustomerDetails();
              }
              else {
                  await this.isCustomerDetailsValid();
                  resolve(true);
              }    
          }
          else {
              await this.loadCartPromise().then(async (result) => {
                /* Check to Do */
                if (result) {
                  this.utilService.isLoading = false;                   
                  resolve(true);          
                }                 
              });
          }         
      }  
      this.disclaimer = sessionStorage.getItem("disclaimer");
      this.getAutoShowDisclaimer();
  
      this.ga.sendEvent('cart_viewed','');     
    });
  }

  CheckAutoAddItemToCartShowItem(showAutoAddItemId: number, items: any[]) {
    if (showAutoAddItemId) {
      this.utilService.showLoadingMessage(this.translate.instant("Opening Item...."));
      const itemToEditIndex = items.findIndex(i => i.id == showAutoAddItemId);
      if (itemToEditIndex != -1) {
        const itemToEdit = items[itemToEditIndex];
        this.orderRouting.goToEditItem(showAutoAddItemId, "auto-add", itemToEdit.uniqueCartItemId, itemToEditIndex);      
        this.utilService.hideLoadingMessage(); 
      }
    }
  }

  validatePromoCode() {
    this.cartService.applyPromoCode(this.promoCode);
    this.utilService.showLoadingMessage(this.translate.instant("Calculating Total..."));
    this.loadCartPromise(true);
  }

  clearPromoCode() {
    this.promoCode = "";
    this.cartService.applyPromoCode(this.promoCode);
    this.utilService.showLoadingMessage(this.translate.instant("Calculating Total..."));
    this.loadCartPromise();
  }

  getAutoShowDisclaimer() {
    if (sessionStorage.getItem("auto_show_disclaimer") == "1") {
      this.autoShowDisclaimer = true;
    }
  }

  getHideOrderLevelSpecialInstruction() {
    if (sessionStorage.getItem("hide_order_level_special_instruction") == "1") {
      this.hideOrderLevelSpecialInstruction = true;
    } else {
      this.hideOrderLevelSpecialInstruction = false;
    }
  }

  /* Use this to wait for result */
  loadCartPromise(autoAddPromoCodeItem: boolean = false): Promise<any> 
  {
    return new Promise<any>(async (resolve, reject) => {
      console.log('preparing order');
      this.isLoaded = false;
      await this.cartService.prepareOrderPromise(this.customerSession.locationId).then(
        (result) => {
          if (result) {
            console.log("prepareOrderPromise => done");
            this.order = this.cartService.order;  
            this.currencySymbol = this.order.details.currencySymbol;

            this.showRedeemables = (this.order.redeemables.length > 0) && !this.order.promoCode.promoCodeIsValid;
            this.availableRedeemablesCount = this.order.redeemables.filter(x => x.isAvailable === true).length;

            this.catalogHasImage = this.catalogService.getCatalogHasImage();
            this.getPlaceHolderImage();
            this.generateRecommendItemsArray(this.order.recommendedItems);

            this.isLoaded = true;
            this.changeDetectorRef.detectChanges();  
            this.utilService.hideLoadingMessage(); 
            resolve(true);       
            if (autoAddPromoCodeItem) {
              this.CheckAutoAddItemToCartShowItem(this.order.showAutoAddItemId, this.order.items);       
            }
          }
          
        },
          (err) => { 
          this.isLoaded = true;      
          this.utilService.hideLoadingMessage();
          resolve(true);  
          this.errorHandlerService.handleError(err);
        } 
      );
    });
  }

  getPlaceHolderImage(){
    if(sessionStorage.getItem("placeHolderImgUrl") && sessionStorage.getItem("placeHolderImgUrl") != "") {
      var splitArray = sessionStorage.getItem("placeHolderImgUrl").split(sessionStorage.getItem("guid"));
      var sizeParameter = "100xAUTO";
      var new_url = splitArray[0] + `${sessionStorage.getItem("guid")}/` + sizeParameter + splitArray[1];
      this.placeHolderImageUrl = new_url;
    }   
  }
  generateRecommendItemsArray(orderRecommendedItems){
    this.recommenededItems = [];
    if (!orderRecommendedItems || orderRecommendedItems.length == 0){
      return this.recommenededItems;
    } else {
      orderRecommendedItems.forEach((recItem) => {
        console.log(recItem);
        this.catalogService.getItemDetailsById(recItem.itemId).toPromise().then(result => {
          console.log('got recommended item:', result);
          this.recommenededItems.push(result);
        });
      })
    }
  }

  openConfirmModal(index) {
    this.lineItemIndex = index;
    this.EditItemModal.confirmItemRemoval();
  }
    
  async remove(index){
    this.spinner.show();
    this.utilService.showLoadingMessage(this.translate.instant("Removing Item...."));
    let hasDiscount = this.cartService.order.details.lines[index].discount
    if (await this.cartService.removeFromCart(index) != true) {
        this.spinner.hide();
        this.alertPopup.show(this.translate.instant("Error!"), this.translate.instant("Failed to remove the Item. Please try again."));
        return;
    }
    if (hasDiscount){
      this.promoCode = "";
    }
    this.utilService.hideLoadingMessage();
    if(this.cartService.cart.items.length > 0) {
     this.utilService.showLoadingMessage(this.translate.instant("Calculating Total..."));
     this.loadCartPromise();
    }
    else{
      this.cartHasItems = false;
    }

    this.ga.sendEvent('menu_item_removed_from_cart','');
    this.spinner.hide();
  }

  get updateCheckBoxValueFunc() {
    return this.remove.bind(this);
  }

  back(){    
    this.orderRouting.goToCatalog();
  }

  goToCheckout() 
  {
      if (this.CheckForMinimumDeliveryOrderTotal() == false) {
          return;
      }
   
    /* FB, GTM Tracking  for Merchant */
    let currencyCode = sessionStorage.getItem("currencyCode");

    this.gtmService.trackEvent(
      {eventName: 'CheckoutInitiated',
       eventCategory: "Click Events", 
       eventAction: "Checkout Button Clicked", 
       eventLabel: "Checkout", 
       eventValue: this.order.details.price, 
       eventLocation: "Cart Page",
       eventApp: "TapMango Online Order",
       currency: currencyCode});

    this.fbPixelService.sendCustomEvent('CheckoutInitiated', this.order.details.price);   

    this.saveSpecialInstructions();
    this.orderRouting.goToCheckout();
  }

  CheckForMinimumDeliveryOrderTotal() {
        let isDelivery = sessionStorage.getItem('is_delivery_order');
        let minOrdrTtlForDel = this.cartService.minimumOrderTotalForDelivery;

        let cartSubtotal = this.order.details.price; //order subtotal
        
        if (isDelivery && isDelivery.toLowerCase() == "true") {
            if (minOrdrTtlForDel > 0
                && cartSubtotal < minOrdrTtlForDel) {
                this.minimumOrderTotalForDelivery = this.currencySymbol + "" + this.decimalPipe.transform(minOrdrTtlForDel , '1.2-2')  ;
                this.showMinimumOrderTotalAlert = true;
                return false;
            }
        }
        return true;
  }

  addMoreItems() {
      this.showMinimumOrderTotalAlert = false;
      this.back();
  }

  changeToPickUp() {
        this.showMinimumOrderTotalAlert = false;
        this.orderRouting.goToOrderTypePicker();
  }

  closePopUp() {
    this.showMinimumOrderTotalAlert = false;
  }

  navigateToRedeemables(){   
    this.orderRouting.goToRedeemables();
  }

  // saveSpecialInstructions(){
  //   console.log(this.specialInstructions);
  //   this.cartService.setSpecialInstructions(this.specialInstructions);
  // }

  updateUtensilsRequired(){
    console.log('utensils required: ' + this.utensilsRequired);
    this.cartService.updateUtensilsRequired(this.utensilsRequired);
  }

  removeRedeemable(){
    this.cartService.removeRedeemable();
    this.promoCode = "";
    //this.loadCart();
    this.utilService.showLoadingMessage(this.translate.instant("Calculating Total..."));
    this.loadCartPromise();
  }

  async getCustomerDetails() {
      this.spinner.show();
      await this.dataService.getCustomerDetails().toPromise().then(
            (result) => {
                if (result) {
                    this.spinner.hide();
                    this.customerDetails = <CustomerDetails>result;
                    this.customerSession.setCustomerDetails(this.customerDetails);
                    this.isCustomerDetailsValid();
                }
                else {
                    this.spinner.hide();
                    this.errorHandlerService.alertMessage(this.translate.instant("Something went wrong. Please try again."));
                    this.back();
                }
            }, (err) => {
                console.log(err);
                this.spinner.hide();
                this.errorHandlerService.handleError(err);
                this.back();
            }
        );
  }

  async savePhoneAndEmail() {
        this.spinner.show();
        let customerDtls = this.customerDetailsForm.value;
        if (this.phoneRequired && customerDtls.phone && customerDtls.phone.trim() != "") this.customerDetails.phone = customerDtls.phone;
        if (this.emailRequired && customerDtls.email && customerDtls.email.trim() != "") this.customerDetails.email = customerDtls.email;

        await this.dataService.setCustomerDetails(this.customerDetails).toPromise().then(
            (result) => {
                if (result) {
                    this.customerDetails = <CustomerDetails>result;
                    this.customerSession.setCustomerDetails(this.customerDetails);
                    this.askCustomerDetails = false;
                    this.spinner.hide();                    
                   // this.loadCart();
                   this.loadCartPromise();
                }
                else {
                    this.spinner.hide();
                    this.errorHandlerService.alertMessage(this.translate.instant("Something went wrong. Please try again."));
                    this.back();
                }
            }, (err) => {
                console.log(err);
                this.spinner.hide();
                this.errorHandlerService.handleError(err);
            }
        );
  }

  async isCustomerDetailsValid() {
        this.phoneRequired = false;
        this.emailRequired = false;

        if (this.isGuest) {
          await this.loadCartPromise();
        }
        else {
          if (this.paymentSetup.phoneRequired && (this.customerDetails.phone == "" || this.customerDetails.phone == undefined || this.customerDetails.phone == null)) {
            this.askCustomerDetails = true;
            this.phoneRequired = true;
            this.phone = new FormControl('', [Validators.required, this.utilService.validatePhoneNumber]);
          }
          if (this.paymentSetup.emailRequired && (this.customerDetails.email == "" || this.customerDetails.email == undefined || this.customerDetails.email == null)) {
              this.askCustomerDetails = true;
              this.emailRequired = true;
              this.email = new FormControl('', [Validators.required, Validators.email]);
          }

          if (this.askCustomerDetails) {
              this.customerDetailsForm = this.fb.group({
                  'email': this.email,
                  'phone': this.phone
              });
          }
          else {
            // this.loadCart();
            await this.loadCartPromise();
          }
        }
  }

  closeCustomerDetailsPopup() {
        this.askCustomerDetails = false;
        this.back();
  }

  addRecommendedItem(itemId){
    this.orderRouting.goToItem(itemId, "recommendation");
  }

  shrinkText(text: any) {
    let str = '';
    if (text && text.length > 55) {
       str =  text.substring(0, 55);
       str = str + '....';
       return str;
    }       
    else{
        return text;
    }        
  }

  async modifyQuantity(event: Event, text: any, lineId: any, itemId: any, lineItemIndex: any, clickType: string) {  
      if (clickType == "btn-click") {
        var oldValue = $(`#${lineId}`).val();      
        if (text == "+") {
          var newVal = parseFloat(oldValue.toString()) + 1;        
          this.updateQuantity(lineItemIndex, newVal, lineId);
        } 
        else {
          if (oldValue > 1) {
            var newVal = parseFloat(oldValue.toString()) - 1;            
            this.updateQuantity(lineItemIndex, newVal, lineId);
          } else {
            newVal = 1;          
            $(`#${lineId}`).val(newVal); 
          }
        }
      }
     return;
  }

  async updateQuantity(lineItemIndex: any, newVal: number, lineId: any) {
      this.utilService.showLoadingMessage(this.translate.instant("Updating Cart...")); 
      let catalogId = this.catalogService.getCurrentCatalog().id;
      let findOldItem = this.cartService.cart.items[lineItemIndex];

      findOldItem.quantity = newVal;

      if (await this.cartService.updateCart(findOldItem, catalogId, lineItemIndex) != true) {        
        this.utilService.hideLoadingMessage();
        this.alertPopup.show(this.translate.instant("Error!"), this.translate.instant("Failed to update the Item to the cart. Please try again."));
        return;
      }

      await this.loadCartPromise().then(async (result) => {
        if (result) {
          $(`#${lineId}`).val(newVal); 
          this.utilService.hideLoadingMessage();
        }                 
      });
  }

  async openEditItemModal(event: Event, uniqueLineItemId: any, itemId: any, lineItemIndex: any, clickType: string) {
    if (clickType == "name-click") {
      this.orderRouting.goToEditItem(itemId, "edit", uniqueLineItemId, lineItemIndex);
    }   
  }

  openspecialInstructionModal() {
    console.log(this.specialInstructions);
    this.modalService.open(this.SpeciaInstructionModal, {
      size: 'lg',
      ariaLabelledBy: 'NewsLetter-Modal',
      centered: true,
      windowClass: 'template-modal'
    }).result.then((result) => {
      this.modalOpen = true;
      `Modal Result ${result}`
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    $("#specialInstructionsHeader").trigger('focus');
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeSpecialInstructionModal() {
    this.modalService.dismissAll();
  }

  saveSpecialInstructions() {
    console.log(this.specialInstructions);
    this.cartService.setSpecialInstructions(this.specialInstructions);
    this.modalService.dismissAll();
  }

}