import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { GooglePaymentData } from './models/google-payment-data';
import { PaymentAction } from './models/enum';
import { PaymentService } from './payment.service';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class SquareGooglePayService {
  private googlePayAvailable$ = new BehaviorSubject<boolean>(false);
  private token$ = new BehaviorSubject<any>(null);
  private googlePaymentData$ = new BehaviorSubject<GooglePaymentData>(null);
  squareLocationId$ = new BehaviorSubject<string>(null);
  squareAppId$ = new BehaviorSubject<string>(null);
  isMobile: boolean;
  isMobileApp: boolean;

  constructor(private paymentService: PaymentService, private util: UtilService) 
  { 
    this.util.postAppSpecificRequestsToFrame();
    this.checkIfMobileApp();
    console.log('IS MOBILE APP: ' + this.isMobileApp);
  }

  checkIfMobileApp() {
    let mobileAppResult = this.util.checkIfMobileApp();
    this.isMobile = mobileAppResult.isMobile;
    this.isMobileApp = mobileAppResult.isMobileApp;   
  }

  checkGooglePaySessionAvailable() {
    /* This method needs actual merchat id to validate */
    // if ((<any>window).PaymentMethodRequest) {

    //   const supportedPaymentMethods = [
    //     {
    //       supportedMethods: 'https://google.com/pay'          
    //     },
    //   ];

    //   const paymentMethodRequest = new (<any>window).PaymentMethodRequest(supportedPaymentMethods);

    //   if (paymentMethodRequest.canMakePayment) {
    //     paymentMethodRequest.canMakePayment().then((result) => {
    //       if (result) {
    //         console.log('Google Pay is supported!');
    //         this.googlePayAvailable$.next(true);
    //       } else {
    //         console.log('Google Pay is not supported.');
    //         this.googlePayAvailable$.next(false);
    //       }
    //     });
    //   } else {
    //     console.log('PaymentMethodRequest API is not supported in this browser.');
    //     this.googlePayAvailable$.next(false);
    //   }      
    // }   
    // else {
    //   console.log('No Google Pay Session Found');
    //   this.googlePayAvailable$.next(false);
    // }
  }

  getGooglePayAvailable() {
    return this.googlePayAvailable$.getValue();
  }

  setGoogleAvailable(val: boolean) {
    this.googlePayAvailable$.next(val);
  }

  processGooglePay() {    
    if (!this.getGooglePayAvailable()) {
      return;
    }    
    var merchantId = this.paymentService.getMerchantId();
    var merchantLocationId = this.paymentService.getMerchantLocationId();     
    console.log('merchantId => ' + merchantId);
    console.log('merchantLocationId => ' + merchantLocationId);
    this.goToNewTab();    
  }


  processGooglePayV3(googlePaymentData: GooglePaymentData, appId: string, locationId: string) {
    this.googlePaymentData$.next(googlePaymentData);
    this.squareAppId$.next(appId);
    this.squareLocationId$.next(locationId);
    this.checkIfMobileApp();
    if (this.isMobile && this.isMobileApp && this.paymentService.request.action == PaymentAction.PLACEORDER) {
      parent.postMessage(
        {
          action: 'initiate_google_pay',
          paymentData: JSON.stringify(this.googlePaymentData$.getValue()),
          etaMessage: this.paymentService.getEtaMessage(),
          orderType: this.paymentService.getOrderType()
        }, 
        sessionStorage.getItem("hostingapp")
      );
    }  
    else if (this.isMobile && this.isMobileApp && this.paymentService.request.action == PaymentAction.LOADWALLET) {
      parent.postMessage(
        {
          action: 'initiate_google_pay_wallet',
          paymentData: JSON.stringify(this.googlePaymentData$.getValue()),
          etaMessage: this.paymentService.getEtaMessage(),
          orderType: this.paymentService.getOrderType()
        }, 
        sessionStorage.getItem("hostingapp")
      );
    }
    else {
      this.processGooglePay();
    }  
  }

  goToNewTab() {  
    let popup = this.popupCenter(environment.SQUARE_WALLET_PAY_END_POINT, "Google Pay", 500, 500);      
    timer(1000).subscribe(x => {
        console.log('Posting Message');
        popup.postMessage(JSON.stringify(this.googlePaymentData$.getValue()), '*');           
    });
  }

  popupCenter (url, title, w, h) {
    // Fixes dual-screen position                              Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    const left = (window.screen.width/2)-(w/2) + dualScreenLeft;
    const top = (window.screen.height/2)-(h/2) + dualScreenTop;
    
    this.squareAppId$.next('sandbox-sq0idb-TlWc_7Vw0TB3--xscWRGtA');
    this.squareLocationId$.next('RK2MJ33RTE049');
    return window.open(`${url}?appid=${this.squareAppId$.getValue()}&locationid=${this.squareLocationId$.getValue()}&paymentType=googlePay`, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

}
