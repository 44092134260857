import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Location } from "@angular/common";



@Injectable({
    providedIn: 'root'
})
export class MembershipRoutingService {
    guid = '';
    base = '';
    isRoutingInitialized = new BehaviorSubject<boolean>(null);
    currentRoute = '';

    membership: any;

    constructor(private router: Router) { }

    setGuid(guid) {
        this.guid = guid;
        this.base = 'memberships/' + this.guid;
        this.isRoutingInitialized.next(true);
    }

    goToAbsoluteRoute(route) {
        this.router.navigate([route]);
    }

    goToLogin() {
        this.currentRoute = 'login';
        this.router.navigate([this.base, 'login']);
    }

    goToRegister() {
        this.currentRoute = 'register';
        this.router.navigate([this.base, 'register']);
    }

    goToForgotPassword() {
        this.currentRoute = 'forgot-password';
        this.router.navigate([this.base, 'forgot-password']);
    }

    goToConnectInStore() {
        this.currentRoute = 'connect-instore';
        this.router.navigate([this.base, 'connect-instore']);
    }

    goToRegisterKeytag() {
        this.currentRoute = 'register-keytag';
        this.router.navigate([this.base, 'register-keytag']);
    }

    goToMembershipList() {
        this.currentRoute = 'list';
        this.router.navigate([this.base, 'list']);
    }

    goToMembershipDetails(id: number) {
        this.currentRoute = 'details';
        this.router.navigate([this.base + '/details', id]);
    }

    goToPaymentManage() {
        this.currentRoute = 'payment-manage';
        this.router.navigate([this.base + '/payment-manage']);
    }

    goToPaymentCheckoutForm() {
        this.currentRoute = 'payment-checkout';
        this.router.navigate([this.base, 'payment-checkout']);
    }

    goToSquarePaymentForm() {
        this.currentRoute = 'square-payment';
        this.router.navigate([this.base, 'square-payment']);
    }

    goToStripePaymentForm() {
        this.currentRoute = 'payment-add';
        this.router.navigate([this.base, 'payment-add']);
    }

    goToCloverPaymentForm() {
        this.currentRoute = 'clover-payment';
        this.router.navigate([this.base, 'clover-payment']);
    }

    goToCheckout() {
        this.currentRoute = 'checkout';
        this.router.navigate([this.base, "checkout"]);
    }

    goToCancellation(id: number) {
        this.currentRoute = 'cancel';
        this.router.navigate([this.base + '/cancel', id]);
    }

    setMembership(membership) {
        this.membership = membership;
    }

    getMembership() {
        return this.membership;
    }

    goBack() {
        let returnRoute = sessionStorage.getItem('return_url');
        if (returnRoute) {
            console.log('login - returnRoute: ' + returnRoute);
            sessionStorage.removeItem('return_url');
            this.goToAbsoluteRoute(returnRoute);
        } else {
            this.goToMembershipList();
        }
    }

    /******** Getters & Setters ********/
    get updatePaymentRequired(): boolean {
        return sessionStorage.getItem('updatePaymentRequired') == "true" ? true : false;
    }

    set updatePaymentRequired(value: boolean) {
        sessionStorage.setItem('updatePaymentRequired', value.toString());
    }

    get membershipToUpdate(): number {
        return +sessionStorage.getItem('membershipToUpdate');
    }

    set membershipToUpdate(value: number) {
        sessionStorage.setItem('membershipToUpdate', value.toString());
    }

    get redirectToDetails(): boolean {
        return sessionStorage.getItem("redirectToDetails") == "true" ? true : false;
    }

    set redirectToDetails(value: boolean) {
        sessionStorage.setItem("redirectToDetails", value.toString());
    }

    get redirectToLogin(): boolean {
        return sessionStorage.getItem("redirectToLogin") == "true" ? true : false;
    }

    set redirectToLogin(value: boolean) {
        sessionStorage.setItem("redirectToLogin", value.toString());
    }

    get redirectToList(): boolean {
        return sessionStorage.getItem("redirectedToList") == "true" ? true : false;
    }

    set redirectToList(value: boolean) {
        sessionStorage.setItem("redirectedToList", value.toString());
    }

    get detailsLandingPage(): boolean {
        return sessionStorage.getItem("detailsLandingPage") == "true" ? true : false;
    }

    set detailsLandingPage(value: boolean) {
        sessionStorage.setItem("detailsLandingPage", value.toString());
    }

    get membershipError(): any {
        return sessionStorage.getItem("membershipError");
    }

    set membershipError(value: any) {
        sessionStorage.setItem("membershipError", value.toString());
    }

    get membershipId(): number {
        return +sessionStorage.getItem("membershipId");
    }

    set membershipId(value: number) {
        sessionStorage.setItem("membershipId", value.toString());
    }

    get membershipSelectedForPurchase(): number {
        return +sessionStorage.getItem("membershipSelectedForPurchase");
    }

    set membershipSelectedForPurchase(value: number) {
        sessionStorage.setItem("membershipSelectedForPurchase", value.toString());
    }
}
