import { Injectable } from '@angular/core';
import { CartService } from './cart.service';
import { OrderRoutingService } from './order-routing.service';
import { CustomerSessionService } from './customer-session.service';
import { AlertController } from '@ionic/angular';
import { UtilService } from './util.service';
import { EmailVerificationGuardService } from './email-verification/email-verification-guard.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    constructor(private cartService: CartService,
        private orderRouting: OrderRoutingService,
        private alertController: AlertController,
        private customerSession: CustomerSessionService,
        private emailVerification: EmailVerificationGuardService,
        private translate: TranslateService) { }

    async handleError(err) {
        console.log(err);
        if (err.type == "CREDIT_CARD") {
            this.errorAlertPOS("Invalid card information. Please try another card.", "ERROR").then((result) => {
                if (result) {
                    this.handleErrorAction(err);
                    this.handleErrorRedirect(err);
                }
            });
        }
        else if (err.type == "POS") {
            let errorMsg: string;
            let locationPhone = sessionStorage.getItem("locationPhone");
            if (err.action == "STOP_ORDER") {
                errorMsg = "There was a technical issue encountered while communicating with the stores payment processing system. We kindly ask that you do not attempt to submit the order again, as it may have been successfully submitted. " 
                
                if (locationPhone != null && locationPhone != "") {
                    errorMsg += `Please reach out to the store directly by calling ${locationPhone}`
                } else {
                    errorMsg += "Please reach out to the store directly"
                }

                if (err.source != null && err.source != "") {
                    errorMsg += `, referencing the following order ID: ${err.source}, to confirm if your order was successfully submitted. `
                } else {
                    errorMsg += " with your order/contact details to confirm if your order was successfully submitted. "
                }
            }
            else {
                let isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;
                if (err.message == "Please provide a valid email" && isGuest) {
                    errorMsg = `We were unable to submit your order to the store. Error: ${err.message}.`;
                }
                else if (locationPhone != null && locationPhone != "") {
                    errorMsg =
                    "We were unable to submit your order to the store, please contact the merchant for support. Store Number - " +
                    locationPhone;
                } else {
                    errorMsg =
                        "Unable to submit your order to the store. Please try again later";
                }
            }
            if(err.refnum && err.refnum != "") errorMsg += (" \nRef #" + err.refnum);
            this.errorAlertPOS(errorMsg, "ERROR").then(async (result) => {
                if (result) {
                    await this.handleErrorAction(err);
                    this.handleErrorRedirect(err);
                }
            });
        }
        else if (err.type == "DELIVERY") {
            this.errorAlertPOS(err.message, "Error").then(async (result) => {
                if (result) {
                    await this.handleErrorAction(err);
                    this.handleErrorRedirect(err);
                }
            });
        }
        else if (err.type == "EMAIL_VERIFICATION") {
            await this.handleErrorAction(err);
            this.handleErrorRedirect(err);
        }
        else if (err.type == "PHONE_VERIFICATION") {
            await this.handleErrorAction(err);
            this.handleErrorRedirect(err);
        }
        else if (err.status != 401) {
            let errorMsg: string;
            errorMsg = err.message;
            if(err.refnum && err.refnum != "") errorMsg += (" \nRef #" + err.refnum);
            this.alertMessage(errorMsg).then(async (result) => {
                if (result) {
                    await this.handleErrorAction(err);
                    this.handleErrorRedirect(err);
                }
            });
        }
    }

    private async handleErrorAction(err: any) {
        if (err.action && err.action != "") {
            if (err.action == "CLEAR_CART" || err.action == "STOP_ORDER") {
                this.cartService.clearCart();
                await this.cartService.setOrderAheadSetting();
                console.log("after");
            }
            else if (err.action == "CLEAR_ORDERID") {
                this.cartService.clearCartOrderId();
            }
            else if (err.action == "CLEAR_LOCATION") {
                this.customerSession.locationId = null;
            } else if (err.action == "CLEAR_REDEEMABLE") {
                this.cartService.removeRedeemable();
            } else if (err.action == "RELOAD_ORDERAHEADSETTINGS") {
                this.cartService.refreshOrderAheadSetting = true;
                await this.cartService.setOrderAheadSetting();
            } else if (err.action == "EMAIL_VERIFICATION_REQUIRED") {
                this.emailVerification.emailVerificationRequired = true;
            }
        }
    }

    private handleErrorRedirect(err: any) {
        if (err.redirectTo && err.redirectTo != "") {
            this.orderRouting.redirectOnError(err.redirectTo);
        }
    }


    alertMessage(message): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            let alert = await this.alertController.create({
                header: this.translate.instant("Oops"),
                message: this.translate.instant(message),
                buttons: [{
                    text: this.translate.instant('OK'),
                    handler: () => {
                        alert.dismiss().then(() => { resolve(true); });
                        return false;
                    }
                }]
            });
            alert.present();
        });
    }

    errorAlertPOS(errorMsg: string, errorHeader: string): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            let alert = await this.alertController.create({
                header: this.translate.instant(errorHeader),
                subHeader: this.translate.instant(errorMsg),
                buttons: [
                    {
                        text: this.translate.instant("OK"),
                        cssClass: "alertButton",
                        handler: () => {
                            alert.dismiss().then(() => { resolve(true); });
                            return false;
                        }
                    },
                ],
                backdropDismiss: false 
            });
            alert.present();
        });
    }
}