import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Platform, LoadingController } from '@ionic/angular';
import { CustomerSessionService } from '../customer-session.service';
import { DataService } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderRoutingService } from '../order-routing.service';
import { environment } from 'src/environments/environment';
import { MenuController } from '@ionic/angular';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CatalogService } from '../catalog.service';
import { AlertPopupComponent } from '../common/alert-popup/alert-popup.component';
import { CartService } from '../cart.service';
import Swal from 'sweetalert2';
import { UtilService } from '../util.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AssetService } from '../merchant-assets/asset.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
    dashboardLogoImageUrl;
    dashboardLabel;
    isMobile;
    loggedIn: boolean = false;
    ssoId: string;
    waitForProcess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loading: any;
    targetCatalogId: string;
    targetItemId: string;
    showCatalogChangebtn: boolean = false;
    orderType: string;
    tableName: string;
    languages: string[];
    selectedLanguage: string;
    messageEvent : Subscription; 
    preferredLanguage: string = null;
    isGuest: boolean = false;

    @ViewChild('alertPopup') alertPopup: AlertPopupComponent;

    private locationMenu = {
        title: 'Change Location',
        url: 'location',
        icon: 'pin',
        hidden: false,
        id: 4
    }
    private paymentMenu = {
        title: 'Payment',
        url: 'payment-manage',
        icon: 'card',
        hidden: true,
        id: 3
    }
    private changeCatalogMenu = {
        title: 'Change Catalog',
        url: '',
        icon: '',
        hidden: this.showChangeCatalog() ? false : true
    }
    private myOrdersMenu = {
        title: 'My Orders',
        url: 'my-orders',
        iconSrc: '/assets/svg/inprogress-order.svg',
        hidden: false,
        id: 2
    }
    public appPages = [
        {
            title: 'Catalog',
            url: 'catalog',
            iconSrc: '/assets/svg/menu.svg',
            id: 1
        },
        this.changeCatalogMenu,
        this.myOrdersMenu,
        this.paymentMenu,
        this.locationMenu
    ];

    constructor(private platform: Platform,
        private customerSession: CustomerSessionService,
        private dataService: DataService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private orderRouting: OrderRoutingService,
        public menuCtrl: MenuController,
        private spinner: NgxSpinnerService,
        private util: UtilService,
        private catalogService: CatalogService,
        private cartService: CartService,
        private assetService: AssetService,
        private translate: TranslateService) {

        console.log('constructor - dashboard component');
        this.messageEvent = fromEvent(window, 'message').subscribe(e=> this.onMessage(e));

        this.route.queryParams.subscribe(params => {
            this.ssoId = params['sso'];
            this.targetCatalogId = params['catalogId'];
            this.targetItemId = params['itemId'];

            this.orderType = params['ordertype'];
            this.tableName = params['tablename'];            
        });
        this.validateAndUpdateMerchant();

        try {
            parent.postMessage({action: 'showHomeButton'}, sessionStorage.getItem("hostingapp"));         
            parent.postMessage({action: 'language'}, sessionStorage.getItem("hostingapp"));   
            parent.postMessage({action: 'appMode'}, sessionStorage.getItem("hostingapp"));     
        } 
        catch (error) {
           console.log("Handled Non-IFrame Response");
        }

        
    }

    async ngAfterViewInit(): Promise<void> {
       // this.spinner.show();
        console.log("I was called =>  initComponent");
        await this.assetService.getMerchantAssets().toPromise().then(assets => {
            this.languages = assets.translationLangJSONs.map(l => l.lang);
            if (this.languages && this.languages.length > 0) {
                this.translate.addLangs(assets.translationLangJSONs.map(l => l.lang));
                if (this.isMobile && this.preferredLanguage != null) {
                    console.log('Language selected on mobile app => ' + this.preferredLanguage);
                    this.translate.setDefaultLang(this.preferredLanguage);         
                    this.assetService.selectedLanguage = this.preferredLanguage;       
                }  
                else {
                    this.translate.setDefaultLang('english');                    
                }
                console.log('Asset Service default language => ' + this.assetService.selectedLanguage);
                this.translate.use(this.assetService.selectedLanguage);
                this.selectedLanguage = this.translate.currentLang;                          
            }
        });
        
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.selectedLanguage = event.lang;
        });
    }

    ngOnInit() {
        this.isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.selectedLanguage = event.lang;
        });
        sessionStorage.removeItem("memberships_module"); 
        this.authService.isAuthenticated.subscribe(x => { this.loggedIn = x })
        if (!this.loggedIn) {
            // ensures "sign out" option appears in web version if id_token is present 
            let hasToken: boolean = sessionStorage.getItem('id_token') ? true : false;
            if (hasToken && this.isGuest == false) {
                this.loggedIn = true;
            }
        }
        console.log("login status : " + this.loggedIn);
        this.customerSession.mobileMode.subscribe((data) => {
            console.log("DASHBOARD COMPONENT SUBSCRIPTION FOR MOBILE MODE: " + data);
            if (data) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        });

        this.dataService.getCatalogLocations().subscribe((locations) => {
            if (locations) {
                if (locations.length <= 1) {
                    this.locationMenu.hidden = true;
                }

                if (locations.length > 0) {
                    let selectedLocationId = sessionStorage.getItem("locationId")
                    if (!selectedLocationId || this.isGuest) {
                        this.paymentMenu.hidden = true;
                    }
                    else {
                        this.paymentMenu.hidden = false;
                    }
                    // this.customerSession.setPaymentSetupInfo(locations[0].paymentSetup);
                }
            }
        });

        this.dataService.getDashboardData().subscribe((data) => {
            console.log(data);
            if (data.merchantName) {
                this.dashboardLabel = data.merchantName;
                sessionStorage.setItem("merchantName", this.dashboardLabel);
            }
            if (data.disclaimer) {
                sessionStorage.setItem("disclaimer", data.disclaimer);
            }
            if (data.autoShowDisclaimer == true) {
                sessionStorage.setItem("auto_show_disclaimer", "1");
            }
            if (data.logoName) {
                this.dashboardLogoImageUrl = this.makeImageUrl(data.logoName);
                sessionStorage.setItem("logoUrl", this.dashboardLogoImageUrl);
            }
            if (data.hideItemPageSpecialInstruction == true) {
                sessionStorage.setItem("hide_item_page_special_instruction", "1");
            }
            else {
                sessionStorage.removeItem("hide_item_page_special_instruction");
            }

            if (data.hideOrderLevelSpecialInstruction == true) {
                sessionStorage.setItem("hide_order_level_special_instruction", "1");
            }
            else {
                sessionStorage.removeItem("hide_order_level_special_instruction");
            }

            if (data.dineInConfirmationMsg) {
                sessionStorage.setItem("dinein_confirmation_msg", data.dineInConfirmationMsg);
            }

            if (data.showLocationConfirmationPopup == true) {
                sessionStorage.setItem("show_location_confirmation_popup", "1");
            } else {
                sessionStorage.removeItem("show_location_confirmation_popup");
            }

            if (data.hideCatalogWhenNotOpen == true) {
                sessionStorage.setItem("hide_catalog_when_not_open", "1");
            } else {
                sessionStorage.removeItem("hide_catalog_when_not_open");
            }

            if (data.phoneVerificationRequired == true) {
                sessionStorage.setItem("phone_verification_required", "1");
            } else {
                sessionStorage.removeItem("phone_verification_required");
            }
        }, (error) => {
            console.log(error);
        });       

        this.cartService.dineInChanged.subscribe((val) => {
            if (this.cartService.isDineInOrder) {
                this.locationMenu.hidden = true;
            }
            else {
                this.locationMenu.hidden = false;
            }
        });

        //added this because having trouble routing in the locationguard 
        //since merchant/guid is not set
        this.waitForProcess.subscribe(wait => {
            if (!wait) {
                var redirectToGuestOrderStatus: boolean = sessionStorage.getItem("redirectToGuestOrderStatus") == "1" ? true : false;
                var orderGuid: string = sessionStorage.getItem("orderGuid");
                if (redirectToGuestOrderStatus && orderGuid) {
                    sessionStorage.removeItem("redirectToGuestOrderStatus");
                    // make sure guest authentication is clear when accessing this page (after placing order)
                    if (this.isGuest) {
                        this.authService.removeUserData();
                    }
                    this.orderRouting.goToGuestOrderStatus(orderGuid);
                }
                else {
                    this.orderRouting.goToCatalog();
                }
            }
        });
       
    }

    onMenuOpen() {
        let selectedLocationId = sessionStorage.getItem("locationId")
        this.isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;
        
        if (!selectedLocationId || this.isGuest) {
            this.paymentMenu.hidden = true;
        }
        else {
            this.paymentMenu.hidden = false;
        }

        if (this.isGuest) {
            this.myOrdersMenu.hidden = true;
        }
        else {
            this.myOrdersMenu.hidden = false;
        }
        this.showChangeCatalog();
    }

    signOut() {
        this.menuCtrl.toggle();
        this.authService.logout().subscribe((result) => { console.log(result) });
    }

    removeGuestData() {
        this.authService.removeUserData();
        this.menuCtrl.toggle();
        this.orderRouting.goToLogin();
    }

    toggleMenu() {
        this.menuCtrl.toggle(); //Add this method to your button click function        
    }

    makeImageUrl(name) {
        let endpoint = environment.PORTAL_END_POINT;
        return endpoint + "/FileManager/Index?imageName=" + name;
    }


    ionViewDidEnter() {
        console.log("entered side menu");
    }

    goTo(url) {
        console.log(url);
        if (url == "location") {
            this.orderRouting.goToLocation();
        } else if (url == "payment-manage") {
            this.orderRouting.goToPaymentManage();
        } else if (url == "catalog") {
            this.orderRouting.goToCatalog();
        } else if (url == "my-orders") {
            this.orderRouting.goToMyOrders();
        }
    }

    validateAndUpdateMerchant() {
        let guid = this.route.snapshot.params['guid'];
        let existingGuid = sessionStorage.getItem('guid');

        if (existingGuid != null && existingGuid != guid) {
            sessionStorage.clear();
        }
        sessionStorage.setItem('guid', guid);

        let locationId = this.route.snapshot.params['locationid'];        

        /* Scenario START: When user changes the Location Id manually in the Route => Clear Selected catalog, which was previously selected via location click */
         let previousSelectedLocationId =  sessionStorage.getItem('locationId');  
            if (locationId && previousSelectedLocationId) {
                if (previousSelectedLocationId !=  locationId) {

                        //reset the last catalog only if selected location is different
                        this.catalogService.setLastSelectedCatalogId(0);
                        this.cartService.clearFutureOrderSettingAndData();
                        this.catalogService.defaultlastSelectedCategoryId();
                        //delete payment info if the location is different
                        this.customerSession.removePaymentSetupInfo();                       
                        this.cartService.clearSessionPromise().then(async (result) => {
                            if(result) 
                            {  
                              sessionStorage.setItem('locationId', locationId);                               
                              location.reload();
                            }
                        }).catch(error => {
                            console.log(error);
                        });          
                }
            }
        
        /* END */
        this.checkAndClearData(guid);
        console.log('dashboard - guid is: ' + guid);
        console.log('dashboard - location is: ' + locationId);

        if (locationId) {
            sessionStorage.setItem('target_location_id', locationId);         
            this.cartService.isCorrectOrderLocation = false;  
        }       

        if (this.targetCatalogId && this.targetCatalogId != "") {
            this.catalogService.setTargetCatalogId(this.targetCatalogId);
        }        

        if (this.ssoId && this.ssoId != "") {
            this.authService.removeUserData();
            this.waitForProcess.next(true);
            this.authService.authenticateCustomerBySso(this.ssoId).toPromise().then(
                (response: any) => {
                    this.spinner.hide();
                    this.waitForProcess.next(false);
                },
                (err) => {
                    this.spinner.hide();
                    this.alertPopup.show(this.translate.instant("Error!"), this.translate.instant("Unable to Login. Please try again."));                
                }
            );
        } else if (sessionStorage.getItem('id_token') == null && localStorage.getItem(guid)) {
            this.authService.getNewRefreshToken().toPromise().then(
                (tokenresponse: any) => {
                    if (tokenresponse) {
                        sessionStorage.setItem('id_token', localStorage.getItem(guid));
                        this.authService.isAuthenticated.next(true);
                    }
                    else {
                        this.authService.logout();
                    }
                },
                (err) => {
                    this.authService.logout();
                    console.log(err);
                }
            );
        }


        if (this.orderType && this.orderType == "dinein") {
            if (this.tableName && this.tableName.trim() != "") {
                this.cartService.isDineInOrder = true;
                sessionStorage.setItem("order_type_picked", "Dine-In");
                this.cartService.dineInTableName = this.tableName;
                this.cartService.askedForOrderType = true;
                this.cartService.askedForFutureOrder = true;
                this.locationMenu.hidden = true;
            }
            else {
                this.alertPopup.show(this.translate.instant("Error!"), this.translate.instant("Ooops! Something went wrong while initiating the Dine-in order. Please try again."))                
            }
        }

        this.orderRouting.setGuid(guid);
    }

    checkAndClearData(guid) {
        let storageGuid = sessionStorage.getItem('guid');
        if (this.customerSession.isMobile() == false && storageGuid && storageGuid != guid) {
            console.log('GUID DIFFERENT - session guid stored and the current url guid are different: ' + storageGuid + ", " + guid);
            sessionStorage.clear();
        }
    }

    alertPopupClosed() {
        this.waitForProcess.next(false);
    }

    backToMobileApp(event) {
        parent.postMessage({action: 'goToHostingApp'}, sessionStorage.getItem("hostingapp"));
    }

    showHomeButton() {
       return this.util.showHomeButton$;
    }

    changeCatalog() 
    {
       this.catalogService.setCatalogChangeRequested(true);
       this.toggleMenu();
       this.orderRouting.goToCatalogPicker();
    }

    showChangeCatalog() {
        let timeFrames = this.cartService.getCatalogsTimeFrames();
        if (timeFrames && timeFrames.length > 1 && this.catalogService.getLastSelectedCatalogId() > 0) {
            this.showCatalogChangebtn = true;           
        }
        else {
            this.showCatalogChangebtn = false;          
        }
       return this.showCatalogChangebtn;
    }

     /* Accessibility Implementation for Key Press */
    eventHandler(event) 
    {
        try {
            if (event.currentTarget.id == "closeMenu" && event.keyCode == 13) {
                this.menuCtrl.toggle();
            }   
            if (event.currentTarget.id == "menu-item-1" && event.keyCode == 13) {
                this.menuCtrl.toggle().then(() => {
                    this.orderRouting.goToCatalog();
                });                
            }  
            if (event.currentTarget.id == "menu-item-2" && event.keyCode == 13) {
                this.menuCtrl.toggle().then(() => {
                    this.orderRouting.goToMyOrders();
                });                
            }  
            if (event.currentTarget.id == "menu-item-3" && event.keyCode == 13) {
                this.menuCtrl.toggle().then(() => {
                    this.orderRouting.goToPaymentManage();
                });                
            }  
            if (event.currentTarget.id == "menu-item-4" && event.keyCode == 13) {
                this.menuCtrl.toggle().then(() => {
                    this.orderRouting.goToLocation();
                });                
            }                    
        } catch (error) {
            
        }      
    }

    languageChanged(ev) {
        if (this.languages.findIndex(l => l == ev.detail.value) >= 0) {
            this.translate.use(ev.detail.value);
            this.assetService.selectedLanguage = ev.detail.value;
            console.log(this.selectedLanguage);
        }
    }

    async onMessage(e) {
        let originString : string = e.origin;
        console.log('order app - got message from origin ' + e.origin, e.data);
        if ((originString.toLowerCase().indexOf("localhost") > -1) || (originString.toLowerCase().indexOf("file://") > -1) || (originString.toLowerCase().indexOf("customer.tapmango.com") > -1)
        || (originString.toLowerCase().indexOf("https://qa2.order-checkout-square.tapmango.com") > -1)) {

            if (e.data.language) {
                console.log("language is " + e.data.language);    
                if (e.data.language == "en" || e.data.language == "fr") {            
                    if (e.data.language == "en") {
                        this.preferredLanguage = "english";
                    }
                    if (e.data.language == "fr") {
                        this.preferredLanguage = "french";
                    }            
                }
                if (this.preferredLanguage && this.languages && this.languages.findIndex(l => l == this.preferredLanguage) >= 0) {
                    this.translate.use(this.preferredLanguage);
                    this.assetService.selectedLanguage = this.preferredLanguage;
                    console.log(this.selectedLanguage);
                }
            }
            if (e.data.appMode) {
                console.log("app mode is " + e.data.appMode);  
            }
        }
    }
}
