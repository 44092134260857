import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { CartService } from '../cart.service';
import { OrderRoutingService } from '../order-routing.service';
import { UtilService } from '../util.service';

@Component({
  selector: 'app-order-type-picker',
  templateUrl: './order-type-picker.component.html',
  styleUrls: ['./order-type-picker.component.scss']
})
export class OrderTypePickerComponent implements OnInit {

  selectedOrderType: number; 
  orderAheadEnabled: boolean = false;
  curbSidePickUpEnabled: boolean = false;
  disableInStorePickUp: boolean = false;
  deliveryEnabled: boolean = false;
  isExternalDelivery: boolean = false;
  deliveryOrder: boolean;  
  curbSideOrder: boolean;
  logoUrl: string = null;
  isBackButtonVisible: boolean;

    constructor(private cartService: CartService,
        private orderRouting: OrderRoutingService,
        private utilService: UtilService,
        private spinner: NgxSpinnerService) { }

  async ngOnInit() {}

  async ionViewWillEnter()
  { 
      this.spinner.hide();

      if (this.cartService.isDineInOrder) {
          //this.orderRouting.goToCart();
          this.back();
      }

    this.isBackButtonVisible = this.utilService.isBackButtonVisible();
    this.logoUrl = sessionStorage.getItem("logoUrl");  
    this.orderAheadEnabled = this.cartService.orderAheadEnabled;
    this.curbSidePickUpEnabled = this.cartService.curbSidePickUpEnabled;
    this.disableInStorePickUp = this.cartService.disableInStorePickUp;
    this.deliveryEnabled = this.cartService.deliveryEnabled;
    this.isExternalDelivery = this.cartService.isExternalDelivery;
  }

  /* LOGICAL METHODS */
  onOrderTypeSelected(orderType: number) 
  {
    this.selectedOrderType = orderType;        

    switch (this.selectedOrderType) {
         case 0:
             this.deliveryOrder = true;
             this.curbSideOrder = false;
             break;
         case 1:
             this.deliveryOrder = false;
             this.curbSideOrder = false;                
             break;
         case 2:
             this.deliveryOrder = false;
             this.curbSideOrder = true;
             break;
    }

    

    this.cartService.isCurbSideOrder = this.curbSideOrder;
    this.cartService.isDeliveryOrder = this.deliveryOrder; 
    this.cartService.selectedOrderType = this.selectedOrderType; 

    this.orderRouting.goToOrderAheadPicker();
  }
  

  back() 
  {
    this.utilService.routeBack();
  }

}
