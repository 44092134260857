import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, throwError, pipe } from 'rxjs';
import { catchError, map, switchMap, tap, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerSessionService } from '../customer-session.service';
import { OrderRoutingService } from '../order-routing.service';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CategoryTrackedService } from '../common/category-tracked.service';
import { CatalogService } from '../catalog.service';
import { LoadingController } from '@ionic/angular';
import { UtilService } from '../util.service';
import { MembershipRoutingService } from '../membership-routing.service';
import { ToastApplePayService } from '../toast-apple-pay.service';
import { GuestDetails } from '../models/guest-details.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authUrl = environment.apiUrl + "/auth";
  authRefreshUrl = environment.apiUrl + "/auth/refreshtoken";
  signoutUrl = environment.apiUrl + "/auth/signout";
  exchangeToken  = new Subject<string>();
  isAuthenticated = new BehaviorSubject<boolean>(null); 
  orderGuid = '';
  membershipModule: boolean = false;
  isMobile: boolean = false;

  constructor(private httpClient : HttpClient, 
              private router: Router, 
              private customerSession: CustomerSessionService,
              private categoryTrackedService: CategoryTrackedService,
              private orderRouting: OrderRoutingService,
              private membershipRouting: MembershipRoutingService,
              private catalogService: CatalogService,
              public util: UtilService,
              private toastApplePayService: ToastApplePayService,
              private modalService: NgbModal,
              ) {
    
    //always listen to events now   
    window.addEventListener('message', (event) => {
      this.onMessage(event);
    });
    let getTokenOnInit = this.waitForExchange().subscribe(() =>{
      getTokenOnInit.unsubscribe();
    });  
    
    this.checkIfMobileDevice();
    sessionStorage.removeItem('shared_lat');
    sessionStorage.removeItem('shared_long');     
    if (this.isMobile) {
      parent.postMessage({action: 'getPlatform'}, sessionStorage.getItem("hostingapp")); 
      parent.postMessage({action: 'getApplePayAvailable'}, sessionStorage.getItem("hostingapp"));     
      parent.postMessage({action: 'isMobileApp'}, sessionStorage.getItem("hostingapp"));   
    }
  }

  async onMessage(e)
  {
    let originString : string = e.origin;
    console.log('order app - got message from origin ' + e.origin, e.data);
    if ((originString.toLowerCase().indexOf("localhost") > -1) || (originString.toLowerCase().indexOf("file://") > -1) || (originString.toLowerCase().indexOf("customer.tapmango.com") > -1)
    || (originString.toLowerCase().indexOf("https://qa2.order-checkout-square.tapmango.com") > -1)){ 
      if(e.data.token) {
        //sessionStorage.setItem("id_token", null);
        sessionStorage.setItem("hostingapp", e.origin);
        this.exchangeToken.next(e.data.token);
      }
      if (e.data.orderGuid){
        this.orderGuid = e.data.orderGuid;//produce (set) guid
        console.log('AuthService: setting order GUID for navigation = ' + this.orderGuid);

        setTimeout(()=>{    
          this.orderRouting.isRoutingInitialized.subscribe((value) => {
            if (value === true){
              let orderRouteGuid = this.orderGuid;
              console.log('AuthService: navigating to order GUID = ' + orderRouteGuid);
              this.orderGuid = '';//consume guid
              this.orderRouting.goToOrderStatus(orderRouteGuid); 
            } else {
              console.log('AuthService: OrderRoutingInitialized value is: ' + value);
            }
          });
        }, 2000);

      }
      if(e.data.showHomeButton) {
        this.util.sethomeButton(e.data.showHomeButton);
        sessionStorage.setItem("show_home_button", e.data.showHomeButton.toString());        
        console.log(`Setting ShowHomeButton => ${e.data.showHomeButton}`);
      }
      if(e.data.membershipId) {
        console.log(`membershipId from mobile app tile => ${e.data.membershipId}`);
        sessionStorage.setItem("membershipIdFromMobileApp", e.data.membershipId);
      }
      if(e.data.applepayToken) {
        console.log(`applepayToken => ${e.data.applepayToken}`);   
        if (e.data.puid) {
          this.toastApplePayService.setPuidReceivedfromMobileApp(e.data.puid);
        }
        if (e.data.applicationData) {
          this.toastApplePayService.setApplicationDataReceivedfromMobileApp(e.data.applicationData);
        }
        this.toastApplePayService.processApplePayV3(e.data.applepayToken);  
      }
      if(e.data.applepayWalletToken) {
        console.log(`applepayWalletToken => ${e.data.applepayWalletToken}`);   
        if (e.data.puid) {
          this.toastApplePayService.setPuidReceivedfromMobileApp(e.data.puid);
        }
        if (e.data.applicationData) {
          this.toastApplePayService.setApplicationDataReceivedfromMobileApp(e.data.applicationData);
        }
        this.toastApplePayService.processApplePayV3(e.data.applepayWalletToken);  
      }
      if(e.data.applePay) {
        sessionStorage.setItem("isApplePayAvailable", e.data.applePay);
        console.log("IsApplePayAvailable " + e.data.applePay);  
        sessionStorage.setItem('isMobileApp', 'true')
      }  
      if(e.data.platform) {  
        console.log("platform is " + e.data.platform);    
        sessionStorage.setItem('isMobileApp', 'true');
        console.log("IsMobileApp is " +  sessionStorage.getItem('isMobileApp')); 
        sessionStorage.removeItem('shared_lat');
        sessionStorage.removeItem('shared_long'); 
        parent.postMessage({action: 'getLocation'}, sessionStorage.getItem("hostingapp"));
      }  
      if(e.data.location) {
        if(e.data.location.success && e.data.location.position) {    
          console.log('Received Location Co-Ordinates');  
          console.log(e.data.location);
          if ( e.data.location.position && e.data.location.position.coords && e.data.location.position.coords.latitude && e.data.location.position.coords.longitude) {
            console.log('Storing Received Location Co-Ordinates to Session Storage');
            sessionStorage.setItem('shared_lat', e.data.location.position.coords.latitude);
            sessionStorage.setItem('shared_long',  e.data.location.position.coords.longitude);           
          }        
        }
        else if(e.data.location.success == false && e.data.location.error) {
          sessionStorage.removeItem('shared_lat');
          sessionStorage.removeItem('shared_long');        
        }
        else {
          
        }
      }     
      if(e.data.squarePaymentsObj) {
        console.log(JSON.parse(e.data.squarePaymentsObj));
        const appId = 'sandbox-sq0idb-TlWc_7Vw0TB3--xscWRGtA';
        const locationId = 'YCB11PBGVXTTJ';
        const payments = (<any>window).Square.payments(appId, locationId);
        const applePay = await payments.applePay(e.data.squarePaymentsObj);
      }
      if(e.data.isMobileApp) {
        console.log(`IsMobileApp => ${e.data.isMobileApp}`);
        if (e.data.isMobileApp) {
          console.log(`Setting IsMobileApp in Session => ${e.data.isMobileApp}`);
          sessionStorage.setItem('isMobileApp', 'true');
        }        
      }
      if(e.data.square_payment_modal == "close_square_payment_modal") {   
        console.log('CLOSING PAYMENT MODAL');     
        this.modalService.dismissAll();
      }    
    }
  }

  dismissLoader() 
  {
    
  }

  waitForExchange(){    
    return this.exchangeToken
      .pipe(
        switchMap(token => this.authenticate(token)),
        tap(async (success) => {           
          this.isAuthenticated.next(success);
          console.log('got auth result ' + success);          
        }),
        catchError((err) =>{
          console.error("error authenticating :" + err.message)
          this.isAuthenticated.next(false);         
          return throwError(err);
        })
        ) 
  }

  

  private authenticate(exchangeToken :string) : Observable<boolean>{
    return this.httpClient.post(this.authUrl + '/token', {exchangeToken:  exchangeToken})
        .pipe(map((response) => this.handleTokenResponse(response)));
  }

  public authenticateCustomer(email: string, password: string, token: string, recaptchaAction: string) : Observable<boolean>{
    return this.httpClient.post(this.authUrl + '/customer', {email:  email, password: password, recaptchaToken: token, recaptchaAction: recaptchaAction})
        .pipe(map((response) => this.handleTokenResponse(response)));
  }

  public authenticateGuest(token: string, recaptchaAction: string) : Observable<boolean>{
    return this.httpClient.post(this.authUrl + '/guest', {recaptchaToken: token, recaptchaAction: recaptchaAction})
        .pipe(map((response) => this.handleTokenResponse(response)));
  }

  public authenticateCustomerBySso(ssoId: string) : Observable<boolean>{
    return this.httpClient.post(this.authUrl + '/singlesignon', { ssoId: ssoId})
        .pipe(map((response) => this.handleTokenResponse(response)));
  }

  public createCustomer(createCustomerRequest: any) : Observable<boolean>{
    return this.httpClient.post(this.authUrl + '/createcustomer', createCustomerRequest)
        .pipe(map((response) => this.handleTokenResponse(response)));
  }

  public connectInStoreAccount(connectInStoreAccountRequest: any): Observable<boolean> {
    return this.httpClient.post(this.authUrl + '/connectinstoreaccount', connectInStoreAccountRequest)
        .pipe(map((response) => this.handleTokenResponse(response)));
  }

  public customerRequiresEmailVerification(): Observable<any> {
    return this.httpClient.post<any>(this.authUrl + '/customerrequiresemailverification', { });
  }

  public verifyCustomerPhoneNumber(verificationCode: string): Observable<any> {
    return this.httpClient.post<any>(this.authUrl + '/verifycustomerphonenumber', { verificationCode: verificationCode });
  }

  public async checkGuestPhoneVerification(guestDetails: GuestDetails): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.httpClient.post<any>(this.authUrl + "/checkguestphoneverification", guestDetails).subscribe((result) => {
        resolve(result);
      }, (error) => {
        reject(error)
      });      
    });
  }

  public verifyGuestPhoneNumber(orderGuid: string, verificationCode: string): Observable<any> {
    return this.httpClient.post<any>(this.authUrl + '/verifyguestphonenumber', { orderGuid: orderGuid, verificationCode: verificationCode });
  }

  public customerPhoneVerification(merchantLocationId: number, sendCode: boolean): Observable<any> {
    return this.httpClient.post<any>(this.authUrl + '/customerphoneverification', { merchantLocationId: merchantLocationId, sendCode: sendCode });
  }

  public guestPhoneVerification(orderGuid: string, merchantLocationId: number, sendCode: boolean): Observable<any> {
    return this.httpClient.post<any>(this.authUrl + '/guestphoneverification', { orderGuid: orderGuid, merchantLocationId: merchantLocationId, sendCode: sendCode });
  }

  public forgotPassword(email: string) : Observable<any>{
    return this.httpClient.post(this.authUrl + '/forgotpassword', {email: email});
  }

  private handleTokenResponse(response) {
    if (response.customerId != undefined && response.customerId != null) {
      this.setSession(response);
    }
    else if (response.guestId != undefined && response.guestId != null) {
      this.setGuestSession(response);
    }
    return this.validateTokens();
  }

  public getToken(){
   return sessionStorage.getItem("id_token");
  }
  
  private setSession(authResult) {
    console.log('got auth response for customer', authResult);
    this.customerSession.customerId =  authResult.customerId;   
    sessionStorage.removeItem("id_token");
    sessionStorage.setItem('id_token', authResult.token);
    sessionStorage.setItem('mguid', authResult.mguid);
    sessionStorage.setItem('email_verification', authResult.requiresEmailVerification);
    if (authResult.requiresEmailVerification) {
      sessionStorage.setItem('customer_email', authResult.customerEmail);
      sessionStorage.setItem('verification_email_sent_time', authResult.verificationEmailSentTime);
    }
    sessionStorage.setItem('authType', 'LOYALTY');

    let guid = sessionStorage.getItem("guid");
    let isMobile = sessionStorage.getItem('is_mobile');

    this.isAuthenticated.next(true);   

    if (guid) {
      localStorage.setItem(guid, authResult.token)
    }  
  }

  private setGuestSession(authResult) {
    console.log('got auth response for guest', authResult);
    this.customerSession.customerId =  authResult.guestId;  // need guestSession? 
    sessionStorage.removeItem("id_token");
    sessionStorage.setItem('id_token', authResult.token);
    sessionStorage.setItem('mguid', authResult.mguid);
    sessionStorage.setItem('authType', 'GUEST');

    let guid = sessionStorage.getItem("guid");
    let isMobile = sessionStorage.getItem('is_mobile');

    this.isAuthenticated.next(true);   

    if (guid) {
      sessionStorage.setItem(guid, authResult.token)
    }  
  }

  private validateTokens() : boolean{
    const token = this.getToken();
    if(token)
      return true;
    else
      return false;
  }

  /* Method to get new refresh token */
  getNewRefreshToken() : Observable<any>
  {
    let token = localStorage.getItem(sessionStorage.getItem("guid"));

    let refreshTokenRequest = {"oldToken" : token}

    return this.httpClient.post<any>(this.authRefreshUrl, JSON.stringify(refreshTokenRequest), {headers: {'Content-Type': 'application/json', 'Anonymous':'True'}}).pipe(
      map(result => {
        if(result && result.token)
        {
          this.isAuthenticated.next(true);          
          localStorage.setItem(sessionStorage.getItem("guid"), result.token);
          sessionStorage.setItem('id_token', result.token);   
          sessionStorage.setItem('customerId', result.customerId);
          sessionStorage.setItem('email_verification', result.requiresEmailVerification); 
        }
        return <any>result;

      })
    );

  }

  logout() : Observable<any>
  {
    let isMobile = sessionStorage.getItem('is_mobile');
    if(isMobile){
      parent.postMessage({action: 'reloadorderapp'}, sessionStorage.getItem("hostingapp"));
      return new Observable<any>();
    }
    else{
      let token = localStorage.getItem(sessionStorage.getItem("guid"));
      // first call to http signout then
      this.httpClient.post<any>(this.signoutUrl, {}).subscribe(result => {
        console.log(result);
      });
      this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;
      this.removeUserData();
      if (this.membershipModule) {
        this.membershipRouting.goToLogin();
      } else {
        this.categoryTrackedService.resetCart(); 
        this.orderRouting.goToLocation();  
      }  
      return new Observable<any>();
    }
  }

  removeUserData() {
    if (!this.membershipModule) {
      sessionStorage.removeItem("customerDetails"); 
      localStorage.removeItem("recentlyVisitedStore");
      sessionStorage.removeItem("cart"); 
      this.catalogService.defaultlastSelectedCategoryId();
      sessionStorage.setItem("reload_catalog", "true"); 
    } else {
      sessionStorage.removeItem("membershipSelectedForPurchase");
    }

    localStorage.removeItem(sessionStorage.getItem("guid"));
    sessionStorage.removeItem("id_token");
    sessionStorage.removeItem("customerId"); 
    sessionStorage.removeItem("email_verification"); 
    sessionStorage.removeItem('customer_email');
    sessionStorage.removeItem('verification_email_sent_time');
    sessionStorage.removeItem('verification_phone_sent_time');
    sessionStorage.removeItem('authType');
    sessionStorage.removeItem('orderGuid');
    sessionStorage.removeItem("guestPhone");
    sessionStorage.removeItem("guestEmail");
    sessionStorage.removeItem("guestName");
    sessionStorage.removeItem("locationId");

    this.isAuthenticated.next(false);
  }

  MustMatch(firstControl : AbstractControl) : ValidatorFn
  {
    return (secondControl : AbstractControl) : {[key: string] : boolean } | null   =>
    {
      // return null if controls haven't initialised yet
      if(!firstControl && !secondControl)
      {
        return null;
      }

      // return null if another validator has already found an error on the matchingControl
      if (secondControl.hasError && !firstControl.hasError)
      {
        return null;
      }
      // set error on matchingControl if validation fails
      if(firstControl.value !== secondControl.value)
      {
        return { 'mustMatch': true };
      }
      else {
        return null;
      }

    }

  }

  async domainInfo(url: string): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.httpClient.post(this.authUrl + '/domaininfo', {url: url}).subscribe((result) => {
        resolve(result);
      }, (error) => {
        reject(error)
      });      
    });
  } 

  checkIfMobileDevice() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);   
    // Fix for iPadOS not being detected as mobile on safari (known bug)
     if (!this.isMobile) {
       let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
       if (isIpad) {
         this.isMobile = true;
       }
     } 
     if (this.isMobile) {
        console.log('POSTING MESSAGE TO CHECK FOR MOBILE DEVICE AND APPLE PAY');
        parent.postMessage({action: 'getPlatform'}, sessionStorage.getItem("hostingapp")); 
        parent.postMessage({action: 'getApplePayAvailable'}, sessionStorage.getItem("hostingapp"));   
        parent.postMessage({action: 'isMobileApp'}, sessionStorage.getItem("hostingapp"));
     }
  }

}
