import { Component, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { DataService } from '../../data.service';
import { UtilService } from 'src/app/util.service';
import { getCurrencySymbol, Location } from '@angular/common';
import { LoadingController } from '@ionic/angular';
import { CustomerSessionService } from '../../customer-session.service';
import { MembershipRoutingService } from '../../membership-routing.service';
import { AlertController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertPopupComponent } from '../../common/alert-popup/alert-popup.component';

@Component({
    selector: 'memberships-list',
    templateUrl: './memberships-list.component.html',
    styleUrls: ['./memberships-list.component.scss']
})
export class MembershipsListComponent {
    locationId: number = 0;
    imageWidth: number = 0;
    
    memberships: any[] = [];
    availableMemberships: any[] = [];
    customerMemberships: any[] = [];
    isMobile;
    hasOrders: boolean = true;
    currencySymbol: string = "$";
    isLoading: boolean = true;
    showAvailableMemberships: boolean = true;
    membershipBannerURL = "../../../assets/imgs/membership-list-graphic.png";

    @ViewChild('alertPopup') alertPopup: AlertPopupComponent;

    constructor(
        public router: Router,
        private dataService: DataService,
        private customerSession: CustomerSessionService,
        private location: Location,
        private membershipRouting: MembershipRoutingService,
        private alertController: AlertController,
        private spinner: NgxSpinnerService,
    ) { }

    ionViewWillEnter() {
        let currencyCode = sessionStorage.getItem("currencyCode");
        this.currencySymbol = getCurrencySymbol(currencyCode, "narrow");
        if (this.currencySymbol == currencyCode) {
            this.currencySymbol = "$";
        }

        this.memberships = [];
        this.availableMemberships = [];
        this.customerMemberships = [];
        
        this.spinner.show();
        if (this.membershipRouting.updatePaymentRequired && this.membershipRouting.membershipToUpdate > 0 ) {
            // if trying to access membership details directly via URL, check if user is logged in and direct accordingly
            var hasToken: boolean = sessionStorage.getItem('id_token') ? true : false;
            if (!hasToken) {
                this.spinner.hide();
                // need to set this so when the memberships dashboard is loaded again, the user is redirected to login
                this.membershipRouting.redirectToLogin = true; 
                this.membershipRouting.goToLogin();
            } else {
                this.spinner.hide();
                // need to set this so when the memberships dashboard is loaded again, the user is redirected to membership details
                this.membershipRouting.redirectToDetails = true;
                
                // store membership id for redirect from memberships dashboard
                this.membershipRouting.membershipId = this.membershipRouting.membershipToUpdate; 
                this.membershipRouting.goToMembershipDetails(this.membershipRouting.membershipToUpdate);
            }
        } else { 
            this.loadData().subscribe(
                (data) => {
                    this.memberships = data.memberships;
                    if (data.membershipBannerURL) {
                        this.membershipBannerURL = data.membershipBannerURL;
                    }                    
                    if (this.memberships != null && this.memberships.length == 1) {
                        this.membershipRouting.detailsLandingPage = true;
                        this.membershipRouting.goToMembershipDetails(this.memberships[0].membershipId);
                    } else {
                        sessionStorage.removeItem("detailsLandingPage");
                        this.sortMemberships();
                        this.spinner.hide();
                        this.isLoading = false;
                    }
                },
                (err) => {
                    this.spinner.hide();
                    this.isLoading = false;
                    this.alertMessage(err.error.message);
                }
            )
        }
    }

    ionViewDidEnter() {
        this.customerSession.mobileMode.subscribe((data) => {
            console.log("DASHBOARD COMPONENT SUBSCRIPTION FOR MOBILE MODE: " + data);
            if (data) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        });
        
        if (this.membershipRouting.redirectToList) {
            sessionStorage.removeItem("redirectedToList");

            var message: string = "";
            var membershipError: any = JSON.parse(this.membershipRouting.membershipError);
            sessionStorage.removeItem("membershipError");
            
            if (membershipError.type == "NOTFOUND") {
                message = "The selected membership is unavailable."
            } else {
                message = membershipError.message;
            }
            this.alertMessage(message);
        }
    }

    doRefresh(event) {
        this.loadData().subscribe(
            (data) => {
                this.memberships = data.memberships;  
                this.customerMemberships = [];
                this.availableMemberships = [];
                this.sortMemberships();            
                event ? event.target.complete() : "";
            },
            (err) => {
                event ? event.target.complete() : "";
                this.alertMessage(err.error.message);
            }
        )
    }

    loadData() {
        return this.dataService.getMemberships();
    }

    sortMemberships() {
        if (this.memberships.length > 0) {
            this.memberships.forEach(membership => {
                if (membership.isCustomerMembership) {
                    if (membership.customerMembership.status == "ACTIVE" && membership.customerMembership.nextRenewalDate != null) {
                        membership.hasRenewalDate = true;
                    } else if (membership.customerMembership.status == "CANCELLED" && membership.customerMembership.validUntilDate != null) {
                        membership.hasValidUntilDate = true;
                    } else {
                        membership.hasRenewalDate = false;
                        membership.hasValidUntilDate = false;
                    }

                    this.customerMemberships.push(membership);
                } else if (!this.isMobile || (this.isMobile && !membership.isHiddenOnMobileApp)) {
                    this.availableMemberships.push(membership);
                }
            });

            this.showAvailableMemberships = !this.purchasedEqualToAll();
        }
    }

    purchasedEqualToAll(): boolean {
        // checks to see if all memberships returned were customer memberships
        if (this.memberships.length === this.customerMemberships.length) {
            return this.memberships.every(e => {
                if (this.customerMemberships.includes(e)) {
                    return true;
                }
                return false;
            });
        }
        return false;
    }

    membershipStatusColor(status): object {
        var color: any;
        switch (status) {
            case "ACTIVE":
            case "AUTOMATIC":
               color = {"color": "#22C55E"};
               break;
            case "CANCELLED":
                color = {"color": "#F59E0B"};
                break;
            case "SUSPENDED":
                color = {"color": "#EF4444"};
                break;
        }
        return color;
    } 

    selectMembership(membership) {
        this.membershipRouting.goToMembershipDetails(membership.membershipId);
    }

    async alertMessage(message) {
        const alert = await this.alertController.create({
            header: 'Oops',
            message: message,
            buttons: ['OK']
        });
        await alert.present();
    }

    back() {
        this.location.back();
    }
}
