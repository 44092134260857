
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { OrderRoutingService } from '../order-routing.service';
import { CustomerSessionService } from '../customer-session.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class AuthGuardService implements CanActivate {
  
  constructor(public auth: AuthService, public router: Router, private customerSession: CustomerSessionService, public route: ActivatedRoute) {}
  
  getParamValueQueryString( paramName ) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  canActivate(): Observable<boolean> {
    //wait for token on startup
    return this.auth.isAuthenticated
        .pipe( 
            filter(authenticated => authenticated != null),//ignore initial value
            tap((authenticated) => { 
              if(authenticated === false) {
                this.router.navigate(['unauthorized']);
              } else {
                //if we have authenticated on the app then we should now start going through WO
                //TODO: redirect to dashboardcomponent route
                //get mguid stored by auth result
                let mguid = sessionStorage.getItem('mguid');
                if (mguid){
                  sessionStorage.setItem('is_mobile', 'true');
                  this.customerSession.mobileMode.next(true);
                  //to not get confused lets consume the mguid
                  sessionStorage.removeItem('mguid');

                  // check the query string for a 'subpage' to navigate to, hidden under the p= query param
                  let subpage = this.getParamValueQueryString('p') || 'merchant';
                  // if subpage was passed, navigate to it
                  console.log(`Navigating to ${subpage}`);
                  this.router.navigate([subpage, mguid]);
                } else {
                  console.log('ERROR: no mguid');
                  //do error?
                }
              }
            })        
        )
  }
}