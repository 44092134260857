import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { OrderRoutingService } from './order-routing.service';
import { PaymentMode } from './models/payment-mode';
import { CustomerDetails } from './models/customer-details.model';
import { CatalogService } from './catalog.service';
import { MembershipRoutingService } from './membership-routing.service';
import { MembershipPurchasePayment } from './models/membership-purchase-payment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class CustomerSessionService {
    mobileMode = new BehaviorSubject<boolean>(false);
    locations = [];
    paymentMode = PaymentMode.NONE;

    membershipModule: boolean = false;

    constructor(
        private dataService: DataService, 
        public router: Router, 
        private orderRouting: OrderRoutingService,
        private membershipRouting: MembershipRoutingService,
        private translate: TranslateService) { }

    hasLocation(): Observable<boolean> {
        return this.dataService.getCatalogLocations()
            .pipe(
                tap((locations) => {
                    this.locations = locations;
                    let selectedLocation = null;
                    let targetLocationId = sessionStorage.getItem('target_location_id');  

                    if (targetLocationId) {                        
                        let targetedLocation = locations.find((location) => location.merchantLocationId === +targetLocationId);
                        if (targetedLocation) {
                            selectedLocation = targetedLocation;
                        }
                        sessionStorage.removeItem('target_location_id');
                    }
                    if (selectedLocation == null && locations.length == 1) {
                        selectedLocation = locations[0];
                    }
                    else if (selectedLocation == null && locations.length > 1) {
                        try {
                            selectedLocation = this.getLastLocation(locations);
                        } catch (error) {
                            console.log(error);
                        }                       
                    }
                    this.locationId = selectedLocation == null ? null : selectedLocation.merchantLocationId;//save location to storage   
                }),
                map(() => {
                    //prompt user to select location
                    if (this.locationId == 0 || this.locationId == null) {
                        return false;
                    }
                    else {
                        return true;
                    }
                })
            );
    }

    private getLastLocation(locations) {
        //customer hasnt chosen location
        if (this.locationId == 0 || this.locationId == null)
            return null;

        let storedLocation = locations.find((location) => location.merchantLocationId === this.locationId);
        //clear stored location if no longer there
        if (storedLocation == null) {
            this.locationId = null;
        }
        let locationPhone = storedLocation.businessDetails.phone == null ? "" : storedLocation.businessDetails.phone;
        sessionStorage.setItem("locationPhone", locationPhone.toString());
        return storedLocation;
    }

    get locationId(): number {
        return Number(sessionStorage.getItem("locationId"));
    }

    set locationId(locationId: number) {
        if (locationId) {
            sessionStorage.setItem("locationId", locationId.toString());
        }
        else {
            sessionStorage.removeItem("locationId");
        }
    }

    get customerId(): number {
        return Number(sessionStorage.getItem("customerId"))
    }

    set customerId(customerId: number) {
        //check if customer switched
        if (this.customerId != null && this.customerId !== customerId && this.customerId !== 0) {
            console.log('customer has changed. clearing storage.');

            let hostOrigin = sessionStorage.getItem("hostingapp")
            sessionStorage.clear();
            //re-add hosting app so we can post message to host
            sessionStorage.setItem("hostingapp", hostOrigin);
        }
        sessionStorage.setItem("customerId", customerId.toString());
    }

    setStripePaymentKey(stripePaymentKey) {
        sessionStorage.setItem("stripePaymentKey", stripePaymentKey);
    }

    getStripePaymentKey() {
        let stripePaymentKey = sessionStorage.getItem('stripePaymentKey');

        if (stripePaymentKey) {
            return stripePaymentKey;
        } else {
            return '';
        }
    }

    setPaymentSetupInfo(paymentSetupInfo) {
        sessionStorage.setItem("paymentSetUpInfo", JSON.stringify(paymentSetupInfo));
    }

    async getNMIToken() {
        var data = await this.getPaymentSetupInfo();
        if (data && data.nmiTokenId) {
            return data.nmiTokenId;
        }
    }

    removePaymentSetupInfo(){
        sessionStorage.removeItem('paymentSetUpInfo');
    }

    getPaymentSetupInfo() {
        let paymentSetUpInfo = sessionStorage.getItem('paymentSetUpInfo');
        // if (!paymentSetUpInfo) {
        //     // console.log("waiting to get data for payment info for location"); 
        //     await this.getPaymentInfoForLocation();
        //     // console.log("got data for payment info for location"); 
        // }
        
        // // console.log("we had data or we might have gotten data");
        // //check if data is there now and use it else return null
        // paymentSetUpInfo = sessionStorage.getItem('paymentSetUpInfo');
        if (paymentSetUpInfo){
            let paymentSetUpInfoObj = JSON.parse(paymentSetUpInfo);
            return paymentSetUpInfoObj;
        }
        
        return null;
        
    }

    async getPaymentProvider(membershipModule: boolean = false) {
        let paymentSetup = this.getPaymentSetupInfo()
        if (paymentSetup) {
            if (!membershipModule && paymentSetup.paymentProvider) {
                return paymentSetup.paymentProvider;
            } else if (membershipModule && paymentSetup.integrationType) {
                return paymentSetup.integrationType;
            }
        }
        return null;
    }

    async getStoredCardEnabledSettings() {
        let paymentSetup = this.getPaymentSetupInfo()
        if (paymentSetup) {
            if (paymentSetup.storedCardEnabled != null || paymentSetup.storedCardEnabled != undefined) {
                return paymentSetup.storedCardEnabled;
            }
        }
        return true;
    }

    async navigateToPaymentPage(membershipId: number = null) {
        this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;
        //get latest integration setting before navigating
        let paymentSetup = null;
        if (this.membershipModule == false) {
            await this.getIntegrationSettings();
        }
        else {
            await this.getMembershipPaymentSetup(membershipId);
        }
        paymentSetup = this.getPaymentSetupInfo();
        console.log("Payment Setup from sessionStorage is: ", paymentSetup);
        if (paymentSetup) {
            if (this.membershipModule) {
                if (paymentSetup.integrationType == "CLOVER") {
                    this.membershipRouting.goToCloverPaymentForm();
                } else if (paymentSetup.integrationType == "TOAST") {
                    this.membershipRouting.goToPaymentCheckoutForm();
                } else if (paymentSetup.integrationType == "SQUAREPAYMENTS") {
                    this.membershipRouting.goToSquarePaymentForm();
                } else if (paymentSetup.integrationType == "STRIPEPAYMENTS") {
                    this.membershipRouting.goToStripePaymentForm();
                }
            } else {
                if (paymentSetup.paymentProvider == "Square") {
                    this.orderRouting.goToSquarePaymentForm();
                } else if (paymentSetup.paymentProvider == "Stripe") {
                    this.orderRouting.goToStripePaymentForm();
                } else if (paymentSetup.paymentProvider == "NMI") {
                    this.orderRouting.goToNMIPaymentForm();
                } else if (paymentSetup.paymentProvider == "Clover") {
                    this.orderRouting.goToPaymentCheckoutForm();
                } else if (paymentSetup.paymentProvider == "CloverEcom") {
                    this.orderRouting.goToCloverPaymentForm();
                } else if (paymentSetup.paymentProvider == "Toast") {
                    this.orderRouting.goToPaymentCheckoutForm();
                }
            }
        } else {
            alert(this.translate.instant('Error - Payment Form Not Available, Please Try Again Later'));
            if (this.membershipModule == false) {
                await this.getIntegrationSettings();
            }
            else {
                await this.getMembershipPaymentSetup(membershipId);
            }
        }
    }

    async getIntegrationSettings() {
        await this.dataService.getIntegrationSettings(this.locationId, this.paymentMode).toPromise().then(async (result) => {
            console.log("Result from Integration Call is: ", result);
            //set paymentSetupInfo
            if (result.isSuccess == true && result.data.paymentSetup) {
                this.setPaymentSetupInfo(result.data.paymentSetup);                
            } else {
                //alert('Error - Payment Form Not Available, Please Try Again Later');
            }
        },
        (err) => {
            console.log(err);
            //alert('Error - Payment Form Not Available, Please Try Again Later');
        });
    }

    async getMembershipPaymentSetup(membershipId) {
        await this.dataService.getPaymentSetup(membershipId).toPromise().then(
            async (result) => {
                console.log("Result from get payment setup call is: ", result);
                this.setPaymentSetupInfo(result);
            },
            (err) => {
                console.log(err);
            }
        );
    }

    isMobile() {
        let isMobile = (sessionStorage.getItem('is_mobile') == 'true') ? true : false;
        return (isMobile);
    }

    setCustomerDetails(customerDetails: CustomerDetails) {
        sessionStorage.setItem("customerDetails", JSON.stringify(customerDetails));
    }

    getCustomerDetails(): CustomerDetails {
        let customerDetails = sessionStorage.getItem('customerDetails');
        if (customerDetails) {
            let customerDetailsObj = <CustomerDetails>JSON.parse(customerDetails);
            return customerDetailsObj;
        }
        return null;
    }
}

