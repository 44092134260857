import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { DecimalPipe, Location } from '@angular/common';
import { CustomerSessionService } from '../customer-session.service';
import { GoogleAnalyticService } from '../google-analytic.service';
import { LoadingController } from '@ionic/angular';
import { CartService } from '../cart.service';
import { CatalogService } from '../catalog.service';
import { CategoryTrackedService } from '../common/category-tracked.service';
import { OrderRoutingService } from '../order-routing.service';
import { PaymentService } from '../payment.service';
import { MembershipPaymentService } from '../membership-payment.service';
import { MembershipRoutingService } from '../membership-routing.service';
import { saveCustomerCardRequest } from '../models/save-customer-card';
import { ErrorHandlerService } from '../error-handler.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-square-payment',
    templateUrl: './square-payment.component.html',
    styleUrls: ['./square-payment.component.scss']
})
export class SquarePaymentComponent implements OnInit {
    SqPaymentForm: any;
    paymentForm: any;
    card: any;
    payments: any;

    cardHolderName = "";
    saveCreditCard: boolean = false;
    Square3DSEnabled: boolean = false;

    address = "";
    city = "";
    state = "";
    countryCode: any;

    countries: any;

    buttonText: string;

    customerValidated: boolean = false;
    membershipModule: boolean = false;
    showPaymentConfirmation: boolean = false;
    isGuest: boolean = false;

    constructor(private dataService: DataService,
        private location: Location,
        private customerSession: CustomerSessionService,
        private ga: GoogleAnalyticService,
        private loadingController: LoadingController,
        private cartService: CartService,
        private catalogService: CatalogService,
        private categorytrackedService: CategoryTrackedService,
        private orderRouting: OrderRoutingService,
        private decimalPipe: DecimalPipe,
        private paymentService: PaymentService,
        private membershipPaymentService: MembershipPaymentService,
        private errorHandlerService: ErrorHandlerService,
        private membershipRouting: MembershipRoutingService,
        private translate: TranslateService) { }

    ngOnInit() { }

    async ionViewWillEnter() {
        this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;
        this.isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;
        let paymentSetup = await this.customerSession.getPaymentSetupInfo();
        let squareApplicationId = '';
        let squareLocationId = '';

        if (paymentSetup) {
            squareApplicationId = paymentSetup.squareApplicationId;
            squareLocationId = paymentSetup.squareLocationId;
            this.Square3DSEnabled = paymentSetup.square3DSEnabled;
        }

        if (squareApplicationId == '' && squareLocationId == '') {
            alert(this.translate.instant('Sorry! Looks like we are experiencing some difficulty with our payment form. Please try again later.'));
            this.back();
        }

        if (this.membershipModule) {
            this.buttonText = this.membershipPaymentService.getPaymentBtnText();
            this.saveCreditCard = true;
            this.customerValidated = true;
        } else {
            this.buttonText = this.paymentService.getActionText();
            this.customerValidated = paymentSetup.storedCardEnabled;
        }

        this.dataService.getCountries().subscribe((result) => {
            console.log(result);
            if (result.isSuccess) {
                this.countries = result.data.countries;
                console.log(this.countries);
            } else {
                this.countries = null;
            }
        });

        this.init(squareApplicationId, squareLocationId);
    }

    async requestCardNonce() {
        if (!this.cardHolderName.replace(/\s/g, '').length) {
            alert(this.translate.instant("Card Holder Name is Required"));
            return;
        }

        const processingOrder = await this.loadingController.create({
            message: this.translate.instant('Processing...')
        });
        await processingOrder.present();

        console.log("save cc is: " + this.saveCreditCard);

        const result = await this.card.tokenize({
            accountHolderName: this.cardHolderName
        });
        if (result.status === 'OK') {

            if (this.membershipModule) {
                console.log('about to submit membership with name: ' + this.cardHolderName);
                this.membershipPaymentService.paymentDetails.Token = result.token;
                this.membershipPaymentService.paymentDetails.CardHolderName = this.cardHolderName;
                processingOrder.dismiss();
                this.processMembershipPayment();
            } else {
                if (this.Square3DSEnabled == true) {
                    if (this.saveCreditCard) {
                        processingOrder.dismiss();
                        let pgCardId: string = await this.saveCardAndGetId(result.token);
                        if (!pgCardId || pgCardId.trim() == "") {                            
                            return;
                        }

                        await processingOrder.present();
                        this.paymentService.request.tempNotes = pgCardId;
                        this.paymentService.request.squareVerificationToken = await this.verifyBuyer(pgCardId, this.paymentService.request.amount); 
                        this.paymentService.request.tempNotes += " " + this.paymentService.request.squareVerificationToken + " " + pgCardId;
                        this.paymentService.request.payWithSavedCreditCard = true;

                        let vrfction = this.paymentService.request.squareVerificationToken;
                        if (vrfction == null || vrfction == undefined || vrfction == "") {
                            processingOrder.dismiss();
                            this.errorHandlerService.alertMessage(this.translate.instant("Card verification failed."));
                            return;
                        }
                    }
                    else {
                        this.paymentService.request.token = result.token;
                        this.paymentService.request.tempNotes = result.token;
                        this.paymentService.request.squareVerificationToken = await this.verifyBuyer(result.token, this.paymentService.request.amount);
                        this.paymentService.request.tempNotes += " " + this.paymentService.request.squareVerificationToken + " " + result.token;
                        this.paymentService.request.cardHolderName = this.cardHolderName;
                        this.paymentService.request.saveCreditCard = this.saveCreditCard;
                        this.paymentService.request.payWithSavedCreditCard = false;

                        let vrfction = this.paymentService.request.squareVerificationToken;
                        if (vrfction == null || vrfction == undefined || vrfction == "") {
                            processingOrder.dismiss();
                            this.errorHandlerService.alertMessage(this.translate.instant("Card verification failed."));
                            return;
                        }
                    }
                }
                else {
                    this.paymentService.request.token = result.token;
                    this.paymentService.request.cardHolderName = this.cardHolderName;
                    this.paymentService.request.saveCreditCard = this.saveCreditCard;
                    this.paymentService.request.payWithSavedCreditCard = false;
                }

                console.log('about to submit payment with name: ' + this.cardHolderName);
                processingOrder.dismiss();
                this.paymentService.processPayment();
            }
        }
        else {
            processingOrder.dismiss();
            alert(this.translate.instant("Ooops! Something went wrong. Please try again."));
        }
    }

    async saveCardAndGetId(token) {
        let cardId: string;
        let request: saveCustomerCardRequest = new saveCustomerCardRequest();
        request.token = token,
        request.cardHolderName = this.cardHolderName;

        const savingCard = await this.loadingController.create({
            message: this.translate.instant('Processing...')
        });
        await savingCard.present();

        await this.dataService.saveCustomerCard(request).toPromise().then((result) => {
            console.log(result);
            if (result.isSuccess) {
                cardId = result.cardId;
                savingCard.dismiss();
            } else {
                savingCard.dismiss();
                if (result.errors.length > 0) {
                    console.log(result.errors[0]);
                    this.errorHandlerService.alertMessage(result.errors[0]);
                }
                else {
                    console.log(result);
                    this.errorHandlerService.alertMessage(result.message);
                }
            }
            if (!cardId || cardId.trim() == "") {
                this.errorHandlerService.alertMessage(this.translate.instant("Unable to save the card. Please try again."));
            }
        }, async (err) => {
            console.log(err);
            savingCard.dismiss();
            if (err.redirectTo) {
                await this.errorHandlerService.alertMessage(err.message);
                await this.orderRouting.redirectOnError(err.redirectTo);
            }
            else {
                this.errorHandlerService.alertMessage(this.translate.instant("Unable to save the card. Please try again."));
            }
        });
        return cardId;
    }

    async processMembershipPayment() {
        await this.membershipPaymentService.processPayment()
            .then((result) => {
                if(result) {
                    this.showPaymentConfirmation = true;
                }
            })
            .catch(error => {
                console.log(error);
            }); 
    }

    saveCreditCardChecked(event) {
        if (event.detail.checked) {
            this.saveCreditCard = true;
        } else {
            this.saveCreditCard = false;
        }
    }

    
    async init(squareApplicationId, squareLocationId) {
        this.initPayments(squareApplicationId, squareLocationId);
        this.card = await this.payments.card();
        await this.card.attach('#card-container');
    }

    initPayments(squareApplicationId, squareLocationId) {
        console.log('Init Square Payment Form: ApplicationId=' + squareApplicationId + ' LocationId=' + squareLocationId);
        this.payments = (<any>window).Square.payments(squareApplicationId, squareLocationId);
    }

    async verifyBuyer(tokenOrCardId, amount) {
        const verificationDetails = {
            amount: this.decimalPipe.transform(amount, '1.2-2'),            
            currencyCode: sessionStorage.getItem("currencyCode"),
            intent: 'CHARGE',
            billingContact: {}
        };

        const verificationResults = await this.payments.verifyBuyer(
            tokenOrCardId,
            verificationDetails
        );
        return verificationResults.token;
    }

    back() {
        this.location.back();
    }

    handleError(err) {
        let errorMsg: string;
        console.log(err);
        alert(err.message);
    }

    continue() {
        this.membershipRouting.goToMembershipList();
    }

}
