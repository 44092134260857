import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-load-wallet',
  templateUrl: './load-wallet.component.html',
  styleUrls: ['./load-wallet.component.scss']
})
export class LoadWalletComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
