import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { CategoryTrackedService } from '../category-tracked.service';
import { BreadCrumb } from '../breadcrumb';
import { OrderRoutingService } from 'src/app/order-routing.service';
import { PropertyReader } from '../property-reader';

@Component({
    selector: 'app-sub-category-view',
    templateUrl: './sub-category-view.component.html',
    styleUrls: ['./sub-category-view.component.scss']
})
export class SubCategoryViewComponent implements OnInit, PropertyReader, OnChanges {
    
    @Input() selectedCategory: any;
    @Input() catalogHasImage: boolean = false;
    @Input() currencySymbol: any;
    @Input() placeHolderImageUrl: string;
    @Output() OpenCategory = new EventEmitter<any>();
    
    
    breadcrumb: BreadCrumb[];
    
    constructor(private categoryTrackedService: CategoryTrackedService,
        private orderRouting: OrderRoutingService,
        private ref: ChangeDetectorRef) {
        categoryTrackedService.registerSubCatComponent(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(this.selectedCategory);
        this.ref.detectChanges();
        this.selectedCategory = this.selectedCategory;
    }

    ngOnInit() { }

    openCategory(cat) {
        this.OpenCategory.emit(cat);
    }

    addItem(item) {
        this.categoryTrackedService.addItem(item);
    }

    // Interface member needs to be implemented
    public readProperty<T>(prop: string): T {
        if (!this.hasOwnProperty(prop)) {
            throw Error(`Property "${prop}" does not exists`);
        }
        return this[prop];
    }

    public setProperty<T>(prop: string, val: number) {
        if (!this.hasOwnProperty(prop)) {
            throw Error(`Property "${prop}" does not exists`);
        }
    }

    public callOpenCategory(val: any) {
        this.openCategory(val);
    }

    public callResetCart() {

    }

    public callAddItem(val: any) {
        this.addItem(val);
    }

    shrinkText(text: any) {
        let str = '';
        if (text && text.length > 55) {
           str =  text.substring(0, 55);
           str = str + '....';
           return str;
        }       
        else{
            return text;
        }        
    }
}
