// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  apiUrl :"https://qa2.order-api.tapmango.com/api",
  googleAnalyticsTrackingID: "UA-47238161-5",
  recaptchaSiteKey: "6LcN4FwiAAAAAK0o0FKVogrnejx2X-mc1pvNbuTu", // recommend generating a new site key for QA - same one currently being used for QA and PROD
  PORTAL_END_POINT: "https://qa2.portal.tapmango.com",
  FETCH_APPLE_PAY_SESSION: "https://qa2.order-api.tapmango.com/api/applepay/getapplepaysession",
  POST_LOG_SLACK_MESSAGE: "https://qa2.order-api.tapmango.com/api/applepay/log-message",
  SQUARE_WALLET_PAY_END_POINT: "https://qa2.order-checkout-square.tapmango.com",
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
