import { Component } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { MembershipRoutingService } from 'src/app/membership-routing.service';
import { CustomerSessionService } from 'src/app/customer-session.service';
import { MembershipPaymentService } from 'src/app/membership-payment.service';
import { privacyPolicy } from 'src/app/modal-content-helper';
import { terms } from 'src/app/modal-content-helper';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'membership-checkout',
  templateUrl: './membership-checkout.component.html',
  styleUrls: ['./membership-checkout.component.scss']
})
export class MembershipCheckoutComponent {
  membership: any;

  locationId: number = 0;
  hasCard: boolean = false;
  cardType: string;
  cardLast4: string;
  cardImgSrc: string;

  merchantName: string = "";
  isLoading: boolean;

  privacyPolicy: any;
  showPrivacyPolicy: boolean = false;
  terms: any;
  showTerms: boolean = false;

  approvedAge: number = 18;

  showPaymentConfirmation: boolean = false;

  constructor(
    private membershipRouting: MembershipRoutingService,
    private dataService: DataService,
    private customerSession: CustomerSessionService,
    private membershipPaymentService: MembershipPaymentService,
    private alertController: AlertController
  ) { }

  ionViewWillEnter() {
    // HOTFIX TMDEV-8784 && TMDEV-10623 - set approved age for legal disclaimer to 21
    var guid: string = sessionStorage.getItem("guid").toLocaleLowerCase()
    if (guid == "38ec7e38-3fff-4dca-8e65-11e518e0885c" || guid == "9ae2ca57-f58b-4c30-81e6-f4dcc7ccad55") {
      this.approvedAge = 21
    }
    
    this.merchantName = sessionStorage.getItem("merchantName");
    this.isLoading = true;
    this.membership = this.membershipRouting.getMembership();
    console.log(this.membership);
    if (this.membership.paymentIntegrationId && this.membership.paymentIntegrationId != null && this.membership.paymentIntegrationId > 0) {
      this.getStoredCard();
    }
    this.isLoading = false;
  }

  getStoredCard() {
    this.dataService.getCardByIntegrationId(this.membership.paymentIntegrationId).subscribe( 
      (result) => {
        console.log("data received from getCardByIntegrationId", result);
        if (result.isSuccess) {
          if (result.data.cardIsPopulated) {
            this.cardType = result.data.cardType;
            this.cardLast4 = result.data.cardLast4;
            this.hasCard = true;
            let imgFile = "";
            if (this.cardType.toUpperCase() === "VISA") imgFile = "visa.png";
            else if (this.cardType.toUpperCase() === "MASTERCARD")
              imgFile = "mastercard.png";
            else if (this.cardType.toUpperCase() === "AMERICAN_EXPRESS")
              imgFile = "american-express.png";
            else imgFile = "payment-card.png";

            this.cardImgSrc = `/assets/imgs/${imgFile}`;
          } else {
            this.hasCard = false;
          }
        }
      },
      (err) => {
        console.log("get card -- error is: ", err);
      }
    );
  }

  async removeCard() {
    await this.membershipPaymentService.removeCard(this.membership.paymentIntegrationId).then((result) => {
        if (result) {
            this.hasCard = false;
            this.ionViewWillEnter();
        }
    });
  }

  async loadPrivacy() {
    this.privacyPolicy = privacyPolicy;  
    await this.dataService.getMerchantAmendments("privacy").subscribe((result) => {
      if (result && result != null && result.length > 0) {
        this.addAmendments(result, "privacy");
      }
      this.showPrivacyPolicy = true;
    }, 
    (err) => {
      console.log("Error getting privacy policy amendments => " + err.error.message);
      this.showPrivacyPolicy = true;
    });       
  }

  async loadTerms() {
    this.terms = terms;
    await this.dataService.getMerchantAmendments("terms").subscribe((result) => {
      if (result && result != null && result.length > 0) {
        this.addAmendments(result, "terms");
      }
      this.showTerms = true;
    },
    (err) => {
      console.log("Error getting terms and conditions amendments => " + err.error.message);
      this.showTerms = true;
    })
  }

  addAmendments(amendments, type) {
    //console.log(amendments);
    var count = type == "privacy" ? 13 : 8;
    amendments.forEach(item => {
        var title: any = `<h3>` + count + `. ` + item.title + `</h3>`;
        var content: any = `<p>` + item.content + `</p>`;

        if (type == "privacy") {
          this.privacyPolicy = this.privacyPolicy.concat(title.toString(), content.toString());
        }
        else {
          this.terms = this.terms.concat(title.toString(), content.toString());
        }
        count++;
    });

    if (type == "privacy") {
      this.privacyPolicy.concat(`<br><br>`);
    }
    else {
      this.terms.concat(`<br><br>`);
    }
  }
  
  purchaseMembership() {
    this.membershipPaymentService.membership = this.membership;
    this.membershipPaymentService.paymentDetails.Amount = this.membership.total;
    this.membershipPaymentService.paymentDetails.PayWithSavedCreditCard = this.hasCard;
    
    if (this.hasCard){
      this.processMembershipPayment();
    } else {
      this.customerSession.navigateToPaymentPage(this.membership.membershipId);
    }
  }

  async processMembershipPayment() {
    await this.membershipPaymentService.processPayment()
      .then((result) => {
          if(result) {
              this.showPaymentConfirmation = true;
          }
      })
      .catch(error => {
          console.log(error);
      }); 
  }

  continue() {
    this.membershipRouting.goToMembershipList();
  }

  async alertMessage(message) {
    const alert = await this.alertController.create({
        header: 'Oops',
        message: message,
        buttons: ['OK']
    });
    await alert.present();
}

  back() {
    if (this.showPrivacyPolicy) {
      this.showPrivacyPolicy = false;
    } else if (this.showTerms) {
      this.showTerms = false;
    } else {
      this.membershipRouting.goToMembershipDetails(this.membership.membershipId);
    }
  }

}
