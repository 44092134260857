import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { CartService } from '../cart.service';
import { OrderRoutingService } from '../order-routing.service';

@Component({
  selector: 'app-rewards-modal',
  templateUrl: './rewards-modal.component.html',
  styleUrls: ['./rewards-modal.component.scss']
})
export class RewardsModalComponent implements OnInit, AfterViewInit {

  availableRedeemables: any[] = [];
  unavailableRedeemables: any[] = [];
  selectedRedeemable: any;
  selectedCode: any;

  constructor(private popoverContrl: PopoverController, 
              private cartService: CartService, 
              private orderRouting: OrderRoutingService) { }

  async ionViewWillEnter() 
  {
    this.availableRedeemables = [];
    this.unavailableRedeemables = [];

    if (this.cartService.order.redeemables) {
      this.availableRedeemables = this.cartService.order.redeemables.filter(x => x.isAvailable === true);  
      this.unavailableRedeemables = this.cartService.order.redeemables.filter(x => x.isAvailable === false);  
    }
    
    
    if(this.cartService.getAppliedRedeemable()){
      this.selectedRedeemable = this.availableRedeemables.filter(x => x.code === this.cartService.getAppliedRedeemable());
      if ( this.selectedRedeemable && this.selectedRedeemable.length > 0) {
        this.selectedCode = this.selectedRedeemable[0].code;
      }      
    }

    let showRewardsPopup = sessionStorage.getItem("show_rewards_popup")
    if (this.cartService.cart.redeemableCode && !showRewardsPopup || this.cartService.cart.items.length == 0) {
      this.closeRewardsModal();
    }     
  }
  
  ngOnInit() {
  }

  ngAfterViewInit(): void {
     
  }


  applyRedeemable(obj: any){
    this.selectedRedeemable = obj;
    if(this.selectedRedeemable == null) return;
    this.cartService.applyRedeemable(this.selectedRedeemable.code);
    this.closeRewardsModal();
    this.reLoad();
  }

  reLoad(){
    this.orderRouting.goToCartRouter();
  }

  applyNotToday() 
  {
    
  }

  async closeRewardsModal() 
  {
   await this.popoverContrl.dismiss();
  }

  async presentPopover(val: any) {
    const popover = await this.popoverContrl.create({
      component: RewardsModalComponent,
      cssClass: 'rewards-ui-dialog',
      translucent: true
    }); 
    if (val && val == 1) {
      sessionStorage.setItem("show_rewards_popup", "true")
    }
    else
    {
      sessionStorage.removeItem("show_rewards_popup")
    }
    return await popover.present();
  }

}
