import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderRoutingService } from '../order-routing.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { AlertPopupComponent } from '../common/alert-popup/alert-popup.component';
import { AssetService } from '../merchant-assets/asset.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MembershipRoutingService } from '../membership-routing.service';
import { UtilService } from '../util.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-register-keytag',
    templateUrl: './register-keytag.component.html',
    styleUrls: ['./register-keytag.component.scss']
})
export class RegisterKeytagComponent implements OnInit {

    registerKeyTagForm: FormGroup;
    keyTag: FormControl;
    name: FormControl;
    email: FormControl;
    password: FormControl;
    cpassword: FormControl;
    phone: FormControl;
    agreeToCommunication: FormControl;
    agreeToTerms: FormControl;
    isUnloading = false;
    CURRENT_VIEW_ENTERED = "register-keytag";
    merchantGuid: string;
    membershipModule: boolean = false;

    siteKey: string = environment.recaptchaSiteKey;

    @ViewChild('alertPopup') alertPopup: AlertPopupComponent;

    constructor(private orderRouting: OrderRoutingService,
        private authService: AuthService,
        private assetService: AssetService, private spinner: NgxSpinnerService,
        private fb: FormBuilder,
        private membershipRouting: MembershipRoutingService,
        private utilService: UtilService,
        private translate: TranslateService) { }

    
    async ionViewWillEnter() 
    {
        this.membershipModule = sessionStorage.getItem('memberships_module') == "true" ? true : false;
        if (this.membershipModule) {
            this.merchantGuid = this.membershipRouting.guid;
        } else {
            this.merchantGuid = this.orderRouting.guid;
        }

      this.isUnloading = false;
      /* LOAD CUSTOM STYLE IF PROVIDED - <param - component name> */     
      if (sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) && sessionStorage.getItem(this.CURRENT_VIEW_ENTERED) !== "") {
        this.assetService.getCustomCSS(sessionStorage.getItem(this.CURRENT_VIEW_ENTERED), this.CURRENT_VIEW_ENTERED);
      }       
    }
    
    async ionViewWillLeave() 
    {    
      this.spinner.show(); 
      this.isUnloading = true;    
      const clearCustomCss = () => new Promise<boolean>(async (resolve, reject) => { $("#" + this.CURRENT_VIEW_ENTERED + "_CUSTOM_CSS").remove(); return resolve(true)});  
      await clearCustomCss().then((result) => console.log("CLEARED CUSTOM CSS FOR " + this.CURRENT_VIEW_ENTERED)).then(() => {
        setTimeout(() => {
          this.spinner.hide();
        }, 1000); 
      });      
    }    

    ngOnInit() {
        this.initializeFormSettings();
    }

    initializeFormSettings() {
        this.keyTag = new FormControl('', [Validators.required]);
        this.name = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(2)]);
        this.email = new FormControl('', [Validators.required, Validators.email]);
        this.password = new FormControl('', [Validators.required, Validators.maxLength(15), Validators.minLength(5)]);
        this.cpassword = new FormControl('', [Validators.required, this.authService.MustMatch(this.password)]);
        this.phone = new FormControl('', [Validators.required, this.utilService.validatePhoneNumber]);
        this.agreeToTerms = new FormControl('false', [Validators.requiredTrue]);
        this.agreeToCommunication = new FormControl('false', [Validators.requiredTrue]);

        this.registerKeyTagForm = this.fb.group({
            'keyTag': this.keyTag,
            'name': this.name,
            'email': this.email,
            'password': this.password,
            'cpassword': this.cpassword,
            'phone': this.phone,
            'agreeToTerms': this.agreeToTerms,
            'agreeToCommunication': this.agreeToCommunication
        });
    }

    back() {
        if (this.membershipModule) {
            this.membershipRouting.goToConnectInStore();
        } else {
            this.orderRouting.goToConnectInStore();
        }
    }

    async register() {

        let userDetails = this.registerKeyTagForm.value;

        if (this.registerKeyTagForm.controls['agreeToCommunication'].value == false) {
            $("#agreeToCommunicationlbl").addClass("text-danger");
            return;
        }

        if (this.registerKeyTagForm.controls['agreeToTerms'].value == false) {
            $("#agreeToTermslbl").addClass("text-danger");
            return;
        }

        // get action token
        const recaptchaAction = 'RegisterCustomerOOKeytag'
        const token = await (<any>window).grecaptcha.enterprise.execute(this.siteKey, {action: recaptchaAction});

        var createCustomerRequest = {
            name: userDetails.name,
            email: userDetails.email,
            password: userDetails.password,
            phone: userDetails.phone,
            merchantLocationId: userDetails.merchantLocationId,
            tagNumber: userDetails.keyTag,
            recaptchaToken: token,
            recaptchaAction: recaptchaAction
        }
        console.log('register request - ', createCustomerRequest);

        this.authService.createCustomer(createCustomerRequest).subscribe((data) => {
            console.log(data);
            if (this.membershipModule) {
                this.membershipRouting.goBack();
            } else {
                this.orderRouting.goBack();
            }
        }, (err) => {
            console.log('got an error trying to register customer', err);
            if (err.status != 401) {
                this.alertPopup.show(this.translate.instant("Error!"), err.error.message);                
            }
        });
    }
}
